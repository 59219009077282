import React from 'react';
import UiMultiSelect from '../ui-multi-select/UiMultiSelect';
import { Select } from 'antd';
import { useGetConfigQuery } from '../../api/configApi';
import { useParams } from 'react-router-dom';

import './LabelMultiSelect.scss';

const { Option } = Select;

export type ILabelMultiSelectValue = any;

export interface ILabelMultiSelectProps {
    value: ILabelMultiSelectValue[];
    onChange: (event: ILabelMultiSelectValue) => any;
    label?: string;
    width?: number;
}

export const LabelMultiSelect: React.FC<ILabelMultiSelectProps> = (props: ILabelMultiSelectProps) => {
    const { activeOrg } = useParams() as { activeOrg: string };
    const { data, isSuccess } = useGetConfigQuery(activeOrg);

    return isSuccess ? (
        <div className="label-multi-select">
            <div className="lms-label">{props.label || 'Add Label'}</div>
            <UiMultiSelect
                mode="multiple"
                showDropdown={true}
                width={props.width}
                defaultValue={props.value}
                handleChange={(event: any) => props.onChange(event)}
            >
                {data!.labels!.map((label: string) => (
                    <Option key={label} value={label}>
                        {label}
                    </Option>
                ))}
            </UiMultiSelect>
        </div>
    ) : null;
};
