import humanizeDuration, { Unit } from 'humanize-duration';
import moment, { DurationInputArg2, Moment } from 'moment';
import React, { ReactElement } from 'react';
import { AxiosResponse } from 'axios';
import { isIP } from 'is-ip';
import isCidr from 'is-cidr';

import { ApiLabel } from 'components/api-label/ApiLabel';
import { IEndpointSelectOption, ISelectOptionGrp } from 'components/query-builder/query-row/query-row';
import { UiTooltip } from 'components/ui-tooltip/UiTooltip';
import { ActionType } from 'components/settings/components/automated-action-rule-action/AutomatedActionRuleAction';
import { AlertSeverityEnum, AlertStatusEnum } from 'containers/alerts/Alerts';
import { EventTypesEnum } from 'enums/eventTypes.enum';
import { IAlertResponse } from 'interfaces/alert.interface';
import { IEndpointDisplay, ISimpleEndpoint } from 'interfaces/endpoint.interface';
import { IAutomatedActionRuleAction, ISuppressionRulePredicate } from 'interfaces/throttle.interface';
import { checkFeature } from 'services/feature-toggle/feature-toggle-service';
import { httpPatch } from './http-service';
import { successMessage } from './toast-service';
import { getActiveOrg } from './auth-service';
import { IEntity } from 'interfaces/entity.interface';
import { IConfigResponse } from 'api/configApi';
import { TPresetName } from 'components/shared/UiChronoRangePicker/utils';
import { ITenant } from 'api/tenantListApi';
import { Detokenizable } from 'components/Detokenizable/Detokenizable';

/* Date/Time Utils */
export type TDatetimeRange = [moment.Moment, moment.Moment];
export type TDatetimeRangeOrPreset = TDatetimeRange | TPresetName;
export type TTimeRange = [string, string];

const SECONDS_IN_15_MINUTES = 900;

export function shiftDatetimeToUTC(datetime: moment.Moment): moment.Moment {
    const newDate = moment(datetime).utc();
    const day = parseInt(datetime.format('DD'));
    const utcDay = parseInt(newDate.format('DD'));
    const timeZoneOffset = new Date().getTimezoneOffset();

    const isUtcDay = day === utcDay;
    // True - UTC-X - NY, False - UTC+X - Israel
    const isPositiveTimeZone = timeZoneOffset > 0;

    // Gets the right day to query from BE
    return (isUtcDay && !isPositiveTimeZone) || (!isUtcDay && isPositiveTimeZone)
        ? datetime
        : moment(datetime).add(1, 'day');
}

export function shiftDatetimeRangeToUTC(range: TDatetimeRange): TDatetimeRange {
    if (range[1].diff(range[0], 'days') === 0) {
        return range;
    }
    return range.map(shiftDatetimeToUTC) as TDatetimeRange;
}

export function getTimeRangeQueryParams(timeRange: TDatetimeRange) {
    return `from_timestamp=${timeRange[0].format('X')}&to_timestamp=${timeRange[1].format('X')}`;
}

export enum TimeDisplayResEnum {
    MIN = 'MIN',
    SEC = 'SEC',
    MS = 'MS',
}

export const timeStringFormat = (timeStamp: string, resolution: TimeDisplayResEnum = TimeDisplayResEnum.MS): string => {
    const ms = getMillisecondsByTimeStamp(timeStamp);
    const seconds = getSecondsByTimeStamp(timeStamp);
    const hours = getHoursByTimeStamp(timeStamp);
    const minutes = getMinutesByTimeStamp(timeStamp);
    let val = `${addDigit(hours)}:${addDigit(minutes)}`;

    if (resolution === TimeDisplayResEnum.MS) {
        val += `:${addDigit(seconds)}.${addDigit(ms)}`;
    } else if (resolution === TimeDisplayResEnum.SEC) {
        val += `:${addDigit(seconds)}`;
    }

    return val;
};

export const dateStringFormat = (timeStamp: string): string => {
    return moment(timeStamp).locale('en').format('DD MMM YYYY');
};

export const dateTimeStringFormat = (timeStamp: number): string => {
    return moment.unix(timeStamp).locale('en').format('DD MMM');
};

export const dateTimeStringFormatMinute = (timeStamp: number): string => {
    return moment.unix(timeStamp).locale('en').format('HH:mm');
};

const addDigit = (value: number) => {
    return value < 10 ? '0' + value : value;
};

export const fullDateStringFormat = (timeStamp: string, milliseconds: boolean = true): string => {
    return `${dateStringFormat(timeStamp)} \n${timeStringFormat(
        timeStamp,
        milliseconds ? TimeDisplayResEnum.MS : TimeDisplayResEnum.SEC
    )}`;
};

export const getYearByTimeStamp = (timeStamp: string): number => {
    return new Date(timeStamp).getFullYear();
};

export const getMonthByTimeStamp = (timeStamp: string): number => {
    const month = new Date(timeStamp).getMonth();
    return month + 1;
};

export const getHoursByTimeStamp = (timeStamp: string): number => {
    return new Date(timeStamp).getHours();
};

export const getMinutesByTimeStamp = (timeStamp: string): number => {
    return new Date(timeStamp).getMinutes();
};

export const getSecondsByTimeStamp = (timeStamp: string): number => {
    return new Date(timeStamp).getSeconds();
};

export const getMillisecondsByTimeStamp = (timeStamp: string): number => {
    return new Date(timeStamp).getMilliseconds();
};

export const getDayByTimeStamp = (timeStamp: string): number => {
    const day = new Date(timeStamp);
    return day.getDate();
};

export const getDiffFromNowInDays = (timestamp: string) => {
    return moment().diff(moment(timestamp), 'days');
};

export const humanReadableTimeShowEmpty = (timestamp: string, daysLimit?: number) => {
    if (!timestamp) {
        return '-';
    }
    return convertTimestampToTimeAgo(timestamp, daysLimit);
};
export const convertTimestampToTimeAgo = (timestamp: string, daysLimit?: number) => {
    // configure the human Readable string units and thresholds and the rounding setting (1 = no rounding)
    // < 3 hour: x minutes ago (e.g., ‘75 minutes ago', '156 minutes ago’)
    // > 1 hour but < 3 days: x hours ago (e.g., ‘16 hours ago’, '71 hours ago')
    // > 3 days: x days ago (e.g., ‘8 days ago’, ‘35 days ago’)
    const unitConfig = [
        {
            upperLimit: Infinity,
            units: 'd', // more than 3 days
        },
        {
            upperLimit: 3 * 24 * 60 * 60 * 1000, // up to 3 days
            units: 'h',
        },
        {
            upperLimit: 3 * 60 * 60 * 1000, // up to 3 hours
            units: 'm',
        },
    ];

    const diff = moment().diff(timestamp);

    let requiredUnit = 'd';

    unitConfig.forEach((item: any) => {
        if (diff < item.upperLimit) {
            requiredUnit = item.units;
        }
    });

    const res = humanizeDuration(diff, { units: [requiredUnit as Unit] });
    const [amount, units] = res.split(' ');
    if (daysLimit && Number(amount) > daysLimit && requiredUnit === 'd') {
        return `> ${Math.floor((daysLimit % 365) / 30)} months`;
    }

    return `${Math.floor(Number(amount))} ${units} ago`;
};

export const formatDateTimeString = (date: Date) => {
    //formats 13/06/2021 15:22 => '20210613_1522'
    const yy = date.getFullYear();
    const mm = date.getMonth();
    const dd = date.getDate();
    const h = date.getHours();
    const m = date.getMinutes();
    return `${yy}${mm < 10 ? 0 : ''}${mm + 1}${dd < 10 ? 0 : ''}${dd}_${h}${m}`;
};

export const getDateRangeAround = (datetime: string, daysAround: number): { from: number; to: number } => {
    return {
        from: moment(datetime).subtract(daysAround, 'days').unix().valueOf(),
        to: Math.min(moment(datetime).add(daysAround, 'days').unix().valueOf(), moment().unix()),
    };
};

export const getTimestampQuery = (periodStr: string): { from_timestamp: string; to_timestamp: string } => {
    const periodMap = {
        m: 'minutes',
        h: 'hours',
        D: 'days',
        W: 'weeks',
        M: 'months',
        Y: 'years',
    } as { [key: string]: DurationInputArg2 };

    const period = periodStr.substr(-1);
    const amount = parseInt(periodStr.substr(0, periodStr.length - 1));

    return {
        from_timestamp: moment().subtract(amount, periodMap[period]).format('X'),
        to_timestamp: moment().format('X'),
    };
};

export const convertTo15MinuteInterval = (timestamp: number, side: 'start' | 'end'): number => {
    const roundFunction = side === 'start' ? Math.ceil : Math.floor;
    return roundFunction(timestamp / SECONDS_IN_15_MINUTES) * SECONDS_IN_15_MINUTES;
};

export const getTimeRangeInLast24Hours = (
    startMoment: moment.Moment,
    now: moment.Moment
): [moment.Moment, moment.Moment] => {
    const normalizedStartTimestamp = Math.max(startMoment.unix(), now.clone().subtract(1, 'day').unix());

    return [
        moment.unix(convertTo15MinuteInterval(normalizedStartTimestamp, 'start')),
        moment.unix(convertTo15MinuteInterval(now.unix(), 'end')),
    ];
};

export function isSameDay(fromTime: Moment, toTime: Moment): boolean {
    return toTime.isSame(fromTime, 'date');
}

export function isDateInLast24Hours(now: Moment, date: Moment): boolean {
    const diff = now.diff(date, 'hours');
    return diff >= 0 && diff < 24;
}

export function isDateInLast48Hours(now: Moment, dateRange: TDatetimeRange): boolean {
    return now.diff(dateRange[0], 'hours') < 48;
}

export const isDisabledDate =
    (config: IConfigResponse) =>
    (current: moment.Moment): boolean => {
        if (config?.timeframe) {
            const startDate = moment(config?.timeframe?.start_timestamp).startOf('day');
            const endDate = moment(config?.timeframe?.end_timestamp).endOf('day');

            return current < startDate || current > endDate;
        }
        return false;
    };

export const isObjectEmpty = (obj: Object): boolean => {
    if (!obj) return false;
    return !!Object.keys(obj).length;
};

export const bulletSpaceFormat = (str: string): string => {
    return str?.replace(/\n\*\s/g, '\n● ').replace(/^\*\s/g, '● ') || '';
};

export const bulletSpaceMarkup = (text: string, detokenizable?: boolean): ReactElement => {
    return (
        <>
            {bulletSpaceFormat(text)
                .split('\n')
                .map((item) => (
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {detokenizable ? (
                            <Detokenizable
                                tokenizedContent={item}
                                renderTooltip={(content) => <span>{bulletSpaceFormat(content)}</span>}
                                renderContent={(content) => <span>{bulletSpaceFormat(content)}</span>}
                            />
                        ) : (
                            <UiTooltip title={item}>
                                <span>{item}</span>
                            </UiTooltip>
                        )}
                    </div>
                ))}
        </>
    );
};
export const decodeHTMLChars = (str: string): string => {
    const elm = document.createElement('textarea');
    elm.innerHTML = str;
    return elm.value;
};

export const isEmptyObj = (obj: Object): boolean => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const makeUid = () => {
    return Math.round(Math.random() * 1e8).toString(32);
};

export const copyToClipboard = async (text: string) => {
    try {
        let clipboardPermission;
        if (navigator.permissions && navigator.permissions.query) {
            try {
                clipboardPermission = await navigator.permissions.query({ name: 'clipboard-write' as PermissionName });
            } catch {
                // Firefox did not implement clipboard-write permissions check -_-
                clipboardPermission = { state: 'granted' };
            }
        }
        switch (clipboardPermission ? clipboardPermission.state : 'granted') {
            case 'granted':
            case 'prompt':
                await navigator.clipboard.writeText(text);
                successMessage('Copied to clipboard');
                break;
            case 'denied':
                throw new Error('No permission to "copy to clipboard"');
            default:
                throw new Error('Cannot check permission to "copy to clipboard"');
        }
    } catch (e: any) {
        console.log('copy to clipboard failed: ', e?.message);
    }
};

type toolTipPosition =
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom';

export const singleLineWithTooltip = (
    text: string,
    minCharsForToolTip: number = 0,
    nimCharsForWideToolTip: number = 50,
    tooltipPosition: toolTipPosition = 'top',
    delay?: number,
    detokenizable?: boolean
) => {
    let toolTipMode: null | 'regular' | 'wide' = null;
    if (text.length > minCharsForToolTip) {
        toolTipMode = 'regular';
        if (text.length > nimCharsForWideToolTip) {
            toolTipMode = 'wide';
        }
    }

    return (
        <div className="clamp-1-line">
            {toolTipMode ? (
                detokenizable ? (
                    <Detokenizable
                        tokenizedContent={text.trim()}
                        renderTooltip={(content) => content}
                        renderContent={(content) => <span className="clamp-1-line">{content}</span>}
                    />
                ) : (
                    <UiTooltip
                        title={text.trim()}
                        wide={toolTipMode === 'wide'}
                        delay={delay}
                        placement={tooltipPosition}
                    >
                        <span className="clamp-1-line">{text}</span>
                    </UiTooltip>
                )
            ) : (
                text
            )}{' '}
        </div>
    );
};

export const multArrThreeTimes = (inputArr: any[] = []) => {
    return [...inputArr, ...inputArr, ...inputArr];
};

export const getEntityURL = (
    redirectUrl: string,
    org: string,
    entityType: string,
    entityId: string,
    eventType: EventTypesEnum,
    eventId: string,
    eventTimestamp?: number
): string => {
    return `${redirectUrl}/${org}/entity/${entityType}/event/${eventType}/${eventId}/${eventTimestamp}`;
};

export const investigateInNewTab = (
    redirectUrl: string,
    currOrg: ITenant,
    eventType: EventTypesEnum,
    eventId: string,
    entityType: string,
    entityId: string,
    eventTimestamp?: number
) => {
    // eslint-disable-next-line no-useless-concat
    const win = window.open(
        getEntityURL(redirectUrl, currOrg?.key, entityType, entityId, eventType, eventId, eventTimestamp),
        '_blank'
    );
    if (win != null) {
        win.focus();
    }
};

export const goToEndpointInNewTab = (
    redirectUrl: string | undefined,
    serviceName: string,
    endpointId: string,
    period: string,
    currOrg: ITenant | null,
    timestamp?: [number, number]
) => {
    const isNewDiscovery = checkFeature(process.env, currOrg?.key || '', 'new-discovery'); // TODO: remove once new discovery is in prod
    const url = isNewDiscovery
        ? `${redirectUrl}/${currOrg?.key}/discovery/services/${serviceName}/endpoints/${endpointId}${
              timestamp ? `?from_timestamp=${timestamp[0]}&to_timestamp=${timestamp[1]}` : ''
          }`
        : `${redirectUrl}/${currOrg?.key}/discovery/${endpointId}/interval/${period}`;
    const win = window.open(url, '_blank');
    if (win != null) {
        win.focus();
    }
};

export const goToAlertsInNewTab = (tenant: string, severity: AlertSeverityEnum, label?: string) => {
    const windowURL = `${process.env.REACT_APP_REDIRECT_SIGN_IN}/${tenant}/alerts/All/?severity=${severity}&${
        label ? 'label=' + label : ''
    }`;
    const win = window.open(windowURL, '_blank');
    if (win != null) {
        win.focus();
    }
};

export const goToSuppressInNewTab = (redirectUrl: string | undefined, alertId: string, currOrg: ITenant | null) => {
    const win = window.open(`${redirectUrl}/${currOrg?.key}/settings/suppression/rule/?parentId=${alertId}`, '_blank');
    if (win != null) {
        win.focus();
    }
};

export const patchAlert = (
    patchedProperty: string,
    currOrg: ITenant,
    alertId: string,
    updatedVal: AlertStatusEnum | AlertSeverityEnum
): Promise<void | AxiosResponse<IAlertResponse>> => {
    return httpPatch(`organizations/${currOrg?.key}/alerts/${alertId}`, { [patchedProperty]: updatedVal }).catch(
        (err) => console.log(err)
    );
};
export const getValidInterval = (requestedInterval: string, intervalOptions: { value: string; label: string }[]) => {
    const defaultInterval = '1Y';

    const isIntervalValid = intervalOptions.map((option) => option.value).includes(requestedInterval);

    if (isIntervalValid) {
        return requestedInterval;
    } else {
        console.error(`Requested time range '${requestedInterval}' is invalid  `);
        return defaultInterval;
    }
};

export const sumArrayValues = (arr1: number[], arr2: number[]) => {
    if (arr2.length > arr1.length) {
        const tempArr = arr1;
        arr1 = arr2;
        arr2 = tempArr;
    }
    return arr1.map((num, i) => num + (arr2[i] || 0));
};

export const groupEndpointsByService = (endpointList: IEndpointDisplay[]): ISelectOptionGrp[] => {
    const groupedOptions: ISelectOptionGrp[] = endpointList.reduce(
        (serviceGroup: ISelectOptionGrp[], currItem: IEndpointDisplay) => {
            let nextServiceGrp = [...serviceGroup];
            let serviceGrpIndex = serviceGroup.findIndex((c: ISelectOptionGrp) => c.title === currItem.service_name);
            let serviceGrp = serviceGroup[serviceGrpIndex];

            const singleOption: IEndpointSelectOption = {
                label: (
                    <ApiLabel
                        apiType={currItem.method}
                        apiName={currItem.endpoint_path}
                        labelWidth={50}
                        minWidth={190}
                    />
                ),
                key: currItem.endpoint_path,
                value: currItem.id,
                endpointPath: currItem.endpoint_path,
            };

            if (serviceGrpIndex > -1) {
                // we already created the service Grp
                serviceGrp = {
                    ...serviceGrp,
                    options: [...serviceGrp.options, singleOption],
                };
                nextServiceGrp = [...nextServiceGrp];
                nextServiceGrp[serviceGrpIndex] = serviceGrp;
            } else {
                // creating the service Grp
                serviceGrp = {
                    title: currItem.service_name || ' N/A',
                    options: [singleOption],
                };
                nextServiceGrp = [...nextServiceGrp, serviceGrp];
            }
            // });
            return nextServiceGrp;
        },
        []
    );

    groupedOptions
        .sort((a, b) => {
            return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
        })
        .forEach((grp) => {
            grp.options.sort((option_a, option_b) => {
                // sort the options for each option group
                option_a = option_a as IEndpointSelectOption;
                option_b = option_b as IEndpointSelectOption;
                const label_a = option_a.label.props.apiType.toLowerCase();
                const label_b = option_b.label.props.apiType.toLowerCase();
                const endpointPath_a = option_a.endpointPath.toLowerCase();
                const endpointPath_b = option_b.endpointPath.toLowerCase();

                return endpointPath_a.localeCompare(endpointPath_b) || label_a.localeCompare(label_b);
            });
        });

    return groupedOptions;
};

export const stringifyRulePredicate = (predicate: ISuppressionRulePredicate): string => {
    const result: string[] = [];
    const predicateKeyMap: { [key: string]: string } = {
        endpoints: 'Endpoint',
        services: 'Service',
        associated_entities: 'Entity',
        alert_names: 'Alert Name',
        alert_categories: 'Alert Category',
        alert_severities: 'Alert Severity',
        alert_description: 'Alert Description',
        labels: 'Label',
    };

    for (let key in predicate) {
        if (predicate[key as keyof ISuppressionRulePredicate]) {
            if (key === 'associated_entities') {
                let str = '';
                predicate.associated_entities?.forEach((predicateValue, i) => {
                    str += `${i > 0 ? ' AND ' : ''}${predicateValue.type} is ${predicateValue.id}`;
                });
                result.push(str);
            } else if (key === 'alert_description') {
                let str = `${predicateKeyMap[key]} ${
                    (
                        predicate[
                            key as keyof ISuppressionRulePredicate
                        ] as ISuppressionRulePredicate['alert_description']
                    )?.operand
                } ${
                    (
                        predicate[
                            key as keyof ISuppressionRulePredicate
                        ] as ISuppressionRulePredicate['alert_description']
                    )?.value
                }`;
                result.push(str);
            } else if (key === 'endpoints') {
                let str = `${predicateKeyMap[key]} is`;
                (predicate[key as keyof ISuppressionRulePredicate] as ISimpleEndpoint[]).forEach(
                    (predicateValue: ISimpleEndpoint, i) => {
                        str += `${i > 0 ? ' OR' : ''} ${predicateValue.method} ${predicateValue.endpoint_path}`;
                    }
                );
                result.push(str);
            } else {
                let str = `${predicateKeyMap[key]} is`;
                (predicate[key as keyof ISuppressionRulePredicate] as string[]).forEach((predicateValue: string, i) => {
                    str += `${i > 0 ? ' OR' : ''} ${predicateValue}`;
                });
                result.push(str);
            }
        }
    }

    return result.join('\nAND\n');
};

export const stringifyRuleAction = (action: IAutomatedActionRuleAction): string => {
    const result: string[] = [];

    for (let key in action) {
        if (action[key as keyof IAutomatedActionRuleAction]) {
            if (key === ActionType.ChangeSeverity) {
                result.push(`Change severity to ${action.change_severity}`);
            } else if (key === ActionType.Label) {
                result.push(`Add label ${action.label}`);
            } else if (key === ActionType.Email) {
                result.push(`Send email to ${action.email}`);
            } else if (key === ActionType.Webhook) {
                result.push(`Send request to ${action.webhook?.endpoint}`);
            } else if (key === ActionType.JiraTicket) {
                result.push(`Create Jira ticket on ${action.jira_ticket?.project}`);
            } else if (key === ActionType.KongIntegration) {
                result.push(
                    `${action.kong_integration?.action === 'rate_limit' ? 'Rate limit consumer' : 'Block consumer'} ${
                        action.kong_integration?.kong_url
                    }`
                );
            } else if (key === ActionType.F5Integration) {
                result.push(
                    `${action.f5_integration?.action === 'rate_limit' ? 'Rate limit IP' : 'Block IP'} ${
                        action.f5_integration?.admin_api_url
                    }`
                );
            }
        }
    }

    return result.join('\nAND\n');
};

export const roundToDigits = (input: number, digits: number): number => {
    const factor = Math.pow(10, digits);
    return Math.round(input * factor) / factor;
};

export const formatNumberDisplay = (num: number) => {
    return new Intl.NumberFormat('en').format(num);
};

export const sortAlphabetically = (key: string) => {
    return (a: { [key: string]: any }, b: { [key: string]: any }): number => {
        const str1 = a[key]?.toLowerCase(),
            str2 = b[key]?.toLowerCase();

        if (str1 < str2)
            //sort string ascending
            return -1;
        if (str1 > str2) return 1;

        return 0;
    };
};

export const sortLexicographically = (a: string, b: string) => {
    return a.localeCompare(b, 'en', { caseFirst: 'upper' });
};

export const sortBySeverity = (key: string) => {
    return (a: { [key: string]: AlertSeverityEnum }, b: { [key: string]: AlertSeverityEnum }): number => {
        const severity1 = AlertSeverityEnum[a[key]],
            severity2 = AlertSeverityEnum[b[key]];

        if (severity1 === undefined || severity2 === undefined) {
            return 0;
        }

        if (severity1 < severity2)
            //sort string ascending
            return -1;
        if (severity1 > severity2) return 1;

        return 0;
    };
};

export type TimeStampedEntity = {
    updatedAt?: string;
    createdAt?: string;
};

export const sortByTime = (a: TimeStampedEntity, b: TimeStampedEntity) => {
    const aTimestamp = new Date(a.updatedAt || a.createdAt!).getTime();
    const bTimestamp = new Date(b.updatedAt || a.createdAt!).getTime();
    return bTimestamp - aTimestamp;
};

export const product = (input: any[][]): any[][] => {
    return input.reduce(
        function tl(accumulator, value) {
            const tmp: any[] = [];
            accumulator.forEach(function (a0) {
                value.forEach(function (a1) {
                    tmp.push(a0.concat(a1));
                });
            });
            return tmp;
        },
        [[]]
    );
};

export const urlEncode = (unencoded: string) => {
    const encoded = btoa(unencoded);
    return encoded.replace('+', '-').replace('/', '_');
};

export const omit = (keys: string[], obj: { [key: string]: any }): Partial<typeof obj> => {
    const result = { ...obj };
    keys.forEach((key) => {
        delete result[key];
    });
    return result;
};

export const filterNullValues = (obj: object): Partial<typeof obj> => {
    const result = { ...obj } as { [key: string]: any };
    for (const key in result) {
        if (result[key] === null) {
            delete result[key];
        }
    }
    return result;
};

export const getTimestampQueryParams = (queryParams: string, range: any[]) => {
    /*
     * Find time-range params in the url and replace the value with the new range.
     * If no time-range are present in the url, it creates them
     * */
    const parsedQueryParams: any = new URLSearchParams(queryParams);
    const from_timestamp = parsedQueryParams.get('from_timestamp');
    const to_timestamp = parsedQueryParams.get('to_timestamp');

    if (!from_timestamp && !to_timestamp) {
        const queryParamsSeparator = queryParams ? '&' : '?';
        return `${queryParams}${queryParamsSeparator}from_timestamp=${range[0].unix()}&to_timestamp=${range[1].unix()}`;
    }

    return queryParams
        .replace(`from_timestamp=${from_timestamp}`, `from_timestamp=${range[0].unix()}`)
        .replace(`to_timestamp=${to_timestamp}`, `to_timestamp=${range[1].unix()}`);
};

export const removeTrailingSlashes = (val: string): string => {
    return val.replace(/\/+$/, '');
};

export const getTimerangeGranularity = (timestamps: number[]) => {
    return timestamps[1] - timestamps[0] < 3600 ? '15m' : '1d';
};

export const splitToDateAndTimeRange = (range: TDatetimeRange) => {
    const timeRange = range.map(
        (selectedTime) => (selectedTime.unix() - selectedTime.clone().startOf('day').unix()) / 60
    );

    const selectedTimeRange = [
        (Math.ceil(timeRange[0] / 15) * 15).toFixed(0).toString(),
        Math.floor(timeRange[1]) === 1439 ? '1439' : (Math.floor(timeRange[1] / 15) * 15).toFixed(0).toString(),
    ] as TTimeRange;

    const selectedDateRange = range.map((dateRange) => dateRange.clone().startOf('day')) as TDatetimeRange;
    return [selectedTimeRange, selectedDateRange] as [TTimeRange, TDatetimeRange];
};

export function timeFormatter(timeStamp: number): string {
    const hours = Math.floor(timeStamp / 60);
    const minutes = timeStamp % 60;
    let hoursString = hours.toString();
    let minutesString = minutes.toString();
    if (minutes < 10) minutesString = '0' + minutesString;
    if (hours < 10) hoursString = '0' + hoursString;
    if (hoursString === '23' && minutesString === '59') return 'EOD';
    return hoursString + ':' + minutesString;
}

let errorTrackingEnabled: boolean;
export const isErrorTrackingEnabled = () => {
    if (errorTrackingEnabled === undefined) {
        errorTrackingEnabled = checkFeature(process.env, getActiveOrg(), 'monitoring');
    }

    return errorTrackingEnabled;
};

export function validateIPAddress(IPAddress: string, supportCidrValidation: boolean = true): boolean {
    return isIP(IPAddress) || (supportCidrValidation && !!isCidr(IPAddress));
}

function findOneDiff(list1: Array<any>, list2: Array<any>): any {
    return list2.filter((ip) => !list1.includes(ip))[0];
}

export function findInvalidIP(prevIPList: string[], currentIPList: string[]): string | null {
    if (currentIPList.length <= prevIPList.length) {
        return null;
    }

    const newIP = findOneDiff(prevIPList, currentIPList);

    return validateIPAddress(newIP) ? null : newIP;
}

export function decodeURIReservedCharacters(str: string): string {
    return str
        .replace(/%24/g, '$')
        .replace(/%26/g, '&')
        .replace(/%2B/g, '+')
        .replace(/%2C/g, ',')
        .replace(/%2F/g, '/')
        .replace(/%3A/g, ':')
        .replace(/%3B/g, ';')
        .replace(/%3F/g, '?')
        .replace(/%3D/g, '=')
        .replace(/%40/g, '@');
}

export function decodeURIUnsafeCharacters(str: string): string {
    return str
        .replace(/%20/g, ' ')
        .replace(/%22/g, '"')
        .replace(/%3C/g, '<')
        .replace(/%3E/g, '>')
        .replace(/%23/g, '#')
        .replace(/%7B/g, '{')
        .replace(/%7C/g, '}')
        .replace(/%5C/g, '\\')
        .replace(/%5E/g, '^')
        .replace(/%7E/g, '~')
        .replace(/%5B/g, '[')
        .replace(/%5D/g, ']')
        .replace(/%60/g, '`')
        .replace(/%25/g, '%');
}

export function pseudoIdempotentURIEncode(str: string): string {
    return encodeURIComponent(decodeURIUnsafeCharacters(decodeURIReservedCharacters(str)));
}

export function sortEntitiesByPrecedence(entities: IEntity[]): IEntity[] {
    const actorEntitiesByPrecedence = ['user', 'token', 'key', 'ip'];

    const actorEntities: IEntity[] = [];
    const nonActorEntities: IEntity[] = [];

    // split the entities by 'family' and duplicate for purity reasons:
    entities.forEach((ent) => {
        if (ent.family === 'actor') {
            actorEntities.push(ent);
        } else {
            nonActorEntities.push(ent);
        }
    });

    actorEntities.sort((a, b) => {
        // protection for if both entities are of unknown class
        if (
            !actorEntitiesByPrecedence.includes(a.class.toLowerCase()) &&
            !actorEntitiesByPrecedence.includes(b.class.toLowerCase())
        ) {
            return a.index - b.index;
        }

        return actorEntitiesByPrecedence.indexOf(a.class) - actorEntitiesByPrecedence.indexOf(b.class);
    });

    nonActorEntities.sort((a, b) => {
        return a.index - b.index;
    });

    return [...actorEntities, ...nonActorEntities];
}

const DEFAULT_ERROR_MESSAGE = 'Unexpected error';
export const extractErrorMessage = (errorObj: any, fallbackMessage?: string): string => {
    return (
        errorObj?.response?.data?.detail ||
        errorObj?.data?.detail ||
        errorObj?.error ||
        fallbackMessage ||
        DEFAULT_ERROR_MESSAGE
    );
};

export const decodeUtf8Base64String: (encoded: string, errorMessage?: string) => string = (
    encoded: string,
    errorMessage?: string
) => {
    // logic heavily borrowed from https://stackoverflow.com/a/30106551/570009
    try {
        return decodeURIComponent(
            atob(encoded)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );
    } catch (e) {
        return errorMessage ?? 'Error - unable to parse';
    }
};

export const isVerticalScrollNeeded = (elem: HTMLElement, contractedHeight: number): boolean => {
    const el = elem;
    const curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === 'visible') {
        el.style.overflow = 'hidden';
    }
    const curTransition = el.style.transition;
    if (!curTransition) {
        el.style.transition = 'none';
    }
    const elStyle = window.getComputedStyle(el);
    const margin = parseInt(elStyle.marginTop) + parseInt(elStyle.marginBottom);
    const padding = parseInt(elStyle.paddingTop) + parseInt(elStyle.paddingBottom);
    const contentTooHighToFit = contractedHeight - margin - padding < el.scrollHeight;

    el.style.overflow = curOverflow;
    el.style.transition = curTransition;
    return contentTooHighToFit;
};

export const gotoACC = () => {
    window.open(
        process.env.REACT_APP_ENV === 'production'
            ? 'https://control.akamai.com/apps/advanced-api-security'
            : 'https://control.cloud-sqa-shared.akamai.com/apps/advanced-api-security'
    );
};
