import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { NoTenant } from 'containers/no-tenant/NoTenant';
import { FourOFour } from 'containers/404/404';
import { User } from 'containers/user/User';
import { Alerts } from 'containers/alerts/Alerts';
import { Query } from 'containers/query/Query';
import { Discovery } from 'containers/discovery/discovery';
import { checkFeature } from 'services/feature-toggle/feature-toggle-service';
import { Testing } from 'components/Testing/Testing';
import { Settings } from 'components/settings/Settings';
import { Home } from 'containers/home/Home';
import Spinner from 'containers/spinner/Spinner';
import { fourOFour, selectCurrentTenantKey, selectTenantList, useGetTenantsListQuery } from 'api/tenantListApi';
import { useEffect } from 'react';
import { trackTenant } from 'analyticsUtils';
import { useGetUserSessionTokenPayloadQuery } from 'api/userApi';

export const AppRoutes = () => {
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const tenantList = useSelector(selectTenantList);
    const { isError: isTenantListError } = useGetTenantsListQuery();
    const { data: user } = useGetUserSessionTokenPayloadQuery(null);

    useEffect(() => {
        currentTenantKey && trackTenant(currentTenantKey, user.email);
    }, [currentTenantKey, user?.email]);

    if (!user) {
        return <Spinner show />;
    }

    if (isTenantListError || (tenantList && Object.keys(tenantList).length == 0)) {
        return <NoTenant />;
    }

    if (!currentTenantKey) {
        return <Spinner show />;
    }

    if (currentTenantKey === fourOFour) {
        return <FourOFour />;
    }

    return (
        <Switch>
            <Route path={'/:activeOrgParam/user'}>
                <User />
            </Route>
            <Route path={'/:activeOrgParam/alerts/:activeFilterId'}>
                <Alerts />
            </Route>
            <Route path={'/:activeOrgParam/alerts'}>
                <Alerts />
            </Route>
            <Route path="/:activeOrgParam/query">
                <Query />
            </Route>
            <Route path="/:activeOrgParam/discovery/services">
                <Discovery />
            </Route>
            {
                // TODO: Remove when testing is open for all
                currentTenantKey && checkFeature(process.env, currentTenantKey, 'testing') ? (
                    <Route path="/:activeOrg/testing" exact>
                        <Testing />
                    </Route>
                ) : null
            }
            <Route path="/:activeOrg/testing/:subsection">
                <Testing />
            </Route>
            <Route path="/:activeOrgParam/entity/:entityType/event/:eventType/:eventId/:eventTimestamp">
                <User />
            </Route>
            <Route path="/:activeOrg/settings">
                <Settings />
            </Route>
            <Route path="/">
                <Home />
            </Route>
            <Route path="/:activeOrgParam">
                <Home />
            </Route>
        </Switch>
    );
};
