import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { NavLink, Route, Switch, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { Allotment } from 'allotment';

import { AutomatedActionsList } from './components/automated-actions-list/AutomatedActionsList';
import { AutomatedActionRule } from './components/automated-action-rule/AutomatedActionRule';
import { CollectorConfigurationAddEdit } from './components/collectorConfigurations/CollectorConfigurationAddEdit/CollectorConfigurationAddEdit';
import { CollectorConfigurationList } from './components/collectorConfigurations/CollectorConfigurationList/CollectorConfigurationList';
import { DataSourcesRoutes } from './components/dataSources/DataSourcesRoutes/DataSourcesRoutes';
import { EndpointFilterList } from './components/endpoint-filters/endpoint-filter-list/EndpointFilterList';
import { EndpointPatternsList } from './components/endpoint-patterns-list/EndpointPatternsList';
import { EntityList } from './components/entity-management/EntityList/EntityList';
import { SuppressionRuleList } from './components/suppression-rule-list/SuppressionRuleList';
import { SuppressionRule } from './components/suppression-rule/SuppressionRule';
import { AkamaiConfigurationList } from 'components/settings/components/akamaiConfigurations/akamaiConfigurationList/AkamaiConfigurationList';
import { AkamaiConfigurationEdit } from 'components/settings/components/akamaiConfigurations/akamaiConfigurationEdit/AkamaiConfigurationEdit';
import { checkFeature } from 'services/feature-toggle/feature-toggle-service';
import { UiIcon } from 'components/icon/UiIcon';
import { useGetConfigQuery } from 'api/configApi';
import { useGetUserSessionTokenPayloadQuery } from 'api/userApi';
import { IAkamaiConfigResponse, useGetPluginsConfigurations } from 'api/akamaiConfigs';
import { ReleaseNotes } from '../release-notes/ReleaseNotes';
import { gotoACC } from 'general/utils';

import './Settings.scss';

interface ISettingItem {
    id: SettingsSubsectionsEnum;
    displayName: string;
    path: string;
    extraClassname?: string;
}

interface IToolbarItem {
    item: ISettingItem;
    section: ToolbarSection;
    show: boolean; // Check feature
}

export interface ISettingsPathParams {
    activeOrg: string;
}

enum SettingsSubsectionsEnum {
    SUPPRESSION = 'Suppression',
    AUTOMATED_ACTIONS = 'Automated Actions',
    RELEASE_NOTES = 'Release Notes',
    DATA_SOURCES = 'Data Sources',
    COLLECTOR_CONFIGURATIONS = 'Node Configurations',
    ENDPOINT_FILTERS = 'Endpoint Filtering',
    ENDPOINT_PATTERNS = 'Endpoint Patterns',
    ENTITY_MANAGEMENT = 'Entity Management',
    AKAMAI_CONFIGURATIONS = 'Akamai Configurations',
}

const SETTINGS_SECTIONS = ['Alerts', 'Discovery', 'Data Acquisition', 'API Testing', 'Management'] as const;

type ToolbarSection = typeof SETTINGS_SECTIONS[number];

export const Settings = () => {
    const { activeOrg } = useParams<ISettingsPathParams>();
    const [toolbarItems, setToolbarItems] = useState<IToolbarItem[]>([]);
    const { data: config } = useGetConfigQuery(activeOrg || skipToken);
    const { data: user } = useGetUserSessionTokenPayloadQuery<{ data: { [key: string]: any } }>(null);
    const { data: pluginsConfigurations } = useGetPluginsConfigurations<{ data: IAkamaiConfigResponse }>(activeOrg);

    function getMenuItems(items: IToolbarItem[]) {
        const relevantItems = items.filter(({ show }) => show);
        return SETTINGS_SECTIONS.map((section) => (
            <>
                {relevantItems
                    .filter(({ section: activeSec }) => section === activeSec)
                    .map(({ item }, index) => (
                        <>
                            {index === 0 && (
                                <div key={section} className="title-container">
                                    {section}
                                </div>
                            )}
                            <NavLink
                                to={`/${activeOrg}/${item.path}`}
                                className={`navigation-item ${item.extraClassname}`}
                                key={item.id}
                                activeClassName="selected"
                            >
                                {item.displayName}
                            </NavLink>
                        </>
                    ))}
            </>
        ));
    }

    useEffect(() => {
        setToolbarItems([
            {
                item: {
                    id: SettingsSubsectionsEnum.SUPPRESSION,
                    displayName: 'Suppression',
                    path: 'settings/suppression',
                },
                show: true,
                section: 'Alerts',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.AUTOMATED_ACTIONS,
                    displayName: 'Automated Actions',
                    path: 'settings/automated-actions',
                },
                show: checkFeature(process.env, activeOrg, 'automated-actions'),
                section: 'Alerts',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.DATA_SOURCES,
                    displayName: SettingsSubsectionsEnum.DATA_SOURCES,
                    path: 'settings/data-sources',
                },
                show: checkFeature(process.env, activeOrg, 'collectors-mgmt', user),
                section: 'Data Acquisition',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.COLLECTOR_CONFIGURATIONS,
                    displayName: SettingsSubsectionsEnum.COLLECTOR_CONFIGURATIONS,
                    path: 'settings/node-configurations',
                },
                show: checkFeature(process.env, activeOrg, 'collectors-mgmt', user),
                section: 'Data Acquisition',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.AKAMAI_CONFIGURATIONS,
                    displayName: SettingsSubsectionsEnum.AKAMAI_CONFIGURATIONS,
                    path: 'settings/akamai-configs',
                },
                show:
                    pluginsConfigurations?.items.length > 0 &&
                    checkFeature(process.env, activeOrg, 'akamai-configs', user),
                section: 'Data Acquisition',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.ENTITY_MANAGEMENT,
                    displayName: SettingsSubsectionsEnum.ENTITY_MANAGEMENT,
                    path: 'settings/entities',
                },
                show: checkFeature(process.env, activeOrg, 'entity-management', user),
                section: 'Data Acquisition',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.ENDPOINT_FILTERS,
                    displayName: 'Endpoint Filtering',
                    path: 'settings/endpoint-filter',
                },
                show: checkFeature(process.env, activeOrg, 'endpoint-filters'),
                section: 'Discovery',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.ENDPOINT_PATTERNS,
                    displayName: 'Endpoint Patterns',
                    path: 'settings/endpoint-patterns',
                },
                show: checkFeature(process.env, activeOrg, 'endpoint-patterns', user),
                section: 'Discovery',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.ENDPOINT_PATTERNS,
                    displayName: 'API Keys',
                    path: 'settings/api-keys',
                },
                show: checkFeature(process.env, activeOrg, 'testing'),
                section: 'API Testing',
            },
            {
                item: {
                    id: SettingsSubsectionsEnum.RELEASE_NOTES,
                    displayName: 'Release Notes',
                    path: 'settings/release-notes',
                    extraClassname: 'release-notes-menu-item',
                },
                show: checkFeature(process.env, activeOrg, 'release-notes'),
                section: 'Data Acquisition',
            },
        ]);
    }, [activeOrg, user, pluginsConfigurations]);

    return (
        <div className="settings-container">
            <Allotment vertical={false} defaultSizes={[250, 1500]}>
                <Allotment.Pane minSize={250} maxSize={500}>
                    <div className="settings-menu">
                        <div className="settings-menu-items">{getMenuItems(toolbarItems)}</div>
                        {checkFeature(process.env, activeOrg, 'acc-management-url', undefined, config) && (
                            <div className="settings-menu-footer">
                                <div className="settings-menu-footer-title">Akamai Control Center</div>
                                <a
                                    className="settings-menu-footer-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        gotoACC();
                                    }}
                                >
                                    Go to configuration
                                    <UiIcon name="externalLink" />
                                </a>
                            </div>
                        )}
                    </div>
                </Allotment.Pane>
                <Allotment.Pane>
                    <div className="settings-content-container">
                        <Switch>
                            <Route exact path="/:activeOrg/settings">
                                <Redirect to={`/${activeOrg}/settings/suppression`} />
                            </Route>
                            <Route exact path="/:activeOrg/settings/suppression">
                                <SuppressionRuleList />
                            </Route>
                            <Route path="/:activeOrg/settings/suppression/rule/:ruleId?">
                                <SuppressionRule />
                            </Route>
                            <Route exact path="/:activeOrg/settings/automated-actions">
                                <AutomatedActionsList />
                            </Route>
                            <Route path="/:activeOrg/settings/automated-actions/rule/:ruleId?">
                                <AutomatedActionRule />
                            </Route>
                            <Route path="/:activeOrg/settings/data-sources">
                                <DataSourcesRoutes />
                            </Route>
                            <Route exact path="/:activeOrg/settings/node-configurations/:configId/edit">
                                <CollectorConfigurationAddEdit />
                            </Route>
                            <Route exact path="/:activeOrg/settings/node-configurations/add">
                                <CollectorConfigurationAddEdit />
                            </Route>
                            <Route path="/:activeOrg/settings/node-configurations">
                                <CollectorConfigurationList />
                            </Route>
                            <Route exact path="/:activeOrg/settings/endpoint-filter">
                                <EndpointFilterList />
                            </Route>
                            <Route exact path="/:activeOrg/settings/endpoint-filter/add">
                                <EndpointFilterList editMode />
                            </Route>
                            <Route exact path="/:activeOrg/settings/endpoint-filter/edit/:endpointFilterId">
                                <EndpointFilterList editMode />
                            </Route>
                            <Route path="/:activeOrg/settings/release-notes">
                                <ReleaseNotes />
                            </Route>
                            <Route exact path="/:activeOrg/settings/endpoint-patterns">
                                <EndpointPatternsList />
                            </Route>
                            <Route exact path="/:activeOrg/settings/endpoint-patterns/add">
                                <EndpointPatternsList editMode />
                            </Route>
                            <Route exact path="/:activeOrg/settings/endpoint-patterns/edit/:endpointPatternId">
                                <EndpointPatternsList editMode />
                            </Route>
                            <Route exact path="/:activeOrg/settings/entities">
                                <EntityList />
                            </Route>
                            <Route exact path="/:activeOrg/settings/entities/add">
                                <EntityList addMode />
                            </Route>
                            <Route exact path="/:activeOrg/settings/akamai-configs">
                                <AkamaiConfigurationList />
                            </Route>
                            <Route exact path="/:activeOrg/settings/akamai-configs/:pluginName/edit">
                                <AkamaiConfigurationEdit />
                            </Route>
                            <Route exact path="/:activeOrg/settings/api-keys">
                                <h1>API Keys - Coming Soon</h1>
                            </Route>
                            <Route exact path="/:activeOrg/settings/api-keys/add">
                                <h1>API Keys Add - Coming Soon</h1>
                            </Route>
                        </Switch>
                    </div>
                </Allotment.Pane>
            </Allotment>
        </div>
    );
};
