import React, { useState } from 'react';
import { Steps } from 'antd';

import { UiModal } from '../ui-modal/UiModal';
import { UiButton } from '../button/Button';

import './UiWizard.scss';

export interface IWizardStep {
    title: string;
    content: JSX.Element;
}

export interface IUiWizardProps {
    wrapClassName: string;
    title: string;
    onCancel: () => void;
    /**
     * onNext callback returned promise should resolve to false if the wizard should **NOT** advance to next step, true otherwise
     *
     *  The current step number is passed and can be utilized if necessary
     **/
    onNext: (step?: number) => Promise<boolean>;
    onFinish: () => void;
    steps: IWizardStep[];
}

export const UiWizard = ({ wrapClassName, title, onCancel, onNext, onFinish, steps }: IUiWizardProps) => {
    const [currentStep, setCurrentStep] = useState(0);

    const handleNextClick = async () => {
        (await onNext(currentStep)) && setCurrentStep((step) => (currentStep < steps.length ? step + 1 : step));
    };

    const handleConfirmClick = () => {
        onFinish();
    };

    const handleBackClick = () => {
        setCurrentStep((step) => (step > 0 ? step - 1 : step));
    };

    return (
        <UiModal
            {...{ title, onCancel }}
            wrapClassName={`UiWizard ${wrapClassName}`.trim()}
            width={745}
            isFormModal
            isVisible
            acceptButton={{
                text: currentStep === steps.length - 1 ? 'Confirm' : 'Next',
                onClick: currentStep === steps.length - 1 ? handleConfirmClick : handleNextClick,
            }}
            rejectButton={{
                text: 'Cancel',
                onClick: onCancel,
            }}
            extraFooterContent={
                <div className="form-footer">
                    {currentStep > 0 ? <UiButton type="secondary" text="Back" onClick={handleBackClick} /> : <span />}
                </div>
            }
        >
            <Steps current={currentStep} className="wizard-steps">
                {steps.map(({ title }, i) => (
                    <Steps.Step key={i} title={title} />
                ))}
            </Steps>
            {steps[currentStep].content}
        </UiModal>
    );
};
