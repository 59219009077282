import React from 'react';
import './UiOverlayMenu.scss';
import { UiIcon } from '../icon/UiIcon';
import { Dropdown, Menu } from 'antd';

export interface IOverlayMenu {
    menuItems: {
        label: string | JSX.Element;
        onClick?: Function;
        disabled?: boolean;
        icon?: JSX.Element;
        tooltip?: string;
    }[];
    disabled?: boolean;
    icon?: JSX.Element;
    handleClickMenu?: Function;
    menuId?: string;
}

export const UiOverlayMenu = ({ menuItems, disabled, icon, handleClickMenu, menuId }: IOverlayMenu) => {
    const menu = (
        <Menu className="overlay-menu" data-testid={menuId}>
            {menuItems.map((menuItem: any, index) => {
                if (menuItem.label === 'divider') {
                    return <Menu.Divider key={index} />;
                } else
                    return (
                        <Menu.Item
                            key={menuItem.label + menuId}
                            onClick={menuItem.onClick}
                            disabled={menuItem.disabled}
                            icon={menuItem.icon ? menuItem.icon : null}
                        >
                            <span title={menuItem.disabled ? menuItem.tooltip : ''}>{menuItem.label}</span>
                        </Menu.Item>
                    );
            })}
        </Menu>
    );

    return (
        <div
            className={`overlay-menu-container ${disabled || !menuItems?.length ? 'disabled' : ''}`}
            onClick={(e) => {
                handleClickMenu && handleClickMenu();
                e.stopPropagation();
            }}
        >
            <Dropdown
                className={'overlay-dropdown'}
                overlay={menu}
                trigger={['click']}
                disabled={disabled || !menuItems?.length}
                destroyPopupOnHide
            >
                {icon || <UiIcon name="more" role="button" />}
            </Dropdown>
        </div>
    );
};
