import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'antd';

import { errorMessage, successMessage } from '../../../../../general/toast-service';
import { ISettingsPathParams } from '../../../Settings';
import { UiIcon } from '../../../../icon/UiIcon';
import { IWizardStep, UiWizard } from '../../../../UiWizard/UiWizard';
import { useFetchAllCollectorConfigurations } from '../../collectorConfigurations/shared/useFetchAllCollectorConfigurations';
import {
    createCollector,
    ICollector,
    INewNode,
    INodeProxy,
    NodeConnectionMode,
    NodeTypes,
} from '../shared/collectorApis';
import { formatCollectorConfigsForDropdown, IDropDownOptionsProps } from '../CollectorList/CollectorList';
import { ConfigRadiosOptions, NeosecNodeAddFormStep1 } from '../NeosecNodeAddFormStep1/NeosecNodeAddFormStep1';
import { NeosecNodeAddFormStep2 } from '../NeosecNodeAddFormStep2/NeosecNodeAddFormStep2';

import './CollectorAddEditModal.scss';

export interface ICollectorAddEditModalProps {
    onClose: (refreshNeeded?: boolean) => void;
    collectors?: ICollector[];
}

interface ICollectorAddEditModalParams extends ISettingsPathParams {
    dataSourceId: string;
    collectorId?: string;
}

export const CollectorAddEditModal = (props: ICollectorAddEditModalProps) => {
    const { onClose, collectors = [] } = props;

    const { activeOrg, dataSourceId } = useParams<ICollectorAddEditModalParams>();
    const [collectorConfigsOptions, setCollectorConfigsOptions] = useState<IDropDownOptionsProps[]>([]);
    const [newNode, setNewNode] = useState<Partial<INewNode>>();

    const [generalForm] = Form.useForm();
    const [typeForm] = Form.useForm();

    useFetchAllCollectorConfigurations(setCollectorConfigsOptions, formatCollectorConfigsForDropdown);

    useEffect(() => {
        generalForm.setFieldsValue({ type: 'iso', configDropdownEnabler: ConfigRadiosOptions.LATER });
    }, [generalForm]);

    const handleNext = useCallback(async (): Promise<boolean> => {
        try {
            const { name, currentConfigId, proxy } = await generalForm.validateFields();

            const splitProxy = proxy?.split('://');
            const proxyObj: INodeProxy = proxy && { schema: splitProxy[0], url: splitProxy[1] };

            const tempNode: Partial<INewNode> = {
                name,
                collectorConfId: currentConfigId,
                proxy: proxyObj,
                sourceId: dataSourceId,
                tenantKey: activeOrg,
            };
            setNewNode(tempNode);
            return true;
        } catch {
            return false;
        }
    }, [activeOrg, dataSourceId, generalForm]);

    const handleSave = async () => {
        let refreshNeeded: boolean = true;
        try {
            const { type, timezone, connectionMode, fixedIp, netMask, defaultGateway, dnsServer } =
                await typeForm.validateFields();

            const tempNode: Partial<INewNode> = { ...newNode, deploymentType: type, timezone };
            if (connectionMode === NodeConnectionMode.FIXED_IP) {
                tempNode.fixedIp = {
                    address: fixedIp,
                    netMask,
                    defaultGateway,
                    nameServer: dnsServer,
                };
            }

            await createCollector(tempNode as INewNode);

            if (type === NodeTypes.ISO) {
                successMessage(`Image for node '${newNode?.name}' is being created, will take approx. 3 minutes`, {
                    icon: <UiIcon name="cycle" />,
                });
            } else {
                successMessage(`Node '${newNode?.name}' created - use ‘Deploy’ from the action menu to deploy it`, {
                    duration: 10,
                });
            }
            onClose(refreshNeeded);
        } catch (e: any) {
            refreshNeeded = false;
            if (e.hasOwnProperty('errorFields')) {
                // Handled by the form
                return null;
            } else if (e.response?.status === 409) {
                errorMessage('Error: Node with this name already exists');
            } else {
                errorMessage(`Could not save Node`);
            }
            onClose(refreshNeeded);
        }
    };

    const wizardSteps: IWizardStep[] = [
        {
            title: 'General',
            content: (
                <NeosecNodeAddFormStep1
                    form={generalForm}
                    collectorConfigsOptions={collectorConfigsOptions}
                    collectors={collectors}
                />
            ),
        },
        { title: 'Type', content: <NeosecNodeAddFormStep2 form={typeForm} /> },
    ];

    return (
        <UiWizard
            wrapClassName="CollectorAddEditModal"
            title="Add Neosec Node"
            onCancel={() => onClose(false)}
            onNext={handleNext}
            onFinish={handleSave}
            steps={wizardSteps}
        />
    );
};
