import React, { ReactNode } from 'react';
import { Select } from 'antd';
import './UiDropDown.scss';
import { icons } from '../../assets/icons';

const { Option } = Select;

export interface IDropdownOption {
    label: string;
    value: any;
    content?: ReactNode;
    disabled?: boolean;
}

interface IProps {
    controlLabel?: string;
    icon?: ReactNode;
    width?: number | string;
    dropdownMatchSelectWidth?: boolean;
    minWidth?: number;
    value?: any;
    handleChange?: any;
    defaultValue?: any;
    placeholder?: string;
    disabled?: boolean;
    ref?: HTMLSelectElement;
    options: IDropdownOption[];
}

export const UiDropDown = (props: IProps) => {
    const { options, handleChange, controlLabel, width, minWidth, dropdownMatchSelectWidth } = props;
    const getDropDownItems = () =>
        options?.map((item, index) => (
            <Option key={index} value={item.value} disabled={item.disabled} title={item.label}>
                {item.content ? (
                    <div className="icon-item">
                        <span children={item.content}></span>
                        <span className="label">{item.label}</span>
                    </div>
                ) : (
                    <span>{item.label}</span>
                )}
            </Option>
        ));

    return (
        <div className="ui-dropdown" title={options.filter((opt) => opt.value === props.value)[0]?.label}>
            {controlLabel && <span className="control-label">{controlLabel}</span>}
            <Select
                defaultValue={props.defaultValue || options[0]?.value}
                onChange={handleChange}
                disabled={props.disabled}
                placeholder={props.placeholder}
                style={{ width, minWidth }}
                value={props.value}
                suffixIcon={icons.treeOpen}
                dropdownMatchSelectWidth={dropdownMatchSelectWidth !== false}
            >
                {getDropDownItems()}
            </Select>
        </div>
    );
};
