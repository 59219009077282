import React from 'react';
import { useHistory } from 'react-router-dom';
import { removeTrailingSlashes } from '../../../../../general/utils';

import { Card } from '../../../../Card/Card';
import { IDataSource } from '../shared/dataSourceApis';

import './DataSourceCard.scss';
import { gridCellGenerator } from '../../../../Card/gridCellGenerator';

export interface IConfigCardProps {
    menu: React.ReactNode;
    ds: IDataSource;
    handleClick?: () => void;
}

export const DataSourceCard = ({ menu, ds: { id, name, numberOfCollectors } }: IConfigCardProps) => {
    const history = useHistory();

    return (
        <div
            className="between-card"
            onClick={() => history.push(`${removeTrailingSlashes(history.location.pathname)}/${id}`)}
        >
            <Card title={name} menu={menu}>
                <div className="data-source-grid">
                    {gridCellGenerator('Neosec Nodes', numberOfCollectors)}
                    {/*{gridCellGenerator('Sensors',numberOfSensors )}*/}
                </div>
            </Card>
        </div>
    );
};
