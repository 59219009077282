import React from 'react';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';

import { checkFeature } from 'services/feature-toggle/feature-toggle-service';
import { selectCurrentTenantKey } from 'api/tenantListApi';

import './NavigationMenu.scss';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    handleClick: any;
    current: string;
}

const NavigationMenu = ({ handleClick, current }: IProps) => {
    const currentTenantKey = useSelector(selectCurrentTenantKey);

    return (
        <div className="navigation-menu">
            <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
                <Menu.Item key="home">
                    <NavLink to={'/' + currentTenantKey + '/home/'}>Home</NavLink>
                </Menu.Item>
                <Menu.Item key="alerts">
                    <NavLink to={'/' + currentTenantKey + '/alerts/'}>Alerts</NavLink>
                </Menu.Item>
                <Menu.Item key="query">
                    <NavLink to={'/' + currentTenantKey + '/query/'}>Query</NavLink>
                </Menu.Item>
                <Menu.Item key="discovery">
                    <NavLink
                        to={
                            // TODO: remove checkFeature once new discovery is in prod
                            '/' +
                            currentTenantKey +
                            (checkFeature(process.env, currentTenantKey, 'new-discovery')
                                ? '/discovery/services/'
                                : '/discovery/')
                        }
                    >
                        Discovery
                    </NavLink>
                </Menu.Item>
                {
                    // TODO: Remove when testing is open for all
                    currentTenantKey && checkFeature(process.env, currentTenantKey, 'testing') ? (
                        <Menu.Item key="testing">
                            <NavLink to={'/' + currentTenantKey + '/testing/'}>Testing</NavLink>
                        </Menu.Item>
                    ) : null
                }
            </Menu>
        </div>
    );
};

export default NavigationMenu;
