import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/query';

import { deTokApi, useDeTokQuery } from 'api/deTokApi';
import { selectCurrentTenantKey } from 'api/tenantListApi';

export const useDetok = (
    tokenizedContent: string
): [isDetokenized: boolean, content: string, toggleDetok: () => void] => {
    const dispatch = useDispatch();
    const tenant = useSelector(selectCurrentTenantKey);
    const [isDetokenized, setIsDetokenized] = useState<boolean>(false);
    const {
        data: detokenizedContent,
        isLoading,
        isError,
    } = useDeTokQuery(isDetokenized ? { tenant, tokenizedContent } : skipToken);

    useEffect(() => {
        isError && setIsDetokenized(false);
    }, [isError]);

    return [
        !!(isDetokenized && detokenizedContent),
        isDetokenized && detokenizedContent ? detokenizedContent : tokenizedContent,
        () => {
            !isLoading &&
                setIsDetokenized((prevIsDetokenized) => {
                    dispatch(
                        deTokApi.util.updateQueryData(
                            'detokenize',
                            { tenant, tokenizedContent },
                            (cachedDetokenizedContent) => {
                                if (isDetokenized && detokenizedContent) {
                                    return undefined;
                                }

                                return detokenizedContent;
                            }
                        ) as unknown as AnyAction
                    );

                    return !prevIsDetokenized;
                });
        },
    ];
};
