import { ReactNode } from 'react';

import { Card } from 'components/Card/Card';
import { gridCellGenerator } from 'components/Card/gridCellGenerator';
import { IAkamaiConfig } from 'api/akamaiConfigs';
import { fullDateStringFormat } from 'general/utils';

import './AkamaiConfigurationCard.scss';

interface IAkamaiConfigurationCardProps {
    title: string;
    menu: ReactNode;
    item: IAkamaiConfig;
}
export const AkamaiConfigurationCard = ({
    title,
    menu,
    item: { updatedAt, updatedBy },
}: IAkamaiConfigurationCardProps) => {
    return (
        <div className="AkamaiConfigurationCard">
            <Card title={title} menu={menu} isDisabled={false}>
                <div className="AkamaiConfigurationGrid">
                    {gridCellGenerator('Author', updatedBy)}
                    {gridCellGenerator('Last modified', fullDateStringFormat(updatedAt, false))}
                </div>
            </Card>
        </div>
    );
};
