import React, { useEffect } from 'react';
import { Checkbox } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

interface ICheckboxFilters {
    setFilters: Function;
    serviceData?: any;
}

enum filterHideOptions {
    allHidden = 'allHidden',
    noActivity = 'noActivity',
}

const options = (totalHiddenEndpoints: number | string = '-') => [
    {
        label: `Show all hidden endpoints (${totalHiddenEndpoints})`,
        value: filterHideOptions.allHidden,
    },
    {
        label: 'Show endpoints with no activity',
        value: filterHideOptions.noActivity,
    },
];

const filterHideAllHiddenEndpoints = [{ name: 'hidden', operator: 'eq', value: false }];
const filterHideNoActivityEndpoints = [{ name: 'calls_count', operator: 'ne', value: 0 }];

export const CheckboxFilters = ({ setFilters, serviceData }: ICheckboxFilters) => {
    useEffect(() => {
        setFilters([filterHideAllHiddenEndpoints]);
    }, []);

    const onChange = (checkedValues: CheckboxValueType[]) => {
        const allHidden = checkedValues.includes('allHidden');
        const noActivity = checkedValues.includes('noActivity');

        if (checkedValues.length === 0) {
            setFilters([filterHideAllHiddenEndpoints, filterHideNoActivityEndpoints]);
            return;
        }

        if (checkedValues.length === 2) {
            setFilters([]);
        }

        if (!allHidden) {
            setFilters([filterHideAllHiddenEndpoints]);
        }

        if (!noActivity) {
            setFilters([filterHideNoActivityEndpoints]);
        }
    };

    return (
        <Checkbox.Group
            options={options(serviceData?.hidden_endpoints_count)}
            defaultValue={[filterHideOptions.noActivity]}
            onChange={onChange}
        />
    );
};
