import React from 'react';
import { Checkbox } from 'antd';

import { CheckboxValueType } from 'antd/es/checkbox/Group';

export interface IServicesCheckboxProps {
    setFilters: Function;
}

enum FilterHideOptions {
    noActivity = 'NO_ACTIVITY',
}

const options = [
    {
        label: 'Show services with no activity',
        value: FilterHideOptions.noActivity,
    },
];

const filterHideNoActivityEndpoints = [{ name: 'calls_count', operator: 'ne', value: 0 }];

export default function ServicesCheckbox({ setFilters }: IServicesCheckboxProps) {
    const onChange = (checkedValues: CheckboxValueType[]) => {
        const noActivity = checkedValues.includes('NO_ACTIVITY');

        if (noActivity) {
            setFilters([]);
        } else {
            setFilters([filterHideNoActivityEndpoints]);
        }
    };
    return <Checkbox.Group options={options} defaultValue={[FilterHideOptions.noActivity]} onChange={onChange} />;
}
