import React from 'react';

import { formatNumberDisplay } from '../../../general/utils';

import './LegendSubtotal.scss';

interface IProps {
    name: string;
    subtotal: number;
    pct: number;
    color: string;
    isDark: boolean;
}

export const LegendSubtotal = ({ name, subtotal, pct, color, isDark }: IProps) => {
    return (
        <div className={`legend-container ${isDark ? 'dark-legend' : ''}`}>
            <div className="title">
                <div className="dot-container">
                    <div className="dot" style={{ background: color }} />
                </div>
                <div className="grp-title">{name}</div>
            </div>
            <div className="value">
                <span className="number">{formatNumberDisplay(subtotal)}</span>
                <span className="pct"> ({pct}%)</span>
            </div>
        </div>
    );
};
