import { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { ColDef, ColumnApi, GridApi } from '@ag-grid-community/core';

import { IScan } from 'api/testingApi';
import { IServerQueryResponse } from 'api/baseServerApi';
import { UiIcon } from 'components/icon/UiIcon';
import { TESTING_SECTION_PATH } from 'components/Testing/Testing';
import CustomDateFilter from 'components/ui-ag-grid/customDateFilter';
import { BASIC_COLUMN_DEFINITION, UiAgGridSSRM } from 'components/ui-ag-grid/UiAgGridSSRM';
import { agSetFilter, agTextFilter, BASIC_AGGRID_COL_TYPE } from 'components/ui-ag-grid/commonOptions';
import {
    CellRenderLink,
    CellRenderRangeSensitiveMore,
    CellRenderScanStatus,
    CellRenderTimeStamp,
    CellRenderVerticalCenter,
    ServiceFilterCellRenderer,
    TableColumnHeader,
} from 'components/ui-ag-grid/customCellRenderers';
import { httpPOST } from 'general/http-service';
import { errorMessage } from 'general/toast-service';
import { IFilter } from 'interfaces/filter.interface';

export interface IScanListTableProps {
    activeOrg: string;
    onGridReady: (params: { api: GridApi; columnApi: ColumnApi }) => void;
    setScanIdToRun: (id: string) => void;
    setScanIdToDelete: (id: string) => void;
    className?: string;
}

export const ScanListTable = memo((props: IScanListTableProps) => {
    const history = useHistory();
    const [filters, setFilters] = useState<IFilter[][]>([]);

    const scanListColumnDefinitions: ColDef[] = [
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'ID',
            field: 'scan_id',
            sort: 'desc',
            cellRenderer: CellRenderLink,
            cellRendererParams: ({ value }: { value: string }) => ({ path: `scans/${value}`, text: value }),
            width: 110,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            type: 'agSetFilter',
            headerName: 'Status',
            field: 'status',
            cellRenderer: 'cellRenderScanStatus',
            cellRendererParams: ({ value }: { value: { scan_id: string; status: string } }) => ({
                value: value.status,
                path: `scans/${value.scan_id}`,
            }),
            filter: 'agSetFilter',
            filterParams: {
                ...agSetFilter.filterParams,
                cellRenderer: ServiceFilterCellRenderer,
                values: (params: any) => {
                    params.success(['Created', 'Queued', 'Completed', 'Running', 'Cancelled', 'Failed']);
                },
            },
            flex: 2,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            type: 'agSetFilter',
            headerName: 'Status Change',
            field: 'status_change',
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                displaySingleLine: true,
            },
            filter: 'customDateFilter',
            flex: 2,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Triggered By',
            field: 'triggered_by',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['clear', 'apply'],
                filterOptions: ['contains', 'notContains'],
                closeOnApply: true,
            },
            flex: 2,
            sortable: false,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Application',
            field: 'application_name',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['clear', 'apply'],
                filterOptions: ['contains', 'notContains'],
                closeOnApply: true,
            },
            flex: 2,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Scan Engine',
            field: 'scan_engine',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['clear', 'apply'],
                filterOptions: ['contains', 'notContains'],
                closeOnApply: true,
            },
            flex: 2,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'HIGH',
            headerComponentParams: { prefixIconName: 'alertHigh' },
            field: 'findings_high_count',
            suppressMenu: true,
            width: 120,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'MEDIUM',
            headerComponentParams: { prefixIconName: 'alertMedium' },
            field: 'findings_medium_count',
            suppressMenu: true,
            width: 140,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'LOW',
            headerComponentParams: { prefixIconName: 'alertLowOpaque' },
            field: 'findings_low_count',
            suppressMenu: true,
            width: 120,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'INFO',
            headerComponentParams: { prefixIconName: 'alertInfo' },
            field: 'findings_info_count',
            suppressMenu: true,
            width: 120,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: '',
            field: 'more',
            width: 64,
            flex: 0,
            cellRenderer: 'moreButtonRender',
            cellRendererParams: (params: { data: Omit<IScan, 'status'> & { status: { status: string } } }) => ({
                menuItems: [
                    {
                        icon: <UiIcon name="show" />,
                        label: 'View Details',
                        onClick: () => {
                            history.push(`/${props.activeOrg}/${TESTING_SECTION_PATH}/scans/${params.data.scan_id}`);
                        },
                    },
                    {
                        icon: <UiIcon name="clicker" />,
                        label: params.data.status.status === 'Created' ? 'Run Locally' : 'Re-run Locally',
                        disabled: ['Running', 'Queued'].includes(params.data.status.status),
                        onClick: () => {
                            props.setScanIdToRun(params.data.scan_id);
                        },
                    },
                    {
                        icon: <UiIcon name="copy" />,
                        label: 'Clone',
                        tooltip: 'Will be available in the near future',
                        disabled: true,
                        onClick: () => {},
                    },
                    {
                        icon: <UiIcon name="deleteComment" />,
                        label: 'Cancel',
                        tooltip: 'Will be available in the near future',
                        disabled: !['Running', 'Queued'].includes(params.data.status.status),
                        onClick: () => {},
                    },
                    {
                        icon: <UiIcon name="trash" />,
                        label: 'Delete',
                        tooltip: 'Will be available in the near future',
                        disabled: ['Running', 'Queued'].includes(params.data.status.status),
                        onClick: () => {
                            props.setScanIdToDelete(params.data.scan_id);
                        },
                    },
                ],
                id: (() => {
                    return params?.data?.scan_id;
                })(),
            }),
            suppressMenu: true,
            suppressMovable: true,
            filter: false,
            filterParams: null,
            sortable: false,
            resizable: false,
        },
    ];

    function getData(
        start: number,
        end: number,
        sortParams?: string
    ): Promise<AxiosResponse<IServerQueryResponse<IScan>>> {
        const sort_by = sortParams ? sortParams.split('=')[1] : 'desc(scan_id)';
        return httpPOST(`organizations/${props.activeOrg}/testing/scans/query`, {
            filters,
            sort_by,
            offset: start,
            limit: 100,
        }).catch((error) => {
            return errorMessage(`${error.response?.data?.detail || 'Unexpected error while fetching data'}`);
        });
    }

    function handleFilterChange(params: any) {
        const filterArray: IFilter[][] = Object.keys(params).reduce((acc: IFilter[][], columnName: any) => {
            console.log(params);
            const column = params[columnName];

            if (column.filterType === 'set') {
                return [
                    ...acc,
                    column.values?.map((value: any) => ({
                        name: columnName,
                        operator: 'eq',
                        value,
                    })),
                ];
            }

            if (column.filterType === 'number') {
                return [
                    ...acc,
                    [
                        {
                            name: columnName,
                            operator: column.type === 'lessThan' ? 'lt' : column.type === 'greaterThan' ? 'gt' : 'eq',
                            value: column.filter,
                        },
                    ],
                ];
            }

            if (column.filterType === 'text') {
                if (column.operator === 'OR') {
                    return [
                        ...acc,
                        [
                            {
                                name: columnName,
                                operator: column.condition1.type === 'notContains' ? 'notcontains' : 'contains',
                                value: column.condition1.filter,
                            },
                            {
                                name: columnName,
                                operator: column.condition2.type === 'notContains' ? 'notcontains' : 'contains',
                                value: column.condition2.filter,
                            },
                        ],
                    ];
                }
                if (column.operator === 'AND') {
                    return [
                        ...acc,
                        [
                            {
                                name: columnName,
                                operator: column.condition1.type === 'notContains' ? 'notcontains' : 'contains',
                                value: column.condition1.filter,
                            },
                        ],
                        [
                            {
                                name: columnName,
                                operator: column.condition2.type === 'notContains' ? 'notcontains' : 'contains',
                                value: column.condition2.filter,
                            },
                        ],
                    ];
                }

                return [
                    ...acc,
                    [
                        {
                            name: columnName,
                            operator: column.type === 'notContains' ? 'notcontains' : 'contains',
                            value: column.filter,
                        },
                    ],
                ];
            }

            if (column.filterType === 'customDateFilter') {
                return [
                    ...acc,
                    [
                        {
                            name: columnName,
                            operator: 'lt',
                            value: column.value.filterDateRange[0],
                            value_type: 'timestamp',
                        },
                    ],
                    [
                        {
                            name: columnName,
                            operator: 'ge',
                            value: column.value.filterDateRange[1],
                            value_type: 'timestamp',
                        },
                    ],
                ];
            }

            return [
                ...acc,
                [
                    {
                        name: columnName,
                        operator: column.type,
                        value: column.filter,
                        value_type: column.filterType,
                    },
                ],
            ];
        }, []);
        setFilters(filterArray);
    }

    return (
        <div className={`ScanList${props.className ? ` ${props.className}` : ''}`}>
            <UiAgGridSSRM
                isHideRowsCount
                columns={scanListColumnDefinitions}
                refetchInterval={30000}
                options={{
                    columnTypes: {
                        basic: BASIC_AGGRID_COL_TYPE,
                        agSetFilter: agSetFilter,
                        agTextFilter: agTextFilter,
                    },
                    components: {
                        agColumnHeader: TableColumnHeader,
                        moreButtonRender: CellRenderRangeSensitiveMore,
                        cellRenderVerticalCenter: CellRenderVerticalCenter,
                        cellRenderScanStatus: CellRenderScanStatus,
                        cellRenderTimeStamp: CellRenderTimeStamp,
                        customDateFilter: CustomDateFilter,
                    },
                }}
                getData={getData}
                onFilterChange={handleFilterChange}
                dataMappingFunction={(data: any) =>
                    data.map((scan: any) => {
                        for (const key in scan) {
                            if (!scan[key] && typeof scan[key] === 'string') {
                                scan[key] = '-';
                            }
                        }
                        return {
                            ...scan,
                            status: { scan_id: scan.scan_id, status: scan.status },
                        };
                    })
                }
                onGridReady={props.onGridReady}
            />
        </div>
    );
});
