import React from 'react';
import {useEntity} from "../../services/data/hooks";
import {EntityName} from "../../services/data/entityMap";
import UiMultiSelect from "../ui-multi-select/UiMultiSelect";
import {Select} from "antd";
import './AlertCategoryMultiSelect.scss';
import {sortAlphabetically} from "../../general/utils";
const {Option} = Select;

interface IAlertCategoryMultiSelectProps {
    label?: string;
    value: string[];
    width: number;
    onChange: (event: string[]) => any;
}

export const AlertCategoryMultiSelect: React.FC<IAlertCategoryMultiSelectProps> = (props) => {
    const [alertCategoryList] = useEntity<{items: {}[]}>(EntityName.AlertCategory);

    return (
        alertCategoryList ?
        <div className="alert-category-multi-select">
            <div className="acms-label">
                {props.label || 'Alert Category'}
            </div>
            <UiMultiSelect
                mode="multiple"
                showDropdown={true}
                width={props.width}
                defaultValue={props.value}
                handleChange={(event: any) => props.onChange(event)}
            >
                {
                    alertCategoryList.items
                        .sort(sortAlphabetically('category'))
                        .map((alertCategory: any) =>
                            <Option key={alertCategory.category} value={alertCategory.category}>{alertCategory.category}</Option>
                        )
                }
            </UiMultiSelect>
        </div> : null
    );
}
