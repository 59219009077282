import React from 'react';

import './ReleaseNotes.scss';

export const ReleaseNotes = () => (
    <div className="ReleaseNotes">
        <h2>Release Notes</h2>
        <div className="release-title">Jan 2023 Release</div>
        <table id="issuetable" className="aui " border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr className="rowHeader">
                    <th className="colHeaderLink headerrow-issuekey" data-id="issuekey">
                        Key
                    </th>

                    <th className="colHeaderLink headerrow-summary" data-id="summary">
                        Summary
                    </th>

                    <th className="colHeaderLink headerrow-updated" data-id="updated">
                        Updated
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr id="issuerow11396" data-rel="11396" data-issuekey="PROT-1356" className="issuerow">
                    <td className="issuekey">PROT-1356</td>
                    <td className="summary">
                        <p>Alerts Table | Add &#39;Export to CSV&#39; functionality</p>
                    </td>
                    <td className="updated"> 29/Jan/23 4:40 PM</td>
                </tr>

                <tr id="issuerow20074" data-rel="20074" data-issuekey="PROT-9936" className="issuerow">
                    <td className="issuekey">PROT-9936</td>
                    <td className="summary">
                        <p>Alerts | Comments on Alerts</p>
                    </td>
                    <td className="updated"> 23/Jan/23 4:03 PM</td>
                </tr>

                <tr id="issuerow18874" data-rel="18874" data-issuekey="PROT-8802" className="issuerow">
                    <td className="issuekey">PROT-8802</td>
                    <td className="summary">
                        <p>Collector | Neosec Node images to simplify installation</p>
                    </td>
                    <td className="updated"> 22/Jan/23 2:06 PM</td>
                </tr>

                <tr id="issuerow16422" data-rel="16422" data-issuekey="PROT-6355" className="issuerow">
                    <td className="issuekey">PROT-6355</td>
                    <td className="summary">
                        <p>UI | New Look and Feel</p>
                    </td>
                    <td className="updated"> 22/Jan/23 10:51 AM</td>
                </tr>

                <tr id="issuerow18176" data-rel="18176" data-issuekey="PROT-8106" className="issuerow">
                    <td className="issuekey">PROT-8106</td>
                    <td className="summary">
                        <p>Status Indication in Entity Timeline</p>
                    </td>
                    <td className="updated"> 16/Jan/23 3:58 PM</td>
                </tr>

                <tr id="issuerow17572" data-rel="17572" data-issuekey="PROT-7502" className="issuerow">
                    <td className="issuekey">PROT-7502</td>
                    <td className="summary">
                        <p>Upgrade several key libraries FE uses</p>
                    </td>
                    <td className="updated"> 16/Jan/23 3:58 PM</td>
                </tr>
            </tbody>
        </table>
        <div className="release-title">Dec 2022 Release</div>
        <table id="issuetable" className="aui " border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr className="rowHeader">
                    <th className="colHeaderLink headerrow-issuekey" data-id="issuekey">
                        Key
                    </th>

                    <th className="colHeaderLink headerrow-summary" data-id="summary">
                        Summary
                    </th>

                    <th className="colHeaderLink headerrow-updated" data-id="updated">
                        Updated
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr id="issuerow18126" data-rel="18126" data-issuekey="PROT-8056" className="issuerow">
                    <td className="issuekey">PROT-8056</td>
                    <td className="summary">
                        <p>Entity Management in Neosec UI</p>
                    </td>
                    <td className="updated"> 27/Dec/22 11:42 AM</td>
                </tr>

                <tr id="issuerow18434" data-rel="18434" data-issuekey="PROT-8362" className="issuerow">
                    <td className="issuekey">PROT-8362</td>
                    <td className="summary">
                        <p>Service level labels</p>
                    </td>
                    <td className="updated"> 25/Dec/22 3:56 PM</td>
                </tr>

                <tr id="issuerow18340" data-rel="18340" data-issuekey="PROT-8268" className="issuerow">
                    <td className="issuekey">PROT-8268</td>
                    <td className="summary">
                        <p>Display tenant name and user in the UI top bar</p>
                    </td>
                    <td className="updated"> 19/Dec/22 12:25 PM</td>
                </tr>

                <tr id="issuerow18620" data-rel="18620" data-issuekey="PROT-8548" className="issuerow">
                    <td className="issuekey">PROT-8548</td>
                    <td className="summary">
                        <p>Endpoint type classification</p>
                    </td>
                    <td className="updated"> 08/Dec/22 2:13 PM</td>
                </tr>
            </tbody>
        </table>
        <div className="release-title">Oct 2022 Release</div>
        <table id="issuetable" className="aui " border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr className="rowHeader">
                    <th className="colHeaderLink headerrow-issuekey" data-id="issuekey">
                        Key
                    </th>

                    <th className="colHeaderLink headerrow-summary" data-id="summary">
                        Summary
                    </th>

                    <th className="colHeaderLink headerrow-updated" data-id="updated">
                        Updated
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr id="issuerow18626" data-rel="18626" data-issuekey="PROT-8554" className="issuerow">
                    <td className="issuekey">PROT-8554</td>
                    <td className="summary">
                        <p>User deletion of automatically-classified endpoint type labels</p>
                    </td>
                    <td className="updated"> 23/Oct/22 6:13 PM</td>
                </tr>
            </tbody>
        </table>
        <div className="release-title">Sep 2022 Release</div>
        <table id="issuetable" className="aui " border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr className="rowHeader">
                    <th className="colHeaderLink headerrow-issuekey" data-id="issuekey">
                        Key
                    </th>

                    <th className="colHeaderLink headerrow-summary" data-id="summary">
                        Summary
                    </th>

                    <th className="colHeaderLink headerrow-updated" data-id="updated">
                        Updated
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr id="issuerow13654" data-rel="13654" data-issuekey="PROT-3588" className="issuerow">
                    <td className="issuekey">PROT-3588</td>
                    <td className="summary">
                        <p>Collector | Managing Collectors and Their Configurations in Neosec Cloud</p>
                    </td>
                    <td className="updated"> 29/Sep/22 12:25 PM</td>
                </tr>

                <tr id="issuerow18822" data-rel="18822" data-issuekey="PROT-8750" className="issuerow">
                    <td className="issuekey">PROT-8750</td>
                    <td className="summary">
                        <p>Discovery | Enhancing recently added endpoints in the discovery pages</p>
                    </td>
                    <td className="updated"> 08/Sep/22 4:20 PM</td>
                </tr>

                <tr id="issuerow17649" data-rel="17649" data-issuekey="PROT-7579" className="issuerow">
                    <td className="issuekey">PROT-7579</td>
                    <td className="summary">
                        <p>Improving Loading time of Endpoint in any page in the UI</p>
                    </td>
                    <td className="updated"> 05/Sep/22 5:03 PM</td>
                </tr>
            </tbody>
        </table>
        <div className="release-title">Aug 2022 Release</div>
        <table id="issuetable" className="aui " border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr className="rowHeader">
                    <th className="colHeaderLink headerrow-issuekey" data-id="issuekey">
                        Key
                    </th>

                    <th className="colHeaderLink headerrow-summary" data-id="summary">
                        Summary
                    </th>

                    <th className="colHeaderLink headerrow-updated" data-id="updated">
                        Updated
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr id="issuerow17571" data-rel="17571" data-issuekey="PROT-7501" className="issuerow">
                    <td className="issuekey">PROT-7501</td>
                    <td className="summary">
                        <p>Decide and implement a good modern test automation procedures for the FE code</p>
                    </td>
                    <td className="updated"> 25/Aug/22 12:18 PM</td>
                </tr>

                <tr id="issuerow18508" data-rel="18508" data-issuekey="PROT-8436" className="issuerow">
                    <td className="issuekey">PROT-8436</td>
                    <td className="summary">
                        <p>Collector | Debian support</p>
                    </td>
                    <td className="updated"> 22/Aug/22 5:57 PM</td>
                </tr>

                <tr id="issuerow12285" data-rel="12285" data-issuekey="PROT-2245" className="issuerow">
                    <td className="issuekey">PROT-2245</td>
                    <td className="summary">
                        <p>Discovery page | New Discovery page</p>
                    </td>
                    <td className="updated"> 11/Aug/22 1:21 PM</td>
                </tr>

                <tr id="issuerow17015" data-rel="17015" data-issuekey="PROT-6946" className="issuerow">
                    <td className="issuekey">PROT-6946</td>
                    <td className="summary">
                        <p>Discovery page | UI refinement (Phase 2)</p>
                    </td>
                    <td className="updated"> 02/Aug/22 8:57 PM</td>
                </tr>
            </tbody>
        </table>
        <div className="release-title">Jul 2022 Release</div>
        <table id="issuetable" className="aui " border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr className="rowHeader">
                    <th className="colHeaderLink headerrow-issuekey" data-id="issuekey">
                        Key
                    </th>

                    <th className="colHeaderLink headerrow-summary" data-id="summary">
                        Summary
                    </th>

                    <th className="colHeaderLink headerrow-updated" data-id="updated">
                        Updated
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr id="issuerow15988" data-rel="15988" data-issuekey="PROT-5922" className="issuerow">
                    <td className="issuekey">PROT-5922</td>
                    <td className="summary">
                        <p>Discovery page | Hide endpoints</p>
                    </td>
                    <td className="updated"> 11/Jul/22 3:54 PM</td>
                </tr>
            </tbody>
        </table>
        <div className="release-title">Mar 2022 Release</div>
        <table id="issuetable" className="aui " border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr className="rowHeader">
                    <th className="colHeaderLink headerrow-issuekey" data-id="issuekey">
                        Key
                    </th>

                    <th className="colHeaderLink headerrow-summary" data-id="summary">
                        Summary
                    </th>

                    <th className="colHeaderLink headerrow-updated" data-id="updated">
                        Updated
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr id="issuerow12629" data-rel="12629" data-issuekey="PROT-2588" className="issuerow">
                    <td className="issuekey">PROT-2588</td>
                    <td className="summary">
                        <p>Source ID and Collector ID</p>
                    </td>
                    <td className="updated"> 20/Mar/22 4:15 PM</td>
                </tr>

                <tr id="issuerow12442" data-rel="12442" data-issuekey="PROT-2402" className="issuerow">
                    <td className="issuekey">PROT-2402</td>
                    <td className="summary">
                        <p>Alerts page | Savable alert filters</p>
                    </td>
                    <td className="updated"> 20/Mar/22 3:58 PM</td>
                </tr>
            </tbody>
        </table>
        <div className="release-title">Feb 2022 Release</div>
        <table id="issuetable" className="aui " border={1} cellPadding="3" cellSpacing="1" width="100%">
            <thead>
                <tr className="rowHeader">
                    <th className="colHeaderLink headerrow-issuekey" data-id="issuekey">
                        Key
                    </th>

                    <th className="colHeaderLink headerrow-summary" data-id="summary">
                        Summary
                    </th>

                    <th className="colHeaderLink headerrow-updated" data-id="updated">
                        Updated
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr id="issuerow12159" data-rel="12159" data-issuekey="PROT-2119" className="issuerow">
                    <td className="issuekey">PROT-2119</td>
                    <td className="summary">
                        <p>Change Font Throughout the UI</p>
                    </td>
                    <td className="updated"> 17/Feb/22 4:49 PM</td>
                </tr>

                <tr id="issuerow11974" data-rel="11974" data-issuekey="PROT-1934" className="issuerow">
                    <td className="issuekey">PROT-1934</td>
                    <td className="summary">
                        <p>Entity timeline | Add Typical User-Agent for actor entities (and some small stuff)</p>
                    </td>
                    <td className="updated"> 17/Feb/22 4:47 PM</td>
                </tr>

                <tr id="issuerow11523" data-rel="11523" data-issuekey="PROT-1483" className="issuerow">
                    <td className="issuekey">PROT-1483</td>
                    <td className="summary">
                        <p>Entity timeline | Show Total and Activity graphs for entities</p>
                    </td>
                    <td className="updated"> 17/Feb/22 4:34 PM</td>
                </tr>

                <tr id="issuerow11229" data-rel="11229" data-issuekey="PROT-1189" className="issuerow">
                    <td className="issuekey">PROT-1189</td>
                    <td className="summary">
                        <p>Alert presentation | Provide more info on alert</p>
                    </td>
                    <td className="updated"> 17/Feb/22 10:41 AM</td>
                </tr>

                <tr id="issuerow11210" data-rel="11210" data-issuekey="PROT-1170" className="issuerow">
                    <td className="issuekey">PROT-1170</td>
                    <td className="summary">
                        <p>Discovery page | Link directly to endpoint</p>
                    </td>
                    <td className="updated"> 17/Feb/22 10:38 AM</td>
                </tr>

                <tr id="issuerow11114" data-rel="11114" data-issuekey="PROT-1074" className="issuerow">
                    <td className="issuekey">PROT-1074</td>
                    <td className="summary">
                        <p>IP Enrichment | Display IP enrichment for IP actor entities</p>
                    </td>
                    <td className="updated"> 17/Feb/22 10:37 AM</td>
                </tr>

                <tr id="issuerow11007" data-rel="11007" data-issuekey="PROT-967" className="issuerow">
                    <td className="issuekey">PROT-967</td>
                    <td className="summary">
                        <p>Alerting | Add severity levels, labels, filters</p>
                    </td>
                    <td className="updated"> 17/Feb/22 10:33 AM</td>
                </tr>
            </tbody>
        </table>
    </div>
);
