import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { ColDef } from '@ag-grid-community/core';

import { IFilterState } from '../ui-ag-grid/UiAgGridCSRM';
import { FilterTag } from './filter-tag/FilterTag';

import './FilterTagList.scss';

export interface IFilterTagProps {
    filterTags: IFilterState[];
    resetFilter?: (field: string) => any;
    resetAllFilters?: any;
    isShiftedLeft?: boolean;
}

const operatorsSymbolDict: Map<string, string> = new Map([
    ['equals', '='],
    ['lessThan', '<='],
    ['greaterThan', '>='],
]);

export function getConditionStr({ type, filter }: { type: string; filter: string }, isSymbol: boolean = false) {
    return (
        <>
            <span className="condition-title">{isSymbol ? operatorsSymbolDict.get(type) : type} </span>
            <span>{filter}</span>
        </>
    );
}

export function getTooltipContent(values: string[]): JSX.Element[] {
    const tooltipDisplayCount = 20;
    const list = values.slice(0, tooltipDisplayCount).map((value: string) => <div key={value}>{value}</div>);
    if (values.length > tooltipDisplayCount) {
        list.push(<div>+{values.length - tooltipDisplayCount} more</div>);
    }
    return list;
}

export function getFilterState(filterModel: { [key: string]: any }): (colDef: ColDef) => IFilterState {
    return function (col: ColDef): IFilterState {
        const colFilterModel = filterModel[col.field as string];
        let activeValues = [];
        let tagDisplay = undefined;
        let tooltipDisplay = undefined;
        if (colFilterModel?.filterType === 'set') {
            activeValues = colFilterModel?.values;
            tooltipDisplay = getTooltipContent(activeValues);
        } else if (colFilterModel?.filterType === 'text') {
            tagDisplay = colFilterModel?.operator ? (
                <span>
                    {getConditionStr(colFilterModel.condition1)} {colFilterModel?.operator}{' '}
                    {getConditionStr(colFilterModel.condition2)}
                </span>
            ) : (
                <span>{getConditionStr(colFilterModel)}</span>
            );
            tooltipDisplay = tagDisplay;
        } else if (colFilterModel?.filterType === 'customDateFilter') {
            const dateRange = colFilterModel.value.filterDateRange.map((dateStr: string) => moment(dateStr));
            const showYear = dateRange[0].format('YYYY') !== dateRange[1].format('YYYY');
            const showMonth = dateRange[0].format('M') !== dateRange[1].format('M') || showYear;
            const fromFormat = `DD ${showMonth ? 'MMM' : ''} ${showYear ? 'YYYY' : ''}`;
            tagDisplay = (
                <span>
                    {dateRange[0].format(fromFormat)}- {dateRange[1].format('DD MMM YYYY')}
                </span>
            );
            tooltipDisplay = tagDisplay;
        } else if (colFilterModel?.filterType === 'number') {
            tagDisplay = <span>{getConditionStr(colFilterModel, true)}</span>;
            tooltipDisplay = tagDisplay;
        }

        return {
            colName: col.headerName || '',
            field: col.field || '',
            active: !!colFilterModel?.filterType,
            filterType: colFilterModel?.filterType,
            modified: colFilterModel?.modified,
            activeValues,
            tooltipDisplay,
            tagDisplay,
        };
    };
}

export const listDisplayOption = (props: IFilterTagProps) => {
    const displayDataTemp = props.filterTags
        .filter((filterTag: IFilterState) => filterTag.active)
        .map((filterTag: IFilterState, index) => {
            return <FilterTag filterTag={filterTag} index={index} resetFilter={props.resetFilter} />;
        });

    if (displayDataTemp.length > 1 && !!props.resetFilter) {
        displayDataTemp.push(
            <Button key={props.filterTags.length} type="link" className="clear-all" onClick={props.resetAllFilters}>
                Clear All
            </Button>
        );
    }
    return displayDataTemp;
};

export const FilterTagList = (props: IFilterTagProps) => {
    const [displayData, setDisplayData] = useState<JSX.Element[]>([]);

    useEffect(() => {
        const data = listDisplayOption(props);
        setDisplayData(data);
    }, [props.filterTags]);

    return <div className="filter-tag-list">{displayData}</div>;
};
