import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
    CellRenderDetokenizable,
    CellRenderGenericMore,
    CellRenderTimeStamp,
    CellRenderToggle,
    CellRenderVerticalCenter,
} from '../../../ui-ag-grid/customCellRenderers';
import { CustomTooltip } from '../../../ui-ag-grid/customToolTip';
import { BASIC_AGGRID_COL_TYPE } from '../../../ui-ag-grid/commonOptions';
import { IRulesTableItem, ISuppressionRule } from '../../../../interfaces/throttle.interface';
import { stringifyRulePredicate, TimeDisplayResEnum } from '../../../../general/utils';
import { UiIcon } from '../../../icon/UiIcon';
import { openDialog } from '../../../../services/dialog/dialog-service';
import { httpDelete, httpGet, httpPatch } from '../../../../general/http-service';
import { ruleActionTypeEnum } from '../../../../interfaces/ruleActionType.enum';
import { SettingsSectionHeader } from '../SettingsSectionHeader/SettingsSectionHeader';
import { UiAgGridCSRM } from '../../../ui-ag-grid/UiAgGridCSRM';

import './SuppressionRuleList.scss';

const gridOptions: any = {
    rowHeight: 70,
    rowDragManaged: true,
    components: {
        cellRenderTimeStamp: CellRenderTimeStamp,
        cellRenderGenericMore: CellRenderGenericMore,
        cellRenderVerticalCenter: CellRenderVerticalCenter,
        cellRenderToggle: CellRenderToggle,
        cellRenderDetokenizable: CellRenderDetokenizable,
        customTooltip: CustomTooltip,
    },
    columnTypes: {
        basic: BASIC_AGGRID_COL_TYPE,
    },
    rowClassRules: {
        'disabled-row': function (params: any) {
            return !params.data?.isActiveRule || false;
        },
    },
};

export const SuppressionRuleList = () => {
    const history = useHistory();
    const params = useParams() as { activeOrg: string };
    const activeOrg = params.activeOrg;
    const [tableData, setTableData] = useState<IRulesTableItem[]>([]);
    const [user, setUser] = useState<{ username: string }>();

    const columnsDefs = [
        {
            headerName: 'No.',
            rowDrag: true,
            field: 'order',
            type: 'basic',
            width: 80,
            cellRenderer: 'cellRenderVerticalCenter',
            sortable: false,
            headerTooltip: 'Order',
        },
        {
            headerName: 'State',
            field: 'isActiveRule',
            type: 'basic',
            width: 100,
            sortable: false,
            cellRenderer: 'cellRenderToggle',
            headerTooltip: 'Disabled/Enabled',
            cellRendererParams: (params: any) => {
                return {
                    onRowToggleHandler: (e: boolean, idx: number, ruleId: string) => onRowToggleHandler(e, idx, ruleId),
                };
            },
        },
        {
            headerName: 'Name',
            field: 'name',
            type: 'basic',
            width: 120,
            flex: 1,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'Rule name',
        },
        {
            headerName: 'Match',
            field: 'match',
            type: 'basic',
            width: 180,
            flex: 1,
            cellRenderer: 'cellRenderDetokenizable',
            sortable: false,
            headerTooltip: 'Rule match conditions',
        },
        {
            headerName: 'Action',
            field: 'action',
            type: 'basic',
            width: 110,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'Action to take on rule match',
        },
        {
            headerName: 'Hits',
            field: 'hits',
            type: 'basic',
            width: 110,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'The number of rule hits',
        },
        {
            headerName: 'Created At',
            field: 'lastModified',
            type: 'basic',
            width: 160,
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                timeResolution: TimeDisplayResEnum.MIN,
            },
            headerTooltip: 'Rule’s last modification timestamp',
        },
        {
            headerName: 'Author',
            field: 'author',
            type: 'basic',
            width: 140,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'Rule creator',
        },
        {
            headerName: 'Last Modified',
            field: 'lastModified',
            type: 'basic',
            width: 160,
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                timeResolution: TimeDisplayResEnum.MIN,
            },
            headerTooltip: 'Rule’s last modification timestamp',
        },
        {
            headerName: 'Last Modified By',
            field: 'LastModifiedBy',
            type: 'basic',
            width: 180,
            cellRenderer: 'cellRenderVerticalCenter',
            sortable: false,
            test: 'test',
            headerTooltip: 'Last user to modify rule',
        },
        {
            headerName: '',
            field: 'overlay',
            sortable: false,
            width: 60,
            type: 'basic',
            cellRenderer: 'cellRenderGenericMore',
            cellRendererParams: (params: any) => {
                const menuItems = [
                    {
                        label: `Edit Rule`,
                        icon: <UiIcon name="pencil" />,
                        onClick: () => {
                            history.push(`/${activeOrg}/settings/suppression/rule/${params.data?.id}`);
                        },
                    },
                    {
                        label: `Delete Rule`,
                        icon: <UiIcon name="trash" />,
                        onClick: () => {
                            openDialog({
                                text: `Are you sure you would like to permanently delete Rule ${params.data.name}?`,
                                icon: 'trash2',
                                title: 'Delete Rule',
                            })
                                .then(() => deleteRuleHandler(params.data.id))
                                .catch(() => {});
                        },
                    },
                ];
                return { menuItems };
            },
        },
    ];

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((authUser) => {
            setUser(authUser);
        });
    }, []);

    useEffect(() => {
        getAndSetRulesData();
    }, []);

    function getAndSetRulesData() {
        return httpGet(`organizations/${activeOrg}/throttling_rules`).then((res) => {
            const rulesData = res.data?.items?.map((rule: ISuppressionRule, idx: number) => {
                return {
                    isActiveRule: rule.status === 'enabled',
                    id: rule.id,
                    order: rule.rule_order + 1,
                    name: rule.rule_name || 'no name provided',
                    match: stringifyRulePredicate(rule.predicate),
                    action: rule.frequency === 0 ? ruleActionTypeEnum.SUPPRESS : ruleActionTypeEnum.THROTTLE,
                    hits: rule.hit_count,
                    author: rule.created_by,
                    lastModified: rule.updated_at,
                    LastModifiedBy: rule.updated_by,
                };
            });
            setTableData(rulesData);
        });
    }

    function deleteRuleHandler(ruleId: string) {
        httpDelete(`organizations/${activeOrg}/throttling_rules/${ruleId}`)
            .then(() => {
                getAndSetRulesData();
            })
            .catch((error) => {
                console.log(`An error occurred while deleting rule ${error}`);
                getAndSetRulesData();
            });
    }

    function onRowToggleHandler(e: boolean, rowNumber: number, ruleId: string) {
        const newStatus = e ? 'enabled' : 'disabled';
        httpPatch(`organizations/${activeOrg}/throttling_rules/${ruleId}`, {
            status: newStatus,
            updated_by: user?.username,
        })
            .then(() => {
                getAndSetRulesData();
            })
            .catch((error) => {
                console.log(`An error occurred while toggling rule ${error}`);
                getAndSetRulesData();
            });
    }

    function onRowDragEnd(e: any) {
        httpPatch(`organizations/${activeOrg}/throttling_rules/${e.node.data.id}`, {
            rule_order: e.overIndex,
            updated_by: user?.username,
        })
            .then(() => {
                getAndSetRulesData();
            })
            .catch((error) => {
                console.log(`An error occurred while re-ordering order ${error}`);
                getAndSetRulesData();
            });
    }

    return (
        <div className="suppression-rules-container">
            <SettingsSectionHeader
                title="Suppression and Throttling Rules"
                urlPath="settings/suppression/rule"
                buttonText="Add Rule"
            />
            {user && (
                <div className="suppression-rules-table">
                    <UiAgGridCSRM
                        options={gridOptions}
                        draggableRows
                        data={tableData}
                        showRowCount={true}
                        columns={columnsDefs}
                        rowDragEndHandler={onRowDragEnd}
                        rowCountTitle="Rules"
                    />
                </div>
            )}
        </div>
    );
};
