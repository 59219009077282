import { useParams } from 'react-router-dom';
import { Select } from 'antd';

import { useGetServicesQuery } from 'api/discoveryApi';
import { ISettingsPathParams } from 'components/settings/Settings';
import UiMultiSelect from 'components/ui-multi-select/UiMultiSelect';

import './ServiceMultiSelect.scss';

const { Option } = Select;

export type IServiceMultiSelectValue = any;

export interface IServiceMultiSelectProps {
    value: IServiceMultiSelectValue[];
    onChange: (event: IServiceMultiSelectValue) => any;
    label?: string;
    width?: number;
}

export const ServiceMultiSelect: React.FC<IServiceMultiSelectProps> = (props: IServiceMultiSelectProps) => {
    const { activeOrg } = useParams<ISettingsPathParams>();
    const { data: serviceList } = useGetServicesQuery(activeOrg);

    return serviceList ? (
        <div className="service-multi-select">
            <div className="sms-label">{props.label || 'Service'}</div>
            <UiMultiSelect
                mode="multiple"
                showDropdown={true}
                width={props.width}
                defaultValue={props.value}
                handleChange={(event: any) => props.onChange(event)}
            >
                {serviceList.items.map((service) => (
                    <Option key={service.name} value={service.name}>
                        {service.name}
                    </Option>
                ))}
            </UiMultiSelect>
        </div>
    ) : null;
};
