import { ReactPortal } from 'react';
import { createPortal } from 'react-dom';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';

import { ITab, UiTabsNav } from 'components/ui-tabs-nav/UiTabsNav';
import { UiTooltip } from 'components/ui-tooltip/UiTooltip';
import { ScanList } from 'components/Testing/ScanList/ScanList';
import { ScanDetails } from 'components/Testing/ScanDetails/ScanDetails';
import { FindingTypeDetails } from 'components/Testing/FindingTypeDetails/FindingTypeDetails';
import { checkFeature } from 'services/feature-toggle/feature-toggle-service';

import './Testing.scss';

enum subsectionKeys {
    SCANS = 'scans',
    APPLICATIONS = 'applications',
    SCAN_TEMPLATES = 'scanTemplates',
    TEST_CONFIGS = 'testConfigs',
    AUTH_CONFIGS = 'authConfigs',
}

export const TESTING_SECTION_PATH = 'testing';

const TESTING_CTA_BUTTON_PORTAL_ID = 'testing-cta-button-portal';
const getTestingCtaPortal = () => document.getElementById(TESTING_CTA_BUTTON_PORTAL_ID);
export const renderCtaButton = (buttonElm: JSX.Element): ReactPortal =>
    createPortal(buttonElm, getTestingCtaPortal() || document.createElement('div'));

const SUBSECTION_NAV_TABS: ITab[] = [
    {
        key: subsectionKeys.SCANS,
        title: 'Scans',
    },
    {
        key: subsectionKeys.APPLICATIONS,
        title: (
            <UiTooltip title="Will be available in the near future" disableCopyButton>
                Applications
            </UiTooltip>
        ),
        disabled: true,
    },
    {
        key: subsectionKeys.SCAN_TEMPLATES,
        title: (
            <UiTooltip title="Will be available in the near future" disableCopyButton>
                Scan Templates
            </UiTooltip>
        ),
        disabled: true,
    },
    {
        key: subsectionKeys.TEST_CONFIGS,
        title: (
            <UiTooltip title="Will be available in the near future" disableCopyButton>
                Test Configurations
            </UiTooltip>
        ),
        disabled: true,
    },
    {
        key: subsectionKeys.AUTH_CONFIGS,
        title: (
            <UiTooltip title="Will be available in the near future" disableCopyButton>
                Auth Configurations
            </UiTooltip>
        ),
        disabled: true,
    },
];

export interface ITestingPathParams {
    activeOrg: string;
    subsection?: subsectionKeys;
}

export const Testing = () => {
    const { activeOrg } = useParams<ITestingPathParams>();
    const history = useHistory();

    return (
        // TODO: Remove when testing is open for all
        checkFeature(process.env, activeOrg, 'testing') ? (
            <div className="Testing">
                <Switch>
                    <Route path={`/:activeOrg/${TESTING_SECTION_PATH}/:subsection`} exact>
                        <div className="subsections-command-bar">
                            <UiTabsNav
                                tabs={SUBSECTION_NAV_TABS}
                                onTabSelected={(subsectionId) => {
                                    history.push(`/${activeOrg}/${TESTING_SECTION_PATH}/${subsectionId}`);
                                }}
                                className="tabs-nav"
                            />
                            <div id={TESTING_CTA_BUTTON_PORTAL_ID}></div>
                        </div>
                    </Route>
                </Switch>
                <div className="testing-content">
                    <Switch>
                        <Route exact path={`/:activeOrg/${TESTING_SECTION_PATH}`}>
                            <Redirect to={`/${activeOrg}/${TESTING_SECTION_PATH}/scans`} />
                        </Route>
                        <Route path={`/:activeOrg/${TESTING_SECTION_PATH}/scans`} exact>
                            <ScanList activeOrg={activeOrg} />
                        </Route>
                        <Route path={`/:activeOrg/${TESTING_SECTION_PATH}/scans/:scanId`} exact>
                            <ScanDetails />
                        </Route>
                        <Route
                            path={`/:activeOrg/${TESTING_SECTION_PATH}/scans/:scanId/findingTypes/:findingTypeId`}
                            exact
                        >
                            <FindingTypeDetails />
                        </Route>
                    </Switch>
                </div>
            </div>
        ) : null
    );
};
