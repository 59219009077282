import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EntityMultiSelect, EntityMultiSelectState } from './EntityMultiSelect';
import { UiIcon } from '../icon/UiIcon';
import { httpGet } from '../../general/http-service';
import { errorMessage } from '../../general/toast-service';
import { ISettingsPathParams } from '../settings/Settings';
import './EntityMultiSelect.scss';

export interface EntityMultiSelectListProps {
    initialState: EntityMultiSelectState[];
    onChange: (event: EntityMultiSelectState[]) => void;
}

export type TEntityFamily = string;

export interface IEntityType {
    source_attribute: string;
    index: number;
    name: string;
    pretty_name: string;
    class: string;
    family: TEntityFamily;
    value_type: string;
    column_name: string;
}

export function fetchEntityTypes(activeOrg: string): Promise<IEntityType[]> {
    return httpGet(`organizations/${activeOrg}/entities`)
        .then((res) => {
            return res.data?.items;
        })
        .catch((error) => {
            return errorMessage(`${error.response?.data?.detail || 'Could not fetch entity types'}`);
        });
}

export const EntityMultiSelectList = (props: EntityMultiSelectListProps) => {
    const [componentState, setComponentState] = useState<EntityMultiSelectListProps['initialState']>(
        props.initialState
    );
    const [entityTypeList, setEntityTypeList] = useState<IEntityType[]>();
    const { activeOrg } = useParams<ISettingsPathParams>();

    useEffect(() => {
        fetchEntityTypes(activeOrg).then(setEntityTypeList);
    }, []);

    function onChange(event: EntityMultiSelectState, index: number) {
        const newState = [...componentState];
        newState.splice(index, 1, event);
        setComponentState(newState);
        props.onChange(newState);
    }

    function remove(index: number) {
        const newState = [...componentState];
        newState.splice(index, 1);
        setComponentState(newState);
        props.onChange(newState);
    }

    function add() {
        const newState = componentState.concat([{ entity: { type: '', id: [] } }]);
        setComponentState(newState);
    }

    return entityTypeList ? (
        <div className="entity-multi-select-list">
            <div className="ems-label">Associated Entity</div>
            {componentState.map((entityMultiSelectState, i) => (
                <div key={`${i}${entityMultiSelectState.entity.type}`}>
                    {i > 0 ? (
                        <div className="ems-separator">
                            AND<div className="ems-separator-line"></div>
                        </div>
                    ) : null}
                    <div
                        style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginBottom: '15px' }}
                    >
                        <EntityMultiSelect
                            initialState={entityMultiSelectState}
                            onChange={(event) => onChange(event, i)}
                            entityTypeList={entityTypeList!}
                        />
                        <span style={{ marginLeft: '15px' }}>
                            <UiIcon name="remove" onClick={() => remove(i)} />
                        </span>
                    </div>
                </div>
            ))}
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => add()}>
                <UiIcon name="add" />
                <span style={{ color: '#436ace', marginLeft: '5px' }}>Add Entity</span>
            </div>
        </div>
    ) : null;
};
