import React, { useState } from 'react';
import { Checkbox, Form, Input, Radio, Select } from 'antd';
import { alphaNumericWhitespaceAndSpecialCharsRule, formFieldRequired } from '../../general/forms';

export interface F5IntegrationConfig {
    admin_api_url: string;
    basic_auth_username: string;
    basic_auth_password: string;
    action: 'block_ip' | 'rate_limit';
    timeout: number;
    requests: number;
    frequency: number;
    timeframe: 'minute' | 'hour' | 'day';
}

export interface F5IntegrationActionProps {
    config: F5IntegrationConfig;
    onChange: (event: F5IntegrationConfig) => any;
}

export const F5IntegrationAction = ({ config, onChange }: F5IntegrationActionProps) => {
    const [timeoutCheckbox, setTimeoutCheckbox] = useState<boolean>(config.timeout > 0);
    const [form] = Form.useForm();

    return (
        <div style={{ position: 'relative', marginTop: '-5px' }}>
            <div className="control-label" style={{ marginBottom: '16px' }}>
                Set F5 Policy
            </div>
            <Form
                form={form}
                initialValues={{ ...config }}
                onValuesChange={(changedValues, allValues) => onChange(allValues)}
            >
                <Form.Item name="admin_api_url" label="Admin API URL">
                    <Input />
                </Form.Item>
                <Form.Item name="basic_auth_username" label="Basic Auth Username">
                    <Input />
                </Form.Item>
                <Form.Item name="basic_auth_password" label="Basic Auth password">
                    <Input />
                </Form.Item>
                <Form.Item
                    name="partition"
                    label="Partition"
                    initialValue="Common"
                    rules={[{ message: formFieldRequired.errorMessage }, alphaNumericWhitespaceAndSpecialCharsRule]}
                >
                    <Input maxLength={64} />
                </Form.Item>
                <Form.Item
                    name="denyListDataGroupName"
                    label="Deny list data group name"
                    initialValue="neosec-blacklist-ips-dg"
                    rules={[{ message: formFieldRequired.errorMessage }, alphaNumericWhitespaceAndSpecialCharsRule]}
                >
                    <Input maxLength={64} />
                </Form.Item>
                <Form.Item name="action" label="Action">
                    <Select style={{ minWidth: 500 }}>
                        <Select.Option value="block_ip">Block IP</Select.Option>
                        <Select.Option value="rate_limit">Rate Limit IP</Select.Option>
                    </Select>
                </Form.Item>
                <div style={{ display: 'flex', alignItems: 'baseline', gap: '15px' }}>
                    <Checkbox
                        defaultChecked={timeoutCheckbox}
                        onChange={(event) => {
                            if (!event.target?.checked) {
                                form.setFieldsValue({ timeout: 0 });
                                onChange({ ...form.getFieldsValue(), timeout: 0 });
                            }
                            setTimeoutCheckbox(event.target?.checked || false);
                        }}
                    />
                    <Form.Item name="timeout" label="Timeout">
                        <Input disabled={!timeoutCheckbox} style={{ width: '50px' }} />
                    </Form.Item>
                    <div className="control-label">Minutes</div>
                </div>
                {(!form.getFieldValue('action') && config.action === 'rate_limit') ||
                form.getFieldValue('action') === 'rate_limit' ? (
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                        <Form.Item name="requests">
                            <Input style={{ width: '50px', marginRight: '5px' }}></Input>
                        </Form.Item>
                        <Form.Item name="frequency" label="Requests Every">
                            <Input style={{ width: '50px' }}></Input>
                        </Form.Item>
                        <Form.Item name="timeframe">
                            <Radio.Group style={{ marginLeft: '15px' }} buttonStyle="solid">
                                <Radio value="minute">Minutes</Radio>
                                <Radio value="hour">Hours</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                ) : null}
            </Form>
        </div>
    );
};
