import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Auth } from 'aws-amplify';

import { serverApi } from 'api/baseServerApi';
import { history } from 'App';

export const userApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserSessionTokenPayload: builder.query({
            queryFn: async () => {
                try {
                    const user = await Auth.currentAuthenticatedUser();
                    return { data: user?.signInUserSession?.idToken?.payload };
                } catch (error) {
                    localStorage.setItem('lastRoute', history?.location?.pathname || '');
                    const searchParams = new URLSearchParams(history?.location?.search);
                    const customProvider = searchParams.get('customProvider');
                    if (customProvider) {
                        Auth.federatedSignIn({ customProvider });
                    } else {
                        Auth.federatedSignIn();
                    }
                    return { error: error as FetchBaseQueryError };
                }
            },
        }),
    }),
});

export const useGetUserSessionTokenPayloadQuery = userApi.endpoints.getUserSessionTokenPayload.useQuery;
