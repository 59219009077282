/**
 * Common/shared patterns for form validations:
 */
import { Rule } from 'rc-field-form/lib/interface';
import { validateIPAddress } from './utils';

export const formFieldName = {
    pattern: /^[\w- ]+$/,
    errorMessage: 'Invalid characters',
};

export const alphaNumericOnlyRule: Rule = {
    pattern: /^[\w-]+$/,
    message: 'Invalid characters',
};

export const alphaNumericWhitespaceAndSpecialCharsRule: Rule = {
    pattern: /^[\s\w-_/.,;:!@#$%^&*]+$/,
    message: 'Invalid characters',
};

export const alphabeticOnlyRule = (message?: string): Rule => ({
    pattern: /^[a-zA-Z]+$/,
    message: message || 'Invalid characters - only alphabetic characters allowed',
});

export const pathParametersRule: Rule = {
    validator: (_, value) => {
        if (/{([^}]+)}/.test(value)) {
            return Promise.reject('Invalid path parameters');
        }
        return Promise.resolve();
    },
    message: 'Invalid path parameters',
};

export const formFieldRequired = {
    errorMessage: 'This field is required',
};

export const urlRule = (message?: string): Rule => ({
    validator: (_, value) => {
        if (!value || /^https?:\/\/.+$/.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject(message || 'Invalid URL syntax');
    },
    message: message || 'Invalid URL syntax',
});

export const IpV4Rule: Rule = {
    validator: (_, value) => {
        if (validateIPAddress(value, false)) {
            return Promise.resolve();
        }
        return Promise.reject('Invalid IP address');
    },
    message: 'Invalid IP address',
};

export const IpV4ListRule: Rule = {
    validator: (_, value: string) => {
        if (value.split(',').every((address: string) => validateIPAddress(address, false))) {
            return Promise.resolve();
        }
        return Promise.reject('Invalid IP list');
    },
    message: 'Invalid IP list',
};

// ================
// Validators for Ant's Form system
// ================
export const validateFormFieldChanged: (oldValue: string) => (_: any, value: string) => Promise<string | Error> =
    (oldValue) => async (_: any, value: string) =>
        value !== oldValue
            ? Promise.resolve('')
            : Promise.reject(new Error('Value should be different from the previous one'));

export const isDuplicatedNameValidator = (
    existingItemsList: { name: string }[],
    newVal: string,
    entityTitle: string,
    oldVal = ''
) => {
    if (newVal === oldVal) {
        return Promise.resolve();
    }

    const isDuplicate = existingItemsList.find(({ name }) => name === newVal.trim());
    return isDuplicate ? Promise.reject(`${entityTitle} name already exists`) : Promise.resolve();
};

export const isRegexValidator = async (value: string) => {
    try {
        return !!new RegExp(value);
    } catch (e) {
        throw e;
    }
};
// ================
// End of Validators
// ================

export const formFieldUrlPath = {
    pattern: /^\S*$/,
    errorMessage: 'Invalid characters',
};
