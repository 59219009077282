import { CognitoUser } from '@aws-amplify/auth';
import { checkUser, UserFilters } from './feature-by-user-service';
import { IConfigResponse } from 'api/configApi';

function createdInBackoffice(creationSystem: IConfigResponse['creation_system']) {
    return creationSystem === 'manual';
}

export const featureMap = {
    'automated-actions': (env: any, tenant: string): boolean =>
        [
            'payments',
            'streaming',
            'kong_demo',
            'e2e_payments',
            'payments_neosec',
            'nginx_lab',
            'payments_rsa',
            'webinar_lab',
        ].includes(tenant),

    'release-notes': (env: any, tenant: string): boolean => true,
    // TODO: if no issues arise and we can continue have new discovery opened to all remove next line
    // ['payments', 'streaming'].includes(tenant),

    'new-discovery': (env: any, tenant: string): boolean => true,
    // TODO: if no issues arise and we can continue have new discovery opened to all remove next line
    // env.REACT_APP_ENV !== 'production' || ['payments_neosec', 'payments_rsa'].includes(tenant),

    'collectors-mgmt': (env: any, tenant: string, user?: CognitoUser): boolean =>
        checkUser(user, UserFilters.IS_NEOSEC),

    'endpoint-filters': (env: any, tenant: string): boolean => true,

    'parameter-naming': (env: any, tenant: string): boolean => true,

    'endpoint-patterns': (env: any, tenant: string, user?: CognitoUser): boolean =>
        checkUser(user, UserFilters.IS_NEOSEC),

    'entity-management': (env: any, tenant: string, user?: CognitoUser): boolean =>
        checkUser(user, UserFilters.IS_NEOSEC),

    'akamai-configs': (env: any, tenant: string, user?: CognitoUser, config?: IConfigResponse) => {
        if (!config) {
            return false;
        }
        return checkUser(user, UserFilters.IS_NEOSEC) && !createdInBackoffice(config.creation_system);
    },

    'acc-management-url': (env: any, tenant: string, user?: CognitoUser, config?: IConfigResponse): boolean =>
        config?.creation_system === 'config',

    monitoring: (env: any, tenant: string): boolean =>
        tenant === 'payments' &&
        env.REACT_APP_IS_REAL_USER_MONITORING === 'true' &&
        !navigator.userAgent.includes('DatadogSynthetics'),

    testing: (env: any, tenant: string): boolean =>
        [
            'api_testing_neosec_lab',
            'api_testing_field_lab',
            'api_testing_rsa_lab',
            'api_testing_rsa_2_lab',
            'e2e_payments',
        ].includes(tenant),

    detokenization: (env: string, tenant: string, userSessionPayload?: any): boolean =>
        userSessionPayload?.hasDetokPermissions,
};

export function checkFeature(
    env: any,
    tenant: string,
    featureKey: keyof typeof featureMap,
    user?: CognitoUser | any,
    config?: IConfigResponse
): boolean {
    return featureMap[featureKey]?.(env, tenant, user, config) as boolean;
}
