import React, { useState, useCallback } from 'react';
import { deleteCollectorConfig, ICollectorConfiguration } from '../shared/collectorConfigurationApis';
import { useHistory, useParams } from 'react-router-dom';

import { ISettingsPathParams } from '../../../Settings';
import { SettingsSectionHeader } from '../../SettingsSectionHeader/SettingsSectionHeader';
import { errorMessage, successMessage } from '../../../../../general/toast-service';
import { UiOverlayMenu } from '../../../../ui-overlay-menu/UiOverlayMenu';
import { UiIcon } from '../../../../icon/UiIcon';
import { CollectorConfigurationCard } from '../CollectorConfigurationCard/CollectorConfigurationCard';
import TroubleshootingModal from '../../../../shared/troubleshooting-modal/TroubleshootingModal';
import { TroubleshootingContext } from '../../../../shared/troubleshooting-modal/TroubleshootingModal.enums';
import { ReactComponent as NoConfigsImage } from '../../../shared/assets/no_documents.svg';
import { CollectorConfigurationCloneModal } from '../CollectorConfigurationCloneModal/CollectorConfigurationCloneModal';
import { useFetchAllCollectorConfigurations } from '../shared/useFetchAllCollectorConfigurations';
import { ApiResponse } from '../../../../../interfaces/apiResponse.interface';
import Spinner from '../../../../../containers/spinner/Spinner';
import { EmptyStateList } from '../../../../shared/emptyStateList/EmptyStates';
import { UiModal } from '../../../../ui-modal/UiModal';
import { CollectorConfigurationAddModal } from '../CollectorConfigurationAddModal/CollectorConfigurationAddModal';

import './CollectorConfigurationList.scss';

export const CollectorConfigurationList = () => {
    const { activeOrg } = useParams<ISettingsPathParams>();

    const history = useHistory();

    // don't change the default 'undefined' value for collectorConfigs. It facilitates no flashing 'empty state'
    const [collectorConfigs, setCollectorConfigs] = useState<ICollectorConfiguration[]>();

    const [isTroubleshootingModalOpen, setIsTroubleshootingModalOpen] = useState<boolean>(false);

    const [configToDelete, setConfigToDelete] = useState<ICollectorConfiguration>();
    const [configToClone, setConfigToClone] = useState<ICollectorConfiguration>();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    const triggerListReload = useFetchAllCollectorConfigurations(setCollectorConfigs);

    const editCollectorConfiguration = useCallback(
        (configId: string) => {
            history.push({ pathname: `/${activeOrg}/settings/node-configurations/${configId}/edit` });
        },
        [activeOrg, history]
    );

    const deleteCollectorConfiguration = async () => {
        try {
            const deleteRes: ApiResponse = await deleteCollectorConfig(activeOrg, configToDelete?.id as string).then(
                (res) => ({
                    status: res.status,
                    message: res.data,
                })
            );
            setConfigToDelete(undefined);
            deleteRes.status === 204 ? successMessage('Deleted successfully') : errorMessage(deleteRes.message);
            await triggerListReload();
        } catch (e) {
            errorMessage('Error: cannot delete Node Configuration');
        }
    };

    const getContextMenuItems = useCallback(
        (collectorConfig: ICollectorConfiguration) => {
            const menuItems = [
                {
                    label: 'Clone',
                    onClick: () => setConfigToClone(collectorConfig),
                    icon: <UiIcon name="copy" />,
                },
                {
                    label: 'Edit',
                    onClick: () => editCollectorConfiguration(collectorConfig.id),
                    disabled: false,
                    icon: <UiIcon name="pencil" />,
                },
                {
                    label: 'Delete',
                    onClick: () => setConfigToDelete(collectorConfig),
                    disabled: !!collectorConfig.edges?.collectors,
                    icon: <UiIcon name="trash" />,
                },
            ];

            return <UiOverlayMenu menuItems={menuItems} />;
        },
        [editCollectorConfiguration, setConfigToDelete, setConfigToClone]
    );

    const cloneModalClosing = (refreshNeeded = true) => {
        setConfigToClone(undefined);
        refreshNeeded && triggerListReload();
    };

    const addModalClosing = (refreshNeeded = true) => {
        setIsAddModalOpen(false);
        refreshNeeded && triggerListReload();
    };

    return (
        <div className="CollectorConfigurationList">
            <SettingsSectionHeader
                title="Node Configurations"
                buttonText="Add Configuration"
                buttonAction={() => {
                    setIsAddModalOpen(true);
                }}
            />
            <div className="subtitle">{`${collectorConfigs?.length || 0} Configurations`}</div>
            <div className="content">
                <Spinner show={!collectorConfigs} />
                {collectorConfigs?.length === 0 && (
                    <EmptyStateList
                        text={
                            <>
                                Start analyzing your traffic <br /> by adding a Node Configuration
                            </>
                        }
                        img={<NoConfigsImage />}
                    />
                )}
                <div className="collector-configs-grid card-grid">
                    {collectorConfigs?.map((config) => (
                        <CollectorConfigurationCard
                            key={config.id}
                            menu={getContextMenuItems(config)}
                            config={config}
                        />
                    ))}
                </div>
            </div>
            {configToDelete && (
                <UiModal
                    onCancel={() => setConfigToDelete(undefined)}
                    acceptButton={{ text: 'Delete', onClick: deleteCollectorConfiguration }}
                    rejectButton={{ text: 'Cancel', onClick: () => setConfigToDelete(undefined) }}
                    title={`Delete Node Configuration?`}
                    icon="trash2"
                >{`Delete the Node Configuration '${configToDelete.name}'?`}</UiModal>
            )}
            {isTroubleshootingModalOpen && (
                <TroubleshootingModal
                    troubleshootContext={TroubleshootingContext.collector}
                    onDismiss={() => {
                        setIsTroubleshootingModalOpen(false);
                    }}
                />
            )}
            {isAddModalOpen && (
                <CollectorConfigurationAddModal existingConfigs={collectorConfigs} onClose={addModalClosing} />
            )}
            {configToClone && (
                <CollectorConfigurationCloneModal
                    collectorConfig={configToClone}
                    existingConfigs={collectorConfigs}
                    onClose={cloneModalClosing}
                />
            )}
        </div>
    );
};
