import React from 'react';

import { checkFeature } from '../../../../services/feature-toggle/feature-toggle-service';
import { removeTrailingSlashes } from '../../../../general/utils';
import { UiIcon } from '../../../../components/icon/UiIcon';
import { GridApi, ICellRendererParams } from '@ag-grid-community/core';

export const endpointTableMenu = (
    setModalData: Function,
    activeOrg: string,
    history: any,
    base64EncodedServiceName: string,
    handleHideShowEndpoints: (api: GridApi, id: string, hidden: boolean) => void
) => {
    const pathnameWithoutTrailingSlashes = removeTrailingSlashes(history.location.pathname);

    return (params: ICellRendererParams) => {
        const {
            data: { id, hidden, endpoint_path, path_parameters },
            api,
        } = params;

        const editParameterNamesMenuItem = {
            icon: <UiIcon name={'rename'} />,
            label: `Edit Parameter Names`,
            onClick: () => {
                setModalData({
                    pathParameters: path_parameters,
                    serviceName: base64EncodedServiceName,
                    endpointPath: endpoint_path,
                });
            },
        };

        const goToEndpointMenuItem = {
            icon: <UiIcon name="goTo" />,
            label: `Go to Endpoint`,
            onClick: () => {
                history.push(
                    `${pathnameWithoutTrailingSlashes}/endpoints/${encodeURIComponent(params.data.id)}/${
                        history.location.search
                    }`
                );
            },
        };

        let menuItems = [
            {
                icon: <UiIcon name={hidden ? 'showTransparent' : 'hide'} />,
                label: `${hidden ? 'Show' : 'Hide'} Endpoint`,
                onClick: () => handleHideShowEndpoints(api, id, hidden),
            },
        ];

        if (params.data?.calls_count) {
            menuItems.push(goToEndpointMenuItem);
        }
        if (params.data.path_parameters?.length > 0 && checkFeature(process.env, activeOrg, 'parameter-naming')) {
            menuItems.push(editParameterNamesMenuItem);
        }

        return { menuItems };
    };
};
