import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getConfigurations, ICollectorConfiguration } from './collectorConfigurationApis';
import { errorMessage } from '../../../../../general/toast-service';
import { ISettingsPathParams } from '../../../Settings';
import { sortByTime } from '../../../../../general/utils';

export const useFetchAllCollectorConfigurations = (
    storeFunc: (list: any[]) => void,
    formatter?: (list: ICollectorConfiguration[]) => any[],
    runOnInitialization = true
) => {
    const { activeOrg } = useParams<ISettingsPathParams>();
    const [updateNow, setUpdateNow] = useState<boolean>(runOnInitialization);

    useEffect(() => {
        const getConfigurationCall = async () => {
            try {
                const collectorConfigsRes = await getConfigurations(activeOrg);
                const sortedList = collectorConfigsRes.sort(sortByTime);
                storeFunc(formatter ? formatter(sortedList) : sortedList);
            } catch (e) {
                errorMessage('Error: cannot fetch Node Configurations');
            } finally {
                setUpdateNow(false);
            }
        };
        updateNow && getConfigurationCall();
    }, [activeOrg, formatter, storeFunc, updateNow]);

    return () => setUpdateNow(true);
};
