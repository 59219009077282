import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { IAkamaiConfig, useGetPluginsConfigurations } from 'api/akamaiConfigs';
import { ISettingsPathParams } from 'components/settings/Settings';
import { removeTrailingSlashes } from 'general/utils';
import { UiIcon } from 'components/icon/UiIcon';
import { AkamaiConfigurationCard } from 'components/settings/components/akamaiConfigurations/akamaiConfigurationCard/AkamaiConfigurationCard';
import { UiOverlayMenu } from 'components/ui-overlay-menu/UiOverlayMenu';
import { SettingsSectionHeader } from 'components/settings/components/SettingsSectionHeader/SettingsSectionHeader';
import Spinner from 'containers/spinner/Spinner';

import './AkamaiConfigurationList.scss';

export const AkamaiConfigurationList = () => {
    const history = useHistory();

    const { activeOrg } = useParams<ISettingsPathParams>();
    const { data, isLoading } = useGetPluginsConfigurations(activeOrg);

    const getContextMenuItems = useCallback(
        ({ pluginName }: IAkamaiConfig) => {
            const menuItems = [
                {
                    label: 'Edit',
                    onClick: () =>
                        history.push({
                            pathname: `${removeTrailingSlashes(history.location.pathname)}/${pluginName}/edit`,
                        }),
                    disabled: false,
                    icon: <UiIcon name="pencil" />,
                },
            ];

            return <UiOverlayMenu menuItems={menuItems} />;
        },
        [history, activeOrg]
    );

    if (isLoading) return <Spinner show />;
    if (!data) return <div>There is not available configurations</div>;

    return (
        <div className="AkamaiConfigurationsList">
            <SettingsSectionHeader title="Akamai Data Configurations" />
            <div className="subtitle">{data?.items.length} Configurations</div>
            <div className="card-grid">
                {data?.items.map((item) => (
                    <AkamaiConfigurationCard
                        key={item.pluginName}
                        menu={getContextMenuItems(item)}
                        item={item}
                        title={item?.displayName}
                    />
                ))}
            </div>
        </div>
    );
};
