import { tenantApi } from './baseServerApi';

export interface IConfigResponse {
    default_interval: string;
    labels: string[];
    timeframe: {
        start_timestamp: number; // epoch in milliseconds
        end_timestamp: number; // epoch in milliseconds
    };
    creation_system: 'config' | 'manual';
}

export const configApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        getConfig: builder.query<IConfigResponse, string>({
            query: (tenant) => `${tenant}/config`,
        }),
    }),
    overrideExisting: false,
});

export const useGetConfigQuery = configApi.endpoints.getConfig.useQuery;
