import React from 'react';

import { UiIconButton } from '../icon-button/UiIconButton';
import { UiTooltip } from '../ui-tooltip/UiTooltip';

import './UserBadge.scss';

export interface IUserBadgeProps {
    email: string;
    className?: string;
}

export const UserBadge = ({ email, className }: IUserBadgeProps) => {
    return email ? (
        <>
            <div className={`UserBadge ${className}`}>
                <UiIconButton name="user" callback={undefined} />
                <UiTooltip title={email}>
                    <div className="email-address">{email}</div>
                </UiTooltip>
            </div>
        </>
    ) : (
        <></>
    );
};
