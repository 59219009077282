import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import './ui-ag-grid.scss';
import { actions, AppStateContext } from '../../contexts/AppStateContext';
import Spinner from '../../containers/spinner/Spinner';
import { AgGridIcons, getContextMenuItems } from './commonOptions';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowNode } from '@ag-grid-community/core';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { ColDef, GridApi, ColumnApi } from '@ag-grid-community/core';
import { FilterTagList, getFilterState } from '../filterTagList/FilterTagList';
import { useOnEscOrClickOutside } from '../../hooks/useOnEscOrClickOutside';
import {
    CellRenderCustomLoading,
    clipboardCopyForAllColTypes,
    clipboardHeadersCopy,
    CustomNoRowsOverlay,
} from './customCellRenderers';
import { SORTING_ORDER } from './UiAgGridSSRM';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

interface IProps {
    title?: string;
    columns: ColDef[];
    options: any;
    data: any[];
    loading?: boolean;
    masterDetail?: boolean;
    blockExpand?: boolean;
    isFiltered?: boolean;
    onRowSelect?: Function;
    detailCols?: any[];
    getDetailData?: (key: any, extraParams: {}) => any;
    detailGridOptions?: { [key: string]: any };
    customDetailComponent?: any;
    components?: { components?: { [key: string]: string } };
    context?: any;
    gridApifromGlobalContext?: [GridApi, React.Dispatch<GridApi>];
    activateGrid?: boolean;
    showRowCount: boolean;
    filterModel?: any;
    sortState?: any;
    onFilterChange?: Function;
    onSortChange?: Function;
    rowCountTitle?: string;
    draggableRows?: Boolean;
    rowDragEndHandler?: Function;
    isHideRowCount?: boolean;
    attributes?: { [key: string]: any };
    onGridReady?: (params: { api: GridApi; columnApi: ColumnApi }) => void;
}

export interface IFilterState {
    colName: string;
    field: string;
    active: boolean;
    filterType: 'set' | 'number' | 'text' | 'date' | '"customDateFilter"';
    activeValues?: string[];
    tooltipDisplay?: JSX.Element | JSX.Element[];
    tagDisplay?: JSX.Element;
    filtersState?: any;
    modified?: boolean;
}

// Ag Grid Client side row model tables
export const UiAgGridCSRM: React.FC<IProps> = (props: IProps) => {
    const [gridApi, setGridApi] = useState<any>([]);
    const [filtersState, setFiltersState] = useState<any>();
    const { dispatch } = useContext(AppStateContext);
    const [rowCount, setRowCount] = useState<{ total: number; displayed: number }>({ total: 0, displayed: 0 });
    const tableWrapper = useRef<HTMLDivElement>(null);

    const onGridReady = (params: { api: GridApi; columnApi: ColumnApi }) => {
        setGridApi(params.api);
        dispatch({ type: actions.SET_TABLE_API, payload: { gridApi: params.api, columnApi: params.columnApi } });

        if (props.gridApifromGlobalContext) {
            props.gridApifromGlobalContext[1](params.api);
        }

        if (props.onGridReady) {
            props.onGridReady(params);
        }
    };

    useEffect(() => {
        setRowCount({ total: props.data.length, displayed: props.data.length });
    }, [props.data.length]);

    useEffect(() => {
        if (props.filterModel && gridApi?.getDisplayedRowCount) {
            const newFiltersState: IFilterState[] = props.columns
                .filter((col: ColDef) => col.headerName)
                .map(getFilterState(props.filterModel));

            setFiltersState(newFiltersState);
            setRowCount((prevRowCount: { total: number; displayed: number }) => ({
                ...prevRowCount,
                displayed: gridApi.getDisplayedRowCount(),
            }));
        }
    }, [props.filterModel, gridApi]);

    const filterChangeHandler = (event: any) => {
        if (props.onFilterChange) {
            props.onFilterChange(event.api.getFilterModel());
        }
    };

    const onRowDragEnd = (e: any) => {
        if (props.rowDragEndHandler) {
            props.rowDragEndHandler(e);
        }
    };

    // Configure the detail table
    const detailCellRendererParams = {
        // provide the Grid Options to use on the Detail Grid
        detailGridOptions: {
            animateRows: true,
            enableRangeSelection: false,
            detailRowAutoHeight: true,
            columnDefs: props.detailCols,
            rowHeight: 75,
            icons: AgGridIcons,
            suppressCellSelection: true,
            allowContextMenuWithControlKey: true,
            getContextMenuItems,
            enableCellTextSelection: true,
            ensureDomOrder: true,
            processCellForClipboard: clipboardCopyForAllColTypes,
            ...props.detailGridOptions,
        },
    };

    const gridOptions = {
        rowModelType: 'clientSide',
        rowSelection: 'single',
        rowDragManaged: props.draggableRows || false,
        animateRows: true,
        enableRangeSelection: false,
        suppressCellSelection: true,
        rowHeight: 40,
        onFilterChanged: filterChangeHandler,
        onFirstDataRendered: (event: any) => {
            if (JSON.stringify(event.api.getFilterModel()) !== JSON.stringify(props.filterModel)) {
                event.api.setFilterModel(props.filterModel);
            }
        },
        detailCellRenderer: props.customDetailComponent,
        detailCellRendererParams,
        detailRowAutoHeight: true,
        suppressLoadingOverlay: true,
        suppressHorizontalScroll: false,
        allowContextMenuWithControlKey: true,
        icons: AgGridIcons,
        getContextMenuItems: getContextMenuItems,
        processCellForClipboard: clipboardCopyForAllColTypes,
        processHeaderForClipboard: clipboardHeadersCopy,
        onRowDragEnd: onRowDragEnd,
        suppressRowClickSelection: true,
        suppressCopyRowsToClipboard: true,
        sortingOrder: SORTING_ORDER,
        ...props.components,
        ...props.options,
    };

    const resetFilter = (field: string): void => {
        const filterComponent = gridApi.getFilterInstance(field);
        filterComponent.setModel(null);
        gridApi.onFilterChanged();
    };

    const resetAllFilters = () => {
        props.columns.forEach((col: ColDef) => resetFilter(col.field as string));
    };

    const deselectRowCb = useCallback(() => {
        gridApi?.deselectAll?.();
    }, [gridApi]);

    function expandRow(e: any) {
        if (props.blockExpand) return;
        const classList = e.event?.target?.classList;

        // expand row if the row is a master/detail or group row and the click target is not an inline drop-down
        if (
            (e.node?.master || e.node?.group) &&
            (classList?.value?.includes('ag-cell') ||
                classList?.value?.includes('ag-react-container') ||
                classList?.value?.includes('custom-cell-renderer') ||
                classList?.value?.includes('api-tag-container') ||
                classList?.value?.includes('ag-grid-row-expand-trigger'))
        ) {
            const isExpanded = e.node?.expanded;
            e.api?.forEachNode((rowNode: RowNode) => {
                rowNode.setExpanded(false);
            });
            e.node.setExpanded(!isExpanded);
        }
    }

    useOnEscOrClickOutside(tableWrapper, deselectRowCb);

    return (
        <div className="UiAgGridCSRM">
            {filtersState ? (
                <div className={`table-header ${!props.showRowCount ? 'display-none' : ''}`}>
                    {' '}
                    {props.showRowCount}
                    <div className={`title box-title ${rowCount.displayed === 0 ? 'hidden' : ''}`}>
                        {props.title}{' '}
                        {rowCount.total === rowCount.displayed
                            ? `(${rowCount.total})`
                            : `(${rowCount.displayed} of ${rowCount.total})`}
                    </div>
                    {props.isFiltered && (
                        <FilterTagList
                            filterTags={filtersState}
                            resetFilter={resetFilter}
                            resetAllFilters={resetAllFilters}
                        />
                    )}
                </div>
            ) : props.isHideRowCount ? (
                <></>
            ) : (
                <div className={`title box-title ${rowCount.displayed === 0 ? 'hidden' : ''}`}>
                    {`Showing ${rowCount.total} ${props.rowCountTitle || 'Rows'}`}
                </div>
            )}
            <div style={{ height: '100%', width: '100%' }} className="ag-theme-alpine ag-grid-csrm" ref={tableWrapper}>
                <div className={`spinner-wrapper ${!!props.loading ? '' : 'hidden'}`}>
                    <Spinner show={true} size={'small'} />
                </div>
                <AgGridReact
                    onGridReady={onGridReady}
                    gridOptions={gridOptions}
                    modules={[
                        ClientSideRowModelModule,
                        ServerSideRowModelModule,
                        MasterDetailModule,
                        CsvExportModule,
                        ExcelExportModule,
                        MenuModule,
                        SetFilterModule,
                        ClipboardModule,
                    ]}
                    masterDetail={props.masterDetail}
                    onRowClicked={(e: any) => expandRow(e)}
                    cacheBlockSize={100}
                    columnDefs={props.columns}
                    rowData={props.data}
                    infiniteInitialRowCount={100}
                    disableStaticMarkup
                    tooltipShowDelay={1000}
                    suppressLoadingOverlay={true}
                    onFilterChanged={filterChangeHandler}
                    loadingCellRenderer={'customLoadingCellRenderer'}
                    noRowsOverlayComponent={'customNoRowsOverlay'}
                    components={{
                        ...props.options.components,
                        customLoadingCellRenderer: CellRenderCustomLoading,
                        customNoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    {...props.attributes}
                />
            </div>
        </div>
    );
};
