import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { SuppressionRulePredicate } from '../suppression-rule/SuppressionRulePredicate';
import { message } from 'antd';
import { useHistory } from 'react-router';
import { ActionType, AutomatedActionRuleAction } from '../automated-action-rule-action/AutomatedActionRuleAction';
import { predicateTypeMap, SuppressionRuleType } from '../suppression-rule/SuppressionRule';
import { actions } from '../automated-actions-list/actions.mock';
import {
    IAutomatedActionRule,
    IAutomatedActionRuleAction,
    ISuppressionRule,
    ISuppressionRulePredicate,
} from '../../../../interfaces/throttle.interface';
import { useEntity } from '../../../../services/data/hooks';
import { EntityName } from '../../../../services/data/entityMap';
import { omit } from '../../../../general/utils';
import { UiIcon } from '../../../icon/UiIcon';
import { IOverlayMenu, UiOverlayMenu } from '../../../ui-overlay-menu/UiOverlayMenu';
import { UiInput } from '../../../ui-input/UiInput';
import { UiButton } from '../../../button/Button';

import '../suppression-rule/SuppressionRule.scss';

function getNextRuleOrder(ruleList: ISuppressionRule[]): number {
    if (ruleList.length === 0) {
        return 0;
    }

    return (
        ruleList.reduce((order, rule) => {
            return rule.rule_order > order ? rule.rule_order : order;
        }, 0) + 1
    );
}

function validateRuleForm(ruleForm: ISuppressionRule): { [key: string]: string } {
    const ruleFormError: { [key: string]: string } = {};

    if (!ruleForm.rule_name) {
        ruleFormError.rule_name = 'This field is required';
    }

    return ruleFormError;
}

export const AutomatedActionRule: React.FC = () => {
    const history = useHistory();
    const params = useParams() as { activeOrg: string; ruleId: string };
    const activeOrg = params.activeOrg;
    const ruleType = new URLSearchParams(useLocation().search).get('type') as SuppressionRuleType;
    const ruleId = params.ruleId;
    const [ruleForm, setRuleForm] = useState<IAutomatedActionRule>();
    const [ruleFormError, setRuleFormError] = useState<{ [key: string]: string }>({});
    // const [rule, updateRule, createRule, ruleError] = useEntity<IAutomatedActionRule>(EntityName.AutomatedActionRule, {}, ruleId || null);

    // mock rule
    let [rule, updateRule, createRule, ruleError] = useEntity<IAutomatedActionRule>(
        EntityName.AutomatedActionRule,
        {},
        null
    );
    if (ruleId) {
        rule = actions[activeOrg]?.find((actionItem) => actionItem.id === ruleId) as IAutomatedActionRule;
    }
    updateRule = (rule: IAutomatedActionRule) => {
        return new Promise<void>((resolve) => {
            const index = actions[activeOrg]?.findIndex((actionItem) => actionItem.id === rule.id);
            actions[activeOrg][index] = rule;
            resolve();
        });
    };
    createRule = (rule: IAutomatedActionRule) => {
        return new Promise<void>((resolve) => {
            actions[activeOrg]?.push({
                ...rule,
                id: Math.floor(Math.random() * 1000).toString(),
            });
            resolve();
        });
    };

    const [ruleList] = useState({ items: [...actions[activeOrg]] }); // useEntity<{items: IAutomatedActionRule[]}>(EntityName.AutomatedActionRule);

    useEffect(() => {
        if (rule && ruleList) {
            setRuleForm({
                ...(omit(['created_by', 'updated_by', 'created_at', 'updated_at'], rule) as IAutomatedActionRule),
                rule_order:
                    rule.rule_order || rule.rule_order === 0 ? rule.rule_order : getNextRuleOrder(ruleList.items),
                rule_type: ruleType || rule.rule_type,
                created_by: 'johnm@example.com',
                updated_by: 'johnm@example.com',
                created_at: new Date().toString(),
                updated_at: new Date().toString(),
            });
        }
    }, [rule, ruleList]);

    useEffect(() => {
        if (ruleError) {
            message.error(ruleError);
        }
    }, [ruleError]);

    function save() {
        if (Object.keys(ruleFormError as {}).length) {
            return;
        }

        let promise;

        if (rule?.id) {
            promise = updateRule(ruleForm);
        } else {
            promise = createRule(ruleForm);
        }

        promise
            .then(() => {
                history.push({ pathname: `/${activeOrg}/settings/automated-actions` });
            })
            .catch((error: any) => {
                message.error(error);
            });
    }

    function cancel() {
        history.push(`/${activeOrg}/settings/automated-actions`);
    }

    function onFormChange(key: string, value: any) {
        let newRuleForm: IAutomatedActionRule;

        if (key === 'rule_name' || key === 'frequency' || key === 'timeframe') {
            newRuleForm = {
                ...ruleForm,
                [key]: value,
            } as IAutomatedActionRule;
        } else if (key === 'alert_description' && value !== undefined) {
            newRuleForm = {
                ...ruleForm,
                predicate: {
                    ...ruleForm?.predicate,
                    [key]: {
                        operand: value.contains === undefined || value.contains ? 'contains' : 'not contains',
                        value: value.text,
                    },
                },
            } as IAutomatedActionRule;
        } else if (key === 'associated_entities' && value?.length === 0) {
            removePredicate(key);
            return;
        } else if (Object.values(ActionType).includes(key as ActionType)) {
            newRuleForm = {
                ...ruleForm,
                action: {
                    ...ruleForm?.action,
                    [key]: value,
                },
            } as IAutomatedActionRule;
        } else {
            newRuleForm = {
                ...ruleForm,
                predicate: {
                    ...ruleForm?.predicate,
                    [key]: value,
                },
            } as IAutomatedActionRule;
        }

        setRuleForm(newRuleForm);
        setRuleFormError(validateRuleForm(newRuleForm));
    }

    function createPredicate(key: string, value?: any, withSeparator?: boolean): JSX.Element {
        return (
            <div key={key} className="sr-predicate-container">
                {withSeparator ? (
                    <div className="sr-separator">
                        AND
                        <div className="sr-separator-line"></div>
                    </div>
                ) : null}
                <div className="sr-predicate">
                    {key !== 'associated_entities' ? (
                        <span className="sr-remove">
                            <UiIcon name="remove" onClick={() => removePredicate(key)} />
                        </span>
                    ) : null}
                    <SuppressionRulePredicate
                        predicateType={predicateTypeMap[key]}
                        value={value || []}
                        onChange={(e: any) => onFormChange(key, e)}
                    />
                </div>
            </div>
        );
    }

    function createAction(key: ActionType, value: any, withSeparator?: boolean): JSX.Element {
        return (
            <div key={key} className="sr-predicate-container">
                {withSeparator ? (
                    <div className="sr-separator">
                        AND
                        <div className="sr-separator-line"></div>
                    </div>
                ) : null}
                <div className="sr-predicate">
                    <span className="sr-remove">
                        <UiIcon name="remove" onClick={() => removeAction(key)} />
                    </span>
                    <AutomatedActionRuleAction
                        actionType={key}
                        value={value}
                        onChange={(e: any) => onFormChange(key, e)}
                    />
                </div>
            </div>
        );
    }

    function createPredicateElements(predicate: ISuppressionRulePredicate): JSX.Element[] {
        return Object.keys(predicate)
            .filter((key) => predicate[key as keyof ISuppressionRulePredicate] !== undefined)
            .map((key, i) =>
                createPredicate(
                    key,
                    key === 'alert_description'
                        ? {
                              contains: predicate.alert_description?.operand === 'contains',
                              text: predicate.alert_description?.value,
                          }
                        : predicate[key as keyof ISuppressionRulePredicate],
                    i > 0
                )
            );
    }

    function createActionElements(action: IAutomatedActionRuleAction): JSX.Element[] {
        return Object.keys(action)
            .filter((key) => action[key as keyof IAutomatedActionRuleAction] !== undefined)
            .map((key, i) => createAction(key as ActionType, action[key as keyof IAutomatedActionRuleAction], i > 0));
    }

    function addPredicate(key: string) {
        onFormChange(key, key === 'associated_entities' ? [{ type: '', id: [] }] : []);
    }

    function addAction(key: ActionType) {
        onFormChange(key, []);
    }

    function removePredicate(key: string) {
        onFormChange(key, undefined);
    }

    function removeAction(key: ActionType) {
        onFormChange(key, undefined);
    }

    function getPredicateOptions(predicate: ISuppressionRulePredicate): IOverlayMenu['menuItems'] {
        const menuItems = [];

        if (!predicate.endpoints) {
            menuItems.push({
                label: 'Endpoint',
                onClick: () => addPredicate('endpoints'),
            });
        }

        if (!predicate.associated_entities) {
            menuItems.push({
                label: 'Entity',
                onClick: () => addPredicate('associated_entities'),
            });
        }

        if (!predicate.services) {
            menuItems.push({
                label: 'Service',
                onClick: () => addPredicate('services'),
            });
        }

        if (!predicate.labels) {
            menuItems.push({
                label: 'Label',
                onClick: () => addPredicate('labels'),
            });
        }

        if (!predicate.alert_description) {
            menuItems.push({
                label: 'Alert Description',
                onClick: () => addPredicate('alert_description'),
            });
        }

        if (!predicate.alert_names) {
            menuItems.push({
                label: 'Alert Name',
                onClick: () => addPredicate('alert_names'),
            });
        }

        if (!predicate.alert_categories) {
            menuItems.push({
                label: 'Alert Category',
                onClick: () => addPredicate('alert_categories'),
            });
        }

        if (!predicate.alert_severities) {
            menuItems.push({
                label: 'Alert Severity',
                onClick: () => addPredicate('alert_severities'),
            });
        }

        return menuItems;
    }

    function getActionOptions(action: IAutomatedActionRuleAction): IOverlayMenu['menuItems'] {
        const menuItems = [];

        if (!action.change_severity) {
            menuItems.push({
                label: 'Change Severity',
                onClick: () => addAction(ActionType.ChangeSeverity),
            });
        }

        if (!action.label) {
            menuItems.push({
                label: 'Add Label',
                onClick: () => addAction(ActionType.Label),
            });
        }

        if (!action.email) {
            menuItems.push({
                label: 'Send Email',
                onClick: () => addAction(ActionType.Email),
            });
        }

        if (!action.webhook) {
            menuItems.push({
                label: 'Trigger Webhook',
                onClick: () => addAction(ActionType.Webhook),
            });
        }

        if (!action.jira_ticket) {
            menuItems.push({
                label: 'Create Jira Ticket',
                onClick: () => addAction(ActionType.JiraTicket),
            });
        }

        if (!action.kong_integration) {
            menuItems.push({
                label: 'Set Kong Policy',
                onClick: () => addAction(ActionType.KongIntegration),
            });
        }

        if (!action.f5_integration) {
            menuItems.push({
                label: 'Set F5 Policy',
                onClick: () => addAction(ActionType.F5Integration),
            });
        }

        return menuItems;
    }

    return ruleForm ? (
        <div className="suppression-rule">
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                <UiInput
                    label="Rule Name"
                    defaultValue={ruleForm?.rule_name}
                    onBlur={(event: any) => onFormChange('rule_name', event.target.value)}
                    width={500}
                    error={ruleFormError?.rule_name}
                />
                <div>
                    <UiButton type="primary" text="Save" onClick={() => save()} />
                    <UiButton type="secondary" text="Cancel" onClick={() => cancel()} style={{ marginLeft: '5px' }} />
                </div>
            </div>
            <h3>Conditions</h3>
            <div className="box sr-conditions">
                <div className="sr-predicate-list">{createPredicateElements(ruleForm.predicate)}</div>
                <div style={{ width: '200px' }}>
                    <UiOverlayMenu
                        icon={
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <UiIcon name="add" />
                                <span style={{ color: '#436ace', marginLeft: '5px' }}>Add condition</span>
                            </div>
                        }
                        menuItems={getPredicateOptions(ruleForm.predicate)}
                    />
                </div>
            </div>
            <h3>Actions</h3>
            <div className="box" style={{ padding: '15px' }}>
                <div>{createActionElements(ruleForm.action)}</div>
                <div style={{ width: '200px' }}>
                    <UiOverlayMenu
                        icon={
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <UiIcon name="add" />
                                <span style={{ color: '#436ace', marginLeft: '5px' }}>Add action</span>
                            </div>
                        }
                        menuItems={getActionOptions(ruleForm.action)}
                    />
                </div>
            </div>
        </div>
    ) : null;
};
