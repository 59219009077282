import React from 'react';
import { useParams } from 'react-router-dom';

import { SettingsSectionHeader } from 'components/settings/components/SettingsSectionHeader/SettingsSectionHeader';
import { IEntityDefinition, useGetEntitiesQuery } from 'api/entitiesApi';
import { ReactComponent as NoEntitiesImage } from 'components/settings/shared/assets/no_documents.svg';
import { EmptyStateList } from 'components/shared/emptyStateList/EmptyStates';
import Spinner from 'containers/spinner/Spinner';
import { EntityCard } from '../EntityCard/EntityCard';
import { AddEntityWizard } from '../AddEntityWizard/AddEntityWizard';

import './EntityList.scss';

export interface IEntityListProps {
    addMode?: boolean;
}

const MAX_ENTITY_COUNT = 20;

export const EntityList = ({ addMode }: IEntityListProps) => {
    const { activeOrg } = useParams() as { activeOrg: string };
    const { data, isLoading } = useGetEntitiesQuery(activeOrg);

    const generateEntityCards = (entities: IEntityDefinition[]) =>
        entities.map((entity) => <EntityCard entity={entity} />);

    const checkMaxEntities = (): boolean => !data?.items || data.items.length >= MAX_ENTITY_COUNT;

    return (
        <div className="EntityList">
            <SettingsSectionHeader
                title="Entity Management"
                buttonText={`${checkMaxEntities() ? 'Entity Amount Limit' : 'Add Entity'}`}
                urlPath="settings/entities/add"
                disableButton={checkMaxEntities()}
            />
            <div className="subtitle">{`${data?.items?.length || 0} Entities`}</div>
            <div className="content">
                <Spinner show={isLoading} />
                {data?.count ? (
                    <div className="entities-grid card-grid">{generateEntityCards(data?.items)}</div>
                ) : (
                    !isLoading && (
                        <EmptyStateList
                            text={
                                <>
                                    Start leveraging the power of
                                    <br />
                                    behavioural analytics by adding an Entity
                                </>
                            }
                            img={<NoEntitiesImage />}
                        />
                    )
                )}
            </div>
            {addMode && <AddEntityWizard />}
        </div>
    );
};
