import { ReactElement, ReactNode, useState } from 'react';
import { Form, Select, Upload, UploadFile } from 'antd';

import { UiModal } from '../../../ui-modal/UiModal';
import { UiButton } from '../../../button/Button';
import { UiIcon } from '../../../icon/UiIcon';
import { httpPOSTMultiPart } from '../../../../general/http-service';
import { extractErrorMessage } from '../../../../general/utils';
import { errorMessage } from '../../../../general/toast-service';
import { useCreateScanMutation } from '../../../../api/testingApi';
import { formFieldRequired } from '../../../../general/forms';
import { UiSingleCustomOptionSelect } from '../../../../components/UiSingleCustomOptionSelect/UiSingleCustomOptionSelect';

import './ScanAddModal.scss';

export interface IScanAddModalProps {
    activeOrg: string;
    open: boolean;
    onCreate: () => void;
    onClose: () => void;
}

const uploadItemRender: (
    originNode: ReactElement,
    file: UploadFile,
    fileList: object[],
    actions: { download: Function; preview: Function; remove: Function }
) => ReactNode = (originNode, file, fileList, actions) => {
    return <Select className="upload-item-render" disabled value={file.name} loading={file.status === 'uploading'} />;
};

const UploadSuccessMessage = (endpointCount: number) => (
    <div>
        <span className="oas-upload-success-message-part-i">
            <UiIcon className="oas-upload-success-icon" name="checkCircleOutlined" isNotDefaultStyle />
            &nbsp;Your OAS has been successfully validated
        </span>
        <span> | </span>
        <span>{endpointCount} Endpoints will be tested</span>
    </div>
);

const UploadErrorMessage = () => (
    <div>
        <span className="oas-upload-error-message">
            <UiIcon className="oas-upload-error-icon" name="exclamationCircleOutlined" isNotDefaultStyle />
            &nbsp;Your OAS has failed validation due to a parsing error
        </span>
    </div>
);

export const ScanAddModal = (props: IScanAddModalProps) => {
    const { activeOrg, open, onCreate, onClose } = props;
    const [form] = Form.useForm();
    const [urlOptions, setUrlOptions] = useState<{ label: string; value: string }[]>([]);
    const [uploadMessage, setUploadMessage] = useState<JSX.Element>();
    const [createScan] = useCreateScanMutation();

    const onUpload = async (customRequestObj: any) => {
        const formData = new FormData();
        formData.append('file', customRequestObj.file);

        try {
            const uploadResponse = await httpPOSTMultiPart(`organizations/${activeOrg}/testing/oas`, formData);
            setUrlOptions(uploadResponse.data.parsed_target_urls?.map((url: string) => ({ label: url, value: url })));
            form.setFieldValue('target_url', undefined);
            setUploadMessage(UploadSuccessMessage(uploadResponse.data.endpoints_count));
            form.setFieldValue('oas_file_id', uploadResponse.data.oas_file_id);
            await form.validateFields(['oas_file_id']);
            customRequestObj.onSuccess();
        } catch (error: any) {
            setUrlOptions([]);
            form.setFieldValue('target_url', undefined);
            customRequestObj.onError(extractErrorMessage(error));
            setUploadMessage(UploadErrorMessage());
        }
    };

    const resetForm = () => {
        form.resetFields();
        setUrlOptions([]);
        setUploadMessage(undefined);
    };

    return (
        <UiModal
            title="New Scan"
            isVisible={open}
            isFormModal
            width={700}
            acceptButton={{
                text: 'Create',
                onClick: async () => {
                    try {
                        await form.validateFields();
                    } catch (error) {
                        return;
                    }

                    try {
                        await createScan({ tenant: activeOrg, body: form.getFieldsValue() }).unwrap();
                        resetForm();
                        onCreate();
                    } catch (error) {
                        errorMessage(extractErrorMessage(error));
                    }
                },
            }}
            rejectButton={{
                text: 'Cancel',
                onClick: () => {
                    resetForm();
                    onClose();
                },
            }}
            onCancel={() => {
                resetForm();
                onClose();
            }}
        >
            <Form form={form} layout="vertical" colon={false} className="ScanAddModal" requiredMark={false}>
                <Form.Item label="Application" name="application_name" initialValue="Default">
                    Default
                </Form.Item>
                <Form.Item
                    label="target oas"
                    name="oas_file_id"
                    rules={[{ required: true, message: formFieldRequired.errorMessage }]}
                >
                    <Upload className="oas-upload" maxCount={1} customRequest={onUpload} itemRender={uploadItemRender}>
                        <UiButton type="primary" text="Browse" />
                    </Upload>
                    <div className="oas-upload-message">{uploadMessage}</div>
                </Form.Item>
                <Form.Item
                    name="target_url"
                    label="target url"
                    rules={[{ required: true, message: formFieldRequired.errorMessage }]}
                >
                    <UiSingleCustomOptionSelect options={urlOptions} disabled={!form.getFieldValue('oas_file_id')} />
                </Form.Item>
                <Form.Item label="test config" name="test_config">
                    Default
                </Form.Item>
                <Form.Item label="auth config" name="auth_config">
                    --
                </Form.Item>
            </Form>
        </UiModal>
    );
};
