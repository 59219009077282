import React, { useState } from 'react';
import { Checkbox, Form, Input, Radio, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { RadioChangeEvent } from 'antd/lib/radio';

import { IEndpointFilter } from '../endpoint-filter-list/EndpointFilterList';
import { formFieldRequired, isRegexValidator } from '../../../../../general/forms';

import './EndpointFilterForm.scss';

export interface IEndpointFilterFormProps {
    form: FormInstance;
    selectedEndpointFilter?: IEndpointFilter;
    isMethodSelectVisible: boolean;
    setIsMethodSelectVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const HTTP_METHODS = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE', 'HEAD', 'CONNECT', 'TRACE'];

const regexHintList = {
    endpoint_path: 'E.g. ^/api/v2/users/(john|jane)',
    response_content_types: 'E.g. .*text.*',
};

export default function EndpointFilterForm({
    form,
    selectedEndpointFilter,
    isMethodSelectVisible,
    setIsMethodSelectVisible,
}: IEndpointFilterFormProps) {
    const [regexHint, setRegexHint] = useState<string>(regexHintList.endpoint_path);

    function toggleMethodSelect(e: any) {
        setIsMethodSelectVisible(e.target.checked);
    }

    function updateRegexHint(event: RadioChangeEvent) {
        setRegexHint(regexHintList[(event.target?.value as 'endpoint_path') || 'response_content_types']);
    }

    return (
        <div className="EndpointFilterForm">
            <Form
                form={form}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={selectedEndpointFilter}
            >
                <div className="endpoint-filter-radios">
                    <Form.Item name="operator" label="Exclude all endpoints that">
                        <Radio.Group>
                            <Radio value="match">Match</Radio>
                            <Radio value="notmatch">Not Match</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="name">
                        <Radio.Group onChange={updateRegexHint}>
                            <Radio value="endpoint_path">URL</Radio>
                            <Radio value="response_content_types">Response Content Type</Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
                <Form.Item
                    label="Regex"
                    name="value"
                    rules={[
                        { required: true, message: formFieldRequired.errorMessage },
                        {
                            validator: (_, value) => isRegexValidator(value),
                            message: 'Invalid regex',
                        },
                    ]}
                >
                    <Input placeholder={regexHint} />
                </Form.Item>
                <Checkbox checked={isMethodSelectVisible as boolean} onChange={toggleMethodSelect}>
                    Method
                </Checkbox>
                {isMethodSelectVisible && (
                    <Form.Item name="method" rules={[{ required: true, message: formFieldRequired.errorMessage }]}>
                        <Select
                            mode="multiple"
                            options={HTTP_METHODS.map((method) => ({ label: method, value: method }))}
                        />
                    </Form.Item>
                )}
            </Form>
        </div>
    );
}
