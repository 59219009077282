import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { ColDef } from '@ag-grid-community/core';

import { agSetFilter, agTextFilter, BASIC_AGGRID_COL_TYPE } from 'components/ui-ag-grid/commonOptions';
import {
    CellExpandButton,
    CellRenderLabelList,
    CellRenderRangeSensitiveMore,
    CellRenderSeverityBasic,
    CellRenderVerticalCenter,
    FindingTypesDetailRenderer,
    TableColumnHeader,
} from 'components/ui-ag-grid/customCellRenderers';
import { UiAgGridSSRM } from 'components/ui-ag-grid/UiAgGridSSRM';
import { UiIcon } from 'components/icon/UiIcon';
import { IScanDetailsPathParams } from '../ScanDetails';
import { httpPOST } from 'general/http-service';
import { errorMessage } from 'general/toast-service';
import { IFilter } from 'interfaces/filter.interface';
import { IServerQueryResponse } from 'api/baseServerApi';
import { IFindingType } from 'api/testingApi';

export const FindingTypesTable = React.memo(() => {
    const history = useHistory();
    const { activeOrg, scanId } = useParams<IScanDetailsPathParams>();
    const [filters, setFilters] = useState<IFilter[]>([]);

    const baseColConfig: Partial<ColDef> = {
        cellRenderer: 'cellRenderVerticalCenter',
        sortable: true,
        resizable: true,
    };

    const findingTypesColumnDefs: ColDef[] = [
        {
            ...baseColConfig,
            headerName: 'Name',
            field: 'name',
            flex: 2,
        },
        {
            ...baseColConfig,
            headerName: 'Findings',
            field: 'findings_count',
            width: 150,
        },
        {
            ...baseColConfig,
            headerName: 'Severity',
            field: 'severity',
            sort: 'desc',
            width: 150,
            cellRenderer: 'severityRender',
        },
        {
            ...baseColConfig,
            headerName: 'Labels',
            field: 'labels',
            cellRenderer: 'labelListRender',
            cellRendererParams: (params: any) => {
                return {
                    value: params.value?.map((label: string) => ({ label })),
                };
            },
            flex: 2,
        },
        {
            ...baseColConfig,
            headerName: '',
            field: 'more',
            width: 64,
            flex: 0,
            cellRenderer: 'moreButtonRender',
            cellRendererParams: (params: any) => ({
                menuItems: [
                    {
                        icon: <UiIcon name="show" />,
                        label: 'View Findings',
                        onClick: () => {
                            history.push(
                                `/${activeOrg}/testing/scans/${scanId}/findingTypes/${params.data.test_result_id}`
                            );
                        },
                    },
                ],
            }),
            suppressMenu: true,
            suppressMovable: true,
            filter: false,
            filterParams: null,
            sortable: false,
            resizable: false,
        },
        {
            headerName: '',
            width: 60,
            field: 'overlay',
            cellRenderer: 'expandButtonCellRenderer',
            suppressMenu: true,
            suppressMovable: true,
        },
    ];

    /* No filters in this table */
    // function handleFilterChange(params: any) {
    //     const filterArray: IFilter[] = Object.keys(params).map((columnName: any) => {
    //         const column = params[columnName];
    //
    //         return {
    //             name: columnName,
    //             operator: column.type,
    //             value: column.filter,
    //             value_type: column.filterType,
    //         };
    //     });
    //     setFilters(filterArray);
    // }

    function getData(
        start: number,
        end: number,
        sortParams?: string
    ): Promise<AxiosResponse<IServerQueryResponse<IFindingType>>> {
        const sort_by = sortParams ? sortParams.split('=')[1] : 'desc(id)';
        return httpPOST(`organizations/${activeOrg}/testing/scans/${scanId}/test_results/query`, {
            filters,
            sort_by,
            offset: start,
            limit: 1000,
        }).catch((error) => {
            return errorMessage(`${error.response?.data?.detail || 'Unexpected error while fetching data'}`);
        });
    }

    return (
        <UiAgGridSSRM
            newCustomTitle={(numRows: number) => `Finding Types (${numRows})`}
            columns={findingTypesColumnDefs}
            options={{
                columnTypes: {
                    basic: BASIC_AGGRID_COL_TYPE,
                    agSetFilter: agSetFilter,
                    agTextFilter: agTextFilter,
                },
                components: {
                    agColumnHeader: TableColumnHeader,
                    moreButtonRender: CellRenderRangeSensitiveMore,
                    cellRenderVerticalCenter: CellRenderVerticalCenter,
                    labelListRender: CellRenderLabelList,
                    severityRender: CellRenderSeverityBasic,
                    findingTypesDetailRenderer: FindingTypesDetailRenderer,
                    expandButtonCellRenderer: CellExpandButton,
                },
                detailRowAutoHeight: false,
            }}
            getData={getData}
            masterDetail={true}
            getDetailData={() => {}}
            customDetailComponent="findingTypesDetailRenderer"
            // onFilterChange={handleFilterChange}
            dataMappingFunction={(data: any) => data}
        />
    );
});
