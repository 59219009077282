import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { IAlert } from 'interfaces/alert.interface';
import { UiIcon } from '../../icon/UiIcon';
import { UiTitle } from '../../ui-title/UiTitle';
import {
    bulletSpaceFormat,
    dateStringFormat,
    goToEndpointInNewTab,
    investigateInNewTab,
    patchAlert,
} from 'general/utils';
import { UiTooltip } from '../../ui-tooltip/UiTooltip';
import { IOverlayMenu, UiOverlayMenu } from '../../ui-overlay-menu/UiOverlayMenu';
import { AlertStatusEnum } from 'containers/alerts/Alerts';
import { CellRenderLabelList } from '../../ui-ag-grid/customCellRenderers';
import { EventTypesEnum } from 'enums/eventTypes.enum';
import { UiColumnProgress } from '../../ui-column-progress/UiColumnPrgress';
import { AlertTypesEnum } from 'enums/alertTypes.enum';
import { ITenant, selectCurrentTenantDetails } from 'api/tenantListApi';
import { Detokenizable } from 'components/Detokenizable/Detokenizable';

import './AlertItem.scss';

interface IProps {
    alertData: IAlert;
    stretchHeight?: boolean;
    reloadAlertsCB: Function;
    showGoToEndpointOption: boolean;
    alertStatusChanged?: (isOpened: boolean) => void;
}

export const AlertItem = ({
    alertData,
    stretchHeight,
    reloadAlertsCB,
    showGoToEndpointOption,
    alertStatusChanged,
}: IProps) => {
    const stretchClass = stretchHeight ? 'stretch-height' : '';
    const currentTenant = useSelector(selectCurrentTenantDetails);

    const alertType = alertData.alert_type === AlertTypesEnum.API ? 'Risk:' : 'User:';
    const AlertTypeUIData = () => {
        return alertData.alert_type === AlertTypesEnum.API ? (
            <div className="ui-risk-progress-container">
                <UiColumnProgress score={alertData.base_risk_score || 0} />
            </div>
        ) : (
            <Detokenizable
                className="value"
                tokenizedContent={alertData.entities[0]?.value}
                renderTooltip={(content) => content}
                renderContent={(content: string) => <ns-truncate chars={16} content={content} />}
            />
        );
    };

    const getMenuItems = (reloadCallback: Function) => {
        const menuItems: IOverlayMenu['menuItems'] =
            alertData.entities
                ?.filter((entity: { name: string; class: string; family: string; value: string }) => entity.value)
                .map((entity: { name: string; class: string; family: string; value: string }) => ({
                    label: `Investigate ${entity.name}`,
                    onClick: () => {
                        investigateInNewTab(
                            process.env.REACT_APP_REDIRECT_SIGN_IN as string,
                            currentTenant as ITenant,
                            EventTypesEnum.Alert,
                            alertData.id,
                            entity.name,
                            entity.value,
                            moment(alertData.timestamp).valueOf()
                        );
                    },
                })) || [];

        if (menuItems.length > 0) {
            menuItems.push({ label: 'divider' });
        }

        if (alertData.endpoints?.length && showGoToEndpointOption) {
            menuItems.push(
                {
                    icon: <UiIcon name="goTo" />,
                    label: 'Go to Endpoint',
                    onClick: () => {
                        goToEndpointInNewTab(
                            process.env.REACT_APP_REDIRECT_SIGN_IN,
                            encodeURIComponent(alertData.endpoints[0].service_name),
                            alertData.endpoints[0].id,
                            '1Y',
                            currentTenant as ITenant,
                            [
                                moment(alertData.timestamp).startOf('day').unix(),
                                moment(alertData.timestamp).isSame(new Date(), 'date')
                                    ? moment().unix()
                                    : moment(alertData.timestamp).endOf('day').unix(),
                            ]
                        );
                    },
                },
                {
                    label: 'divider',
                }
            );
        }

        menuItems.push({
            label: `${alertData.status === 'Open' ? 'Close Alert' : 'Reopen Alert'}`,
            onClick: () => {
                patchAlert(
                    'status',
                    currentTenant as ITenant,
                    alertData.id,
                    `${alertData.status === 'Open' ? AlertStatusEnum.Closed : AlertStatusEnum.Open}` as AlertStatusEnum
                ).then(() => {
                    reloadCallback();
                    alertStatusChanged && alertStatusChanged(true);
                });
            },
        });

        return menuItems;
    };

    return (
        <div className="alert-item">
            <div className={`alert-item-content box ${stretchClass}`}>
                <div className="left-side">
                    <div className="name ellipsis">
                        <div className="severity-icon">
                            <UiIcon name={`alert${alertData.severity}`} />
                        </div>
                    </div>
                    <div className="info-container">
                        <UiTooltip
                            copyText={alertData.name + ', ' + alertData.description}
                            title={
                                <div>
                                    <span className="data-name">{alertData.name}</span>
                                    <div className="pre-wrap data-description">
                                        {bulletSpaceFormat(alertData.description)}
                                    </div>
                                </div>
                            }
                        >
                            <span className="data-name">{alertData.name}</span>
                        </UiTooltip>
                        <div className="date">{dateStringFormat(alertData.timestamp)}</div>
                        <div className="labels-list">
                            <CellRenderLabelList value={alertData.labels?.map((label) => ({ label }))} />
                        </div>
                    </div>
                </div>
                <div className="right-side">
                    <div className="data-row">
                        <UiTitle title={alertType} type={'small'} />
                        <AlertTypeUIData />
                    </div>
                    <div className="data-row">
                        <UiTitle title={'category:'} type={'small'} />
                        <UiTooltip title={alertData.category}>
                            <span className="value ">{alertData.category}</span>
                        </UiTooltip>
                    </div>
                    <div className="data-row">
                        <UiTitle title={'author:'} type={'small'} />
                        <UiTooltip title={alertData.author}>
                            <span className="value ">{alertData.author}</span>
                        </UiTooltip>
                    </div>
                </div>
                <div>
                    <UiOverlayMenu menuItems={getMenuItems(reloadAlertsCB)} />
                </div>
            </div>
        </div>
    );
};
