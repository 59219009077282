import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input } from 'antd';

import { errorMessage, successMessage } from '../../../../../general/toast-service';
import { ISettingsPathParams } from '../../../Settings';
import { formFieldName, formFieldRequired } from '../../../../../general/forms';
import { useFetchAllDataSources } from '../shared/useFetchAllDataSources';
import { UiModal } from '../../../../ui-modal/UiModal';
import { createDataSource, getDataSource, IDataSource, updateDataSource } from '../shared/dataSourceApis';

export interface IDataSourceAddEditModalProps {
    onClose: (refreshNeeded?: boolean) => void;
}

interface IDataSourceAddEditModalParams extends ISettingsPathParams {
    dataSourceId?: string;
}

export const DataSourceAddEditModal = (props: IDataSourceAddEditModalProps) => {
    const { onClose } = props;
    const history = useHistory();

    const { activeOrg, dataSourceId } = useParams<IDataSourceAddEditModalParams>();
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [dataSourceToEdit, setDataSourceToEdit] = useState<IDataSource>();
    const [existingDataSources, setExistingDataSources] = useState<IDataSource[]>([]);
    const [dataSourceForm] = Form.useForm();

    useFetchAllDataSources(setExistingDataSources);

    useEffect(() => {
        const dataSourceFetcher = async () => {
            try {
                if (dataSourceId) {
                    const dsRes = await getDataSource(activeOrg, dataSourceId);
                    setDataSourceToEdit(dsRes);
                    dataSourceForm.setFieldsValue({ name: dsRes?.name });
                }
            } catch (e: any) {
                const currentLocation = history.location.pathname;
                errorMessage('Failed loading existing Data Source - cannot edit', { duration: 5 }).then(() => {
                    if (history.location.pathname === currentLocation) {
                        history.goBack();
                    }
                });
            }
        };
        dataSourceFetcher();
    }, [activeOrg, dataSourceId]);

    const handleSave = useCallback(async () => {
        let refreshNeeded = true;
        try {
            if (dataSourceToEdit) {
                if (dataSourceToEdit.name === dataSourceForm.getFieldValue('name')) {
                    successMessage('No changes to Data Source - nothing to save', { duration: 5 });
                    refreshNeeded = false;
                } else {
                    dataSourceToEdit.name = dataSourceForm.getFieldValue('name');
                    const createRes = await updateDataSource(activeOrg, dataSourceToEdit);
                    successMessage('Data Source updated');
                }
            } else {
                const createRes = await createDataSource(activeOrg, dataSourceForm.getFieldValue('name'));
                successMessage('Data Source created');
            }
        } catch (e: any) {
            refreshNeeded = false;
            if (e.response.status === 409) {
                errorMessage('Error: Data Source name already exists');
            } else {
                errorMessage('Error: cannot save Data Source');
            }
        } finally {
            onClose(refreshNeeded);
        }
    }, [activeOrg, dataSourceToEdit, dataSourceForm, onClose]);

    const isDuplicateDsName = async (value: string) => {
        if (value === dataSourceToEdit?.name) {
            return Promise.resolve('');
        }
        const isDuplicate = existingDataSources.find(({ name }) => name === value.trim());
        return isDuplicate ? Promise.reject('Data source name already exists') : Promise.resolve('');
    };

    return (
        <UiModal
            onCancel={() => onClose()}
            acceptButton={{ text: 'Save', onClick: handleSave, disabled: !isFormValid }}
            rejectButton={{ text: 'Cancel', onClick: onClose }}
            title={`${dataSourceId ? 'Edit ' : 'Create '} Data Source`}
        >
            <div style={{ width: '100%' }}>
                <Form
                    form={dataSourceForm}
                    colon={false}
                    layout="vertical"
                    requiredMark={false}
                    onFieldsChange={(changedFields) => {
                        setIsFormValid(changedFields[0]?.errors?.length === 0);
                    }}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                            {
                                required: true,
                                message: formFieldRequired.errorMessage,
                            },
                            {
                                pattern: formFieldName.pattern,
                                message: formFieldName.errorMessage,
                            },
                            {
                                validator: (_, value) => isDuplicateDsName(value),
                            },
                        ]}
                    >
                        <Input placeholder="E.g. My non-prod VPC traffic mirroring" />
                    </Form.Item>
                </Form>
            </div>
        </UiModal>
    );
};
