import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { ISettingsPathParams } from '../../../Settings';
import { ICollector, updateCollector } from '../shared/collectorApis';
import { errorMessage } from '../../../../../general/toast-service';
import { UiModal } from '../../../../ui-modal/UiModal';

export interface IAssignConfigModalProps {
    onClose: (refreshNeeded?: boolean) => void;
    assignCollectorConfig: { confId: string; collector: ICollector };
    configNames: Record<string, string>;
}

export const ConfirmAssignConfigToCollectorModal = ({
    onClose,
    assignCollectorConfig,
    configNames,
}: IAssignConfigModalProps) => {
    const { activeOrg } = useParams<ISettingsPathParams>();
    const { confId, collector } = assignCollectorConfig;
    const { id, name, sourceId } = collector;

    const handleAssignConfig = useCallback(async () => {
        try {
            await updateCollector(activeOrg, sourceId, id, { collectorConfId: confId });
            onClose(true);
        } catch (e) {
            errorMessage('Error: cannot assign Configuration');
            onClose();
        }
    }, []);

    return (
        <UiModal
            onCancel={() => onClose()}
            acceptButton={{ text: 'Apply', onClick: handleAssignConfig }}
            rejectButton={{ text: 'Cancel', onClick: onClose }}
            title="Apply Configuration?"
            icon="save"
        >
            Apply Configuration `{configNames[confId]}` to node `{name}`?
        </UiModal>
    );
};
