import React from "react";
import '../404/404.scss';
import './NoTenant.scss';

export const NoTenant = () => {
    return (
        <div className="four-o-four-container no-tenant">
            <div className="content">
                <div className="info-message">
                    You have not been granted access to any tenants. Please contact your Neosec administrator.
                </div>
            </div>
        </div>
    )
};