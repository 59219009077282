import React, { useState } from 'react';

import { UiTitle } from '../ui-title/UiTitle';
import { UiInput } from '../ui-input/UiInput';
import { Radio, Select } from 'antd';
import { UiChekcbox } from '../ui-checkbox/UiCheckbox';
import { IEntity } from '../../interfaces/entity.interface';
import { useGetEntitiesQuery } from '../../api/entitiesApi';
import { useParams } from 'react-router-dom';

const Option = Select.Option;

export interface KongIntegrationConfig {
    kong_url: string;
    action: 'block' | 'rate_limit';
    timeout: number;
    requests: number;
    frequency: number;
    timeframe: 'minute' | 'hour' | 'day';
    entityName?: IEntity['name'];
}

export interface KongIntegrationActionProps {
    config: KongIntegrationConfig;
    onChange: (event: KongIntegrationConfig) => any;
}

export const KongIntegrationAction: React.FC<KongIntegrationActionProps> = (props: KongIntegrationActionProps) => {
    const [timeoutCheckbox, setTimeoutCheckbox] = useState<boolean>(props.config.timeout > 0);
    const [entityNameCheckbox, setEntityNameCheckbox] = useState<boolean>(!!props.config.entityName);
    const [output, setOutput] = useState<KongIntegrationConfig>({ ...props.config });
    const { activeOrg } = useParams() as { activeOrg: string };
    const { data: entitiesData, isLoading } = useGetEntitiesQuery(activeOrg);

    function onChange(key: string, value: string | number) {
        const newConfig = {
            ...output,
            [key]: value,
        } as KongIntegrationConfig;

        setOutput(newConfig);
        props.onChange(newConfig);
    }

    return (
        <div style={{ position: 'relative', marginTop: '-5px' }}>
            <div className="control-label" style={{ marginBottom: '16px' }}>
                Set Kong Policy
            </div>
            <UiInput
                label="Admin API URL"
                defaultValue={output.kong_url}
                width={500}
                onChange={(event: any) => onChange('kong_url', event.target.value)}
            />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px' }}>
                <div className="control-label">Action</div>
                <Select
                    style={{ minWidth: 500 }}
                    defaultValue={props.config.action === 'rate_limit' ? 'Rate limit consumer' : 'Block consumer'}
                    options={[
                        { id: 'block', value: 'Block consumer' },
                        { id: 'rate_limit', value: 'Rate limit consumer' },
                    ]}
                    onSelect={(event: any, option) => onChange('action', option.id)}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    marginTop: '15px',
                    gap: '15px',
                }}
            >
                <UiChekcbox
                    value={entityNameCheckbox}
                    onChange={(event: any) => {
                        if (!event.target?.checked) {
                            onChange('entityName', '');
                        }
                        setEntityNameCheckbox(event.target?.checked);
                    }}
                />
                <div className="control-label">Use Entity</div>
                <Select
                    style={{ width: '200px', right: '14px' }}
                    disabled={!entityNameCheckbox}
                    defaultValue={props.config.entityName || null}
                    options={
                        isLoading
                            ? []
                            : entitiesData!.items?.map((entity) => ({ value: entity.name, label: entity.name }))
                    }
                    onSelect={(event: any, option) => onChange('entityName', option.value)}
                />
            </div>
            <div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        marginTop: '15px',
                        gap: '15px',
                    }}
                >
                    <UiChekcbox
                        value={timeoutCheckbox}
                        onChange={(event: any) => {
                            if (!event.target?.checked) {
                                onChange('timeout', 0);
                            }
                            setTimeoutCheckbox(event.target?.checked);
                        }}
                    />
                    <div className="control-label">Timeout</div>
                    <UiInput
                        width={50}
                        disabled={!timeoutCheckbox}
                        defaultValue={output.timeout}
                        onBlur={(event: any) => onChange('timeout', parseInt(event.target.value))}
                    />
                    <div className="control-label">Minutes</div>
                </div>
            </div>
            {output.action === 'rate_limit' ? (
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                    <UiInput
                        width={50}
                        defaultValue={output.frequency}
                        onBlur={(event: any) => onChange('requests', parseInt(event.target.value))}
                    />
                    <span style={{ margin: '0 15px' }}>
                        <UiTitle title="Requests every" type="small" />
                    </span>
                    <UiInput
                        width={50}
                        defaultValue={output.frequency}
                        onBlur={(event: any) => onChange('frequency', parseInt(event.target.value))}
                    />
                    <Radio.Group
                        style={{ marginLeft: '15px' }}
                        buttonStyle="solid"
                        defaultValue={output.timeframe || 'hour'}
                        onChange={(event) => onChange('timeframe', event.target.value)}
                    >
                        <Radio value="minute">Minutes</Radio>
                        <Radio value="hour">Hours</Radio>
                    </Radio.Group>
                </div>
            ) : null}
        </div>
    );
};
