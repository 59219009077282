import { UiIcon } from 'components/icon/UiIcon';
import { UiTooltip } from 'components/ui-tooltip/UiTooltip';
import { latestTransactionStatus } from 'api/tenantListApi';

const errorTxt =
    'An error occurred while applying changes to this tenant. Data may not appear as expected for up to a day. Our support team has been notified and is working on a solution';
const pendingTxt =
    'Ongoing changes are in progress for this tenant, data may not appear as expected until the activation is finished';

const STATUS_READABLE_TEXT = {
    PENDING: pendingTxt,
    FAILED: errorTxt,
    TIMED_OUT: errorTxt,
} as const;

interface IActivationErrorProps {
    status?: latestTransactionStatus | null;
}

export const ActivationErrorIcon = ({ status }: IActivationErrorProps) => {
    return (
        <>
            {status && status !== 'SUCCEED' && (
                <UiTooltip title={STATUS_READABLE_TEXT[status]}>
                    <UiIcon name="alertWhite" />
                </UiTooltip>
            )}
        </>
    );
};
