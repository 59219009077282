import React from 'react';
import { Col, Row } from 'antd';

import { humanReadableTimeShowEmpty, isObjectEmpty } from '../../general/utils';
import { IDiscoveryService } from '../../interfaces/service.interface';
import { UiIcon } from '../icon/UiIcon';
import { UiTitle } from '../ui-title/UiTitle';
import { CellProgressBarView, cellRenderTimestampTooltip } from '../ui-ag-grid/customCellRenderers';

import './ServiceSummary.scss';

interface IProps {
    service: IDiscoveryService | null | undefined;
}

export const maxDiffDaysService = 151;

export interface IStatisticsColumnDefinition {
    displayName: string;
    order: number;
    formatter?: Function;
    getIcon?: Function;
    renderer?: Function;
}

export const colDefs: { [key: string]: IStatisticsColumnDefinition } = {
    risk_score: {
        order: 1,
        displayName: 'Risk',
        renderer: CellProgressBarView,
    },
    risk_change: {
        order: 2,
        displayName: 'Risk Change',
        getIcon: (value: number) => {
            return value > 0 ? <UiIcon name="upArrowRed" /> : '';
        },
    },
    behavior_alerts_count: {
        order: 3,
        displayName: 'Runtime',
    },
    posture_alerts_count: {
        order: 4,
        displayName: 'Posture',
    },
    endpoint_count: {
        order: 5,
        displayName: 'Endpoints',
    },
    endpoints_change: {
        order: 6,
        displayName: 'Endpoints change',
        getIcon: (value: number) => {
            return value > 0 ? (
                <div className="arrow-change-icon">
                    {' '}
                    <UiIcon name="upArrowRed" />
                </div>
            ) : (
                ''
            );
        },
    },
    calls_count: {
        order: 7,
        displayName: 'Calls',
    },
    errors_4xx: {
        order: 8,
        displayName: '4xx',
    },
    errors_5xx: {
        order: 9,
        displayName: '5xx',
    },
    first_seen: {
        order: 10,
        displayName: 'First seen',
        formatter: (timestamp: string) => ({
            timestamp,
            value: humanReadableTimeShowEmpty(timestamp, maxDiffDaysService),
            maxDiffDays: maxDiffDaysService,
        }),
        renderer: cellRenderTimestampTooltip,
    },
    last_seen: {
        order: 11,
        displayName: 'Last seen',
        formatter: (timestamp: string) => ({
            timestamp,
            value: humanReadableTimeShowEmpty(timestamp, maxDiffDaysService),
            maxDiffDays: maxDiffDaysService,
        }),
        renderer: cellRenderTimestampTooltip,
    },
};

export const dummyFunctions = {
    formatter: (e: any) => e,
    getIcon: () => false,
    renderer: (e: any) => e,
};

export const ServiceSummary = ({ service }: IProps) => {
    if (service) {
        return (
            <div className="service-summary-container">
                {isObjectEmpty(service) && (
                    <>
                        <Row className={'service-row'}>
                            {Object.entries(service).map(function ([key, value], index) {
                                return colDefs.hasOwnProperty(key) ? (
                                    <Col key={index} order={colDefs[key].order}>
                                        <UiTitle title={colDefs[key].displayName} type="medium" />
                                        <div className="value alerts-value">
                                            <div className="text">
                                                <span>
                                                    {(colDefs[key].renderer || dummyFunctions.renderer)(
                                                        (colDefs[key].formatter || dummyFunctions.formatter)(value)
                                                    )}
                                                </span>
                                                {colDefs[key].getIcon ? (
                                                    <span className={'icon'}>
                                                        {(colDefs[key].getIcon || dummyFunctions.getIcon)(value)}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </Col>
                                ) : null;
                            })}
                        </Row>
                    </>
                )}
            </div>
        );
    } else return null;
};
