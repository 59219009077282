import React, { useEffect, useState } from 'react';

import { DialogConfig, subscribe } from '../../../services/dialog/dialog-service';
import { UiModal } from '../../ui-modal/UiModal';

export const ConfirmationModal = () => {
    const [modalConfig, setModalConfig] = useState<DialogConfig>();

    useEffect(() => {
        const subscr = subscribe(
            (dialogConfig) => {
                setModalConfig(dialogConfig);
            },
            (error: any) => console.error(error)
        );

        return () => {
            subscr.unsubscribe();
        };
    }, []);

    const handleOk = async () => {
        modalConfig?.resolve && modalConfig.resolve();
        setModalConfig(null);
    };

    const handleCancel = () => {
        modalConfig?.reject && modalConfig.reject();
        setModalConfig(null);
    };

    return modalConfig ? (
        <UiModal
            onCancel={handleCancel}
            acceptButton={{ text: modalConfig.resolveTitle || 'ok', onClick: handleOk }}
            rejectButton={{ text: modalConfig.rejectTitle || 'cancel', onClick: handleCancel }}
            title={modalConfig.title}
            icon={modalConfig.icon}
        >
            {modalConfig.text}
        </UiModal>
    ) : null;
};
