import { tenantApi } from './baseServerApi';

// Contains ALL possible options needed for all endpoints relating to scans
export interface IScansRequestBuilder {
    tenant: string;
    scanId: string;
    findingTypeId: string;
}

export interface ICreateScanRequest {
    application_name: string;
    oas_file_id: string;
    target_url: string;
}

export interface IScan extends ICreateScanRequest {
    scan_id: string;
    status: string;
    duration: number;
    scan_engine: string;
    triggered_by: string;
    test_config_name: string;
    findings_info_count: number;
    findings_low_count: number;
    findings_medium_count: number;
    findings_high_count: number;
    cli_command: string;
    oas_file_name: string;
    created_by: string;
    created_at: string;
    ended_at?: string;
    queued_at?: string;
    requests_count?: number;
    scan_template_name?: string;
    started_at?: string;
    auth_config_name?: string;
    excluded_endpoints?: string[];
    included_endpoints?: string[];
}

export interface IFindingType {
    test_id: string;
    test_result_id: string;
    description: string;
    findings_count: number;
    labels: string[];
    name: string;
    references: string;
    severity: string;
    solution: string;
}

export const testingApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        getScan: builder.query<IScan, Pick<IScansRequestBuilder, 'tenant' | 'scanId'>>({
            query: ({ tenant, scanId }) => `${tenant}/testing/scans/${scanId}`,
            transformResponse: (response: IScan) => ({
                ...{
                    id: 0,
                    status: '',
                    application_name: '',
                    duration: 0,
                    scan_engine: '',
                    oas_file_name: '',
                    target_url: '',
                    triggered_by: '',
                    test_config_name: '',
                    scan_template_name: '',
                    auth_config_name: 'Default',
                    findings_high_count: 0,
                    findings_info_count: 0,
                    findings_low_count: 0,
                    findings_medium_count: 0,
                    requests_count: 0,
                    created_by: '',
                    created_at: '',
                    queued_at: '',
                    started_at: '',
                    ended_at: '',
                    excluded_endpoints: [],
                    included_endpoints: [],
                },
                ...response,
            }),
            providesTags: ['testingScans'],
        }),
        createScan: builder.mutation<IScan, { tenant: string; body: ICreateScanRequest }>({
            query: ({ tenant, body }) => ({
                url: `${tenant}/testing/scans`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['testingScans'],
        }),
        deleteScan: builder.mutation<void, { tenant: string; scanId: string }>({
            query: ({ tenant, scanId }) => ({
                url: `${tenant}/testing/scans/${scanId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['testingScans'],
        }),
        getScanCliCommand: builder.query<{ cli_command: string }, { tenant: string; scanId: string }>({
            query: ({ tenant, scanId }) => `${tenant}/testing/scans/${scanId}/cli_command`,
        }),
        getFindingType: builder.query<IFindingType, IScansRequestBuilder>({
            query: ({ tenant, scanId, findingTypeId }) =>
                `${tenant}/testing/scans/${scanId}/test_results/${findingTypeId}`,
        }),
    }),
    overrideExisting: false,
});

export const useGetScanQuery = testingApi.endpoints.getScan.useQuery;
export const useCreateScanMutation = testingApi.endpoints.createScan.useMutation;
export const useDeleteScanMutation = testingApi.endpoints.deleteScan.useMutation;
export const useGetScanCliCommandQuery = testingApi.endpoints.getScanCliCommand.useQuery;
export const useGetFindingTypeQuery = testingApi.endpoints.getFindingType.useQuery;
