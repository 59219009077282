import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Divider, Form } from 'antd';

import { errorMessage, successMessage } from '../../../../../general/toast-service';
import { extractErrorMessage } from '../../../../../general/utils';
import { gridCellGenerator } from '../../../../Card/gridCellGenerator';
import { IWizardStep, UiWizard } from 'components/UiWizard/UiWizard';
import { ISettingsPathParams } from 'components/settings/Settings';
import { useGetAttributesQuery } from 'api/discoveryApi';
import {
    INewEntityDefinition,
    TEntityFamily,
    useGetClassesQuery,
    useGetEntitiesQuery,
    usePostEntityMutation,
} from 'api/entitiesApi';
import { AddEntityForm } from '../AddEntityForm/AddEntityForm';

import './AddEntityWizard.scss';

interface IAddEntityWizardPathParams extends ISettingsPathParams {}

export const AddEntityWizard = () => {
    const { activeOrg } = useParams<IAddEntityWizardPathParams>();
    const { refetch: refetchEntities } = useGetEntitiesQuery(activeOrg);
    const [postEntity] = usePostEntityMutation();
    const { data: attributes } = useGetAttributesQuery(activeOrg);
    const [newEntityDefinition, setNewEntityDefinition] = useState<INewEntityDefinition>();
    const [family, setFamily] = useState<TEntityFamily>('actor');
    const [form] = Form.useForm();
    const { data: classes } = useGetClassesQuery({
        tenant: activeOrg,
        family,
    });
    const history = useHistory();

    const handleSaveClick = async () => {
        if (newEntityDefinition) {
            try {
                await postEntity({ tenant: activeOrg, body: newEntityDefinition }).unwrap();
                successMessage('Successfully created entity');
                refetchEntities();
            } catch (error) {
                errorMessage(extractErrorMessage(error));
            }
        }
        history.push(`/${activeOrg}/settings/entities`);
    };

    const handleNextClick = async () => {
        try {
            const tempEntityDefinition: Partial<INewEntityDefinition> = await form.validateFields();
            tempEntityDefinition['value_type'] = attributes!.items.find(({ name }) => {
                return name === tempEntityDefinition.source_attribute;
            })?.value_type;
            setNewEntityDefinition(tempEntityDefinition as INewEntityDefinition);
            return true;
        } catch (error) {
            errorMessage(extractErrorMessage(error));
            return false;
        }
    };

    const wizardSteps: IWizardStep[] = [
        {
            title: 'Create Entity',
            content: (
                <div className="add-entity-wizard-container">
                    <div className="add-entity-wizard-body">
                        <AddEntityForm
                            form={form}
                            attributes={attributes?.items}
                            classes={classes?.items}
                            setFamily={setFamily}
                        />
                    </div>
                </div>
            ),
        },
        {
            title: 'Confirm',
            content: (
                <div className="add-entity-wizard-container">
                    <div className="add-entity-confirmation">
                        <div className="entity-preview">
                            <div className="subtitle">Entity Properties</div>
                            <div className="entity-details">
                                {gridCellGenerator('name', newEntityDefinition?.name)}
                                {gridCellGenerator('family', newEntityDefinition?.family)}
                                {gridCellGenerator('class', newEntityDefinition?.class)}
                                {gridCellGenerator('attribute', newEntityDefinition?.source_attribute)}
                            </div>
                        </div>
                        <Divider />
                        <div className="entity-creation-warning">
                            Once created, the entity cannot be edited anymore - continue?
                        </div>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <UiWizard
            wrapClassName="add-entity-wizard"
            title="Add New Entity"
            onCancel={() => {
                history.push(`/${activeOrg}/settings/entities`);
            }}
            onNext={handleNextClick}
            onFinish={handleSaveClick}
            steps={wizardSteps}
        />
    );
};
