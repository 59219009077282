import React from 'react';
import './query-condition.scss';
import {UiAutoComplete} from '../../ui-autocomplete/UiAutoComplete';
import {UiDropDown} from "../../ui-dropdown/UiDropDown";
import {UiInput} from "../../ui-input/UiInput";
import {IApiCallParameter} from "../../../interfaces/apiCallParameter.interface";
import {UiIcon} from "../../icon/UiIcon";
import {IQueryCondition, IQueryValidation} from '../../../interfaces/query.interface';
import UiMultiSelect from '../../ui-multi-select/UiMultiSelect';

export interface dropDownOptions {
    label: string,
    value: string
}

const numbersOperators: dropDownOptions[] = [
    {label: '=', value: 'Eq'},
    {label: '!=', value: 'Ne'},
    {label: '>', value: 'Gt'},
    {label: '>=', value: 'Ge'},
    {label: '<', value: 'Lt'},
    {label: '<=', value: 'Le'},
    {label: 'Exists', value: 'Exists'},
    {label: 'Does Not Exist', value: 'NotExists'}
];
const stringOperators: dropDownOptions[] = [
    {label: 'Contains', value: 'Contains'},
    {label: 'Not Contains', value: 'NotContains'},
    {label: 'Is', value: 'Is'},
    {label: 'Is Not', value: 'IsNot'},
    {label: 'One Of', value: 'OneOf'},
    {label: 'Not One Of', value: 'NotOneOf'},
    {label: 'Exists', value: 'Exists'},
    {label: 'Does Not Exist', value: 'NotExists'}
];

const ipOperators: dropDownOptions[] = [
    {label: 'Matches', value: 'Matches'},
    {label: 'Not Matches', value: 'NotMatches'},
    {label: 'Exists', value: 'Exists'},
    {label: 'Does Not Exist', value: 'NotExists'}
];


interface IQueryConditionProps {
    conditions?: IQueryCondition[];
    conditionsData: [IApiCallParameter[], any];
    onChange: Function;
    loading?: boolean;
    onConditionRemove: Function;
    onHover: any;
    validation?: (string | null)[];
}

export const QueryCondition = (props: IQueryConditionProps) => {
    const flatAttributeOptions = props.conditionsData[0]; // raw response from api request
    const groupedAttributeOptions = props.conditionsData[1]; // grouped for display

    const getOperatorType: any = (value_type: string) => {
        switch (value_type) {
            case ('Integer'): {
                return {defaultValue: 'Eq', options: numbersOperators};
            }
            case  ('String'): {
                return {defaultValue: 'Contains', options: stringOperators};
            }
            case  ('IPv4'): {
                return {defaultValue: 'Matches', options: ipOperators};
            }
            default : {
                return {defaultValue: 'Contains', options: stringOperators};
            }
        }

    }

    const onAttributeSelected = (id: any, index: number) => {
        resetValue(index)
        props.onChange({
            id,
            index,
            name: flatAttributeOptions[id].name,
            in: flatAttributeOptions[id].in,
            partOf: flatAttributeOptions[id].part_of,
            value_type: flatAttributeOptions[id].value_type
        }, index);

        const defaultOperatorValue = getOperatorType(flatAttributeOptions[id].value_type).defaultValue
        onOperatorChanged(defaultOperatorValue, index);
    };

    const onOperatorChanged = (e: any, index: number) => {
        props.onChange({operator: e}, index);
    };

    const onValueChanged = (e: any, index: number) => {
        props.onChange({value: e.target.value}, index)
    };

    const onMultiChanged = (e: any, index: number) => {
        props.onChange({value: e}, index)
    };


    const onHoverChange = (e: any) => {
        e.stopPropagation();
        props.onHover(e, false);
    };

    const resetValue = (index: number) => {
        if (props.conditions && props.conditions[index]) {
            props.conditions[index] = {
                partOf: undefined,
                in: undefined,
                id: undefined,
                operator: undefined,
                value: ''
            }
        }
    }

    return (
        <div className="query-conditions-container">
            {props.conditions?.map((item: any, index: number) => {
                    // const itemData = props.conditionsData[+item.id] || {};
                    // set input control to 'multi select tags' for 'OneOf' / 'NotOneOf' operator
                    let valueInputComponent;
                    if (item.operator === 'OneOf' || item.operator === 'NotOneOf') {
                        valueInputComponent = <UiMultiSelect
                            width={180}
                            handleChange={(e: any) => onMultiChanged(e, index)}
                            placeholder={"Value"}
                        />
                    } else {
                        valueInputComponent = <UiInput
                            type={item.value_type}
                            value={item.value}
                            width={180}
                            maxLength={1000}
                            disabled={(!item.id && item.id !== 0) || item.operator === 'Exists' || item.operator === 'NotExists'}
                            placeholder={"Value"}
                            onChange={(e: any) => onValueChanged(e, index)}/>
                    }

                    return (
                        <div className="condition-element" key={index}>
                            <div className="condition-controls" onMouseOver={onHoverChange}>
                        <span className="remove-icon"><UiIcon name="remove"
                                                              onClick={(e: any) => props.onConditionRemove(index)}/></span>
                                <div className="condition-control">
                                    <UiAutoComplete options={groupedAttributeOptions}
                                                    placeholder={'Parameter'}
                                                    width={180}
                                                    loading={props.loading}
                                                    value={item.id}
                                                    errorMessage={props.validation?.[index]}
                                                    onOptionSelect={(e: any) => onAttributeSelected(e, index)}/>
                                </div>
                                <div className="condition-control">
                                    <UiDropDown options={getOperatorType(item.value_type).options}
                                                placeholder={"Op"}
                                                disabled={!item.id && item.id !== 0}
                                                value={item.operator ? item.operator : null}
                                                handleChange={(e: any) => onOperatorChanged(e, index)}
                                                width={150}/>
                                </div>
                                <div className="condition-control">
                                    {valueInputComponent}
                                </div>
                            </div>
                        </div>
                    )
                }
            )}
        </div>
    );
};
