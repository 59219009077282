import moment from 'moment';

import { IAttributeFilter } from 'interfaces/query.interface';
import { IServerQueryResponse, tenantApi } from './baseServerApi';
import { fetchAll } from './apiUtils';
import { IService } from 'interfaces/service.interface';
import { IServiceLabel } from 'interfaces/labels.interface';

interface IDiscoveryQuery {
    tenant: string;
    query: {
        from_timestamp: string;
        to_timestamp: string;
        service_names?: string[];
        service_labels?: string[];
    };
}

type TPaginatedDiscoveryQuery = IDiscoveryQuery & { query: { limit: number } };

interface IServiceAndEndpointCountResponse {
    total_services_count: number;
    new_services_count: number;
    total_endpoints_count: number;
    new_endpoints_count: number;
}

interface IServiceRisk {
    service_name: string;
    risk_score: number;
}

type TServiceRiskResponse = Pick<IServerQueryResponse<IServiceRisk>, 'items' | 'total'> & { other: number };

interface IServiceRiskChangeResponse {
    positive: IServiceRisk[];
    negative: IServiceRisk[];
    total: number;
}

interface IEndpointApiTypeCount {
    label: string;
    count: number;
}

interface IEndpointApiTypeCountResponse {
    total: IEndpointApiTypeCount[];
    new: IEndpointApiTypeCount[];
}

interface IServiceEndpointCount {
    service_name: string;
    total_endpoint_count: number;
    new_endpoint_count: number;
}

type TServiceEndpointCountResponse = Pick<IServerQueryResponse<IServiceEndpointCount>, 'items' | 'total'>;

interface IServiceSensitiveEndpointsResponse {
    labels: string[];
    stats: {
        [key: string]: { label: string; count: number };
    };
}

export const discoveryApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        getServices: builder.query<IServerQueryResponse<IService>, string>({
            queryFn: async (tenant, _queryApi, _extraOptions, baseQuery) =>
                fetchAll<IService>(
                    `${tenant}/discovery/services?from_timestamp=${moment()
                        .clone()
                        .subtract(1, 'year')
                        .unix()}&to_timestamp=${moment().unix()}`,
                    1000,
                    baseQuery
                ),
        }),
        getServiceNames: builder.query<IService['name'][], string>({
            query: (tenant) => `${tenant}/discovery/services/names`,
        }),
        getServiceLabels: builder.query<IServiceLabel['label'][], string>({
            query: (tenant) => `${tenant}/discovery/services/label_names`,
        }),
        getAttributes: builder.query<IServerQueryResponse<IAttributeFilter>, string>({
            queryFn: async (tenant, _queryApi, _extraOptions, baseQuery) =>
                fetchAll<IAttributeFilter>(
                    `${tenant}/discovery/attributes?from_timestamp=${moment()
                        .clone()
                        .subtract(1, 'year')
                        .unix()}&to_timestamp=${moment().unix()}`,
                    1000,
                    baseQuery
                ),
        }),
        getServiceAndEndpointCount: builder.query<IServiceAndEndpointCountResponse, IDiscoveryQuery>({
            query: (params) => ({
                url: `${params.tenant}/discovery/services/stats/services_endpoints_count`,
                method: 'POST',
                body: params.query,
            }),
        }),
        getServiceRisk: builder.query<TServiceRiskResponse, TPaginatedDiscoveryQuery>({
            query: (params) => ({
                url: `${params.tenant}/discovery/services/stats/risk`,
                method: 'POST',
                body: params.query,
            }),
        }),
        getServiceRiskChange: builder.query<IServiceRiskChangeResponse, TPaginatedDiscoveryQuery>({
            query: (params) => ({
                url: `${params.tenant}/discovery/services/stats/risk_change`,
                method: 'POST',
                body: params.query,
            }),
        }),
        getEndpointApiTypeCount: builder.query<IEndpointApiTypeCountResponse, IDiscoveryQuery>({
            query: (params) => ({
                url: `${params.tenant}/discovery/endpoints/stats/api_type_count`,
                method: 'POST',
                body: params.query,
            }),
        }),
        getServiceEndpointCount: builder.query<TServiceEndpointCountResponse, TPaginatedDiscoveryQuery>({
            query: (params) => ({
                url: `${params.tenant}/discovery/services/stats/endpoints_count`,
                method: 'POST',
                body: params.query,
            }),
        }),
        getServiceSensitiveEndpoints: builder.query<IServiceSensitiveEndpointsResponse, TPaginatedDiscoveryQuery>({
            query: (params) => ({
                url: `${params.tenant}/discovery/services/stats/sensitive_endpoints`,
                method: 'POST',
                body: params.query,
            }),
        }),
        getServiceSensitiveCalls: builder.query<IServiceSensitiveEndpointsResponse, TPaginatedDiscoveryQuery>({
            query: (params) => ({
                url: `${params.tenant}/discovery/services/stats/sensitive_calls`,
                method: 'POST',
                body: params.query,
            }),
        }),
    }),
    overrideExisting: false,
});

export const useGetServicesQuery = discoveryApi.endpoints.getServices.useQuery;
export const useGetServiceNamesQuery = discoveryApi.endpoints.getServiceNames.useQuery;
export const useGetServiceLabelsQuery = discoveryApi.endpoints.getServiceLabels.useQuery;
export const useGetAttributesQuery = discoveryApi.endpoints.getAttributes.useQuery;
export const getServiceAndEndpointCountQuery = discoveryApi.endpoints.getServiceAndEndpointCount.useQuery;
export const getServiceRiskQuery = discoveryApi.endpoints.getServiceRisk.useQuery;
export const getServiceRiskChangeQuery = discoveryApi.endpoints.getServiceRiskChange.useQuery;
export const getEndpointApiTypeCountQuery = discoveryApi.endpoints.getEndpointApiTypeCount.useQuery;
export const getServiceEndpointCountQuery = discoveryApi.endpoints.getServiceEndpointCount.useQuery;
export const getServiceSensitiveEndpointsQuery = discoveryApi.endpoints.getServiceSensitiveEndpoints.useQuery;
export const getServiceSensitiveCallsQuery = discoveryApi.endpoints.getServiceSensitiveCalls.useQuery;
