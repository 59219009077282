import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import moment from 'moment';
import { SelectionChangedEvent } from '@ag-grid-community/core';
import { RowNode } from '@ag-grid-community/core';

import { TDatetimeRange } from 'general/utils';
import { IFilter } from 'interfaces/filter.interface';
import { DiscoveryHeader } from '../DiscoveryHeader/DiscoveryHeader';
import { getTableDataServices, QUERY_RESULTS_LIMIT } from '../shared/discoveryApis';
import { ServiceFilterableTable } from './ServiceFilterableTable/ServiceFilterableTable';
import ServicesCheckbox from './ServiceFilterableTable/ServicesCheckbox/ServicesCheckbox';

import '../../Container.scss';
import './Services.scss';

export const Services = () => {
    const params = useParams() as { activeOrgParam: string };

    const location = useLocation();
    const queryParams: any = new URLSearchParams(location.search);

    const [servicesFilter, setServicesFilter] = useState<IFilter[]>([]);
    const [servicesCheckboxFilters, setServicesCheckboxFilters] = useState<IFilter[]>([]);
    const [rowsCount, setRowsCount] = useState<number>(0);
    const [selectedServices, setSelectedServices] = useState<RowNode[]>([]);
    const [selectedServicesNames, setSelectedServicesNames] = useState<string[]>([]);

    const [timeRangeFromInput, setTimeRangeFromInput] = useState<TDatetimeRange>([
        moment.unix(queryParams.get('from_timestamp')),
        moment.unix(queryParams.get('to_timestamp')),
    ]);

    const getData = useCallback(
        getTableDataServices(
            params.activeOrgParam,
            QUERY_RESULTS_LIMIT,
            [...servicesCheckboxFilters, ...servicesFilter],
            timeRangeFromInput
        ),
        [params.activeOrgParam, servicesFilter, servicesCheckboxFilters, timeRangeFromInput]
    );

    const timeRangeHandler = (range: TDatetimeRange) => {
        setTimeRangeFromInput(range);
    };

    useEffect(() => {
        getSelectedServices();
    }, [selectedServices]);

    function getSelectedServices() {
        const selectedNames = selectedServices.map((selectedService: RowNode) => {
            return selectedService.data.name;
        });
        if (selectedNames) {
            setSelectedServicesNames([...selectedNames]);
        }
    }

    function parseAndSetSelectedServices(event: SelectionChangedEvent) {
        setSelectedServices(event.api.getSelectedNodes());
    }

    return (
        <div className="services-container container">
            <DiscoveryHeader
                queryParams={queryParams}
                selectedServices={selectedServicesNames}
                timeRangeFromInput={timeRangeFromInput}
                onTimeChange={timeRangeHandler}
                pathParams={params}
                title={`Services (${rowsCount})`}
                isNewLabel={false}
                isLabelList={false}
                isEndpointSelect={true}
                isSwagger={true}
                labelList={[]}
                onLabelCreate={() => Promise.resolve()}
                onLabelDelete={() => Promise.resolve()}
                onLabelSuppress={() => Promise.resolve()}
                canDeleteLabel={() => false}
            />
            <ServiceFilterableTable
                tableActions={<ServicesCheckbox setFilters={setServicesCheckboxFilters} />}
                getData={getData}
                activeOrgParam={params.activeOrgParam}
                onFilterChange={setServicesFilter}
                onSelectionChanged={parseAndSetSelectedServices}
                setRowsCount={setRowsCount}
            />
        </div>
    );
};
