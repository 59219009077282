import React, { useEffect, useState } from 'react';
import Editor, { useMonaco } from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { useHistory, useParams } from 'react-router-dom';

import {
    useUpdatePluginConfiguration,
    useGetPluginsConfigurations,
    useGetDefualtConfiguration,
    IAkamaiConfig,
} from 'api/akamaiConfigs';
import { BreadCrumb, INavigationLinks } from 'components/breadcrumb/BreadCrumb';
import { SettingsSectionHeader } from 'components/settings/components/SettingsSectionHeader/SettingsSectionHeader';
import { ISettingsPathParams } from 'components/settings/Settings';
import { UiButton } from 'components/button/Button';
import { errorMessage, successMessage } from 'general/toast-service';
import Spinner from 'containers/spinner/Spinner';

import './AkamaiConfigurationEdit.scss';

export const AkamaiConfigurationEdit = () => {
    const monaco = useMonaco();
    const history = useHistory();
    const { activeOrg, pluginName } = useParams<ISettingsPathParams & { pluginName: string }>();
    const { data: configurationList, isLoading } = useGetPluginsConfigurations(activeOrg);
    const { data: { userConfig } = {} } = useGetDefualtConfiguration({ pluginName });
    const [updatePluginConfiguration] = useUpdatePluginConfiguration();
    const [editorValue, setEditorValue] = useState<string | undefined>('{}');
    const [editorErrors, setEditorErrors] = useState<any>();
    const [currentConfig, setCurrentConfig] = useState<IAkamaiConfig>();
    const [originConfig, setOriginConfig] = useState<string>();
    const [disableSave, setDisableSave] = useState<boolean>();
    const goToSettingsUrl = `/${activeOrg}/settings/akamai-configs`;
    const goToSettings = () => history.push({ pathname: goToSettingsUrl });

    const breadcrumbList: INavigationLinks[] = [
        {
            url: goToSettingsUrl,
            text: 'Akamai Data Configurations',
        },
        {
            url: `/${activeOrg}/settings/akamai-configs/${pluginName}`,
            text: currentConfig?.displayName || '',
        },
    ];

    const handleSave = () => {
        setDisableSave(true);
        updatePluginConfiguration({ tenant: activeOrg, pluginName, configuration: JSON.parse(editorValue || '') })
            .unwrap()
            .then(() => {
                successMessage('Akamai Configuration saved');
                goToSettings();
            })
            .catch(({ data: { error } }) => {
                setDisableSave(false);
                error && errorMessage(error);
            });
    };

    const handleRestore = () => {
        setEditorValue(JSON.stringify(userConfig, null, 1) || '');
    };

    const handleChange = (value?: string, ev?: editor.IModelContentChangedEvent) => {
        setEditorValue(value);
    };

    useEffect(() => {
        monaco?.languages.typescript.javascriptDefaults.setEagerModelSync(true);

        if (configurationList) {
            const pluginConfiguration = configurationList?.items.filter((item) => item.pluginName === pluginName)[0];
            setCurrentConfig(pluginConfiguration);
            const toStringConfig = JSON.stringify(pluginConfiguration?.userConfig, null, 1) || '';
            setOriginConfig(toStringConfig);
            setEditorValue(toStringConfig);
        }
    }, [monaco, isLoading]);

    const handleOnValidate = (markers: editor.IMarker[]) => {
        setEditorErrors(markers);
    };

    if (isLoading) return <Spinner show />;

    return (
        <div className="AkamaiConfigurationEdit">
            <div className="section">
                <div>
                    <BreadCrumb breadcrumbList={breadcrumbList} selectedRoute={breadcrumbList[1].text} />
                    <SettingsSectionHeader title="Edit Akamai Configuration" />
                </div>
                <div className="actions">
                    <UiButton type="secondary" text="cancel" onClick={goToSettings} />
                    <UiButton
                        type="primary"
                        text="save"
                        onClick={handleSave}
                        disabled={disableSave || originConfig == editorValue || (editorErrors || [])?.length > 0}
                    />
                </div>
            </div>
            <div className="section">
                <div>
                    <div className="pluginName">name</div>
                    {currentConfig?.displayName || ''}
                </div>
                <UiButton type="link" onClick={handleRestore} icon="restore" text="Restore Defaults" />
            </div>
            <Editor
                height="75vh"
                value={editorValue}
                language="json"
                onChange={handleChange}
                onValidate={handleOnValidate}
                options={{
                    automaticLayout: true,
                    scrollBeyondLastLine: false,
                    minimap: {
                        enabled: false,
                    },
                }}
            />
        </div>
    );
};
