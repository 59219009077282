import React from 'react';
import { DataSourceDetails } from '../DataSourceDetails/DataSourceDetails';
import { DataSourceList } from '../DataSourceList/DataSourceList';
import { Switch, Route } from 'react-router-dom';

import './DataSourcesRoutes.scss';

export const baseDataSourceDetailsUrl = '/:activeOrg/settings/data-sources/:dataSourceId';

export const DataSourcesRoutes = () => {
    return (
        <div className="DataSources">
            <Switch>
                <Route exact path="/:activeOrg/settings/data-sources">
                    <DataSourceList />
                </Route>
                <Route exact path="/:activeOrg/settings/data-sources/add">
                    <DataSourceList addMode />
                </Route>
                <Route exact path={`${baseDataSourceDetailsUrl}/edit`}>
                    <DataSourceList editMode />
                </Route>
                <Route
                    path={[
                        `${baseDataSourceDetailsUrl}`,
                        `${baseDataSourceDetailsUrl}/`,
                        `${baseDataSourceDetailsUrl}/nodes`,
                        `${baseDataSourceDetailsUrl}/sensors`,
                    ]}
                >
                    <DataSourceDetails />
                </Route>
            </Switch>
        </div>
    );
};
