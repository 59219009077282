import React, { useEffect, useState } from 'react';
import { UiLabel } from '../label/UiLabel';
import { Select, Tooltip } from 'antd';
import {
    IServiceLabel,
    IEndpointLabel,
    LabelValue,
    IBaseLabel,
    LabelSuppressPeriod,
    LabelSuppressPeriodDisplay,
} from '../../interfaces/labels.interface';
import { sortLexicographically } from '../../general/utils';
import { UiModal } from '../ui-modal/UiModal';

import './LabelList.scss';

interface ILabelList {
    labels: IBaseLabel[];
    newLabel?: boolean;
    allLabelsOptions?: LabelValue[];
    canDeleteLabel?: (label: IBaseLabel) => boolean;
    maxWidth?: number;
    stacked?: boolean;
    flex?: boolean;
    isWider?: boolean;
    onLabelCreate?: (labelId: string) => Promise<any>;
    onLabelDelete?: (labelID: string) => Promise<any>;
    onLabelSuppress?: (labelId: string, period: LabelSuppressPeriod) => Promise<any>;
}

interface ILabelOption {
    label?: string;
    value: any;
}

export function sortLabels(labels: ILabelList['labels']): ILabelList['labels'] {
    return labels.sort((a, b) => sortLexicographically(a.label, b.label));
}

export const LabelList = (props: ILabelList) => {
    const [labelList, setLabelList] = useState<ILabelList['labels']>(sortLabels(props.labels));
    const [filteredLabels, setFilteredLabels] = useState<ILabelOption[]>([]);
    const [labelToSuppress, setLabelToSuppress] = useState<IServiceLabel | IEndpointLabel>();
    const [selectedLabelSuppressPeriod, setSelectedLabelSuppressPeriod] = useState<LabelSuppressPeriod>(
        LabelSuppressPeriod['One day']
    );
    const [labelSuppressInProgress, setLabelSuppressInProgress] = useState<boolean>();
    const { isWider, stacked, flex } = props;

    useEffect(() => {
        setLabelList(sortLabels(props.labels));
    }, [props.labels]);

    useEffect(() => {
        if (props.allLabelsOptions) {
            const filteredLabelsOptions = props.allLabelsOptions.filter((labelA) => {
                return !props.labels.map((label) => label.label).find((labelB) => labelA.value === labelB);
            });
            setFilteredLabels(filteredLabelsOptions);
        }
    }, [props.allLabelsOptions, props.labels]);

    function onNewLabel(labelText: string) {
        if (props.onLabelCreate) {
            setLabelList((prev) => sortLabels([...prev, { label: labelText } as IBaseLabel]));
            props.onLabelCreate(labelText).catch(() => {
                setLabelList((prev) => {
                    const newLabelList = [...prev];
                    newLabelList.splice(
                        prev.findIndex((label) => label.label === labelText),
                        1
                    );
                    return newLabelList;
                });
            });
        }
    }

    function onCloseHandler(label: IBaseLabel) {
        if (label.is_automated && props.onLabelSuppress) {
            setLabelToSuppress(label);
            return;
        }

        if (props.onLabelDelete) {
            setLabelList((prev) => prev.filter((l) => l.label !== label.label));
            props.onLabelDelete(label.label).catch(() => setLabelList((prev) => sortLabels([...prev, label])));
        }
    }

    const getlabelList = () => {
        return (
            <div className={`label-list-container ${stacked && 'stacked'} ${flex && 'flex'}`}>
                {labelList?.map((label, index) => (
                    <div className={`label-item ${isWider && 'wider-label-item'}`} key={index}>
                        <UiLabel
                            isClosable={!!props.canDeleteLabel?.(label)}
                            text={label.label}
                            onCloseHandler={() => onCloseHandler(label as IServiceLabel | IEndpointLabel)}
                            maxWidth={props.maxWidth}
                        />
                    </div>
                ))}
                {props.newLabel && (
                    <span className="label-item" key={props.labels?.length}>
                        <UiLabel
                            text={''}
                            isClosable={false}
                            addTag={onNewLabel}
                            newTagOptions={filteredLabels}
                            newLabel={true}
                        />
                    </span>
                )}
                <UiModal
                    className="suppress-label-modal"
                    onCancel={() => setLabelToSuppress(undefined)}
                    acceptButton={{
                        text: 'Suppress',
                        onClick: () => {
                            setLabelSuppressInProgress(true);
                            props.onLabelSuppress?.(labelToSuppress!.label, selectedLabelSuppressPeriod).then(() => {
                                setLabelSuppressInProgress(false);
                                setLabelToSuppress(undefined);
                            });
                        },
                        disabled: labelSuppressInProgress,
                    }}
                    rejectButton={{ text: 'Cancel', onClick: () => setLabelToSuppress(undefined) }}
                    title="Suppress Auto Labeling"
                    isVisible={!!labelToSuppress}
                >
                    <div>
                        <span className="suppress-label-modal-text">Suppress this automatic classification for</span>
                        <Select
                            defaultValue={LabelSuppressPeriod[selectedLabelSuppressPeriod]}
                            options={Object.keys(LabelSuppressPeriod)
                                .filter((key) => !isNaN(Number(key)))
                                .map((key) => ({
                                    key,
                                    value: LabelSuppressPeriod[key as LabelSuppressPeriodDisplay],
                                }))}
                            onChange={(period, option) =>
                                setSelectedLabelSuppressPeriod(parseInt((option as { key: string }).key))
                            }
                        ></Select>
                    </div>
                </UiModal>
            </div>
        );
    };
    const toolTipContent = () => {
        return props.labels?.map(({ label }) => {
            return <div>{label}</div>;
        });
    };
    if (props.maxWidth) {
        return <Tooltip title={toolTipContent()}>{getlabelList()}</Tooltip>;
    } else {
        return getlabelList();
    }
};
