import React from 'react';
import { UiModal } from '../ui-modal/UiModal';

export interface ICommentDeleteConfirmationModalProps {
    date: string;
    onClose: () => void;
    onAccept: () => void;
}

export const CommentDeleteConfirmationModal = ({ date, onClose, onAccept }: ICommentDeleteConfirmationModalProps) => (
    <UiModal
        onCancel={onClose}
        acceptButton={{ text: 'Delete', onClick: onAccept }}
        rejectButton={{ text: 'Cancel', onClick: onClose }}
        title="Delete Comment"
        icon="trash"
    >
        Are you sure you would like to permanently delete your comment from {date}?
    </UiModal>
);
