import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getJWTToken } from 'general/auth-service';

export interface IServerQueryResponse<T> {
    count: number;
    items: T[];
    total: number;
}

const commonServerOptions = (url: string = '') => ({
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_URL}${url}`,
        prepareHeaders: async (headers) => {
            try {
                const JWT = await getJWTToken();
                headers.set('Authorization', `Bearer ${JWT}`);
            } catch (err) {
                console.log(err);
            }
            return headers;
        },
    }),
    endpoints: () => ({}),
});

export const serverApi = createApi({
    reducerPath: 'serverApi',
    ...commonServerOptions(),
});

export const tenantApi = createApi({
    reducerPath: 'tenantApi',
    ...commonServerOptions(`organizations/`),
    tagTypes: ['alertComments', 'testingScans', 'akamaiConfigurationList'],
});

export const cmsApi = createApi({
    reducerPath: 'cmsApi',
    ...commonServerOptions(`cms/`),
});
