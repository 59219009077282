import React from 'react';
import {useEntity} from "../../services/data/hooks";
import {EntityName} from "../../services/data/entityMap";
import UiMultiSelect from "../ui-multi-select/UiMultiSelect";
import {Select} from "antd";
import {UiTitle} from "../ui-title/UiTitle";
import './AlertSeverityMultiSelect.scss';
import {sortBySeverity} from "../../general/utils";
const {Option} = Select;

interface IAlertSeverityMultiSelectProps {
    label?: string;
    value: string[];
    width: number;
    onChange: (event: string[]) => any;
}

export const AlertSeverityMultiSelect: React.FC<IAlertSeverityMultiSelectProps> = (props) => {
    const [alertSeverityList] = useEntity<{items: {}[]}>(EntityName.AlertSeverity);

    return (
        alertSeverityList ?
        <div className="alert-severity-multi-select">
            <div className="asms-label">
                {props.label || 'Alert Severity'}
            </div>
            <UiMultiSelect
                mode="multiple"
                showDropdown={true}
                width={props.width}
                defaultValue={props.value}
                handleChange={(event: any) => props.onChange(event)}
            >
                {
                    alertSeverityList.items
                        .sort(sortBySeverity('severity'))
                        .map((alertSeverity: any) => <Option key={alertSeverity.severity} value={alertSeverity.severity}>{alertSeverity.severity}</Option>)
                }
            </UiMultiSelect>
        </div> : null
    );
}
