import React, { useCallback, useEffect, useState, WheelEvent } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

import { INavigationLinks } from 'components/breadcrumb/BreadCrumb';
import { EndpointExtendSummary } from 'components/endpoint-extend-summary/EndpointExtendSummary';
import {
    MultiExpandableContainer,
    ContentVisibilityModeEnum,
} from 'components/MultiExpandableContainer/MultiExpandableContainer';
import { IAttribute } from 'components/query-builder/query-row/query-row';
import { NoDataAvailableMessage } from 'components/shared/no-data-message/NoDataAvailableMessage';
import { errorMessage } from 'general/toast-service';
import {
    TDatetimeRange,
    dateTimeStringFormat,
    dateTimeStringFormatMinute,
    extractErrorMessage,
    getTimerangeGranularity,
    urlEncode,
} from 'general/utils';
import { IAlert } from 'interfaces/alert.interface';
import { IDiscoveryEndpointResponse } from 'interfaces/endpoint.interface';
import { LabelSuppressPeriod } from 'interfaces/labels.interface';
import Spinner from 'containers/spinner/Spinner';
import { EntityRequestCharts } from '../../user/entity-request-chart/EntityRequestCharts';
import { IEntityChartSeries, IEntityChartTotals } from 'containers/user/User';
import {
    createEndpointLabel,
    deleteEndpointLabel,
    fetchEndpointAlerts,
    fetchEndpointAttributes,
    getConfig,
    getSeriesRequests,
    getTotalRequests,
    queryEndpoints,
    suppressEndpointLabel,
} from '../shared/discoveryApis';
import { IDiscoveryData } from '../shared/discoveryInterfaces';
import { DiscoveryHeader } from '../DiscoveryHeader/DiscoveryHeader';
import { AlertsCarousel } from './alerts-carousella/AlertsCarousel';
import { EndpointSummary } from './endpoint-summary/EndpointSummary';
import { EndpointTable } from './endpoint-table/EndPointTable';

import '../../Container.scss';
import './Endpoint.scss';

const EMPTY_ENDPOINT: IDiscoveryEndpointResponse = {
    id: '',
    endpoint_path: '',
    labels: [],
    endpoint_labels: [],
    behavior_alerts_count: 0,
    posture_alerts_count: 0,
    calls_count: 0,
    errors_4xx: 0,
    errors_5xx: 0,
    first_seen: '',
    last_seen: '',
    method: '',
    path_parameters: [],
};

export async function getEndpoint(
    timeRange: TDatetimeRange,
    activeOrg: string,
    base64EncodedServiceName: string,
    endpointId: string
): Promise<IDiscoveryEndpointResponse> {
    const config = await getConfig(activeOrg);
    const endpoint = (await queryEndpoints(timeRange, activeOrg, base64EncodedServiceName, endpointId))?.[0] || {
        ...EMPTY_ENDPOINT,
    };
    const endpointFromConfig = (
        await queryEndpoints(
            [moment(config?.timeframe?.start_timestamp), moment(config?.timeframe?.end_timestamp)],
            activeOrg,
            base64EncodedServiceName,
            endpointId
        )
    )?.[0] || { ...EMPTY_ENDPOINT };

    return {
        ...endpoint,
        first_seen: endpointFromConfig.first_seen,
        last_seen: endpointFromConfig.last_seen,
    };
}

export const Endpoint = () => {
    const location = useLocation();
    const urlParams = useParams() as { activeOrgParam: string; encodedServiceName: string; endpointId: string };
    const { encodedServiceName, endpointId, activeOrgParam: activeOrg } = urlParams;

    const base64EncodedServiceName = urlEncode(decodeURIComponent(encodedServiceName));
    const serviceName = decodeURIComponent(encodedServiceName);
    const queryParams: any = new URLSearchParams(location.search);

    const [timeRangeFromInput, setTimeRangeFromInput] = useState<TDatetimeRange>([
        moment.unix(queryParams.get('from_timestamp')),
        moment.unix(queryParams.get('to_timestamp')),
    ]);
    const [allDiscoveryData, setAllDiscoveryData] = useState<IDiscoveryData>({
        endpointGroup: [],
        intervalSelectOptions: [],
        labelsData: [],
        alertsData: null,
        attrData: [],
    });
    const [detailsSpinner, setDetailsSpinner] = useState<boolean>(false);
    const [endpointsSpinner, setEndpointsSpinner] = useState<boolean>(false);
    const [isHeaderExpanded, setIsHeaderExpanded] = useState<boolean>(true);
    const [currentEndpoint, setCurrentEndpoint] = useState<IDiscoveryEndpointResponse>();
    const [totalRequests, setTotalRequests] = useState<IEntityChartTotals>();
    const [seriesRequests, setSeriesRequests] = useState<IEntityChartSeries>();
    const [endpointPath, setEndpointPath] = useState<string>('');
    const [isAlertStatusChanged, setIsAlertStatusChanged] = useState<boolean>(false);

    const breadcrumbList: INavigationLinks[] = [
        {
            url: `/${activeOrg}/discovery/services`,
            text: 'Services',
        },
        {
            url: `/${activeOrg}/discovery/services/${encodedServiceName}/`,
            text: serviceName,
        },
        {
            url: `/${activeOrg}/discovery/services/${encodedServiceName}/endpoints/${endpointId}/`,
            text: endpointPath,
        },
    ];

    const getEndpointCallback = useCallback(() => {
        setEndpointsSpinner(true);
        if (timeRangeFromInput && activeOrg && base64EncodedServiceName && endpointId) {
            getEndpoint(timeRangeFromInput, activeOrg, base64EncodedServiceName, endpointId)
                .then((endpoint) => {
                    setCurrentEndpoint(endpoint);
                    setEndpointsSpinner(false);
                })
                .catch(() => errorMessage('Error fetching Data'));
        }
    }, [timeRangeFromInput, activeOrg, base64EncodedServiceName, endpointId, isAlertStatusChanged]);

    useEffect(getEndpointCallback, [getEndpointCallback]);

    const getEndpointAttributesCallback = useCallback(() => {
        Promise.all([
            fetchEndpointAttributes(
                activeOrg,
                serviceName,
                endpointId,
                timeRangeFromInput[0].format('X'),
                timeRangeFromInput[1].format('X')
            ).catch((error: any) => {
                console.error(error);
                return { items: [] };
            }),
            fetchEndpointAlerts(
                activeOrg,
                serviceName,
                endpointId,
                timeRangeFromInput[0].format('X'),
                timeRangeFromInput[1].format('X'),
                true
            ).catch((error: any) => {
                console.error(error);
                return { items: [] };
            }),
        ])
            .then(([endpointAttrRes, endpointAlertsRes]) => {
                setAllDiscoveryData((prev: IDiscoveryData) => ({
                    ...prev,
                    alertsData: endpointAlertsRes?.items as IAlert[],
                    attrData: endpointAttrRes?.items as IAttribute[],
                }));
            })
            .catch(() => errorMessage('Error fetching Data'))
            .finally(() => {
                setDetailsSpinner(false);
            });
        setEndpointPath(currentEndpoint?.method + ' ' + currentEndpoint?.endpoint_path || '');
    }, [activeOrg, currentEndpoint, endpointId, serviceName, timeRangeFromInput]);

    useEffect(getEndpointAttributesCallback, [getEndpointAttributesCallback]);
    useEffect(() => {
        setEndpointsSpinner(true);
        if (timeRangeFromInput) {
            getTotalRequests(
                [timeRangeFromInput[0], timeRangeFromInput[1]],
                activeOrg,
                base64EncodedServiceName,
                endpointId
            )
                .then((response) => {
                    if (response.data) {
                        setTotalRequests({
                            total: response.data.total,
                            countsByStatus: [
                                { name: '1xx', value: response.data.counts['1xx'] },
                                { name: '2xx', value: response.data.counts['2xx'] },
                                { name: '3xx', value: response.data.counts['3xx'] },
                                { name: '4xx', value: response.data.counts['4xx'] },
                                { name: '5xx', value: response.data.counts['5xx'] },
                                { name: 'None', value: response.data.counts['None'] },
                            ],
                        });
                    }
                })
                .catch(() => errorMessage('Error fetching Data'));

            getSeriesRequests(
                [timeRangeFromInput[0], timeRangeFromInput[1]],
                activeOrg,
                base64EncodedServiceName,
                endpointId
            )
                .then((response) => {
                    let seriesData = response.data;
                    if (seriesData) {
                        let formatFunction: Function = dateTimeStringFormat;
                        if (getTimerangeGranularity(seriesData.timestamps) == '15m') {
                            formatFunction = dateTimeStringFormatMinute;
                        }
                        seriesData.timestamps = seriesData.timestamps.map((timestamp: number) => {
                            return formatFunction(timestamp);
                        });
                        setSeriesRequests(seriesData);
                    }
                })
                .catch(() => errorMessage('Error fetching Data'));
        }
    }, [timeRangeFromInput]);

    useEffect(() => {
        if (allDiscoveryData.alertsData && allDiscoveryData.alertsData.length > 0) onAlertCleared();
    }, [isAlertStatusChanged]);

    const onAlertCleared = () => {
        fetchEndpointAlerts(
            activeOrg,
            serviceName,
            currentEndpoint?.id as string,
            timeRangeFromInput[0].format('X'),
            timeRangeFromInput[1].format('X'),
            true
        )
            .then((res) => {
                setAllDiscoveryData((prev) => ({
                    ...prev,
                    alertsData: res.items,
                }));
                setIsAlertStatusChanged(false);
            })
            .catch(() => errorMessage('Error fetching Data'));
    };

    const timeRangeHandler = (range: TDatetimeRange) => {
        setTimeRangeFromInput(range);
    };

    const handleWheelEvent = (event: WheelEvent<HTMLDivElement>) => {
        setIsHeaderExpanded(event.deltaY < 0);
    };

    if (!(currentEndpoint && !isAlertStatusChanged)) {
        return <Spinner show={true} />;
    }

    const onParameterNamingSuccess = () => {
        getEndpointAttributesCallback();
        getEndpointCallback();
    };

    const createLabel = async (labelText: string): Promise<any> => {
        try {
            await createEndpointLabel(activeOrg, serviceName, endpointId, labelText);
            const endpoint = await getEndpoint(timeRangeFromInput, activeOrg, base64EncodedServiceName, endpointId);
            setCurrentEndpoint(endpoint);
        } catch (error: any) {
            if (error.response?.status === 422) {
                errorMessage('Invalid label text');
            } else {
                errorMessage(extractErrorMessage(error));
            }
            throw 'Could not create label';
        }
    };

    const deleteLabel = async (labelId: string): Promise<any> => {
        try {
            await deleteEndpointLabel(activeOrg, serviceName, endpointId, labelId);
            const endpoint = await getEndpoint(timeRangeFromInput, activeOrg, base64EncodedServiceName, endpointId);
            setCurrentEndpoint(endpoint);
        } catch (error: any) {
            errorMessage(extractErrorMessage(error));
            throw 'Could not delete label';
        }
    };

    const suppressLabel = async (labelId: string, period: LabelSuppressPeriod): Promise<any> => {
        try {
            await suppressEndpointLabel(activeOrg, serviceName, endpointId, labelId, period);
            const endpoint = await getEndpoint(timeRangeFromInput, activeOrg, base64EncodedServiceName, endpointId);
            setCurrentEndpoint(endpoint);
        } catch (error: any) {
            errorMessage(extractErrorMessage(error));
            throw 'Could not suppress label';
        }
    };

    return (
        <div className="endpoint-container container" onWheel={handleWheelEvent}>
            <div className="endpoint-header">
                <DiscoveryHeader
                    queryParams={queryParams}
                    selectedServices={[]}
                    timeRangeFromInput={timeRangeFromInput}
                    onTimeChange={timeRangeHandler}
                    pathParams={urlParams}
                    title={endpointPath}
                    isNewLabel
                    isLabelList
                    labelList={currentEndpoint?.endpoint_labels || []}
                    breadcrumb={breadcrumbList}
                    selectedRouteName={breadcrumbList[2].text}
                    isSwagger={false}
                    onLabelCreate={createLabel}
                    onLabelDelete={deleteLabel}
                    onLabelSuppress={suppressLabel}
                    canDeleteLabel={() => true}
                />
                {timeRangeFromInput[0].unix() && (
                    <MultiExpandableContainer
                        contentArray={[
                            {
                                content: <EndpointSummary endpoint={currentEndpoint} isClosed={isHeaderExpanded} />,
                                mode: ContentVisibilityModeEnum.SHOWN_CONTRACTED,
                            },
                            {
                                content: (
                                    <div
                                        className={`expanded-statistical-endpoint-data${
                                            isHeaderExpanded ? ' show' : ''
                                        }`}
                                    >
                                        <EndpointExtendSummary endpoint={currentEndpoint} />
                                        <div className="alerts-carousel-container">
                                            {allDiscoveryData.alertsData && allDiscoveryData.alertsData.length > 0 ? (
                                                <AlertsCarousel
                                                    alertsData={allDiscoveryData.alertsData}
                                                    reloadAlertsCB={onAlertCleared}
                                                    alertStatusChanged={setIsAlertStatusChanged}
                                                />
                                            ) : (
                                                <div className="centered-empty-message">
                                                    <NoDataAvailableMessage type="alerts" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ),
                                mode: ContentVisibilityModeEnum.SHOWN_EXPANDED,
                            },
                            {
                                content:
                                    totalRequests || seriesRequests ? (
                                        <EntityRequestCharts
                                            chartsData={{
                                                totals: totalRequests as IEntityChartTotals,
                                                series: seriesRequests as IEntityChartSeries,
                                            }}
                                            customHeight={
                                                isHeaderExpanded
                                                    ? allDiscoveryData.alertsData?.length === 0
                                                        ? 380
                                                        : 330
                                                    : 0
                                            }
                                        />
                                    ) : (
                                        <></>
                                    ),
                                mode: ContentVisibilityModeEnum.SHOWN_EXPANDED,
                            },
                        ]}
                        isExpanded={isHeaderExpanded}
                        toggleExpanded={() => setIsHeaderExpanded((prev) => !prev)}
                        className="endpoint-expandable"
                    />
                )}
            </div>
            {timeRangeFromInput[0].unix() && (
                <div className="endpoint-table-wrapper">
                    <EndpointTable
                        validOrg={true}
                        selectedEndpoint={currentEndpoint}
                        endpointsSpinner={endpointsSpinner}
                        attrData={allDiscoveryData.attrData}
                        detailsSpinner={detailsSpinner}
                        activeOrg={activeOrg}
                        timeRangeFromInput={timeRangeFromInput}
                        base64EncodedServiceName={base64EncodedServiceName}
                        onParameterNamingSuccess={onParameterNamingSuccess}
                    />
                </div>
            )}
        </div>
    );
};
