import React, {
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { RowModelType } from '@ag-grid-community/core/dist/cjs/es5/interfaces/iRowModel';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
    ServerSideStoreType,
    ColDef,
    GridApi,
    FilterChangedEvent,
    SortChangedEvent,
    ColumnApi,
} from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RowNode } from '@ag-grid-community/core';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { AxiosResponse } from 'axios';

import { actions, AppStateContext } from '../../contexts/AppStateContext';
import { CellRenderCustomLoading, clipboardCopyForAllColTypes, CustomNoRowsOverlay } from './customCellRenderers';
import { AgGridIcons, getContextMenuItems } from './commonOptions';
import { useOnEscOrClickOutside } from '../../hooks/useOnEscOrClickOutside';
import { getFilterState } from '../filterTagList/FilterTagList';
import { IFilterState } from './UiAgGridCSRM';
import { MenuFilterTagList } from '../filterTagList/menu-filter-tag/MenuFilterTag';
import Spinner from '../../containers/spinner/Spinner';

export interface IProps {
    title?: string;
    tableActions?: ReactNode;
    newCustomTitle?: Function;
    columns: any;
    data?: any[];
    options: { [key: string]: any };
    loading?: boolean;
    masterDetail?: boolean;
    detailCols?: any[];
    getDetailData?: (key: any, extraParams: {}) => any;
    detailGridOptions?: { [key: string]: any };
    sortParamsState?: { sortParams: string | undefined; setSortParams: Dispatch<SetStateAction<string>> };
    getData: (start: number, end: number, sortParams: string | undefined) => Promise<AxiosResponse>;
    dataMappingFunction: Function;
    defaultSort?: string;
    entityType?: string;
    rowCountTitle?: string;
    filterModel?: any;
    onFilterChange?: Function;
    customDetailComponent?: any;
    paginationPageSize?: number;
    pagination?: boolean;
    setRowsCount?: Function;
    isHideRowsCount?: boolean;
    isKeepRowSelectedOnClickOutside?: boolean;
    customTitle?: string;
    onGridReady?: (params: { api: GridApi; columnApi: ColumnApi }) => void;
    setGetGridApi?: (gridApi: GridApi) => void;
    attributes?: { [key: string]: any };
    refetchInterval?: number;
}

export const SORTING_ORDER = ['desc', 'asc', null];

export const BASIC_COLUMN_DEFINITION: Partial<ColDef> = {
    type: 'agTextFilter',
    cellRenderer: 'cellRenderVerticalCenter',
    filterParams: {
        suppressAndOrCondition: true,
        closeOnApply: true,
        filterOptions: ['equals', 'lessThan', 'greaterThan'],
    },
    filter: 'agNumberColumnFilter',
    resizable: true,
};

export const UiAgGridSSRM = (props: IProps) => {
    const [gridApi, setGridApi] = useState<GridApi>();
    const [columnApi, setColumnApi] = useState<any>({});
    const [totalRows, setTotalRows] = useState<any>(0);
    const reqSortParamStr = useRef<string>(props.defaultSort || '');
    const rowDisplayStr = useRef<string>(props.rowCountTitle || 'Rows');
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const { dispatch } = useContext(AppStateContext);
    const isMasterDetails = props.masterDetail;
    const tableWrapper = useRef<HTMLDivElement>(null);
    const [filtersState, setFiltersState] = useState<any>();

    const onGridReady = (params: { api: GridApi; columnApi: ColumnApi }) => {
        setGridApi(params.api);
        props.setGetGridApi && props.setGetGridApi(params.api);
        setColumnApi(params.columnApi);
        dispatch({ type: actions.SET_TABLE_API, payload: { gridApi: params.api, columnApi: params.columnApi } });

        if (props.onGridReady) {
            props.onGridReady(params);
        }
    };

    useEffect(() => {
        const fetchData = () => {
            if (gridApi?.setServerSideDatasource) {
                const data = ServerSideDatasource();
                gridApi.setServerSideDatasource(data);
            }
        };
        fetchData();
        let interval: any;
        if (props.refetchInterval) {
            interval = setInterval(() => {
                fetchData();
            }, props.refetchInterval);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [gridApi, props.getData, props.refetchInterval]);

    // Grid's data source includes the getRows callback with is called whenever grid needs data
    const ServerSideDatasource = () => {
        return {
            getRows: function (params: any) {
                setShowSpinner(true);
                props
                    .getData(params.request.startRow, params.request.endRow, reqSortParamStr.current)
                    .then((result: any) => {
                        if (result?.status === 200) {
                            const rowCount = result?.data?.total;
                            props.setRowsCount && props.setRowsCount(rowCount);
                            setTotalRows(rowCount);
                            setShowSpinner(false);
                            if (props.dataMappingFunction) {
                                // mapping function that prepares data for display - passed from parent
                                params.successCallback(props.dataMappingFunction(result.data.items), result.data.total);
                            } else {
                                params.successCallback(result.data.items, result.data.total);
                            }
                        } else {
                            // Following line eliminates empty line on empty response
                            params.successCallback([], 0);
                            throw Error('Grid data failed to load');
                        }
                    })
                    .catch((error: any) => {
                        console.error(error);
                        setShowSpinner(false);
                        params.failCallback();
                    });
            },
            destroy: () => {},
        };
    };

    const setSortParams = useCallback(() => {
        let sortParams = '';
        // translates the colId value passed from the Table to the 'colName' expected by the BE
        const urlSortFieldDict: Map<string, string[]> = new Map([
            ['name', ['name']],
            ['time', ['timestamp']],
            ['entityId', [props.entityType || '']],
            ['api', ['endpoint_path']],
            ['services', ['endpoints[0].service_name']],
            ['sourceIps', ['caller_ips[0]']],
            ['endpoint', ['method', 'endpoint_path', 'service_name']], // params formatted to : ?sort_by=asc(method),asc(endpoint_path),asc(service_name)
            ['endpoints', ['method', 'endpoint_path', 'service_name']], // params formatted to : ?sort_by=asc(method),asc(endpoint_path),asc(service_name)
        ]);

        columnApi.getColumnState().forEach((column: { colId: string; sort: 'asc' | 'desc' }) => {
            if (column.sort) {
                const translatedCols = urlSortFieldDict.get(column.colId);
                sortParams = sortParams ? `${sortParams},` : 'sort_by=';
                if (translatedCols) {
                    sortParams += translatedCols.map((colName) => `${column.sort}(${colName})`).join(',');
                } else {
                    sortParams += `${column.sort}(${column.colId})`;
                }
            }
        });

        reqSortParamStr.current = sortParams;
    }, [columnApi, props.entityType]);

    useEffect(() => {
        columnApi.getColumnState && setSortParams();
    }, [columnApi, props.entityType]);

    // Configure the detail table
    const detailCellRendererParams = {
        // provide the Grid Options to use on the Detail Grid
        detailGridOptions: {
            animateRows: true,
            enableRangeSelection: false,
            detailRowAutoHeight: true,
            columnDefs: props.detailCols,
            rowHeight: 75,
            icons: AgGridIcons,
            suppressCellSelection: true,
            allowContextMenuWithControlKey: true,
            getContextMenuItems,
            enableCellTextSelection: true,
            ensureDomOrder: true,
            processCellForClipboard: clipboardCopyForAllColTypes,
            ...props.detailGridOptions,
        },
        getDetailRowData: function (params: any) {
            const call_ids = params.data.call_ids.join();
            if (props.getDetailData) {
                props
                    .getDetailData(call_ids, params)
                    .then((result: any) => {
                        if (result.status === 200) {
                            params.successCallback(result.data.items);
                        } else {
                            console.log('failed');
                            setShowSpinner(false);
                        }
                    })
                    .catch((err: any) => {
                        console.log('err ', err);
                        setShowSpinner(false);
                    });
            }
        },
    };

    let mainGridOptions = {
        rowModelType: 'serverSide' as RowModelType,
        serverSideStoreType: 'partial' as ServerSideStoreType,
        rowSelection: 'single' as 'single',
        animateRows: true,
        detailRowAutoHeight: true,
        suppressCellSelection: true,
        suppressRowClickSelection: true,
        suppressCopyRowsToClipboard: true,
        rowHeight: 70,
        suppressLoadingOverlay: true,
        blockLoadDebounceMillis: 800,
        enableCellTextSelection: true,
        ensureDomOrder: true,
        maxConcurrentDatasourceRequests: 6,
        detailCellRenderer: props.customDetailComponent,
        detailCellRendererParams,
        icons: AgGridIcons,
        allowContextMenuWithControlKey: true,
        getContextMenuItems,
        processCellForClipboard: clipboardCopyForAllColTypes,
        sortingOrder: SORTING_ORDER as ['desc', 'asc', null],
        ...props?.options,
    };

    const deselectRowCb = useCallback(() => {
        if (!props.isKeepRowSelectedOnClickOutside) {
            gridApi?.deselectAll?.();
        }
    }, [gridApi]);

    useOnEscOrClickOutside(tableWrapper, deselectRowCb);

    useEffect(() => {
        if (props.filterModel) {
            if (
                gridApi?.getFilterModel &&
                JSON.stringify(gridApi.getFilterModel()) !== JSON.stringify(props.filterModel)
            ) {
                gridApi.setFilterModel(props.filterModel);
            }

            const newFiltersState: IFilterState[] = props.columns
                .filter((col: ColDef) => col.headerName)
                .map(getFilterState(props.filterModel));

            setFiltersState(newFiltersState);
        }
    }, [JSON.stringify(props.filterModel), !!gridApi?.getFilterModel]);

    useEffect(() => {
        gridApi?.hideOverlay();
        if (showSpinner) {
            gridApi?.showLoadingOverlay();
        } else {
            if (gridApi?.showNoRowsOverlay) {
                if (totalRows === 0 && !showSpinner) {
                    gridApi?.showNoRowsOverlay();
                } else {
                    gridApi?.hideOverlay();
                }
            }
        }
    }, [gridApi, totalRows, showSpinner]);

    function expandRow(e: any) {
        const classList = e.event?.target?.classList;

        // expand row if the row is a master/detail or group row and the click target is not an inline drop-down
        if (
            (e.node?.master || e.node?.group) &&
            (classList?.value?.includes('ag-cell') ||
                classList?.value?.includes('ag-react-container') ||
                classList?.value?.includes('custom-cell-renderer') ||
                classList?.value?.includes('api-tag-container') ||
                classList?.value?.includes('ag-grid-row-expand-trigger'))
        ) {
            const isExpanded = e.node?.expanded;
            e.api?.forEachNode((rowNode: RowNode) => {
                rowNode.setExpanded(false);
            });
            e.node.setExpanded(!isExpanded);
        }
    }

    const resetFilter = (field: string): void => {
        const filterComponent = gridApi?.getFilterInstance(field);
        filterComponent?.setModel(null);
        gridApi?.onFilterChanged();
    };

    const resetAllFilters = () => {
        props.columns.forEach((col: ColDef) => resetFilter(col.field as string));
    };

    return (
        <div className="UiAgGridSSRM">
            <div className="title-row ">
                {!props.isHideRowsCount && (
                    <div className={`title box-title`}>
                        {(props.newCustomTitle && props.newCustomTitle(totalRows)) ||
                            (props.customTitle
                                ? `${props.customTitle} (${totalRows?.toLocaleString()})`
                                : `Showing ${totalRows?.toLocaleString() || ''} ${rowDisplayStr.current}`)}
                    </div>
                )}
                {filtersState && (
                    <MenuFilterTagList
                        filterTags={filtersState}
                        resetFilter={resetFilter}
                        resetAllFilters={resetAllFilters}
                        isShiftedLeft={props.customTitle === ''}
                    />
                )}
                <div style={{ marginLeft: 'auto' }}>{props.tableActions}</div>
            </div>
            <div className={`grid-wrapper`}>
                <div className={`spinner-wrapper ${showSpinner ? '' : 'hidden'}`}>
                    <Spinner show={true} />
                </div>
                <div ref={tableWrapper} className="ag-theme-alpine ag-grid-ssrm">
                    <AgGridReact
                        onGridReady={onGridReady}
                        gridOptions={mainGridOptions}
                        cacheBlockSize={100}
                        columnDefs={props.columns}
                        rowData={props.data}
                        modules={[
                            ClientSideRowModelModule,
                            ServerSideRowModelModule,
                            MasterDetailModule,
                            MenuModule,
                            CsvExportModule,
                            ExcelExportModule,
                            ClipboardModule,
                            SetFilterModule,
                        ]}
                        masterDetail={isMasterDetails}
                        infiniteInitialRowCount={100}
                        disableStaticMarkup
                        onRowClicked={(e: any) => expandRow(e)}
                        tooltipShowDelay={1000}
                        components={{
                            ...props.options.components,
                            customLoadingCellRenderer: CellRenderCustomLoading,
                            customNoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        loadingCellRenderer={'customLoadingCellRenderer'}
                        noRowsOverlayComponent={'customNoRowsOverlay'}
                        suppressLoadingOverlay={true}
                        onFilterChanged={(event: FilterChangedEvent) => {
                            event.api.deselectAll();
                            props.onFilterChange?.(event.api.getFilterModel());
                        }}
                        onSortChanged={(event: SortChangedEvent) => {
                            event.api.deselectAll();
                            setSortParams();
                        }}
                        paginationPageSize={props.paginationPageSize}
                        pagination={props.pagination}
                        {...props.attributes}
                    />
                </div>
            </div>
        </div>
    );
};
