import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { actions, actionsRes } from './actions.mock';
import moment from 'moment';
import {
    CellRenderDetokenizable,
    CellRenderGenericMore,
    CellRenderTimeStamp,
    CellRenderToggle,
    CellRenderVerticalCenter,
} from '../../../ui-ag-grid/customCellRenderers';
import { CustomTooltip } from '../../../ui-ag-grid/customToolTip';
import { BASIC_AGGRID_COL_TYPE } from '../../../ui-ag-grid/commonOptions';
import { IEndpointDisplay } from '../../../../interfaces/endpoint.interface';
import { stringifyRuleAction, stringifyRulePredicate, TimeDisplayResEnum } from '../../../../general/utils';
import { UiIcon } from '../../../icon/UiIcon';
import { openDialog } from '../../../../services/dialog/dialog-service';
import { IAutomatedActionRule } from '../../../../interfaces/throttle.interface';
import { PAGE_SIZE_LIMIT, recursiveHttpGet } from '../../../../general/http-service';
import { SettingsSectionHeader } from '../SettingsSectionHeader/SettingsSectionHeader';
import { UiAgGridCSRM } from '../../../ui-ag-grid/UiAgGridCSRM';

import './AtomatedActionsList.scss';

const gridOptions: any = {
    rowHeight: 70,
    rowDragManaged: true,
    components: {
        cellRenderTimeStamp: CellRenderTimeStamp,
        cellRenderGenericMore: CellRenderGenericMore,
        cellRenderVerticalCenter: CellRenderVerticalCenter,
        cellRenderToggle: CellRenderToggle,
        cellRenderDetokenizable: CellRenderDetokenizable,
        customTooltip: CustomTooltip,
    },
    columnTypes: {
        // col type that cols inherit from
        basic: BASIC_AGGRID_COL_TYPE,
    },
    rowClassRules: {
        'disabled-row': function (params: any) {
            return !params.data?.isActiveAction || false;
        },
    },
};

const STATIC_TENANTS = ['payments', 'payments_neosec', 'payments_rsa'];

export const AutomatedActionsList = () => {
    const history = useHistory();
    const params = useParams() as { activeOrg: string };
    const activeOrg = params.activeOrg;
    const [tableData, setTableData] = useState<any[]>([]);
    const [user, setUser] = useState();
    const [endpointList, setEndpointList] = useState<IEndpointDisplay[]>([]);

    const columnsDefs = [
        {
            headerName: 'No.',
            rowDrag: true,
            field: 'order',
            type: 'basic',
            width: 80,
            cellRenderer: 'cellRenderVerticalCenter',
            sortable: false,
            headerTooltip: 'Order',
        },
        {
            headerName: 'State',
            field: 'isActiveAction',
            type: 'basic',
            width: 100,
            sortable: false,
            cellRenderer: 'cellRenderToggle',
            headerTooltip: 'Disabled/Enabled',
            cellRendererParams: (params: any) => {
                return {
                    onRowToggleHandler: (e: boolean, idx: number, ruleId: string) => onRowToggleHandler(e, idx, ruleId),
                };
            },
        },
        {
            headerName: 'Name',
            field: 'name',
            type: 'basic',
            flex: 1,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'Action name',
        },
        {
            headerName: 'Match',
            field: 'match',
            type: 'basic',
            flex: 2,
            cellRenderer: 'cellRenderDetokenizable',
            sortable: false,
            headerTooltip: 'Action match conditions',
        },
        {
            headerName: 'Action',
            field: 'action',
            type: 'basic',
            flex: 2,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'Action to take on rule match',
        },
        {
            headerName: 'Created At',
            field: 'createdAt',
            type: 'basic',
            width: 160,
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                timeResolution: TimeDisplayResEnum.MIN,
            },
            headerTooltip: 'Action’s last modification timestamp',
        },
        {
            headerName: 'Author',
            field: 'author',
            type: 'basic',
            width: 140,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'Action creator',
        },
        {
            headerName: 'Last Modified',
            field: 'lastModified',
            type: 'basic',
            width: 160,
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                timeResolution: TimeDisplayResEnum.MIN,
            },
            headerTooltip: 'Action’s last modification timestamp',
        },
        {
            headerName: 'Last Modified By',
            field: 'LastModifiedBy',
            type: 'basic',
            width: 180,
            cellRenderer: 'cellRenderVerticalCenter',
            sortable: false,
            test: 'test',
            headerTooltip: 'Last user to modify rule',
        },
        {
            headerName: '',
            field: 'overlay',
            sortable: false,
            resizable: false,
            width: 60,
            type: 'basic',
            cellRenderer: 'cellRenderGenericMore',
            cellRendererParams: (params: any) => {
                const menuItems = [
                    {
                        label: `Edit Action`,
                        icon: <UiIcon name="pencil" />,
                        onClick: () => {
                            history.push(`/${activeOrg}/settings/automated-actions/rule/${params.data?.id}`);
                        },
                    },
                    {
                        label: `Delete Action`,
                        icon: <UiIcon name="trash" />,
                        onClick: () => {
                            openDialog({
                                text: `Are you sure you would like to permanently delete Rule ${params.data.name}?`,
                                icon: 'trash2',
                                title: 'Delete Rule',
                            })
                                .then(() => deleteRuleHandler(params.data.id))
                                .catch(() => {});
                        },
                    },
                ];
                return { menuItems };
            },
        },
    ];

    if (STATIC_TENANTS.includes(activeOrg)) {
        columnsDefs.splice(5, 0, {
            headerName: 'Hits',
            field: 'hits',
            type: 'basic',
            width: 110,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'The number of rule hits',
        });
    }

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((authUser) => {
            setUser(authUser);
        });

        recursiveHttpGet(
            `organizations/${activeOrg}/discovery/endpoints?limit=${PAGE_SIZE_LIMIT}&from_timestamp=${moment()
                .subtract(1, 'years')
                .format('X')}&to_timestamp=${moment().format('X')}`,
            0,
            PAGE_SIZE_LIMIT,
            [],
            (endpointList: IEndpointDisplay[]) => setEndpointList(endpointList),
            (error: any) => console.error(error)
        );
    }, []);

    useEffect(() => {
        if (user && endpointList?.length) {
            getAndSetRulesData();
        }
    }, [user, endpointList]);

    function getAndSetRulesData() {
        (async () => {
            const res = (await actionsRes) as any;
            const tableItems = res[activeOrg]?.map((action: IAutomatedActionRule, idx: number) => {
                return {
                    isActiveAction: action.status === 'enabled',
                    id: action.id,
                    order: action.rule_order + 1,
                    name: action.rule_name || 'no name provided',
                    match: stringifyRulePredicate(action.predicate),
                    action: stringifyRuleAction(action.action),
                    hits: action.hit_count,
                    createdAt: action.created_at,
                    author: action.created_by,
                    lastModified: action.updated_at,
                    LastModifiedBy: action.updated_by,
                };
            });
            setTableData(tableItems);
        })();
        // return httpGet(`organizations/${activeOrg}/throttling_rules`).then((res => {
        //
        //     const rulesData = res.data?.items?.map((action: IAutomatedAction, idx: number) => {
        //         return {
        //             isActiveAction: action.status === 'enabled',
        //             id: action.id,
        //             order: action.action_order + 1,
        //             name: action.action_name || 'no name provided',
        //             match: stringifyRulePredicate(action.predicate, endpointList),
        //             action: action.action_type,
        //             hits: action.hit_count,
        //             author: action.created_by,
        //             lastModified: action.updated_at,
        //             LastModifiedBy: action.updated_by
        //         }
        //     })
        //     setTableData(rulesData)
        // }))
    }

    function deleteRuleHandler(ruleId: string) {
        const index = actions[activeOrg]?.findIndex((actionItem) => actionItem.id === ruleId);
        actions[activeOrg]?.splice(index, 1);
        getAndSetRulesData();

        // httpDelete(`organizations/${activeOrg}/throttling_rules/${ruleId}`).then(() => {
        //     getAndSetRulesData()
        // }).catch((error) => {
        //     console.log(`An error occurred while deleting rule ${error}`)
        //     getAndSetRulesData()
        // })
    }

    function onRowToggleHandler(e: boolean, rowNumber: number, ruleId: string) {
        const action = actions[activeOrg]?.find((actionItem) => actionItem.id === ruleId);
        action && (action.status = action?.status === 'enabled' ? 'disabled' : 'enabled');
        getAndSetRulesData();

        // const newStatus = e ? 'enabled' : 'disabled';
        // httpPatch(
        //     `organizations/${activeOrg}/throttling_rules/${ruleId}`,
        //     {
        //         status: newStatus,
        //         updated_by: user.username
        //     }
        // ).then(() => {
        //     getAndSetRulesData()
        // }).catch((error) => {
        //     console.log(`An error occurred while toggling rule ${error}`)
        //     getAndSetRulesData()
        // })
    }

    function onRowDragEnd(e: any) {
        return;
        // httpPatch(
        //     `organizations/${activeOrg}/throttling_rules/${e.node.data.id}`,
        //     {
        //         rule_order: e.overIndex,
        //         updated_by: user.username
        //     }
        // ).then(() => {
        //     getAndSetRulesData()
        // }).catch((error) => {
        //     console.log(`An error occurred while re-ordering order ${error}`)
        //     getAndSetRulesData()
        // });
    }

    return (
        <div className="suppression-rules-container">
            <SettingsSectionHeader
                title="Automated Actions"
                urlPath="settings/automated-actions/rule"
                buttonText="Add Action"
            />
            <div className="suppression-rules-table">
                <UiAgGridCSRM
                    options={gridOptions}
                    draggableRows
                    data={tableData}
                    showRowCount={true}
                    columns={columnsDefs}
                    rowDragEndHandler={onRowDragEnd}
                    rowCountTitle="Automated Actions"
                />
            </div>
        </div>
    );
};
