import React, { useRef } from 'react';

import { useOnEscOrClickOutside } from '../../hooks/useOnEscOrClickOutside';
import { ExpandButton } from '../shared/expand-button/ExpandButton';

import './MultiExpandableContainer.scss';

export enum ContentVisibilityModeEnum {
    SHOWN_CONTRACTED,
    SHOWN_EXPANDED,
    SHOWN_ALWAYS,
}

interface IMultiExpandableContainer {
    contentArray: { content: string | JSX.Element; mode: ContentVisibilityModeEnum }[];
    isExpanded: boolean;
    toggleExpanded: () => void;
    className?: string;
    isOverlay?: boolean;
    isDark?: boolean;
}

const checkOpen = (mode: ContentVisibilityModeEnum, isExpanded: boolean): boolean =>
    mode === ContentVisibilityModeEnum.SHOWN_ALWAYS ||
    (mode === ContentVisibilityModeEnum.SHOWN_CONTRACTED && !isExpanded) ||
    (mode === ContentVisibilityModeEnum.SHOWN_EXPANDED && isExpanded);

export const MultiExpandableContainer = ({
    contentArray,
    isExpanded,
    toggleExpanded,
    className,
    isOverlay = false,
    isDark = false,
}: IMultiExpandableContainer) => {
    const panel = useRef<HTMLDivElement | null>(null);
    useOnEscOrClickOutside(panel, () => isExpanded && toggleExpanded(), 'tooltip-wrapper', !isOverlay);

    return (
        <>
            {isOverlay && <div className={`overlay-container ${isExpanded ? 'visible' : 'hidden'}`} />}
            <div
                className={[
                    'ExpandableContainer',
                    ...(isExpanded ? ['container-expanded'] : []),
                    ...(className ? [className] : []),
                    ...(isOverlay ? ['overlay'] : ['']),
                    isDark ? 'dark' : 'light',
                ].join(' ')}
            >
                <div className="panel" ref={panel}>
                    {contentArray.map(({ content, mode }) => (
                        <div className={`panel-section ${checkOpen(mode, isExpanded) ? 'open' : 'closed'}`}>
                            {content}
                        </div>
                    ))}
                </div>
                {<ExpandButton isExpanded={isExpanded} onClick={toggleExpanded} />}
            </div>
        </>
    );
};
