import { UiIcon } from '../../../icon/UiIcon';
import { UiTitle } from '../../../ui-title/UiTitle';

import './ScanRelatedEndpoints.scss';

interface IScanExcludedEndpointsProps {
    excluded_endpoints: string[];
}

export const ScanRelatedEndpoints = ({ excluded_endpoints }: IScanExcludedEndpointsProps) => (
    <div className="ScanRelatedEndpoints" style={{ width: '33%' }}>
        {excluded_endpoints.length ? (
            <>
                <UiTitle title="Excluded endpoints" type="medium" />
                {excluded_endpoints.map((endp: string) => {
                    return <div className="endpoint-item">{endp}</div>;
                })}
            </>
        ) : (
            <div className="endpoint-item no-data">
                <UiIcon name="noEndpoint" />
                <span>No endpoints excluded/included from scan</span>
            </div>
        )}
    </div>
);
