import { CognitoUser } from '@aws-amplify/auth';

type UserFilter = (user: CognitoUser | any) => boolean;

export const UserFilters: { [id: string]: UserFilter } = {
    IS_NEOSEC: (user: CognitoUser | any): boolean => {
        return user?.['cognito:groups'][0].toLowerCase().indexOf('_neosec') >= 0;
    },
};

export function checkUser(user: CognitoUser | any, requirements: UserFilter): boolean {
    try {
        return requirements(user);
    } catch (e) {
        return false;
    }
}
