import React, { ReactNode, useCallback, useState } from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router';
import { GridApi, ColDef, ICellRendererParams, SetFilterValuesFuncParams } from '@ag-grid-community/core';

import { BASIC_COLUMN_DEFINITION, IProps as UISSRMProps } from 'components/ui-ag-grid/UiAgGridSSRM';
import { agSetFilter } from 'components/ui-ag-grid/commonOptions';
import { SMALL_COLUMN } from 'components/ui-ag-grid/agGridConstants';
import { IEndpointPathParameter } from 'interfaces/endpoint.interface';
import { endpointTableMenu } from './endpointTableMenu';
import { UiIcon } from 'components/icon/UiIcon';
import { UiTooltip } from 'components/ui-tooltip/UiTooltip';
import { TDatetimeRange } from 'general/utils';
import { EditEndpointParameterNameModal } from '../../modals/EditEndpointParameterNameModal';
import { getFilterValuesSet, hideShowEndpointApi } from '../../shared/discoveryApis';
import { RiskTooltip } from '../../RiskTooltip/RiskTooltip';
import { FilterableTable } from '../../FilterableTable/FilterableTable';

import './EndpointFilterableTable.scss';

interface IEndpointTableProps {
    tableActions?: ReactNode;
    newCustomTitle?: Function;
    getData: UISSRMProps['getData'];
    activeOrg: string;
    onFilterChange: Function;
    paginationPageSize?: number;
    pagination?: boolean;
    setRowsCount?: Function;
    onSelectionChanged?: Function;
    base64EncodedServiceName: string;
    setGetGridApi?: (gridApi: GridApi) => void;
    timeRangeFromInput: TDatetimeRange;
    onTableDataUpdate: () => void;
    setIsUpdateHiddenCount: Function;
}

export const EndpointFilterableTable = (props: IEndpointTableProps) => {
    const {
        tableActions,
        getData,
        activeOrg,
        onFilterChange,
        paginationPageSize,
        pagination,
        onSelectionChanged,
        base64EncodedServiceName,
        setGetGridApi,
        onTableDataUpdate,
        timeRangeFromInput,
        setIsUpdateHiddenCount,
        newCustomTitle,
    } = props;
    const history = useHistory();

    const [modalData, setModalData] = useState<IEndpointPathParameter | null>(null);

    const columnDef: ColDef[] = [
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Name',
            field: 'endpoint_path',
            filterParams: {
                values: (params: SetFilterValuesFuncParams) => {
                    getFilterValuesSet(activeOrg, base64EncodedServiceName).then(({ data }) => {
                        params.success(data?.endpoint_path);
                    });
                },
                refreshValuesOnOpen: true,
            },
            filter: 'agSetColumnFilter',
            cellRenderer: 'redirectableTitle',
            flex: 2.5,
            headerComponentParams: {
                headerTooltipContent: 'Endpoint name',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Risk',
            field: 'risk_score',
            cellRenderer: 'cellProgress',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: <RiskTooltip />,
                disableCopyButton: true,
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Risk Change',
            field: 'risk_change',
            cellRenderer: 'riskChangeRender',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Difference in cumulative risk between start and end of time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Labels',
            field: 'labels',
            cellRenderer: 'labelListRender',
            filter: 'agSetColumnFilter',
            filterParams: {
                values: (params: SetFilterValuesFuncParams) => {
                    getFilterValuesSet(activeOrg, base64EncodedServiceName).then(({ data }) => {
                        params.success(data?.labels);
                    });
                },
                refreshValuesOnOpen: false,
            },
            type: 'agSetFilter',
            flex: 1.5,
            headerComponentParams: {
                headerTooltipContent: 'All endpoint labels',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Posture',
            field: 'posture_alerts_count',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Open posture alerts at end of time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Runtime',
            field: 'behavior_alerts_count',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Open behavior or runtime alerts at end of time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Calls',
            field: 'calls_count',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Number of requests to service endpoints during the time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: '4XX',
            field: 'errors_4xx',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Number of client-side errors from service endpoints during the time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: '5XX',
            field: 'errors_5xx',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Number of server-side errors from service endpoints during the time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'First seen',
            field: 'first_seen',
            flex: 1.5,
            cellRenderer: 'dateRender',
            headerComponentParams: {
                headerTooltipContent: 'First traffic to the endpoint',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Last seen',
            field: 'last_seen',
            flex: 1.5,
            cellRenderer: 'dateRender',
            initialSort: 'desc',
            headerComponentParams: {
                headerTooltipContent: 'Last traffic to the endpoint',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: '',
            field: 'actions',
            maxWidth: SMALL_COLUMN,
            flex: 0.5,
            cellRenderer: 'showHideIconRenderer',
            suppressMenu: true,
            suppressMovable: true,
            filter: false,
            filterParams: null,
            sortable: false,
            resizable: false,
        },
    ];

    const handleHideShowEndpoints = useCallback((api: GridApi, id: string, hidden: boolean) => {
        hideShowEndpointApi(activeOrg, base64EncodedServiceName, [{ id, hide: !hidden }]).then(() => {
            setIsUpdateHiddenCount(true);
            api.deselectAll();
            api.refreshServerSideStore({});
        });
    }, []);

    const menuCol = {
        ...BASIC_COLUMN_DEFINITION,
        headerName: '',
        field: 'more',
        maxWidth: SMALL_COLUMN,
        flex: 0.5,
        cellRenderer: 'moreButtonRender',
        cellRendererParams: endpointTableMenu(
            setModalData,
            activeOrg,
            history,
            base64EncodedServiceName,
            handleHideShowEndpoints
        ),
        suppressMenu: true,
        suppressMovable: true,
        filter: false,
        filterParams: null,
        sortable: false,
        resizable: false,
    };

    columnDef.push(menuCol);

    const gridOptions = {
        rowClassRules: {
            'disabled-row': (params: any) => params.data?.hidden,
        },
        suppressRowClickSelection: false,
    };

    const CellRenderIconShowHide = ({
        data: { id, hidden, hidden_by, hidden_at },
        api,
    }: ICellRendererParams): ReactNode => {
        const handleClick = () => handleHideShowEndpoints(api, id, hidden);

        const hideShowBtn = (
            <Button className={hidden ? '' : 'hide-show-endpoint'} type="text" onClick={handleClick}>
                <UiIcon name={hidden ? 'showTransparent' : 'hide'} />
            </Button>
        );
        return hidden_by ? (
            <UiTooltip title={`Hidden by ${hidden_by}, at ${hidden_at}`}>{hideShowBtn}</UiTooltip>
        ) : (
            hideShowBtn
        );
    };

    return (
        <div
            className="EndpointFilterableTable"
            onWheel={(e) => {
                e.deltaY < 0 && e.stopPropagation();
            }}
        >
            <FilterableTable
                tableActions={tableActions}
                columns={columnDef}
                options={gridOptions}
                getData={getData}
                onFilterChange={onFilterChange}
                paginationPageSize={paginationPageSize!}
                pagination={pagination!}
                onSelectionChanged={onSelectionChanged!}
                setRowsCount={props.setRowsCount!}
                redirectOption={'ENDPOINT'}
                components={{ showHideIconRenderer: CellRenderIconShowHide }}
                setGetGridApi={setGetGridApi!}
                newCustomTitle={newCustomTitle!}
            />
            {modalData && (
                <EditEndpointParameterNameModal
                    activeOrg={activeOrg}
                    parameters={modalData}
                    onModalSuccess={onTableDataUpdate}
                    onModalClose={() => setModalData(null)}
                    timeRangeFromInput={timeRangeFromInput}
                />
            )}
        </div>
    );
};
