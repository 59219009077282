import React, {useEffect, useState} from 'react';
import {UiTitle} from "../ui-title/UiTitle";
import {UiInput} from "../ui-input/UiInput";
import {UiButton} from "../button/Button";
import {UiChekcbox} from "../ui-checkbox/UiCheckbox";
import {errorMessage, successMessage} from "../../general/toast-service";
import './WebhookInput.scss';

export interface WebhookConfig {
    endpoint: string;
    username?: string;
    password?: string;
}

interface WebhookInputProps {
    webhook: WebhookConfig;
    onChange: (event: WebhookConfig) => void;
}

export const WebhookInput = (props: WebhookInputProps) => {
    const [authenticate, setAuthenticate] = useState<boolean>(true);
    const [testInProgress, setTestInProgress] = useState<boolean>(false);
    const [output, setOutput] = useState({
        endpoint: props.webhook.endpoint,
        username: props.webhook.username,
        password: props.webhook.password
    });

    useEffect(() => {
        if (props.webhook.endpoint && !props.webhook.username && !props.webhook.password) {
            setAuthenticate(false);
        }
    }, []);

    function onChange(event: string | undefined, key: string) {
        const webhook = {
            ...output,
            [key]: event
        };
        setOutput(webhook);
        props.onChange(webhook);
    }

    function test() {
        setTestInProgress(true);
        setTimeout(() => {
            if (authenticate && (!output.username || !output.password)) {
                errorMessage(`Failed to authenticate with ${output.endpoint}`);
            } else {
                successMessage(`Test request was send to ${output.endpoint}`);
            }
            setTestInProgress(false);
        }, 700);
    }

    return(
        <div className="webhook-input">
            <div className="wi-label">Trigger webhook</div>
            <div style={{display: 'flex'}}>
                <UiInput
                    label="Endpoint"
                    defaultValue={output.endpoint}
                    width={500}
                    onChange={(event: any) => onChange(event.target.value, 'endpoint')}
                />
                <UiButton
                    type="primary"
                    text="Test"
                    style={{marginLeft: '15px'}}
                    disabled={testInProgress || !output.endpoint}
                    onClick={test}
                />
            </div>
            <div style={{margin: '15px 0'}}>
                <UiChekcbox
                    label="Authenticate"
                    value={authenticate}
                    onChange={(event: any) => {
                        console.log(event)
                        setAuthenticate(event.target.checked);
                        if (event.target.checked === false) {
                            onChange(undefined, 'username');
                            onChange(undefined, 'password');
                        }
                    }}
                />
            </div>
            {
                authenticate ?
                <div>
                    <div style={{margin: '0 0 15px 0'}}>
                        <UiInput
                            label="Username"
                            defaultValue={output.username}
                            width={500}
                            onBlur={(event: any) => onChange(event.target.value, 'username')}
                        />
                    </div>
                    <div>
                        <UiInput
                            label="Password"
                            type="password"
                            defaultValue={output.password}
                            width={500}
                            onBlur={(event: any) => onChange(event.target.value, 'password')}
                        />
                    </div>
                </div> : null
            }
        </div>
    );
}