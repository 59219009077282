import React, { useState } from 'react';
import { Col, Divider, Form, Input, Radio, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';

import { formFieldRequired, IpV4ListRule, IpV4Rule } from '../../../../../general/forms';
import { NodeConnectionMode, NodeTypes } from '../shared/collectorApis';
import { IDropdownOption } from '../../../../ui-dropdown/UiDropDown';
import timezones from './timezones.json';

export interface INeosecNodeAddFormStep2Props {
    form: FormInstance;
}

export const NeosecNodeAddFormStep2 = ({ form }: INeosecNodeAddFormStep2Props) => {
    const [chosenNodeType, setChosenNodeType] = useState<NodeTypes>(NodeTypes.RPM);
    const [chosenConnectionMode, setChosenConnectionMode] = useState<NodeConnectionMode>(NodeConnectionMode.DHCP);

    const getCollectorTypeOptions: () => IDropdownOption[] = () => {
        return [
            { label: 'ISO', value: NodeTypes.ISO, disabled: false },
            { label: 'RPM', value: NodeTypes.RPM, disabled: false },
        ] as IDropdownOption[];
    };

    const getTimeZoneOptions = () => timezones.map((value) => ({ label: value, value }));

    return (
        <Form
            form={form}
            colon={false}
            layout="vertical"
            requiredMark={false}
            title="Type"
            initialValues={{ type: chosenNodeType, timezone: 'UTC' }}
        >
            <Row gutter={16} className="node-type-form">
                <Col span={12}>
                    <Form.Item name="type" label="Type">
                        <Select
                            options={getCollectorTypeOptions()}
                            placeholder="Select a value"
                            onChange={(value) => setChosenNodeType(value as NodeTypes)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <div className="additional-configuration">
                {chosenNodeType === NodeTypes.RPM ? (
                    <div className="rpm-additional-configuration">No additional configuration required</div>
                ) : (
                    <div className="iso-additional-configuration">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="timezone" label="Time zone">
                                    <Select options={getTimeZoneOptions()} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="connectionMode"
                                    label="Connection mode"
                                    initialValue={NodeConnectionMode.DHCP}
                                >
                                    <Radio.Group
                                        onChange={({ target: { value } }) =>
                                            setChosenConnectionMode(value as NodeConnectionMode)
                                        }
                                    >
                                        <Radio value={NodeConnectionMode.DHCP}>DHCP</Radio>
                                        <Radio value={NodeConnectionMode.FIXED_IP}>Fixed IP</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Fixed IP"
                                    name="fixedIp"
                                    validateFirst
                                    rules={[
                                        {
                                            required: chosenConnectionMode === NodeConnectionMode.FIXED_IP,
                                            message: formFieldRequired.errorMessage,
                                        },
                                        ...[chosenConnectionMode === NodeConnectionMode.FIXED_IP ? IpV4Rule : {}],
                                    ]}
                                >
                                    <Input
                                        disabled={chosenConnectionMode !== NodeConnectionMode.FIXED_IP}
                                        placeholder="E.g. 10.10.12.1"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Netmask"
                                    name="netMask"
                                    validateFirst
                                    rules={[
                                        {
                                            required: chosenConnectionMode === NodeConnectionMode.FIXED_IP,
                                            message: formFieldRequired.errorMessage,
                                        },
                                        ...[chosenConnectionMode === NodeConnectionMode.FIXED_IP ? IpV4Rule : {}],
                                    ]}
                                >
                                    <Input
                                        disabled={chosenConnectionMode !== NodeConnectionMode.FIXED_IP}
                                        placeholder="E.g. 255.255.255.0"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Default Gateway"
                                    name="defaultGateway"
                                    validateFirst
                                    rules={[
                                        {
                                            required: chosenConnectionMode === NodeConnectionMode.FIXED_IP,
                                            message: formFieldRequired.errorMessage,
                                        },
                                        ...[chosenConnectionMode === NodeConnectionMode.FIXED_IP ? IpV4Rule : {}],
                                    ]}
                                >
                                    <Input
                                        disabled={chosenConnectionMode !== NodeConnectionMode.FIXED_IP}
                                        placeholder="E.g. 10.10.12.200"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="DNS Server"
                                    name="dnsServer"
                                    validateFirst
                                    rules={[
                                        {
                                            required: chosenConnectionMode === NodeConnectionMode.FIXED_IP,
                                            message: formFieldRequired.errorMessage,
                                        },
                                        ...[chosenConnectionMode === NodeConnectionMode.FIXED_IP ? IpV4ListRule : {}],
                                    ]}
                                >
                                    <Input
                                        disabled={chosenConnectionMode !== NodeConnectionMode.FIXED_IP}
                                        placeholder="E.g. 10.10.12.12"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        </Form>
    );
};
