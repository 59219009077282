import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { GridApi, ColumnApi, ColDef } from '@ag-grid-community/core';

import { IServerQueryResponse } from 'api/baseServerApi';
import EndpointFilterTable from './endpointFilterTable';
import EndpointFilterForm from '../endpoint-filter-form/EndpointFilterForm';
import { httpGet, httpPatch, httpPOST } from 'general/http-service';
import { errorMessage, successMessage } from 'general/toast-service';
import { BASIC_AGGRID_COL_TYPE } from 'components/ui-ag-grid/commonOptions';
import { ISettingsPathParams } from 'components/settings/Settings';
import { IEndpointResponse } from 'interfaces/endpoint.interface';
import { UiIcon } from 'components/icon/UiIcon';
import { UiAgGridCSRM } from 'components/ui-ag-grid/UiAgGridCSRM';
import { CellRenderEndpointPath, CellRenderVerticalCenter } from 'components/ui-ag-grid/customCellRenderers';
import { IWizardStep, UiWizard } from 'components/UiWizard/UiWizard';
import { SettingsSectionHeader } from 'components/settings/components/SettingsSectionHeader/SettingsSectionHeader';

import 'components/ui-ag-grid/customRenderers.scss';
import './EndpointFilterList.scss';

const LOCALE = 'en-US';

export interface IEndpointFilterListProps {
    editMode?: boolean;
}

export interface IEndpointFilter {
    filter_name: string;
    name: 'endpoint_path' | 'response_content_types';
    operator: 'match' | 'notmatch';
    value: string;
    active: boolean;
    method?: string[];
    id?: string;
}

const emptyEndpointFilter: IEndpointFilter = {
    filter_name: '',
    name: 'endpoint_path',
    operator: 'match',
    value: '',
    active: true,
};

export interface IEndpointFilterListPathParams extends ISettingsPathParams {
    endpointFilterId?: IEndpointFilter['id'];
}

interface IPreviewItems extends Omit<IEndpointResponse, 'id'> {
    endpoint_id: string;
}

export const EndpointFilterList = ({ editMode = false }: IEndpointFilterListProps) => {
    const { activeOrg, endpointFilterId } = useParams<IEndpointFilterListPathParams>();
    const history = useHistory();
    const [form] = Form.useForm();
    const [gridApi, setGridApi] = useState<GridApi>();
    const [selectedEndpointFilter, setSelectedEndpointFilter] = useState<IEndpointFilter>();
    const [createButtonDisabled, setCreateButtonDisabled] = useState<boolean>(true);
    const [filterFields, setFilterFields] = useState<IEndpointFilter>();
    const [isMethodSelectVisible, setIsMethodSelectVisible] = useState<boolean>(false);
    const [previewResponse, setPreviewResponse] = useState<IServerQueryResponse<Partial<IPreviewItems>>>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (editMode && endpointFilterId) {
            httpGet(`organizations/${activeOrg}/discovery/endpoint_filter/${endpointFilterId}`)
                .then((res) => {
                    setSelectedEndpointFilter(res.data);
                    setIsMethodSelectVisible(res.data.method && res.data.method.length > 0);
                })
                .catch((error) => {
                    errorMessage(
                        `${error.response?.data?.detail || 'Unexpected error while fetching endpoint filter'}`
                    );
                });
        } else {
            setSelectedEndpointFilter({ ...emptyEndpointFilter });
            setIsMethodSelectVisible(false);
        }
    }, [editMode]);

    useEffect(() => {
        form.resetFields();
    }, [form, selectedEndpointFilter]);

    const filteredEndpointsColumnDefs: ColDef[] = [
        {
            headerName: 'Service',
            field: 'service',
            type: 'basic',
            flex: 0.3,
        },
        {
            headerName: 'Endpoint',
            field: 'endpoint',
            type: 'basic',
            flex: 1,
            cellRenderer: 'cellRenderEndpointPath',
            resizable: false,
            comparator: (a: [IEndpointResponse], b: [IEndpointResponse]) => {
                return (
                    a[0]?.endpoint_path.localeCompare(b[0]?.endpoint_path) || a[0]?.method.localeCompare(b[0]?.method)
                );
            },
        },
    ];

    const handleSaveClick = () => {
        const method = selectedEndpointFilter?.id ? httpPatch : httpPOST;
        return method(
            `organizations/${activeOrg}/discovery/endpoint_filter${
                selectedEndpointFilter?.id ? '/' + selectedEndpointFilter.id : ''
            }`,
            filterFields
        )
            .then(() => {
                history.push(`/${activeOrg}/settings/endpoint-filter`);
                gridApi?.refreshServerSideStore({ purge: true });
                return successMessage(
                    `${
                        (previewResponse?.total as number) >= 0 ? previewResponse?.total.toLocaleString(LOCALE) : ''
                    } endpoints scheduled for deletion within the hour`
                );
            })
            .catch((error) => {
                errorMessage(`${error.response?.data?.detail || 'Unexpected error while saving endpoint filter'}`);
            });
    };

    const handleNextClick = async () => {
        try {
            await form.validateFields();
            const newFilterFields: IEndpointFilter = {
                filter_name: '',
                operator: form.getFieldValue('operator'),
                name: form.getFieldValue('name'),
                value: form.getFieldValue('value'),
                method: isMethodSelectVisible ? form.getFieldValue('method') : [],
                active: selectedEndpointFilter?.id ? selectedEndpointFilter.active : true,
            };
            setFilterFields(newFilterFields);
            setIsLoading(true);
            const res = await httpPOST(
                `organizations/${activeOrg}/discovery/endpoint_filter/preview?offset=0&limit=100`,
                newFilterFields
            );
            setPreviewResponse(res.data);
            return true;
        } catch (error: any) {
            // both validation and server errors end up here
            if (error.isAxiosError) {
                switch (error.response.status) {
                    case 422:
                        errorMessage('Input validation failed');
                        break;
                    default:
                        errorMessage(
                            `${error.response?.data?.detail || 'Unexpected error while saving endpoint filter'}`
                        );
                }
            }
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const getColumnsDefs = (): ColDef[] =>
        filteredEndpointsColumnDefs.map((item: ColDef) => ({
            ...item,
            type: item.type || 'agTextFilter',
            flex: item.flex || 0.5,
            cellRenderer: item.cellRenderer || 'cellRenderVerticalCenter',
            sortable: item.sortable !== false,
        }));

    const onTableDataLoad = useCallback((data: { items: IEndpointFilter[]; total: number }) => {
        setCreateButtonDisabled(data.total >= 20);
    }, []);

    const onGridReady = useCallback(
        (params: { api: GridApi; columnApi: ColumnApi }) => {
            if (!gridApi) {
                setGridApi(params.api);
            }
        },
        [gridApi]
    );

    const wizardSteps: IWizardStep[] = [
        {
            title: `${selectedEndpointFilter?.id === undefined ? 'Create' : 'Edit'} Filter`,
            content: (
                <div className="endpoint-filter-form-container">
                    <EndpointFilterForm
                        form={form}
                        selectedEndpointFilter={selectedEndpointFilter}
                        isMethodSelectVisible={isMethodSelectVisible}
                        setIsMethodSelectVisible={setIsMethodSelectVisible}
                    />
                </div>
            ),
        },
        {
            title: `Confirm`,
            content: (
                <div className="endpoint-filter-form-container">
                    <div className="endpoint-filter-preview">
                        <div className="endpoint-filter-warning">
                            <UiIcon name="warning" />
                            <span className="warning-message">
                                {(previewResponse?.total as number) >= 0
                                    ? previewResponse?.total.toLocaleString(LOCALE)
                                    : ''}{' '}
                                matching endpoints will be deleted from the API inventory in the Discovery page
                            </span>
                        </div>
                        <div className="display-count">Showing {previewResponse?.count} endpoints</div>
                    </div>
                    <div className="filtered-endpoint-grid">
                        <UiAgGridCSRM
                            columns={getColumnsDefs()}
                            options={{
                                columnTypes: { basic: BASIC_AGGRID_COL_TYPE },
                                components: {
                                    cellRenderVerticalCenter: CellRenderVerticalCenter,
                                    cellRenderEndpointPath: CellRenderEndpointPath,
                                },
                            }}
                            data={
                                previewResponse?.items.map((item) => ({
                                    service: item.service_name,
                                    endpoint: [item],
                                })) || []
                            }
                            showRowCount={false}
                            isHideRowCount
                            loading={isLoading}
                        />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className="EndpointFilterList">
            <SettingsSectionHeader
                title="Endpoint Filters"
                urlPath="settings/endpoint-filter/add"
                buttonText="Add Filter"
                disableButton={createButtonDisabled}
            />
            <div className="endpoint-filter-list-table">
                <EndpointFilterTable activeOrg={activeOrg} onDataLoad={onTableDataLoad} onGridReady={onGridReady} />
            </div>
            {editMode && (
                <UiWizard
                    wrapClassName="endpoint-filter-modal"
                    title={`${selectedEndpointFilter?.id ? 'Edit' : 'Create'} Endpoint Filter`}
                    onCancel={() => {
                        history.push(`/${activeOrg}/settings/endpoint-filter`);
                    }}
                    onNext={handleNextClick}
                    onFinish={handleSaveClick}
                    steps={wizardSteps}
                />
            )}
        </div>
    );
};
