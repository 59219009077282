import { UiIcon } from '../icon/UiIcon';
import './CommentCount.scss';

export const CommentCount = ({ value, showBasedOnValue = false }: { value: number; showBasedOnValue?: boolean }) =>
    !showBasedOnValue || value ? (
        <div className={`CommentCount ag-grid-row-expand-trigger ${typeof value === 'number' ? '' : 'hide-value'}`}>
            <UiIcon
                className="ag-grid-row-expand-trigger"
                name={value > 99 ? 'countBalloonTripleDigit' : 'countBalloon'}
            />
            <span className="count ag-grid-row-expand-trigger">{value}</span>
        </div>
    ) : null;
