import React from 'react';
import { IAGTableColumn } from 'interfaces/tableColumn.interface';
import { TimeDisplayResEnum } from 'general/utils';
import { UiIcon } from 'components/icon/UiIcon';
import { openDialog } from 'services/dialog/dialog-service';
import { httpDelete, httpGet, httpPatch, PAGE_SIZE_LIMIT } from 'general/http-service';
import { errorMessage } from 'general/toast-service';
import * as H from 'history';
import {
    CellRenderGenericMore,
    CellRenderTimeStamp,
    CellRenderToggle,
    CellRenderVerticalCenter,
    CustomNoRowsOverlay,
} from 'components/ui-ag-grid/customCellRenderers';
import { CustomTooltip } from 'components/ui-ag-grid/customToolTip';
import { ConditionCellRenderer } from './ConditionCellRenderer';
import { BASIC_AGGRID_COL_TYPE } from 'components/ui-ag-grid/commonOptions';
import { UiAgGridSSRM } from 'components/ui-ag-grid/UiAgGridSSRM';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router';
import { IEndpointFilter } from './EndpointFilterList';
import { GridApi, ColumnApi } from '@ag-grid-community/core';

export const gridOptions = {
    rowHeight: 70,
    components: {
        customNoRowsOverlay: CustomNoRowsOverlay,
        cellRenderTimeStamp: CellRenderTimeStamp,
        cellRenderGenericMore: CellRenderGenericMore,
        cellRenderVerticalCenter: CellRenderVerticalCenter,
        cellRenderToggle: CellRenderToggle,
        customTooltip: CustomTooltip,
        conditionCellRenderer: ConditionCellRenderer,
    },
    columnTypes: {
        basic: BASIC_AGGRID_COL_TYPE,
    },
    rowClassRules: {
        'disabled-row': (params: any) => !params.data?.active,
    },
};

export function endpointFilterListColumns(history: H.History, activeOrg: string): IAGTableColumn[] {
    return [
        {
            headerName: 'Status',
            field: 'active',
            type: 'basic',
            cellRenderer: 'cellRenderToggle',
            sortable: false,
            headerTooltip: 'Disabled/Enabled',
            cellRendererParams: (params: any) => {
                return {
                    onRowToggleHandler: (e: boolean, idx: number, endpointFilterId: string) => {
                        httpPatch(`organizations/${activeOrg}/discovery/endpoint_filter/${endpointFilterId}`, {
                            active: e,
                        })
                            .catch((error) => {
                                if (error.isAxiosError) {
                                    return errorMessage(
                                        `${
                                            error.response?.data?.detail ||
                                            'Unexpected error while saving endpoint filter'
                                        }`
                                    );
                                }
                            })
                            .then(() => {
                                params.api.refreshServerSideStore({ purge: false });
                            });
                    },
                };
            },
        },
        {
            headerName: 'Condition',
            field: 'condition',
            type: 'basic',
            width: 180,
            flex: 1,
            cellRenderer: 'conditionCellRenderer',
            sortable: false,
            headerTooltip: 'Rule match conditions',
        },
        {
            headerName: 'Created At',
            field: 'created_at',
            type: 'basic',
            width: 160,
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                timeResolution: TimeDisplayResEnum.MIN,
            },
            headerTooltip: 'Rule’s last modification timestamp',
        },
        {
            headerName: 'Author',
            field: 'creating_user',
            type: 'basic',
            width: 140,
            cellRenderer: 'cellRenderVerticalCenter',
            headerTooltip: 'Rule creator',
        },
        {
            headerName: 'Last Modified',
            field: 'updated_at',
            type: 'basic',
            width: 160,
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                timeResolution: TimeDisplayResEnum.MIN,
            },
            headerTooltip: 'Rule’s last modification timestamp',
        },
        {
            headerName: 'Last Modified By',
            field: 'last_updated_user',
            type: 'basic',
            width: 180,
            cellRenderer: 'cellRenderVerticalCenter',
            sortable: false,
            headerTooltip: 'Last user to modify rule',
        },
        {
            headerName: '',
            field: 'overlay',
            sortable: false,
            width: 60,
            type: 'basic',
            cellRenderer: 'cellRenderGenericMore',
            resizable: false,
            cellRendererParams: (params: any) => {
                return {
                    menuItems: [
                        {
                            label: `Edit`,
                            icon: <UiIcon name="pencil" />,
                            onClick: () => {
                                history.push(`/${activeOrg}/settings/endpoint-filter/edit/${params.data?.id}`);
                            },
                        },
                        {
                            label: `Delete`,
                            icon: <UiIcon name="trash" />,
                            onClick: () => {
                                openDialog({
                                    text: `Are you sure you would like to permanently delete Rule ${params.data.filter_name}?`,
                                    icon: 'trash2',
                                    title: 'Delete Rule',
                                })
                                    .then(() => {
                                        return httpDelete(
                                            `organizations/${activeOrg}/discovery/endpoint_filter/${params.data.id}`
                                        );
                                    })
                                    .then(() => {
                                        params.api.refreshServerSideStore({ purge: false });
                                    })
                                    .catch((error) => {
                                        if (error) {
                                            errorMessage(error?.error?.message);
                                            console.error(error);
                                        }
                                    });
                            },
                        },
                    ],
                };
            },
        },
    ];
}

export interface IEndpointFilterTableProps {
    activeOrg: string;
    onDataLoad: (data: { items: IEndpointFilter[]; total: number }) => void;
    onGridReady: (params: { api: GridApi; columnApi: ColumnApi }) => void;
}

const EndpointFilterTable = ({ activeOrg, onDataLoad, onGridReady }: IEndpointFilterTableProps) => {
    const history = useHistory();
    const columns = endpointFilterListColumns(history, activeOrg);

    function getData(start: number, end: number, sortParams?: string): Promise<AxiosResponse> {
        const sort_by = sortParams ? sortParams.split('=')[1] : 'desc(created_at)';
        return httpGet(`organizations/${activeOrg}/discovery/endpoint_filter`, {
            offset: start.toString(),
            limit: PAGE_SIZE_LIMIT.toString(),
            sort_by,
        })
            .then((res) => {
                onDataLoad(res.data);
                return res;
            })
            .catch((error) => {
                return errorMessage(
                    `${error.response?.data?.detail || 'Unexpected error while fetching endpoint filters'}`
                );
            });
    }

    function dataMappingFunction(data: any): any {
        return data.map((endpointFilter: any) => {
            return {
                ...endpointFilter,
                condition: endpointFilter,
            };
        });
    }

    return (
        <UiAgGridSSRM
            options={gridOptions}
            getData={getData}
            dataMappingFunction={dataMappingFunction}
            columns={columns}
            rowCountTitle="Rules"
            onGridReady={onGridReady}
        />
    );
};

export default React.memo(EndpointFilterTable);
