import React from 'react';
import { Tag } from 'antd';
import { LinkProps, NavLink } from 'react-router-dom';

import { UiTooltip } from '../ui-tooltip/UiTooltip';

import './ApiLabel.scss';

interface IApiLabelProps {
    apiType: string;
    apiName?: string;
    tooltip?: string;
    labelWidth?: number | 'unset';
    minWidth?: number;
    path?: LinkProps['to'];
    disable?: boolean;
    place?:
        | 'right'
        | 'top'
        | 'left'
        | 'bottom'
        | 'topLeft'
        | 'topRight'
        | 'bottomLeft'
        | 'bottomRight'
        | 'leftTop'
        | 'leftBottom'
        | 'rightTop'
        | 'rightBottom'
        | undefined;
}

const colors: any = {
    GET: '#17B0E0',
    POST: '#49CC90',
    DELETE: '#F66060',
    PUT: '#FFA52B',
    OPTIONS: '#5540ca',
    HEAD: '#49CC90',
    CONNECT: '#F66060',
    TRACE: '#FFA52B',
    PATCH: '#7569c8',
};

const apiVerbMap = new Map([
    ['GET', 'GET'],
    ['POST', 'POST'],
    ['PUT', 'PUT'],
    ['PATCH', 'PATCH'],
    ['DELETE', 'DEL'],
    ['COPY', 'COPY'],
    ['HEAD', 'HEAD'],
    ['OPTIONS', 'OPT'],
    ['LINK', 'LINK'],
    ['UNLINK', 'UNLINK'],
    ['UNLOCK', 'UNLCK'],
    ['PROPFIND', 'PROP'],
    ['VIEW', 'VIEW'],
]);

const defaultColor = '#7569c8';

export const ApiLabel = ({
    apiType,
    apiName,
    tooltip,
    place,
    minWidth,
    path = '',
    disable = false,
}: IApiLabelProps) => {
    const tagStyle = {
        color: colors[apiType?.toUpperCase()] || defaultColor,
    };

    const ttPlacement = place ? place : 'top';
    const style = { minWidth };

    let mappedApiVerb = '';
    if (apiType) {
        mappedApiVerb = apiVerbMap.get(apiType.toUpperCase()) || apiType.toUpperCase().substring(0, 5);
    }

    return (
        <div className={`api-tag-container ${disable ? 'disabled' : ''}`}>
            <div className="tag-element">
                <Tag className="api-tag" style={tagStyle}>
                    {mappedApiVerb}
                </Tag>
            </div>
            {apiName ? (
                <UiTooltip title={`${mappedApiVerb} ${tooltip || apiName}`} placement={ttPlacement}>
                    {path ? (
                        <NavLink to={path}>
                            <span className="api-name api-name-with-path ag-grid-row-expand-trigger" style={style}>
                                {apiName}
                            </span>
                        </NavLink>
                    ) : (
                        <span className="api-name ag-grid-row-expand-trigger" style={style}>
                            {apiName}
                        </span>
                    )}
                </UiTooltip>
            ) : (
                ''
            )}
        </div>
    );
};
