import { Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';

import { TDatetimeRange, timeFormatter, TTimeRange } from 'general/utils';
import { LabelValue } from 'interfaces/labels.interface';
import { allTimeOptions, EOD_TIME_LABEL_VALUE } from '../utils';

import './UiTimeRangePicker.scss';

export interface IUiTimeRangePickerProps {
    value: TTimeRange;
    selectedDateRange: TDatetimeRange;
    supportedHours: number;
    onChange: (newValue: TTimeRange) => void;
}

export const getFilterFn =
    (
        now: moment.Moment,
        selectedDateRange: TDatetimeRange,
        selectedTimeRange: TTimeRange,
        side: 'start' | 'end',
        supportedHours: number
    ) =>
    (timeRangeOption: LabelValue): boolean => {
        const index = side === 'start' ? 0 : 1;
        const combinedDatetime = moment(selectedDateRange[index]).add(timeRangeOption.value, 'minutes');
        const diffInHoursFromNow = now.diff(combinedDatetime, 'hours');
        const notTooInThePast = diffInHoursFromNow < supportedHours;
        const notInTheFuture = combinedDatetime < now;
        const notContradictsSecondSelectedTime =
            side === 'start'
                ? combinedDatetime < moment(selectedDateRange[1]).add(selectedTimeRange[1], 'minutes')
                : combinedDatetime > moment(selectedDateRange[0]).add(selectedTimeRange[0], 'minutes');

        return notTooInThePast && notInTheFuture && notContradictsSecondSelectedTime;
    };

export const UiTimeRangePicker = (props: IUiTimeRangePickerProps) => {
    const [timeOptionsStart, setTimeOptionsStart] = useState<LabelValue[]>();
    const [timeOptionsEnd, setTimeOptionsEnd] = useState<LabelValue[]>();
    const [timeRange, setTimeRange] = useState<TTimeRange>(props.value);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const now = useMemo(() => moment(), []);

    useEffect(() => {
        const timeOptionsStartToSet = allTimeOptions().filter(
            getFilterFn(now, props.selectedDateRange, timeRange, 'start', props.supportedHours)
        );
        const timeOptionsEndToSet = allTimeOptions().filter(
            getFilterFn(now, props.selectedDateRange, timeRange, 'end', props.supportedHours)
        );
        setIsDisabled(false);
        if (timeOptionsStartToSet.length === 0) {
            timeOptionsStartToSet.push({ label: '00:00', value: '0' });
            setIsDisabled(true);
        }
        if (timeOptionsEndToSet.length === 0) {
            timeOptionsEndToSet.push(EOD_TIME_LABEL_VALUE);
            setIsDisabled(true);
        }

        setTimeOptionsStart(timeOptionsStartToSet);
        setTimeOptionsEnd(timeOptionsEndToSet);

        if (
            timeOptionsStartToSet.find((to) => to.value === props.value[0]) &&
            timeOptionsEndToSet.find((to) => to.value === props.value[1])
        ) {
            setTimeRange(props.value);
        } else {
            const newTimeRange: TTimeRange = [
                timeOptionsStartToSet[0].value,
                timeOptionsEndToSet[timeOptionsEndToSet.length - 1].value,
            ];
            setTimeRange(newTimeRange);
            props.onChange(newTimeRange);
        }
    }, [
        props.value[0],
        props.value[1],
        props.selectedDateRange[0],
        props.selectedDateRange[1],
        timeRange[0],
        timeRange[1],
    ]);

    const onSelect = (newTime: string, side: 'start' | 'end') => {
        const newTimeRange: TTimeRange = side === 'start' ? [newTime, timeRange[1]] : [timeRange[0], newTime];

        setTimeRange(newTimeRange);
        props.onChange(newTimeRange);
    };

    return (
        <div className="UiTimeRangePicker">
            <span className="trp-footer-label">From</span>
            <Select
                value={timeFormatter(parseInt(timeRange[0]))}
                options={timeOptionsStart}
                className="trp-footer-select"
                disabled={isDisabled}
                onSelect={(newTime) => onSelect(newTime as string, 'start')}
            />
            <span className="trp-footer-label">To</span>
            <Select
                value={timeFormatter(parseInt(timeRange[1]))}
                options={timeOptionsEnd}
                className="trp-footer-select"
                disabled={isDisabled}
                onSelect={(newTime) => onSelect(newTime as string, 'end')}
            />
        </div>
    );
};
