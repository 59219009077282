import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Formatter } from 'recharts/types/component/DefaultTooltipContent';
import { formatNumberDisplay } from '../../../general/utils';
import { ISeriesData } from '../../../interfaces/status.interface';

interface IProps {
    colors: string[];
    seriesData: ISeriesData[] | undefined;
}

export const SeriesLineChart = (props: IProps) => {
    const { seriesData, colors } = props;
    return (
        <>
            <div className="title">Activity (UTC)</div>
            <ResponsiveContainer width="98%" height="90%">
                <LineChart
                    width={500}
                    height={180}
                    data={seriesData}
                    margin={{
                        top: 20,
                        right: 10,
                        left: 20,
                        bottom: 6,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={formatNumberDisplay} />
                    <Tooltip formatter={formatNumberDisplay as Formatter<any, any>} />
                    <Line
                        type="monotone"
                        dataKey="1xx"
                        stroke={colors[0]}
                        activeDot={{ r: 8 }}
                        strokeWidth={2}
                        dot={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="2xx"
                        stroke={colors[1]}
                        activeDot={{ r: 8 }}
                        strokeWidth={2}
                        dot={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="3xx"
                        stroke={colors[2]}
                        activeDot={{ r: 8 }}
                        strokeWidth={2}
                        dot={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="4xx"
                        stroke={colors[3]}
                        activeDot={{ r: 8 }}
                        strokeWidth={2}
                        dot={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="5xx"
                        stroke={colors[4]}
                        activeDot={{ r: 8 }}
                        strokeWidth={2}
                        dot={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="None"
                        stroke={colors[5]}
                        activeDot={{ r: 8 }}
                        strokeWidth={2}
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </>
    );
};
