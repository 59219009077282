import React from 'react';
import { Redirect } from 'react-router';

import Spinner from 'containers/spinner/Spinner';
import { IDiscoveryEndpointResponse } from 'interfaces/endpoint.interface';
import { TDatetimeRange } from 'general/utils';
import { EndpointStatTable } from '../endpoint-stat-table/EndpointStatTable';

import './EndPointTable.scss';

interface IEndpointServiceProps {
    validOrg: boolean;
    selectedEndpoint: IDiscoveryEndpointResponse | undefined;
    endpointsSpinner: boolean;
    detailsSpinner: boolean;
    attrData: any[];
    activeOrg: string;
    timeRangeFromInput: TDatetimeRange;
    base64EncodedServiceName: string;
    onParameterNamingSuccess: () => void;
}

export const EndpointTable = (props: IEndpointServiceProps) => {
    const {
        selectedEndpoint,
        endpointsSpinner,
        attrData,
        detailsSpinner,
        activeOrg,
        timeRangeFromInput,
        base64EncodedServiceName,
        onParameterNamingSuccess,
    } = props;
    const loadMasterDetail = () => {
        if (selectedEndpoint) {
            return (
                <div className="discovery-master-detail">
                    {attrData?.length > 0 ? (
                        <EndpointStatTable
                            call_count={selectedEndpoint.calls_count}
                            attrData={attrData}
                            activeOrg={activeOrg}
                            timeRangeFromInput={timeRangeFromInput}
                            base64EncodedServiceName={base64EncodedServiceName}
                            endpointPath={selectedEndpoint.endpoint_path}
                            onParameterNamingSuccess={onParameterNamingSuccess}
                        />
                    ) : (
                        <div className="info-message">
                            <span>No data for selected date range</span>
                        </div>
                    )}
                </div>
            );
        }
    };

    if (typeof props.validOrg === 'boolean' && !props.validOrg) {
        return <Redirect to={'/404'} />;
    } else {
        return (
            <div className="endpoint-table-container">
                <Spinner show={endpointsSpinner} />
                <div className="discovery-master-detail-container">
                    <Spinner show={detailsSpinner} paddingBottom={100} />
                    {detailsSpinner ? null : loadMasterDetail()}
                </div>
            </div>
        );
    }
};
