import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';
import { LicenseManager } from '@ag-grid-enterprise/core';

import App from './App';
import ErrorBoundary from 'core/ErrorBoundary/ErrorBoundary';
import { AppStateProvider } from './contexts/AppStateContext';
import * as serviceWorker from './serviceWorker';
import { store } from './general/store';
import './components/truncate/Truncate';

import './index.scss';

Amplify.configure({
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'xxxxx-xxxx',

    // REQUIRED - Amazon Cognito Region
    region: process.env.AWS_REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //     domain: '.yourdomain.com',
    //     // OPTIONAL - Cookie path
    //     path: '/',
    //     // OPTIONAL - Cookie expiration in days
    //     expires: 365,
    //     // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //     sameSite:  "lax",
    //     // OPTIONAL - Cookie secure flag
    //     // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //     secure: true
    // },

    // OPTIONAL - customized storage object
    // storage: '',

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    oauth: {
        domain: process.env.REACT_APP_AUTH_DOMAIN,
        scope: [
            'email',
            'openid',
            'profile',
            // 'aws.cognito.signin.user.admin',
            process.env.REACT_APP_AUTH_SCOPE,
            'neosec-authz-proxy/endpoints.read',
        ],
        redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
});

LicenseManager.setLicenseKey(
    'CompanyName=Neosec,LicensedApplication=Neosec,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-024113,ExpiryDate=21_February_2023_[v2]_MTY3NjkzNzYwMDAwMA==113b1487b58981cb22b8f7da5d3c3e44'
);

const mixpanel = require('mixpanel-browser');
mixpanel.init('1eab2d81f5c42c130fa87fd1649f4472');

createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <AppStateProvider>
            <Provider store={store}>
                <ErrorBoundary>
                    <Router>
                        <App />
                    </Router>
                </ErrorBoundary>
            </Provider>
        </AppStateProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
