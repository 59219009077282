import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
    CellRenderGenericMore,
    CellRenderTimeStamp,
    CellRenderToggle,
    CellRenderVerticalCenter,
} from '../../../ui-ag-grid/customCellRenderers';
import { BASIC_AGGRID_COL_TYPE } from '../../../ui-ag-grid/commonOptions';
import { extractErrorMessage, TimeDisplayResEnum } from '../../../../general/utils';
import { UiIcon } from '../../../icon/UiIcon';
import { openDialog } from '../../../../services/dialog/dialog-service';
import { SettingsSectionHeader } from '../SettingsSectionHeader/SettingsSectionHeader';
import { UiAgGridCSRM } from '../../../ui-ag-grid/UiAgGridCSRM';

import './EndpointPatternsList.scss';
import { CustomTooltip } from '../../../ui-ag-grid/customToolTip';
import { httpDelete, httpGet, httpPatch, httpPOST } from '../../../../general/http-service';
import { errorMessage } from '../../../../general/toast-service';
import { Form, Input } from 'antd';
import { UiModal } from '../../../ui-modal/UiModal';
import { IEndpointPattern, IEndpointPatternBase } from '../../../../interfaces/endpoint.interface';
import { formFieldUrlPath, formFieldRequired, pathParametersRule } from '../../../../general/forms';
import { ISettingsPathParams } from '../../Settings';
import { SMALL_COLUMN } from '../../../ui-ag-grid/agGridConstants';

const gridOptions: any = {
    rowHeight: 70,
    rowDragManaged: true,
    components: {
        cellRenderTimeStamp: CellRenderTimeStamp,
        cellRenderGenericMore: CellRenderGenericMore,
        cellRenderVerticalCenter: CellRenderVerticalCenter,
        cellRenderToggle: CellRenderToggle,
        customTooltip: CustomTooltip,
    },
    columnTypes: {
        // col type that cols inherit from
        basic: BASIC_AGGRID_COL_TYPE,
    },
    rowClassRules: {
        'disabled-row': function (params: any) {
            return !params.data?.active || false;
        },
    },
};

interface IEndpointPatternsProps {
    editMode?: boolean;
}

interface IEndpointPatternPathParams extends ISettingsPathParams {
    endpointPatternId?: IEndpointPattern['id'];
}

export const EndpointPatternsList = ({ editMode = false }: IEndpointPatternsProps) => {
    const history = useHistory();
    const { activeOrg, endpointPatternId } = useParams<IEndpointPatternPathParams>();
    const [form] = Form.useForm();
    const [tableData, setTableData] = useState<any[]>([]);
    const [selectedEndpointPattern, setSelectedEndpointPattern] = useState<IEndpointPattern>();
    const columnsDefs = [
        {
            headerName: 'No.',
            rowDrag: true,
            field: 'index',
            type: 'basic',
            width: SMALL_COLUMN,
            cellRenderer: 'cellRenderVerticalCenter',
            sortable: false,
            headerTooltip: 'Order',
        },
        {
            headerName: 'Status',
            field: 'active',
            type: 'basic',
            sortable: false,
            width: SMALL_COLUMN,
            cellRenderer: 'cellRenderToggle',
            cellRendererParams: () => {
                return {
                    onRowToggleHandler: (e: boolean, idx: number, endpointPatternId: string) =>
                        onRowToggleHandler(e, idx, endpointPatternId),
                };
            },
        },
        {
            headerName: 'Pattern',
            field: 'template',
            type: 'basic',
            minWidth: 300,
            flex: 1,
            cellRenderer: 'cellRenderVerticalCenter',
        },
        {
            headerName: 'Created At',
            field: 'created_at',
            type: 'basic',
            flex: 1,
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                timeResolution: TimeDisplayResEnum.MIN,
            },
        },
        {
            headerName: 'Author',
            field: 'created_by',
            type: 'basic',
            flex: 1,
            cellRenderer: 'cellRenderVerticalCenter',
        },
        {
            headerName: 'Last Modified',
            field: 'updated_at',
            type: 'basic',
            flex: 1,
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                timeResolution: TimeDisplayResEnum.MIN,
            },
        },
        {
            headerName: 'Last Modified By',
            field: 'updated_by',
            type: 'basic',
            flex: 1,
            cellRenderer: 'cellRenderVerticalCenter',
            sortable: true,
            resizable: false,
        },
        {
            headerName: '',
            field: 'overlay',
            sortable: false,
            width: 60,
            type: 'basic',
            resizable: false,
            cellRenderer: 'cellRenderGenericMore',
            cellRendererParams: (params: any) => {
                const menuItems = [
                    {
                        label: `Edit`,
                        icon: <UiIcon name="pencil" />,
                        onClick: () => {
                            history.push(`/${activeOrg}/settings/endpoint-patterns/edit/${params.data?.id}`);
                        },
                    },
                    {
                        label: `Delete`,
                        icon: <UiIcon name="trash" />,
                        onClick: () => {
                            openDialog({
                                text: `Are you sure you would like to permanently delete endpoint pattern?`,
                                icon: 'trash2',
                                title: 'Delete Endpoint Pattern',
                            })
                                .then(() => deleteEndpointPattern(params.data.id))
                                .catch(() => {});
                        },
                    },
                ];
                return { menuItems };
            },
        },
    ];

    useEffect(() => {
        if (endpointPatternId) {
            getAndSetSingleEndpointPattern(endpointPatternId);
        }
    }, [endpointPatternId]);

    useEffect(() => {
        getAndSetEndpointPatternsData();
    }, []);

    const ENDPOINT_PATTERNS_BASE_PATH = `organizations/${activeOrg}/cms/discovery/endpoint_patterns`;
    const isNewPattern = typeof endpointPatternId === 'undefined';

    function getAndSetEndpointPatternsData() {
        httpGet(`${ENDPOINT_PATTERNS_BASE_PATH}?sort_by=asc(index)&limit=1000`)
            .then((res) => {
                setTableData(res.data);
            })
            .catch((error) => {
                errorMessage(
                    extractErrorMessage(
                        error.response?.data?.detail,
                        'Unexpected error while fetching endpoint patterns'
                    )
                );
            });
    }

    function getAndSetSingleEndpointPattern(endpointPatternId: IEndpointPattern['id']) {
        httpGet(`${ENDPOINT_PATTERNS_BASE_PATH}/${endpointPatternId}`)
            .then((res) => {
                setSelectedEndpointPattern(res.data);
                form.setFieldsValue(res.data);
            })
            .catch((error) => {
                errorMessage(
                    extractErrorMessage(
                        error.response?.data?.detail,
                        'Unexpected error while fetching endpoint patterns'
                    )
                );
            });
    }

    function deleteEndpointPattern(endpointPatternId: string) {
        httpDelete(`${ENDPOINT_PATTERNS_BASE_PATH}/${endpointPatternId}`)
            .then(() => {
                getAndSetEndpointPatternsData();
            })
            .catch((error) => {
                errorMessage(
                    extractErrorMessage(error.response?.data?.detail, 'Unexpected error deleting endpoint pattern')
                );
            });
    }

    function updateEndpointPattern(endpointPatternId: string, payload: IEndpointPatternBase) {
        httpPatch(`${ENDPOINT_PATTERNS_BASE_PATH}/${endpointPatternId}`, payload)
            .then(() => {
                getAndSetEndpointPatternsData();
            })
            .catch((error) => {
                errorMessage(
                    extractErrorMessage(error.response?.data?.detail, 'Unexpected error updating endpoint pattern')
                );
            });
    }

    function createEndpointPattern(payload: IEndpointPatternBase) {
        httpPOST(`${ENDPOINT_PATTERNS_BASE_PATH}/`, payload)
            .then(() => {
                getAndSetEndpointPatternsData();
            })
            .catch((error) => {
                errorMessage(
                    error.response?.data?.status === 409
                        ? 'Endpoint pattern already exists'
                        : extractErrorMessage(
                              error.response?.data?.detail,
                              'Unexpected error updating endpoint pattern'
                          )
                );
            });
    }

    function onRowToggleHandler(active: boolean, rowNumber: number, endpointPatternId: string) {
        updateEndpointPattern(endpointPatternId, {
            active: active,
        });
    }

    function onRowDragEnd(e: any) {
        updateEndpointPattern(e.node.data.id, {
            index: e.overIndex + 1,
        });
    }

    function resetFormAndNavigateToEndpointPatterns() {
        form.resetFields();
        history.push(`/${activeOrg}/settings/endpoint-patterns`);
    }

    return (
        <div className="endpoint-patterns-container">
            <SettingsSectionHeader
                title="Endpoint Patterns"
                urlPath="settings/endpoint-patterns/add"
                buttonText="Add Endpoint Pattern"
            />
            <div className="endpoint-patterns-table">
                <UiAgGridCSRM
                    options={gridOptions}
                    draggableRows
                    data={tableData}
                    showRowCount={true}
                    columns={columnsDefs}
                    rowDragEndHandler={onRowDragEnd}
                    rowCountTitle="Endpoint Patterns"
                />
            </div>
            <UiModal
                isFormModal
                wrapClassName="endpoint-pattern-modal"
                width={700}
                isVisible={editMode}
                onCancel={resetFormAndNavigateToEndpointPatterns}
                title={`${isNewPattern ? 'Create' : 'Edit'} Endpoint Pattern`}
                acceptButton={{
                    text: 'Save',
                    onClick: () => {
                        form.validateFields()
                            .then(() => {
                                if (isNewPattern) {
                                    createEndpointPattern({
                                        template: form.getFieldValue('template'),
                                    });
                                } else {
                                    updateEndpointPattern(endpointPatternId as string, {
                                        template: form.getFieldValue('template'),
                                    });
                                }
                            })
                            .then(resetFormAndNavigateToEndpointPatterns)
                            .catch(() => {});
                    },
                }}
                rejectButton={{
                    text: 'Cancel',
                    onClick: resetFormAndNavigateToEndpointPatterns,
                }}
            >
                <div>
                    <Form form={form} requiredMark={false} layout="vertical">
                        <Form.Item
                            label="Pattern"
                            name="template"
                            rules={[
                                {
                                    required: true,
                                    message: formFieldRequired.errorMessage,
                                },
                                {
                                    pattern: formFieldUrlPath.pattern,
                                    message: formFieldUrlPath.errorMessage,
                                },
                                pathParametersRule,
                            ]}
                        >
                            <Input placeholder={'E.g. v1/users/{}/calls/{}'} />
                        </Form.Item>
                    </Form>
                </div>
            </UiModal>
        </div>
    );
};
