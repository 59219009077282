import React, { ReactNode } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import { ColDef } from '@ag-grid-community/core';

import { httpGet, PAGE_SIZE_LIMIT, recursiveHttpGet } from '../../../../general/http-service';
import { BASIC_COLUMN_DEFINITION, IProps as UISSRMProps } from '../../../../components/ui-ag-grid/UiAgGridSSRM';
import { agSetFilter, agTextFilter } from '../../../../components/ui-ag-grid/commonOptions';
import { FilterableTable } from '../../FilterableTable/FilterableTable';
import { IService } from '../../../../interfaces/service.interface';
import { removeTrailingSlashes } from '../../../../general/utils';
import { SMALL_COLUMN, TINY_COLUMN } from '../../../../components/ui-ag-grid/agGridConstants';
import { RiskTooltip } from '../../RiskTooltip/RiskTooltip';
import { UiIcon } from '../../../../components/icon/UiIcon';

interface IServiceTableProps {
    tableActions?: ReactNode;
    getData: UISSRMProps['getData'];
    activeOrgParam: string;
    onFilterChange: Function;
    paginationPageSize?: number;
    pagination?: boolean;
    setRowsCount: Function;
    onSelectionChanged: Function;
}

export const ServiceFilterableTable = (props: IServiceTableProps) => {
    const {
        tableActions,
        getData,
        activeOrgParam,
        onFilterChange,
        paginationPageSize,
        pagination,
        onSelectionChanged,
    } = props;
    const history = useHistory();
    const pathnameWithoutTrailingSlashes = removeTrailingSlashes(history.location.pathname);

    const cellRendererParams = (params: any) => {
        const menuItems = [
            {
                icon: <UiIcon name="goTo" />,
                label: `Go to Service`,
                onClick: () => {
                    history.push(
                        `${pathnameWithoutTrailingSlashes}/${encodeURIComponent(params.data.name)}/${
                            history.location.search
                        }`
                    );
                },
            },
        ];
        return { menuItems };
    };

    const columnDef: ColDef[] = [
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: '',
            field: 'selection',
            flex: 0,
            maxWidth: TINY_COLUMN,
            sortable: false,
            checkboxSelection: true,
            filter: false,
            filterParams: null,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Name',
            field: 'name',
            filterParams: {
                values: (params: any) => {
                    recursiveHttpGet(
                        `organizations/${activeOrgParam}/discovery/services?limit=${PAGE_SIZE_LIMIT}&from_timestamp=${moment()
                            .subtract(1, 'year')
                            .unix()}&to_timestamp=${moment().unix()}`,
                        0,
                        PAGE_SIZE_LIMIT,
                        [],
                        (services: IService[]) => {
                            const serviceNameList = services.map((service) => service.name);
                            params.success(serviceNameList);
                        },
                        (error: any) => params.reject(error)
                    );
                },
            },
            filter: 'agSetColumnFilter',
            cellRenderer: 'redirectableTitle',
            flex: 2,
            headerComponentParams: {
                headerTooltipContent: 'Service name',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Risk',
            field: 'risk_score',
            cellRenderer: 'cellProgress',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: <RiskTooltip />,
                disableCopyButton: true,
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Risk Change',
            field: 'risk_change',
            cellRenderer: 'riskChangeRender',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Difference in cumulative risk between start and end of time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Labels',
            field: 'labels',
            cellRenderer: 'labelListRender',
            filter: 'agSetColumnFilter',
            filterParams: {
                values: (params: any) => {
                    httpGet(`organizations/${activeOrgParam}/config`).then((res) => {
                        params.success(res.data.labels);
                    });
                },
            },
            type: 'agSetFilter',
            flex: 2,
            headerComponentParams: {
                headerTooltipContent: 'Endpoint labels for all endpoints in the service',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Posture',
            field: 'posture_alerts_count',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Open posture alerts at end of time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Runtime',
            field: 'behavior_alerts_count',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Open behavior or runtime alerts at end of time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Endpoints',
            field: 'endpoint_count',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Active endpoints within time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Endpoint change',
            field: 'endpoints_change',
            cellRenderer: 'changeRender',
            flex: 1.5,
            headerComponentParams: {
                headerTooltipContent: 'Difference in active endpoints between start and end of time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Hidden endpoints',
            field: 'hidden_endpoints_count',
            flex: 1.5,
            headerComponentParams: {
                headerTooltipContent: 'Discovered endpoints that were hidden by users',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Calls',
            field: 'calls_count',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Number of requests to service endpoints during the time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: '4XX',
            field: 'errors_4xx',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Number of client-side errors from service endpoints during the time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: '5XX',
            field: 'errors_5xx',
            flex: 1,
            headerComponentParams: {
                headerTooltipContent: 'Number of server-side errors from service endpoints during the time range',
            },
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: '',
            field: 'more',
            maxWidth: SMALL_COLUMN,
            flex: 0,
            cellRenderer: 'moreButtonRender',
            cellRendererParams,
            suppressMenu: true,
            suppressMovable: true,
            filter: false,
            filterParams: null,
            sortable: false,
            resizable: false,
        },
    ];

    function getColumnsDefs(): ColDef[] {
        return columnDef.map((item: ColDef) => {
            return {
                headerName: item.headerName,
                field: item.field,
                type: item.type || 'agTextFilter',
                flex: item.flex || 0.5,
                maxWidth: item.maxWidth,
                checkboxSelection: item.checkboxSelection,
                cellRenderer: item.cellRenderer || 'cellRenderVerticalCenter',
                filterParams:
                    item.filterParams === null
                        ? null
                        : item.filterParams || {
                              suppressAndOrCondition: true,
                              closeOnApply: true,
                              filterOptions: ['equals', 'lessThan', 'greaterThan'],
                          },
                filter: item.filter === false ? false : item.filter || 'agNumberColumnFilter',
                sortable: item.sortable !== false,
                resizable: true,
                cellRendererParams: item.cellRendererParams,
                suppressMenu: item.suppressMenu,
                ...item,
            };
        });
    }

    return (
        <FilterableTable
            tableActions={tableActions}
            columns={getColumnsDefs()}
            getData={getData}
            onFilterChange={onFilterChange}
            paginationPageSize={paginationPageSize}
            pagination={pagination}
            onSelectionChanged={onSelectionChanged}
            setRowsCount={props.setRowsCount}
            redirectOption={'SERVICE'}
            tableTitle={''}
            isHideRowsCount={true}
        />
    );
};
