import { IEndpointDisplay } from './endpoint.interface';
import { IEntity } from './entity.interface';
import { EventTypesEnum } from '../enums/eventTypes.enum';

export interface IAlert {
    id: string;
    alert_type: 'APIAlert' | 'UserBehaviorAlert';
    callIds: string[];
    name: string;
    timestamp: string;
    status: string;
    severity: string;
    endpoints: IEndpointDisplay[];
    services: string[];
    description: string;
    category: string;
    comments_count: number;
    author: string;
    entities: IEntity[];
    labels: string[];
    recommendations: string;
    alert_info: string;
    eventType: EventTypesEnum;
    icon?: string;
    base_risk_score?: number;
}

export interface IAlertResponse {
    author: string;
    call_ids: string[];
    caller_ips: string[];
    comments_count: number;
    category: string;
    description: string;
    endpoints: Partial<IEndpointDisplay>[];
    id: string;
    labels: string[];
    name: string;
    severity: string;
    status: string;
    timestamp: string;
    entities: IEntity[];
}

export const AlertTypeMap: Map<IAlert['alert_type'], string> = new Map([
    ['APIAlert', 'Posture'],
    ['UserBehaviorAlert', 'Runtime'],
]);

export const AlertTypeReverseMap: Map<string, IAlert['alert_type']> = new Map([
    ['Posture', 'APIAlert'],
    ['Runtime', 'UserBehaviorAlert'],
]);
