import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';

import NavigationMenu from '../navigation-menu/NavigationMenu';
import { UiIcon } from '../icon/UiIcon';
import { UiIconButton } from '../icon-button/UiIconButton';
import { TenantDrawer } from '../tenant-drawer/TenantDrawer';
import { UserBadge } from '../UserBadge/UserBadge';
import { Logo } from 'components/logo/Logo';
import { ActivationErrorIcon } from 'components/activationErrorIcon/ActivationErrorIcon';
import { useGetUserSessionTokenPayloadQuery } from 'api/userApi';
import { selectCurrentTenantDetails } from 'api/tenantListApi';

import './NavBar.scss';
import { checkFeature } from 'services/feature-toggle/feature-toggle-service';

interface INavBarProps {
    showNavigation: boolean;
    signOut: Function;
}

export const NavBar = (props: INavBarProps) => {
    const location = useLocation();
    const routeName = location.pathname.replace('/', '');
    const [current, setCurrent] = useState(routeName || 'home');
    const [tenantDrawerVisible, setTenantDrawerVisible] = useState<boolean>(false);
    const { data: user } = useGetUserSessionTokenPayloadQuery<{ data: { [key: string]: any } }>(null);
    const currentTenant = useSelector(selectCurrentTenantDetails);

    const handleClick = (e: any) => {
        setCurrent(e.key);
        setTenantDrawerVisible(false);
    };

    useEffect(() => {
        const newLocation = location.pathname.replace('/', '');
        if (newLocation !== current) {
            const currentRoute = newLocation.length ? newLocation : 'home';
            setCurrent(currentRoute);
        }
    }, [location, current]);

    const onClose = useCallback(() => {
        setTenantDrawerVisible(false);
    }, []);

    function toggleDrawer() {
        setTenantDrawerVisible((prev) => !prev);
    }

    return (
        <>
            <div className="navbar">
                <div className="mast-head">
                    <div className="tenant-button">
                        <UiIcon name={tenantDrawerVisible ? 'closeTenant' : 'tenants'} onClick={toggleDrawer} />
                    </div>
                    <div className="title-and-tenant-container">
                        <Logo />
                        {currentTenant?.name && (
                            <>
                                <Divider className="tenant-separator" type="vertical" />
                                <span className="tenant-name" title={currentTenant?.name}>
                                    {currentTenant?.name}
                                </span>
                                {checkFeature(process.env, currentTenant.name, 'detokenization', user) && (
                                    <span className="detok-icons">
                                        <UiIcon name="threeStars" className="three-stars" />
                                        <UiIcon name="checkCircleOutlinedGreen" className="check-circle" />
                                    </span>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {props.showNavigation && <NavigationMenu handleClick={handleClick} current={current} />}

                <div className="controls">
                    <ActivationErrorIcon status={currentTenant?.latest_transaction_status} />
                    <UserBadge className="navbar-user-badge" email={user?.email} />
                    {props.showNavigation && (
                        <Link
                            className={`tab-button ${current.includes('settings') ? 'active' : ''}`}
                            to={`/${currentTenant?.key}/settings`}
                            onClick={() => {
                                setTenantDrawerVisible(false);
                            }}
                        >
                            <UiIconButton name="settings" />
                        </Link>
                    )}
                    <div className="tab-button" onClick={() => props.signOut()}>
                        <UiIconButton name="logout" />
                    </div>
                </div>
            </div>
            <TenantDrawer visible={tenantDrawerVisible} onClose={onClose} currentTenant={currentTenant} />
        </>
    );
};
