import { useMemo } from 'react';
import { Col, Row } from 'antd';
import humanizeDuration from 'humanize-duration';

import { UiIcon } from '../../../icon/UiIcon';
import { dummyFunctions } from '../../../service-summary/ServiceSummary';
import { CellRenderScanStatus } from '../../../ui-ag-grid/customCellRenderers';
import { UiTitle } from '../../../ui-title/UiTitle';
import { IScan } from '../../../../api/testingApi';

import './ScanDetailsSummary.scss';

interface IProps {
    scan?: IScan;
}

export interface IScanDetailsColumnDefinition {
    displayName: string;
    order?: number;
    /* Prepares the data for the renderer, since we use shared renderers expecting data in certain format */
    dataPreparer?: Function;
    renderer?: Function;
    getIcon?: Function;
    headerIconName?: string;
    valueIconName?: string;
}

export const colDefs: { [key: string]: IScanDetailsColumnDefinition } = {
    status: {
        order: 1,
        displayName: 'Status',
        dataPreparer: (status: string) => ({ status, value: status }),
        renderer: CellRenderScanStatus,
    },
    findings_total: {
        order: 2,
        displayName: 'Findings',
    },
    findings_high_count: {
        order: 3,
        valueIconName: 'alertHigh',
        displayName: 'High',
    },
    findings_medium_count: {
        order: 4,
        valueIconName: 'alertMedium',
        displayName: 'Medium',
    },
    findings_low_count: {
        order: 5,
        valueIconName: 'alertLow',
        displayName: 'Low',
    },
    findings_info_count: {
        order: 6,
        valueIconName: 'alertInfo',
        displayName: 'Info',
    },
    requests_count: {
        order: 7,
        displayName: 'Requests',
    },
    duration: {
        order: 8,
        displayName: 'Duration',
        dataPreparer: (duration: number) => humanizeDuration(duration),
    },
    application_name: {
        order: 9,
        displayName: 'Application',
    },
    target_url: {
        order: 10,
        displayName: 'Target Url',
    },
    triggered_by: {
        order: 11,
        displayName: 'Triggered by',
    },
    scan_engine: {
        order: 12,
        displayName: 'Scan engine',
    },
};

const calculateTotalFindings = (data: IScan) =>
    data.findings_high_count + data.findings_info_count + data.findings_low_count + data.findings_medium_count;

export const ScanDetailsSummary = ({ scan }: IProps) => {
    const scanWithTotal = useMemo(
        () => ({
            ...scan,
            findings_total: scan ? calculateTotalFindings(scan) : '-',
        }),
        [scan]
    );

    return (
        <div className="ScanDetailsSummary">
            {scanWithTotal ? (
                <Row className="row">
                    {Object.entries(scanWithTotal).map(([key, value], index) => {
                        return (
                            colDefs.hasOwnProperty(key) && (
                                <Col key={index} order={colDefs[key].order}>
                                    <div className="header">
                                        {colDefs[key].headerIconName && (
                                            <UiIcon
                                                className="heading-icon"
                                                name={colDefs[key].headerIconName || 'alertHigh'}
                                            />
                                        )}
                                        <UiTitle title={colDefs[key].displayName} type="medium" />
                                    </div>
                                    <div className="value">
                                        {colDefs[key].valueIconName && (
                                            <UiIcon
                                                className="heading-icon"
                                                name={colDefs[key].valueIconName || 'alertHigh'}
                                            />
                                        )}
                                        {(colDefs[key].renderer || dummyFunctions.renderer)(
                                            (colDefs[key].dataPreparer || dummyFunctions.formatter)(value)
                                        )}
                                        {colDefs[key].getIcon && (
                                            <span className={'icon'}>
                                                {(colDefs[key].getIcon || dummyFunctions.getIcon)(value)}
                                            </span>
                                        )}
                                    </div>
                                </Col>
                            )
                        );
                    })}
                </Row>
            ) : (
                ''
            )}
        </div>
    );
};
