import React from 'react';
import { Input } from 'antd';
import './UiInput.scss';

interface IProps {
    onChange?: any;
    value?: string | number;
    defaultValue?: string | number;
    placeholder?: string;
    width?: number;
    type?: 'text' | 'number' | 'password';
    label?: string;
    maxLength?: number;
    disabled?: boolean;
    onEnterPressed?: any;
    onBlur?: any;
    autoFocus?: boolean;
    error?: string | null;
}

export const UiInput = (props: IProps) => {
    return (
        <div style={{ position: 'relative' }}>
            <div className="ui-input-container">
                {props.label && <span className="control-label">{props.label}</span>}
                <Input
                    type={props.type}
                    style={{ width: props.width }}
                    onChange={props.onChange}
                    value={props.value}
                    defaultValue={props.defaultValue}
                    onBlur={props.onBlur}
                    autoFocus={props.autoFocus}
                    onPressEnter={(e) => props.onEnterPressed && props.onEnterPressed()}
                    maxLength={props.maxLength}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                />
            </div>
            <div className="ui-input-error">{props.error}</div>
        </div>
    );
};
