import React from 'react';
import {useEntity} from "../../services/data/hooks";
import {EntityName} from "../../services/data/entityMap";
import {Select} from "antd";
import './AlertSeveirtySelect.scss';
const {Option} = Select;

interface IAlertSeverityMultiSelectProps {
    label?: string;
    value: string;
    width: number;
    onChange: (event: string[]) => any;
}

export const AlertSeveritySelect: React.FC<IAlertSeverityMultiSelectProps> = (props) => {
    const [alertSeverityList] = useEntity<{items: {}[]}>(EntityName.AlertSeverity);

    return (
        alertSeverityList ?
            <div className="alert-severity-select">
                {
                    props.label &&
                    <div className="ass-label">
                        {props.label || 'Alert Severity'}
                    </div>
                }
                <Select
                    style={{minWidth: props.width}}
                    defaultValue={props.value}
                    onChange={(event: any) => props.onChange(event)}
                >
                    {alertSeverityList.items.map((alertSeverity: any) => <Option key={alertSeverity.id} value={alertSeverity.severity_name}>{alertSeverity.severity_name}</Option>)}
                </Select>
            </div>  : null
    );
}
