import React from 'react';
import { Tabs } from 'antd';

import './UiTabsNav.scss';
import { IBaseProps } from '../shared/IBaseProps.interface';
import { TabPaneProps, TabsProps } from 'antd/lib/tabs';

export interface ITab extends TabPaneProps {
    key: string;
    title: string | JSX.Element;
    extraTabProps?: Omit<TabPaneProps, 'key'>;
}

export interface IUiTabsNavProps extends IBaseProps, TabsProps {
    tabs: ITab[];
    onTabSelected: (key: string | number) => void;
}

export const UiTabsNav = (props: IUiTabsNavProps) => {
    const { className, tabs, onTabSelected, ...extraProps } = props;

    return (
        <Tabs className={`UiTabsNav ${className}`} onChange={onTabSelected} {...extraProps}>
            {tabs.map((tab) => (
                <Tabs.TabPane tab={tab.title} key={tab.key} disabled={tab.disabled} {...tab.extraTabProps} />
            ))}
        </Tabs>
    );
};
