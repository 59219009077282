import moment from 'moment';

import { httpGet } from './http-service';
import { getTimeRangeInLast24Hours, isDateInLast48Hours } from './utils';

export interface IConfigRes {
    default_interval: string;
    total: number;
    timeframe: {
        end_timestamp: number; // moment
        start_timestamp: number; // moment
    };
}

export const getOrganizationConfig = async (activeOrg: string) => {
    const response = await httpGet(`organizations/${activeOrg}/config`);
    return response.data;
};

/**
 * Default time range
 * @description return the latest organization's time range minus 14 days and if not have then the last 14 days.
 * */
export const getDefaultTimeRange = (startTimestamp: number, endTimestamp: number): [moment.Moment, moment.Moment] => {
    if (startTimestamp && endTimestamp) {
        const startMoment = moment(startTimestamp);
        const endMoment = moment(endTimestamp);
        const candidateStartMoment = endMoment.clone().subtract(14, 'd');
        const normalizedStartMoment =
            startMoment.unix() > candidateStartMoment.unix() ? startMoment : candidateStartMoment;

        // If the default should show data in 15 minutes intervals, return last 24 hours or less
        if (isDateInLast48Hours(moment(), [normalizedStartMoment, endMoment])) {
            return getTimeRangeInLast24Hours(normalizedStartMoment, moment());
        }
        return [normalizedStartMoment.startOf('day'), endMoment.endOf('day')];
    }
    return [moment().subtract(14, 'days').add(5, 'minutes'), moment()];
};
