import React from 'react';
import './TopAlertingUsers.scss';
import { UiIcon } from '../icon/UiIcon';

interface ITopUsers {
    data: any[];
    headers: { icon: string; title: string }[];
}

export const TopAlertingUsers = ({ data, headers }: ITopUsers) => {
    const headerDisplay = headers.map((item: { icon: string; title: string }) => {
        const headerClassName = item.title.replace(' ', '-').replace('.', '');
        return (
            <div className={`header-col header-col-${headerClassName}`} key={item.title}>
                <div className="header-col-inner">
                    <UiIcon name={item.icon} />
                    <div className="title">{item.title}</div>
                </div>
            </div>
        );
    });

    return (
        <div className="top-alerting-users-container">
            <div className="users-table-header">{headerDisplay}</div>
            <div className="users-table-body">
                {data?.map((item: any, idx) => {
                    return (
                        <div className="row" key={item.userName?.props?.title || idx}>
                            <div className="user-name cell">{item.userName}</div>
                            <div className="alerts cell">{item.alerts}</div>
                            <div className="location cell">{item.location}</div>
                            <div className="active cell">{item.activeSince}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
