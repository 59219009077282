import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { CallDetailsModal } from 'components/call-details-modal/CallDetailsModal';
import { UiAgGridSSRM } from 'components/ui-ag-grid/UiAgGridSSRM';
import {
    CellRenderCallContent,
    CellRenderGenericMore,
    CellRenderInfo,
    CellRenderLabelList,
    CellRenderSeverity,
    CellExpandButton,
    CellRenderTimeStamp,
    CellRenderEndpointQuery,
    EndpointListRender,
    CellRenderDetokenizable,
} from 'components/ui-ag-grid/customCellRenderers';
import { BASIC_AGGRID_COL_TYPE } from 'components/ui-ag-grid/commonOptions';
import { EventTypesEnum } from 'enums/eventTypes.enum';
import { httpGet } from 'general/http-service';
import { investigateInNewTab } from 'general/utils';
import { IAGTableColumn } from 'interfaces/tableColumn.interface';
import { IBaseLabel } from 'interfaces/labels.interface';
import { IApiCall, IApiCallSequence } from 'interfaces/apiCall.interface';
import { ICallTableData } from 'interfaces/query.interface';

import './QueryResultsTable.scss';

export interface ITableResMetaData {
    count: number;
    total: number;
    labels?: IBaseLabel[];
}

interface IResultsTableProps {
    getTableData: any;
    callType: EventTypesEnum | null;
    entityType: string;
}

interface ModalData {
    isVisible: boolean;
    data: IApiCall | null;
}

export const QueryResultsTable = ({ getTableData, callType, entityType }: IResultsTableProps) => {
    const [modalData, setModalData] = useState<ModalData>({ isVisible: false, data: null });
    const [colDefs, setColDefs] = useState<any[]>([]);
    const params = useParams() as { activeOrgParam: string };
    useEffect(() => {
        if (callType) {
            setColDefs(callType === EventTypesEnum.Call ? callsTableColumns : sequenceTableColumns);
        }
    }, [callType]);

    function getCallAttributesUrl(item: IApiCall): string {
        const callParsedDate = new Date(item.timestamp);
        const timestamp = Math.floor(callParsedDate.getTime() / 1000);
        return `organizations/${params.activeOrgParam}/calls/${item.id}/attributes?call_ts=${moment(
            item.timestamp
        ).valueOf()}&from_timestamp=${timestamp}&to_timestamp=${timestamp + 1}`;
    }

    async function getCallItemWithAttributes(item: IApiCall): Promise<IApiCall> {
        if (item && !item.attributes) {
            const callAttributesUrl = getCallAttributesUrl(item);
            item.attributes = await httpGet(callAttributesUrl).then((res: any) => {
                return res.data.attributes;
            });
        }
        return item;
    }

    const openModal = useCallback(async (item?: any) => {
        getCallItemWithAttributes(item).then((callItemWithAttributes: IApiCall) => {
            setModalData((prevValue) => ({ isVisible: !prevValue.isVisible, data: callItemWithAttributes }));
        });
    }, []);

    const gridOptions = {
        components: {
            cellRenderTimeStamp: CellRenderTimeStamp,
            severity: CellRenderSeverity,
            labelListRender: CellRenderLabelList,
            endpointQueryRender: CellRenderEndpointQuery,
            callContentRender: CellRenderCallContent,
            expandButtonCellRenderer: CellExpandButton,
            moreButtonRender: CellRenderGenericMore,
            cellRenderInfo: CellRenderInfo,
            endpointListRender: EndpointListRender,
            cellRenderDetokenizable: CellRenderDetokenizable,
        },

        columnTypes: {
            // col type that cols inherit from
            basic: BASIC_AGGRID_COL_TYPE,
        },
    };

    const callsTableColumns: any = [
        { field: 'callId', hide: true },
        {
            headerName: 'Time',
            field: 'timestamp',
            type: 'basic',
            minWidth: 80,
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                displaySingleLine: true,
            },
        },
        {
            headerName: 'Entity ID',
            field: 'entityId',
            type: 'basic',
            cellRenderer: 'cellRenderDetokenizable',
        },
        {
            headerName: 'Endpoint',
            field: 'endpoint',
            type: 'basic',
            cellRenderer: 'endpointQueryRender',
        },
        {
            headerName: 'Path',
            field: 'call_path',
            type: 'basic',
            flex: 1,
            minWidth: 200,
            cellRenderer: 'cellRenderDetokenizable',
        },
        {
            headerName: 'status Code',
            field: 'status_code',
            type: 'basic',
            minWidth: 80,
        },
        {
            headerName: 'Source IP',
            field: 'caller_ip',
            type: 'basic',
            minWidth: 80,
        },
        {
            headerName: 'Labels',
            field: 'labels',
            resizable: true,
            minWidth: 200,
            suppressMenu: true,
            suppressMovable: true,
            cellRenderer: 'labelListRender',
        },
        {
            headerName: 'Content',
            field: 'content',
            resizable: true,
            minWidth: 120,
            suppressMenu: true,
            suppressMovable: true,
            cellRenderer: 'callContentRender',
        },
        {
            headerName: '',
            field: 'callDetails',
            width: 60,
            cellRenderer: 'cellRenderInfo',
            cellRendererParams: {
                openModalCb: openModal,
            },
            suppressMenu: true,
            suppressMovable: true,
        },
        {
            headerName: '',
            field: 'more',
            width: 60,
            cellRenderer: 'moreButtonRender',
            cellRendererParams: (params: any) => {
                const menuItems =
                    params.data?.entities
                        ?.filter(
                            (entity: { name: string; class: string; family: string; value: string }) => entity.value
                        )
                        .map((entity: { name: string; class: string; family: string; value: string }) => ({
                            label: `Investigate ${entity.name}`,
                            onClick: () => {
                                investigateInNewTab(
                                    process.env.REACT_APP_REDIRECT_SIGN_IN as string,
                                    params.data.currOrg,
                                    EventTypesEnum.Call,
                                    params.data.id,
                                    entity.name,
                                    entity.value,
                                    moment(params.data.timestamp).valueOf()
                                );
                            },
                        })) || [];

                return { menuItems };
            },
            suppressMenu: true,
            suppressMovable: true,
        },
    ];

    const sequenceTableColumns: IAGTableColumn[] = [
        {
            headerName: 'Time',
            field: 'timestamp',
            type: 'basic',
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                displaySingleLine: false,
            },
        },
        {
            headerName: 'Entity ID',
            field: 'entityId',
            type: 'basic',
            flex: 3,
            width: 100,
        },
        {
            headerName: '# Api Calls',
            field: 'call_count',
            type: 'basic',
            flex: 1,
            width: 100,
        },
        {
            headerName: 'Endpoint',
            field: 'endpoints',
            type: 'basic',
            cellRenderer: 'endpointListRender',
            flex: 3,
        },
        {
            headerName: 'Source IP',
            field: 'sourceIps',
            type: 'basic',
            flex: 2,
            width: 120,
        },
        {
            headerName: 'Duration',
            field: 'duration',
            type: 'basic',
            flex: 1,
            width: 120,
        },

        {
            headerName: '',
            width: 60,
            field: 'duration',
            cellRenderer: 'expandButtonCellRenderer',
            suppressMenu: true,
            suppressMovable: true,
        },
    ];

    const prepareCallData = (data: IApiCall[]): ICallTableData[] =>
        data.map((item, index) => ({
            ...item,
            endpoint: [{ method: item.method, name: item.name }],
            entityId: item.entities?.find(
                (entity: { name: string; class: string; family: string; value: string }) => entity.name === entityType
            )?.value,
            content: {
                requestType: item.request_content_type,
                requestSize: item.request_size,
                responseType: item.response_content_type,
                responseSize: item.response_size,
            },
            callDetails: item,
            more: item,
            currOrg: { key: params.activeOrgParam },
            labels: (item.service_labels || []).concat(item.endpoint_labels || []),
        }));

    function getSeqLabels(item: any) {}

    const prepareSequenceData = (data: IApiCallSequence[]) => {
        return data.map((item: any, index: number) => {
            return {
                ...item,
                number: index + 1,
                timestamp: item.timestamp,
                entityId: item.entities?.find(
                    (entity: { name: string; class: string; family: string; value: string }) =>
                        entity.name === entityType
                )?.value,
                call_count: item.call_count,
                endpoints: item.endpoints.map((endPoint: any) => {
                    return { method: endPoint.method, name: endPoint.endpoint_path };
                }),
                labels: getSeqLabels(item),
                sourceIps: item.caller_ips,
                aggregatedLabels: item.labels,
                duration: item.duration,
            };
        });
    };

    const onRowExpanded = (call_ids: string, extraParams: any) => {
        const queryString =
            extraParams.data.duration && extraParams.data.timestamp
                ? `&timestamp=${moment(extraParams.data.timestamp).valueOf()}&duration=${extraParams.data.duration}`
                : '';
        return httpGet(`organizations/${params.activeOrgParam}/calls?call_ids=${call_ids}${queryString}`).then(
            (res) => {
                const callsData = prepareCallData(res.data.items);
                return {
                    status: res.status,
                    data: {
                        count: res.data.count,
                        items: callsData.sort((a: any, b: any) => {
                            const isAfter = moment(a.timestamp).isAfter(b.timestamp);
                            return isAfter ? 1 : -1;
                        }),
                    },
                };
            }
        );
    };

    return (
        <div className="query-results-table">
            {callType === EventTypesEnum.Sequence && (
                <UiAgGridSSRM
                    columns={sequenceTableColumns}
                    options={gridOptions}
                    masterDetail={true}
                    detailCols={callsTableColumns}
                    getDetailData={onRowExpanded}
                    detailGridOptions={gridOptions}
                    dataMappingFunction={prepareSequenceData}
                    getData={getTableData}
                    entityType={entityType}
                />
            )}
            {callType === EventTypesEnum.Call && (
                <UiAgGridSSRM
                    columns={callsTableColumns}
                    options={gridOptions}
                    dataMappingFunction={prepareCallData}
                    getData={getTableData}
                    entityType={entityType}
                />
            )}

            {modalData.data && (
                <CallDetailsModal isVisible={modalData.isVisible} callData={modalData.data} toggleModal={openModal} />
            )}
        </div>
    );
};
