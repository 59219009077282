import React, { ForwardedRef, PropsWithChildren } from 'react';
import { Select } from 'antd';
import './UIMultiSelect.scss';
import { UiTitle } from '../ui-title/UiTitle';

interface IProps extends PropsWithChildren<{}> {
    width?: number;
    label?: string;
    value?: any;
    defaultValue?: any;
    options?: { value: any }[];
    handleChange?: any;
    placeholder?: string;
    disabled?: boolean;
    showDropdown?: boolean;
    mode?: 'multiple' | 'tags';
    height?: string;
    onBlur?: React.FocusEventHandler;
}

const UiMultiSelect = React.forwardRef((props: IProps, ref: ForwardedRef<any>) => {
    return (
        <div className="multi-select">
            {props.label && (
                <span className="control-label">
                    <UiTitle title={props.label} type="small" />
                </span>
            )}
            <Select
                mode={props.mode || 'tags'}
                style={{ minWidth: props.width, height: props.height ?? '36px' }}
                clearIcon
                className={'multi-select-tags'}
                dropdownStyle={props.showDropdown ? {} : { display: 'none' }}
                placeholder={props.placeholder}
                defaultValue={props.defaultValue}
                value={props.value}
                options={props.options}
                onChange={props.handleChange}
                onBlur={props.onBlur || ((e) => {})}
                ref={ref}
            >
                {props.children}
            </Select>
        </div>
    );
});

export default UiMultiSelect;
