import React from 'react';

import { ApiLabel } from '../api-label/ApiLabel';
import { UiModal } from '../ui-modal/UiModal';
import { UiTitle } from '../ui-title/UiTitle';
import { SingleCallDetails } from '../single-call-details/SingleCallDetails';
import { dateStringFormat, TimeDisplayResEnum, timeStringFormat } from '../../general/utils';
import { LabelList } from '../label-list/LabelList';

import './CallDetailsModal.scss';

export interface IApiDetailsModal {
    isVisible: boolean;
    toggleModal: Function;
    callData: any;
}

export const CallDetailsModal = (props: IApiDetailsModal) => {
    const timestamp = props.callData?.timestamp;

    return props.callData ? (
        <UiModal
            isVisible={props.isVisible}
            width="70vw"
            className="CallDetailsModal"
            onCancel={() => props.toggleModal()}
            acceptButton={{ text: 'OK', onClick: () => props.toggleModal() }}
            title={
                <div className="header">
                    <UiTitle
                        title={
                            <ApiLabel
                                apiType={props.callData?.method}
                                place="top"
                                apiName={props.callData?.endpoint_path}
                            />
                        }
                        type="large"
                    />
                    <UiTitle
                        title={`${dateStringFormat(timestamp)} | ${timeStringFormat(
                            timestamp,
                            TimeDisplayResEnum.MS
                        )} ${props.callData?.labels?.length ? ' |' : ''}`}
                        type="medium"
                    />
                    <LabelList labels={props.callData?.labels || []} />
                </div>
            }
        >
            <div className="single-call-container">
                <SingleCallDetails elementHeight={600} data={props.callData} />
            </div>
        </UiModal>
    ) : null;
};
