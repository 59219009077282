import React from 'react';
import { useHistory } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { UiButton } from 'components/button/Button';
import { checkFeature } from 'services/feature-toggle/feature-toggle-service';
import { gotoACC } from 'general/utils';
import { ActivationErrorIcon } from 'components/activationErrorIcon/ActivationErrorIcon';
import { useGetConfigQuery } from 'api/configApi';
import { ITenant, selectTenantList, currentTenantKeyChanged } from 'api/tenantListApi';

import './TenantDrawer.scss';

interface ITenantDrawerProps {
    visible: boolean;
    currentTenant?: ITenant;
    onClose: (e: any) => void;
}
export const TenantDrawer = ({ currentTenant, onClose, visible }: ITenantDrawerProps) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { data: config } = useGetConfigQuery(currentTenant?.key || skipToken);
    const tenantList = useSelector(selectTenantList);

    const navigationClicked = (tenant: ITenant) => () => {
        onClose(null);
        if (tenant.key !== currentTenant?.key) {
            dispatch(currentTenantKeyChanged({ tenantKey: tenant.key }));
            history?.push(`/${tenant.key}/home`);
        }
    };

    const getTenantList = () => {
        return (
            tenantList &&
            Object.values(tenantList).map((tenant) => {
                return (
                    <div
                        onClick={navigationClicked(tenant)}
                        key={tenant.key}
                        className={`tenant-container ${tenant.name === currentTenant?.name ? ' active ' : ''}`}
                    >
                        <div className="tenant-item">
                            <span>{tenant.name}</span>
                            <ActivationErrorIcon status={tenant?.latest_transaction_status} />
                        </div>
                    </div>
                );
            })
        );
    };

    return (
        <Drawer
            placement="left"
            width={300}
            closable={false}
            mask={true}
            onClose={onClose}
            className={'tenant-drawer'}
            getContainer={'.app-container'}
            visible={visible}
            keyboard
            zIndex={2000}
            title={'Switch to'}
            footer={
                checkFeature(process.env, currentTenant?.key || '', 'acc-management-url', undefined, config) && (
                    <div>
                        <span>Akamai Control Center</span>
                        <UiButton type="primary" text="Go to configuration" onClick={() => gotoACC()} />
                    </div>
                )
            }
        >
            <div className="list">{getTenantList()}</div>
        </Drawer>
    );
};
