import { configureStore, combineReducers, TypedStartListening, createListenerMiddleware } from '@reduxjs/toolkit';

import { serverApi, tenantApi, cmsApi } from 'api/baseServerApi';
import { listenerMiddleware, tenantSlice } from 'api/tenantListApi';

const rootReducer = combineReducers({
    [serverApi.reducerPath]: serverApi.reducer,
    [tenantApi.reducerPath]: tenantApi.reducer,
    [cmsApi.reducerPath]: cmsApi.reducer,
    [tenantSlice.name]: tenantSlice.reducer,
});

export const store = configureStore({
    reducer: rootReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .prepend(listenerMiddleware.middleware)
            .concat([serverApi.middleware, tenantApi.middleware, cmsApi.middleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch)
