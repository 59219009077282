import React from 'react';
import { colDefs, dummyFunctions } from '../service-summary/ServiceSummary';
import { UiTitle } from '../ui-title/UiTitle';
import { IDiscoveryEndpointResponse } from '../../interfaces/endpoint.interface';
import './EndpointExtendSummary.scss';

interface IProps {
    endpoint?: IDiscoveryEndpointResponse;
}

export const EndpointExtendSummary = ({ endpoint }: IProps) => {
    if (endpoint) {
        return (
            <div className="left-extend-container">
                {Object.entries(endpoint).map(function ([key, value], index) {
                    return colDefs.hasOwnProperty(key) ? (
                        <div className="summary-element">
                            <UiTitle title={colDefs[key].displayName} type="medium" />
                            <div className="value alerts-value">
                                <div className="text">
                                    <span>
                                        {(colDefs[key].renderer || dummyFunctions.renderer)(
                                            (colDefs[key].formatter || dummyFunctions.formatter)(value)
                                        )}
                                    </span>{' '}
                                    {colDefs[key].getIcon ? (
                                        <span className={'icon'}>
                                            {(colDefs[key].getIcon || dummyFunctions.getIcon)(value)}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : null;
                })}
            </div>
        );
    } else return null;
};
