import React, { ReactNode, useMemo } from 'react';
import { Badge } from 'antd';
import { datadogRum } from '@datadog/browser-rum';

import { Card } from '../../../../Card/Card';
import { gridCellGenerator } from '../../../../Card/gridCellGenerator';
import { fullDateStringFormat, isErrorTrackingEnabled } from '../../../../../general/utils';
import { collectorStateMachine, CollectorStates, ICollector, NodeImageStatus } from '../shared/collectorApis';
import { IDropdownOption, UiDropDown } from '../../../../ui-dropdown/UiDropDown';

import { UiTooltip } from '../../../../ui-tooltip/UiTooltip';
import './CollectorCard.scss';

const READABLE_STATES: Record<CollectorStates, string> = {
    [CollectorStates.enabled]: 'Enabled',
    [CollectorStates.disabled]: 'Disabled',
    [CollectorStates.pending_enabled]: 'Enabling...',
    [CollectorStates.pending_disabled]: 'Disabling...',
    [CollectorStates.errored]: 'Error',
    [CollectorStates.pending_deleted]: 'Scheduled for deletion...',
    [CollectorStates.deleted]: 'Deleted',
    [CollectorStates.applying_config]: 'Applying configuration...',
    [CollectorStates.upgrading]: 'Upgrading...',
};

const EMPTY_UUID = '00000000-0000-0000-0000-000000000000';

export interface IConfigCardProps {
    menu: ReactNode;
    collector: ICollector;
    collectorConfigsOptions?: IDropdownOption[];
    configNames: Record<string, string>;
    setAssignCollectorConfig: (assignConfig: { confId: string; collector: ICollector }) => void;
}

const subtitle = (
    cmGeneratedId: string,
    name: string,
    collectorState: CollectorStates,
    isHeartbeatActive: boolean,
    errorMsg?: string,
    nodeImageStatus?: NodeImageStatus
) => {
    const res: RegExpMatchArray | null | undefined = errorMsg?.match(/^\[(.+?)]\s+(.+)$/s);
    let errorTitle = '';
    let errorBody = '';
    if (res?.length === 3) {
        errorTitle = res[1];
        errorBody = res[2];
    } else if (errorMsg) {
        errorTitle = 'Error';
        errorBody = 'General error occurred';
        if (isErrorTrackingEnabled()) {
            const error = new Error(`Non parse-able error message for Collector '${name}'`);
            const errorContext = {
                collectorInContext: `collector name: ${name}, collector cmGeneratedId: ${cmGeneratedId}`,
                moreInformation:
                    'Trying to set error message for the user failed due to in badly formatted data sent from BE.',
                howToResolve:
                    'Switch to parse the texts on BE side and SEND AS A COUPLE OF EXTRA JSON PROPERTIES: errorTitle, errorBody.',
                origError: errorMsg,
            };

            datadogRum.addError(error, errorContext);
        }
    }

    return (
        collectorState && (
            <span className="state">
                <div
                    className={`circle node-${isHeartbeatActive ? collectorState : CollectorStates.deleted}${
                        nodeImageStatus === NodeImageStatus.failed ? ' node-image-failed' : ''
                    }`}
                />
                <span>{READABLE_STATES[collectorState]}</span>
                <span>
                    {!isHeartbeatActive && <span className="collector-state-description">(no heartbeat)</span>}
                    {nodeImageStatus === NodeImageStatus.creating && (
                        <span className="collector-state-description">(creating ISO image…)</span>
                    )}
                    {nodeImageStatus === NodeImageStatus.failed && (
                        <span className="collector-state-description">(failed creating ISO image)</span>
                    )}
                    {errorTitle && (
                        <UiTooltip title={errorBody} overlayClassName="double-width">
                            ({errorTitle})
                        </UiTooltip>
                    )}
                </span>
            </span>
        )
    );
};

const ips = (ipAddresses: string[]) => {
    const numOfIps = ipAddresses?.length;
    return (
        <UiTooltip title={numOfIps > 3 && ipAddresses.join(', ')}>
            {ipAddresses?.slice(0, 3).map((ip) => (
                <div key={ip} className="description">
                    {ip}
                </div>
            ))}
            {numOfIps > 3 && <Badge count={`+${numOfIps - 3}`} style={{ backgroundColor: 'var(--border)' }} />}
        </UiTooltip>
    );
};

export const CollectorCard = ({
    menu,
    collector,
    collectorConfigsOptions = [],
    configNames,
    setAssignCollectorConfig,
}: IConfigCardProps) => {
    const {
        cmGeneratedId,
        name,
        deploymentType,
        ipAddresses,
        lastReported,
        logsCollectedCount,
        logsCollectedKb,
        proxy,
        hostname,
        state,
        errorMsg,
        version,
        edges: { sources, nodeImage },
        currentConfigId,
        pendingConfigId,
        isHeartbeatActive,
    } = collector;

    const getConfigDefaultValue = () => {
        if (state === CollectorStates.disabled) {
            if (pendingConfigId && pendingConfigId !== EMPTY_UUID) {
                return configNames[pendingConfigId];
            } else if (currentConfigId) {
                return configNames[currentConfigId];
            }
            return null;
        } else if (state === CollectorStates.applying_config) {
            return configNames[pendingConfigId];
        }
        return currentConfigId;
    };

    const preparedOptionsForConfigDropdown: IDropdownOption[] = useMemo(() => {
        return collectorConfigsOptions.map((option) => {
            return option.value === collector.currentConfigId ? { ...option, disabled: true } : option;
        });
    }, [collector.currentConfigId, collectorConfigsOptions]);

    return (
        <Card
            title={name}
            menu={menu}
            isDisabled={state === CollectorStates.disabled || state === CollectorStates.deleted}
            subtitle={subtitle(cmGeneratedId, name, state, isHeartbeatActive, errorMsg, nodeImage?.status)}
        >
            <div className="card-collector-grid">
                {gridCellGenerator('id', cmGeneratedId)}
                {gridCellGenerator('type', deploymentType)}
                {gridCellGenerator('last seen', lastReported && fullDateStringFormat(lastReported, false))}
                {gridCellGenerator(
                    'Configuration',
                    <UiDropDown
                        options={preparedOptionsForConfigDropdown}
                        handleChange={(confId: string) => setAssignCollectorConfig({ confId, collector })}
                        placeholder="Select Configuration"
                        value={getConfigDefaultValue()}
                        width={180}
                        disabled={collectorStateMachine[state]?.isCanAssignConfig === undefined}
                    />
                )}
                {gridCellGenerator('IP addresses', ipAddresses && ips(ipAddresses))}
                {gridCellGenerator(
                    'Version',
                    collector.edges?.currentPackageVersion?.version || collector?.version || '--'
                )}
                {gridCellGenerator(
                    'Logs',
                    [
                        logsCollectedCount && logsCollectedCount + ' logs',
                        logsCollectedKb && ' | ' + logsCollectedKb + ' KB',
                    ]
                        .join('')
                        .trim()
                )}
                {gridCellGenerator('Source Name', sources.name)}
                {gridCellGenerator('outbound proxy', proxy)}
                {gridCellGenerator('Hostname', hostname)}
            </div>
        </Card>
    );
};
