import { httpDelete, httpGet, httpPOST, httpPut } from '../../../../../general/http-service';
import { AxiosResponse } from 'axios';
import { ICollector } from '../../collectors/shared/collectorApis';
import { IDataSourceType } from '../../../../../api/nodeApi';

export interface ICollectorsAppliedTo {
    collectorName: string;
    collectorId: string;
}

export interface ICollectorConfigurationConfigFile {
    id?: string | number; // default config files do not have an 'id' and it that case it'll get a running number
    fileName: string;
    fileContent: string;
    index: string;
    modified?: boolean;
}

export interface IDataSourceTypesCollectorConfig {
    edges: { data_source_type: IDataSourceType };
}

export interface ICollectorConfiguration {
    name: string;
    id: string;
    appliedTo?: ICollectorsAppliedTo[];
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    updatedBy: string;
    edges: {
        collector_config_files: ICollectorConfigurationConfigFile[];
        collectors?: ICollector[];
        data_source_types_of_collector_config?: IDataSourceTypesCollectorConfig[];
    };
    universalConfigTemplateVersion: string;
}

const cmsConfigBaseUrl = (tenant_key: string) => `organizations/${tenant_key}/cms/configurations`;

export const getConfigurations: (tenantKey: string) => Promise<ICollectorConfiguration[]> = async (tenantKey) => {
    return httpGet(`${cmsConfigBaseUrl(tenantKey)}`).then((res) => res.data);
};

export const getConfiguration: (tenantKey: string, configId: string) => Promise<ICollectorConfiguration> = async (
    tenantKey,
    configId
) => {
    return httpGet(`${cmsConfigBaseUrl(tenantKey)}/${configId}`).then((res) => res.data);
};

export const createCollectorConfig: (
    tenantKey: string,
    name: string,
    dataSourceFilenames: string[],
    universalConfigTemplateVersion: string,
    configurationFiles?: ICollectorConfigurationConfigFile[]
) => Promise<boolean> = async (
    tenantKey,
    name,
    dataSourceFilenames,
    universalConfigTemplateVersion,
    configurationFiles
) => {
    return httpPOST(`${cmsConfigBaseUrl(tenantKey)}`, {
        name,
        configurationFiles,
        dataSourceFilenames,
        universalConfigTemplateVersion,
    }).then((res) => res?.data?.result === 'success');
};

export const updateCollectorConfig: (
    tenant_key: string,
    configuration_id: string,
    name: string,
    configurationFiles: ICollectorConfigurationConfigFile[]
) => Promise<boolean> = async (tenant_key, configuration_id, name, configurationFiles) => {
    return httpPut(`${cmsConfigBaseUrl(tenant_key)}/${configuration_id}`, {
        name,
        configurationFiles,
    }).then((res: AxiosResponse) => {
        return res?.data?.result === 'success';
    });
};

export const cloneCollectorConfig: (
    tenantKey: string,
    configId: string,
    name: string,
    user: string
) => Promise<boolean> = async (tenantKey, configId, name, user) => {
    return httpPOST(`${cmsConfigBaseUrl(tenantKey)}?source=${configId}`, { name, user }).then((res) => {
        return res?.data?.result === 'success';
    });
};

export const deleteCollectorConfig: (tenantKey: string, configId: string) => Promise<AxiosResponse<any>> = async (
    tenantKey,
    configId
) => {
    return httpDelete(`${cmsConfigBaseUrl(tenantKey)}/${configId}`).then((res) => {
        return res;
    });
};

export const getDefaultConfigFiles: (tenantKey: string) => Promise<ICollectorConfigurationConfigFile[]> = async (
    tenantKey
) => {
    return httpGet(`${cmsConfigBaseUrl(tenantKey)}/default-config-files`).then((res) => res.data);
};
