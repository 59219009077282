import React from 'react';

import { Card } from 'components/Card/Card';
import { gridCellGenerator } from 'components/Card/gridCellGenerator';
import { IEntityDefinition } from 'api/entitiesApi';

import './EntityCard.scss';

export interface IEntityCardProps {
    entity: IEntityDefinition;
}

export const EntityCard = ({ entity }: IEntityCardProps) => {
    const { name, source_attribute, class: entityClass, family } = entity;

    return (
        <Card title={name}>
            <div className="card-entity-grid">
                {gridCellGenerator('family', family)}
                {gridCellGenerator('class', entityClass)}
                {gridCellGenerator('attribute', source_attribute)}
            </div>
        </Card>
    );
};
