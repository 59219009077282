import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { IServerQueryResponse } from './baseServerApi';

export const fetchAll = async <T>(
    uriPath: string,
    limit: number,
    baseQuery: Function
): Promise<{ data: IServerQueryResponse<T> } | { error: FetchBaseQueryError }> => {
    const fullResult: Partial<IServerQueryResponse<T>> = {
        count: 0,
        total: undefined,
        items: [],
    };
    let offset: number = 0;

    try {
        while (fullResult.total === undefined || fullResult.total > fullResult.count!) {
            const { data } = await baseQuery(uriPath + `&offset=${offset}&limit=${limit}`);
            fullResult.items?.push(...data.items);
            fullResult.count += data.count;
            fullResult.total = data.total;
            offset += limit;
        }
        return { data: fullResult as IServerQueryResponse<T> };
    } catch (e) {
        return { error: e as FetchBaseQueryError };
    }
};
