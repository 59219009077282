import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { UiAutoComplete } from '../../ui-autocomplete/UiAutoComplete';
import { UiIcon } from '../../icon/UiIcon';
import { QueryCondition } from '../query-condition/query-condition';
import { PAGE_SIZE_LIMIT, recursiveHttpGet } from 'general/http-service';
import { IQueryRowProps } from 'interfaces/query.interface';
import { groupEndpointsByService, urlEncode } from 'general/utils';
import { isValidRow } from '../query-builder';
import { selectCurrentTenantKey } from 'api/tenantListApi';

import './query-row.scss';

export const AUTO_COMPLETE_WIDTH = 366;

export interface ISelectOptionGrp {
    title: string;
    options: (IEndpointSelectOption | IAttributeSelectOption)[];
}

export interface IEndpointSelectOption {
    label: JSX.Element;
    key: string;
    value: string;
    endpointPath: string;
}

export interface IAttributeSelectOption {
    key: string;
    value: number;
    label: string;
}

export interface IStat {
    count: number;
    frequency: number;
    value: string;
}

export interface IAttribute {
    in: string;
    name: string;
    part_of: string;
    statistics: {
        count: number;
        frequency: number;
        low_cardinality: boolean;
        typical_value: String;
        value_statistics: IStat[];
    };
}

export const attrSortOrderObj = {
    part_of: {
        Request: 1,
        Response: 2,
        Attributes: 3,
        Attribute: 3,
    },
    in: {
        Path: 1,
        Query: 2,
        Headers: 3,
        Start_Line: 4,
        Cookies: 5,
        Log: 6,
        Enrichment: 7,
        Body: 8,
    },
};

export const sortAttrGroups = (groupedAttributes: ISelectOptionGrp[]) => {
    // extract the titles from the option group, sort these titles and rebuild the option group in the correct order
    const attrGroupTitles = groupedAttributes.map((attrGrp: ISelectOptionGrp) => attrGrp.title);

    attrGroupTitles.sort((a: any, b: any) => {
        // get order rank from sortOrderObj for a
        const a_title_part_of: string = a.split(' ')[0];
        const a_title_in: string = a.split(' ')[1];
        const a_part_of_sort_order: { [key: string]: number } = attrSortOrderObj.part_of;
        const a_part_of_in: { [key: string]: number } = attrSortOrderObj.in;
        const a_part_of_order = a_part_of_sort_order[a_title_part_of] || 10000;
        const a_in_order = a_part_of_in[a_title_in] || 10000;

        // for b
        const b_title_part_of: string = b.split(' ')[0];
        const b_title_in: string = b.split(' ')[1];
        const b_part_of_sort_order: { [key: string]: number } = attrSortOrderObj.part_of;
        const b_part_of_in: { [key: string]: number } = attrSortOrderObj.in;
        const b_title_part_of_order = b_part_of_sort_order[b_title_part_of] || 10000;
        const b_title_in_order = b_part_of_in[b_title_in] || 10000;

        return a_part_of_order - b_title_part_of_order || a_in_order - b_title_in_order;
    });

    const sortedGroups = attrGroupTitles.map((title) => {
        const attrGroup = groupedAttributes.find((group) => title === group.title);

        const options = attrGroup?.options as IAttributeSelectOption[];
        return {
            title,
            options: options.sort((a: IAttributeSelectOption, b: IAttributeSelectOption) =>
                a.label.toLowerCase().localeCompare(b.label.toLowerCase())
            ),
        };
    });
    return sortedGroups;
};

export const EndpointsSelectHandler = (
    value: string,
    currentOrg: string | undefined,
    callLimit: number,
    endpointsData: any[],
    timeRange: any,
    setQueryIsLoading: Function,
    setAttributes: Function,
    setGroupedAttributes: Function,
    setSelectedAutoComplete: Function
) => {
    const dropdownEl = window?.document?.activeElement as HTMLElement;
    dropdownEl.blur();

    let baseUrl: string;
    if (value === 'any_endpoint') {
        baseUrl = `organizations/${currentOrg}/discovery/attributes?limit=${callLimit}&from_timestamp=${timeRange.from_timestamp}&to_timestamp=${timeRange.to_timestamp}`;
    } else {
        const serviceName = endpointsData.find((e) => e.id === value)?.service_name;
        baseUrl = `organizations/${currentOrg}/discovery/services/${urlEncode(
            serviceName as string
        )}/endpoints/${value}/attributes?limit=${callLimit}&from_timestamp=${timeRange.from_timestamp}&to_timestamp=${
            timeRange.to_timestamp
        }`;
    }
    new Promise<any[]>((resolve, reject) => {
        setQueryIsLoading(true);
        recursiveHttpGet(baseUrl, 0, callLimit, [], resolve, reject);
    }).then((response: any[]) => {
        setQueryIsLoading(false); // group flatAttributes to dynamic groups '<part_of> <in>'
        const groupedAttributes = response.reduce<ISelectOptionGrp[]>(
            (optionGroup: ISelectOptionGrp[], currItem: IAttribute, idx) => {
                const currTitle = `${currItem.part_of} ${currItem.in}`;

                let nextOptionGrp = [...optionGroup];
                // find the index of the option group to update

                let optionGrpIndex = optionGroup.findIndex((c: ISelectOptionGrp) => c.title === currTitle);
                let optionGrp = optionGroup[optionGrpIndex];

                const singleOption: IAttributeSelectOption = {
                    key: idx.toString(),
                    value: idx,
                    label: currItem.name,
                };

                if (optionGrpIndex > -1) {
                    // we already created the option Grp
                    optionGrp.options.push(singleOption);
                    nextOptionGrp = [...nextOptionGrp];
                    nextOptionGrp[optionGrpIndex] = optionGrp;
                } else {
                    // creating the option Grp
                    optionGrp = {
                        title: currTitle,
                        options: [singleOption],
                    };
                    nextOptionGrp = [...nextOptionGrp, optionGrp];
                }

                return nextOptionGrp;
            },
            []
        );

        setAttributes(response);
        const sortedAttrGroups = sortAttrGroups(groupedAttributes);
        setGroupedAttributes(sortedAttrGroups);
    });
    setSelectedAutoComplete(value);
};

export const QueryRow = (props: IQueryRowProps) => {
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const [options, setOptions] = useState<any[]>([]);
    const [queryIsLoading, setQueryIsLoading] = useState<boolean>(true);
    const [groupedAttributes, setGroupedAttributes] = useState<any[]>([]);
    const [flatAttributes, setAttributes] = useState<any[]>([]);
    const addConditionRef = useRef(null);

    useEffect(() => {
        setOptions(groupEndpointsByService(props.endpointsData));
    }, [props.endpointsData]);

    useEffect(() => {
        props.queryRowObj?.defaultValue && onSelectedAutoCompleteOption(props.queryRowObj?.defaultValue);
    }, [props.queryRowObj?.defaultValue]);

    const onSetQueryIsLoading = (flag: boolean) => {
        setQueryIsLoading(flag);
    };

    const onSetAttributes = (response: any[]) => {
        setAttributes(response);
    };

    const onSetGroupedAttributes = (sortedAttrGroups: any[]) => {
        setGroupedAttributes(sortedAttrGroups);
    };

    const onSetSelectedAutoComplete = (value: any) => {
        props.onSelectedAutoComplete(value);
    };

    const onSelectedAutoCompleteOption = (value: string) => {
        EndpointsSelectHandler(
            value,
            currentTenantKey,
            PAGE_SIZE_LIMIT,
            props.endpointsData,
            props.timeRange,
            onSetQueryIsLoading,
            onSetAttributes,
            onSetGroupedAttributes,
            onSetSelectedAutoComplete
        );
    };

    return (
        <div className="query-row">
            <div className="default-element">
                <span className="default-element-title">{props.title}</span>
                <UiAutoComplete
                    options={options}
                    width={AUTO_COMPLETE_WIDTH}
                    value={props.queryRowObj?.defaultValue}
                    className={'query-endpoint-autocomplete'}
                    onOptionSelect={onSelectedAutoCompleteOption}
                    title={{
                        label: <div className="any-endpoint-option">Any Endpoint</div>,
                        value: 'any_endpoint',
                    }}
                    errorMessage={props.validation?.endpoint}
                    loading={options.length === 0}
                />
            </div>
            <div className="conditions">
                <QueryCondition
                    conditions={props.queryRowObj?.conditions}
                    onChange={(e: any, index: number) => props.onConditionsChanged(e, index)}
                    conditionsData={[flatAttributes, groupedAttributes]}
                    loading={queryIsLoading}
                    onHover={props.onHover}
                    validation={props.validation?.conditions}
                    onConditionRemove={(index: number) => props.onConditionRemove(index)}
                />
            </div>
            <div
                ref={addConditionRef}
                className={`add-condition ${!props.validation || isValidRow(props.validation) ? '' : 'disable'}`}
                onClick={(e: any) => props.onAddCondition()}
            >
                <UiIcon name="add" />
                <span className="icon-label">Add condition</span>
            </div>
        </div>
    );
};
