import React, { useRef } from 'react';
import './EntitySummary.scss';
import { UiIcon } from '../icon/UiIcon';
import { UiTitle } from '../ui-title/UiTitle';
import {
    convertTimestampToTimeAgo,
    fullDateStringFormat,
    getDiffFromNowInDays,
    isObjectEmpty,
} from '../../general/utils';
import { IEntityStatistics } from '../../interfaces/user.interface';
import { UiTooltip } from '../ui-tooltip/UiTooltip';
import { Detokenizable } from 'components/Detokenizable/Detokenizable';

interface IEntitySummaryProps {
    entityStatistics: IEntityStatistics | null | undefined;
}

export const maxDiffDaysTimeline = 121;

export function getLocationLine(entityInfo: IEntityStatistics['entity_info']): string {
    let firstLine = getFirstLocationLine(entityInfo);
    let secondLine = getSecondLocationLine(entityInfo);
    if (!firstLine && !secondLine) {
        return 'N/A';
    }
    return firstLine && secondLine ? `${firstLine} | ${secondLine}` : firstLine || secondLine;
}

export function getFirstLocationLine(entityInfo: IEntityStatistics['entity_info']): string {
    let firstLine: string = '';
    if (entityInfo.location_pcp && entityInfo.location_pcp_region) {
        firstLine = `${entityInfo.location_pcp} ${entityInfo.location_pcp_region}`;
    } else if (entityInfo.location_pcp || entityInfo.location_pcp_region) {
        firstLine = `${entityInfo.location_pcp || entityInfo.location_pcp_region}`;
    } else if (entityInfo.location_city || entityInfo.location_region || entityInfo.location_country_code) {
        const cityString = entityInfo.location_city ? `${entityInfo.location_city}, ` : '';
        const regionString = entityInfo.location_region ? `${entityInfo.location_region}, ` : '';
        const countryCodeString = entityInfo.location_country_code || 'N/A';
        firstLine = `${cityString}${regionString}${countryCodeString}`;
    }
    return firstLine;
}

export function getSecondLocationLine(entityInfo: IEntityStatistics['entity_info']): string {
    if (!entityInfo.location_as_name && !entityInfo.location_asn) {
        return '';
    }
    if (!entityInfo.location_as_name) {
        return `ASN ${entityInfo.location_asn}`;
    }
    return `${entityInfo.location_as_name}${entityInfo.location_asn ? ' (ASN ' + entityInfo.location_asn + ')' : ''}`;
}

//////////////////////////////// Component //////////////////////////////////

export const EntitySummary = ({ entityStatistics }: IEntitySummaryProps) => {
    const noOfCols = useRef<number>(5);
    if (entityStatistics) {
        let typicalUserAgent = null;
        if (entityStatistics?.hasOwnProperty('typical_user_agent')) {
            typicalUserAgent = entityStatistics.typical_user_agent !== '' ? entityStatistics.typical_user_agent : 'N/A';
            noOfCols.current = 6;
        }

        const colWidthClass = noOfCols.current === 6 ? 'six-cols' : 'five-cols';

        const getLocationOrEntitiesInfoItem = () => {
            // here will come a conditional returning the Location or Related Entities Component
            return (
                <div className={`info-item info-item-location ${colWidthClass}`}>
                    <UiTitle
                        title={entityStatistics.entity_type === 'IP' ? 'Location' : 'Typical Location'}
                        type="medium"
                    />
                    <UiTooltip title={getLocationLine(entityStatistics.entity_info)}>
                        <div className="value location-value">
                            <UiIcon name="location16" />
                            <div className="text">{getLocationLine(entityStatistics.entity_info)}</div>
                        </div>
                    </UiTooltip>
                </div>
            );
        };

        return (
            <div className="user-summary-container">
                {isObjectEmpty(entityStatistics) && (
                    <>
                        <div className={`info-item info-item-id ${colWidthClass}`}>
                            <UiTitle title={entityStatistics.entity_type} type="medium" />
                            <div className="value id-value">
                                <Detokenizable
                                    tokenizedContent={entityStatistics.entity_id}
                                    renderTooltip={(content) => content}
                                    renderContent={(content) => (
                                        <div className="value ip-value">
                                            <ns-truncate chars={30} content={content} />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className={`info-item info-item-alerts ${colWidthClass}`}>
                            <UiTitle title="OPEN ALERTS" type="medium" />
                            <div className="value alerts-value">
                                <UiIcon name="bell16" />
                                <div className="text">{entityStatistics.open_alerts}</div>
                            </div>
                        </div>
                        {getLocationOrEntitiesInfoItem()}
                        {typicalUserAgent && (
                            <div className={`info-item info-item-user-agent ${colWidthClass}`}>
                                <UiTitle title={'Typical User Agent'} type="medium" />
                                <UiTooltip title={typicalUserAgent}>
                                    <div className="value user-agent-value">
                                        <UiIcon name="userAgent16" />
                                        <div className="text">{typicalUserAgent}</div>
                                    </div>
                                </UiTooltip>
                            </div>
                        )}
                        <div className={`info-item info-item-first-seen ${colWidthClass}`}>
                            <UiTitle title="FIRST SEEN" type="medium" />
                            <UiTooltip
                                title={
                                    entityStatistics.first_seen
                                        ? getDiffFromNowInDays(entityStatistics.first_seen) > maxDiffDaysTimeline
                                            ? ''
                                            : fullDateStringFormat(entityStatistics.first_seen, false)
                                        : 'No accurate timestamp yet'
                                }
                            >
                                <div className="value first-seen-value">
                                    <UiIcon name="calendar16" />
                                    <div className="text">
                                        {' '}
                                        {entityStatistics.first_seen
                                            ? convertTimestampToTimeAgo(
                                                  entityStatistics.first_seen,
                                                  maxDiffDaysTimeline
                                              )
                                            : 'Today'}
                                    </div>
                                </div>
                            </UiTooltip>
                        </div>
                        <div className={`info-item info-item-last-seen ${colWidthClass}`}>
                            <UiTitle title="LAST SEEN" type="medium" />
                            <UiTooltip
                                title={
                                    entityStatistics.last_seen
                                        ? getDiffFromNowInDays(entityStatistics.last_seen) > maxDiffDaysTimeline
                                            ? ''
                                            : fullDateStringFormat(entityStatistics.last_seen, false)
                                        : 'No accurate timestamp yet'
                                }
                            >
                                <div className="value last-seen-value">
                                    <UiIcon name="calendar16" />
                                    <div className="text">
                                        {' '}
                                        {entityStatistics.last_seen
                                            ? convertTimestampToTimeAgo(entityStatistics.last_seen, maxDiffDaysTimeline)
                                            : 'Last 10 minutes'}
                                    </div>
                                </div>
                            </UiTooltip>
                        </div>
                    </>
                )}
            </div>
        );
    } else return null;
};
