import { useEffect, useState } from 'react';
import { AutoComplete } from 'antd';

interface Option {
    label: string;
    value: string;
}

interface UiSingleCustomOptionSelectProps {
    options: Option[];
    disabled?: boolean;
    onChange?: (selectedValue: string) => void;
}

export const UiSingleCustomOptionSelect = (props: UiSingleCustomOptionSelectProps) => {
    const [options, setOptions] = useState<Option[]>(props.options);

    useEffect(() => {
        setOptions(props.options);
    }, [props.options]);

    const addOption = (customOptionValue: string) => {
        const existingIndex = options.findIndex((option) => option.value === customOptionValue) > -1;
        const customUrlExistsInList = options.length > props.options.length;

        props.onChange?.(customOptionValue);

        if (customOptionValue && !existingIndex) {
            const urlOptionsSetter = (prevOptions: typeof options) => {
                const newOptions = customUrlExistsInList ? prevOptions.slice(1) : prevOptions;
                return [{ label: customOptionValue, value: customOptionValue }, ...(newOptions || [])];
            };

            return setOptions(urlOptionsSetter);
        }

        if ((customOptionValue === '' || existingIndex) && customUrlExistsInList) {
            return removeCustomOption();
        }
    };

    const removeCustomOption = () => {
        setOptions((prev) => prev.slice(1));
    };

    const onSelect = (selectedValue: string) => {
        const existingIndex = options.findIndex((option) => option.value === selectedValue);
        const customUrlExistsInList = options.length > props.options.length;

        if (existingIndex > 0 && customUrlExistsInList) {
            removeCustomOption();
        }

        props.onChange?.(selectedValue);
    };

    return (
        <AutoComplete
            options={options}
            filterOption={false}
            onSearch={(customOptionValue) => addOption(customOptionValue)}
            onSelect={onSelect}
            disabled={props.disabled}
        />
    );
};
