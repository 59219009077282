import React, { useState } from 'react';
import { Divider, Space, TreeSelect } from 'antd';
import { UiButton } from '../button/Button';
import './TagMultiSelect.scss';
import { LabelValue } from '../../interfaces/labels.interface';

interface ITagMultiSelectProps {
    options: LabelValue[];
    placeholder: string;
    onChange: Function;
    disabled?: boolean;
    value: LabelValue[];
}

export const TagMultiSelect = ({ options, placeholder, onChange, disabled, value }: ITagMultiSelectProps) => {
    const [selectedOptions, setSelectedOptions] = useState<LabelValue[]>(value);
    const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
    const updateSelectedOptions = (items: any) => {
        setSelectedOptions(items);
        onChange(items);
    };

    const selectAll = () => {
        updateSelectedOptions(options.map((item: LabelValue) => item.value));
    };
    const selectProps = {
        placeholder,
        disabled,
        maxTagTextLength: 18,
        maxTagCount: 3,
        showArrow: true,
        open: isSelectOpen,
        value: selectedOptions,
        mode: 'multiple' as const,
        size: 'large' as const,
        dropdownClassName: 'tag-multi-select-dropdown',
        onChange: updateSelectedOptions,
        onDropdownVisibleChange: setIsSelectOpen,
        maxTagPlaceholder: (val: any) => '+' + val.length,
        dropdownRender: (menu: any) => (
            <>
                <Space align="start" className={'multi-select-button-panel'}>
                    <UiButton type="text" text={'Select All'} onClick={() => selectAll()} />
                </Space>
                <Divider />
                {menu}
                <Divider />
                <Space align="center" className={'multi-select-button-panel'}>
                    <UiButton
                        type="text"
                        text={'Clear all'}
                        onClick={() => {
                            updateSelectedOptions([]);
                        }}
                    />
                    <UiButton type="primary" text={'Apply'} onClick={() => setIsSelectOpen(false)} />
                </Space>
            </>
        ),
        treeNodeFilterProp: 'title',
    };
    return <TreeSelect {...selectProps} className={'tag-multi-select'} treeData={options} multiple treeCheckable />;
};
