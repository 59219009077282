import { IServerQueryResponse, tenantApi } from './baseServerApi';

export type TEntityFamily = 'actor' | 'business';

export interface IEntityDefinition {
    source_attribute: string;
    index: number;
    name: string;
    pretty_name: string;
    class: string;
    family: TEntityFamily;
    value_type: string;
    column_name: string;
}

export type INewEntityDefinition = Pick<
    IEntityDefinition,
    'name' | 'class' | 'family' | 'value_type' | 'source_attribute'
>;

export interface IEntityClass {
    class_name: string;
    family: TEntityFamily;
}

export const entityManagementApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        getEntities: builder.query<IServerQueryResponse<IEntityDefinition>, string>({
            query: (tenant) => `${tenant}/entities`,
        }),
        postEntity: builder.mutation<IEntityDefinition, { tenant: string; body: INewEntityDefinition }>({
            query: ({ tenant, body }) => ({
                url: `${tenant}/entities`,
                method: 'POST',
                body,
            }),
        }),
        getClasses: builder.query<IServerQueryResponse<IEntityClass>, { tenant: string; family: TEntityFamily }>({
            query: ({ tenant, family }) => `${tenant}/entity_classes?family_name=${family}`,
        }),
    }),
    overrideExisting: false,
});

export const useGetEntitiesQuery = entityManagementApi.endpoints.getEntities.useQuery;
export const usePostEntityMutation = entityManagementApi.endpoints.postEntity.useMutation;
export const useGetClassesQuery = entityManagementApi.endpoints.getClasses.useQuery;
