import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Divider } from 'antd';
import moment from 'moment/moment';

import { IScan, useGetFindingTypeQuery, useGetScanQuery } from 'api/testingApi';
import { BreadCrumb } from 'components/breadcrumb/BreadCrumb';
import { ExpandablePanel } from 'components/ExpandablePanel/ExpandablePanel';
import { UiIcon } from 'components/icon/UiIcon';
import { UiLabel } from 'components/label/UiLabel';
import { UiTitle } from 'components/ui-title/UiTitle';
import { UiTooltip } from 'components/ui-tooltip/UiTooltip';
import { ITestingPathParams } from 'components/Testing/Testing';
import { FindingsTable } from 'components/Testing/FindingTypeDetails/FindingsTable/FindingsTable';

import './FindingTypeDetails.scss';

export interface IFindingTypeDetailsParams extends ITestingPathParams {
    scanId: string;
    findingTypeId: string;
}

const scanDetailsTooltip = (
    scanDetails: Pick<IScan, 'scan_id' | 'application_name' | 'target_url' | 'triggered_by' | 'scan_engine'>
) => (
    <div className="scan-details-tooltip">
        <div className="scan-details-title">Scan</div>
        <div className="scan-details-content">
            <UiTitle title="ID" type="medium" />
            <UiTooltip title={scanDetails?.scan_id}>{scanDetails?.scan_id}</UiTooltip>
            <UiTitle title="APPLICATION" type="medium" />
            <UiTooltip title={scanDetails?.application_name}>{scanDetails?.application_name}</UiTooltip>
            <UiTitle title="TARGET URL" type="medium" />
            <UiTooltip title={scanDetails?.target_url}>{scanDetails?.target_url}</UiTooltip>
            <UiTitle title="TRIGGERED BY" type="medium" />
            <UiTooltip title={scanDetails?.triggered_by}>{scanDetails?.triggered_by}</UiTooltip>
            <UiTitle title="SCAN ENGINE" type="medium" />
            <UiTooltip title={scanDetails?.scan_engine}>{scanDetails?.scan_engine}</UiTooltip>
        </div>
    </div>
);

export const FindingTypeDetails = () => {
    const { activeOrg, scanId, findingTypeId } = useParams<IFindingTypeDetailsParams>();
    const [isTopBarExpanded, setIsTopBarExpanded] = useState(false);
    const childRef = useRef<HTMLDivElement>(null);
    const { data: scanData } = useGetScanQuery({ tenant: activeOrg, scanId });
    const { data: findingTypeData } = useGetFindingTypeQuery({
        tenant: activeOrg,
        scanId,
        findingTypeId: findingTypeId,
    });
    const topBarContentRef = useRef<(HTMLDivElement | null)[]>([]);

    useEffect(() => {
        topBarContentRef.current.forEach((element) => {
            element && element.scrollTo(0, 0);
        });
    }, [isTopBarExpanded]);

    return (
        <div className="FindingTypeDetails">
            <BreadCrumb
                isPassParams={true}
                breadcrumbList={[
                    {
                        url: `/${activeOrg}/testing/scans`,
                        text: 'Scans',
                    },
                    {
                        url: `/${activeOrg}/testing/scans/${scanId}/`,
                        text: `Scan ${scanId}`,
                        tooltip: scanDetailsTooltip(
                            scanData as Pick<
                                IScan,
                                'scan_id' | 'application_name' | 'target_url' | 'triggered_by' | 'scan_engine'
                            >
                        ),
                    },
                    {
                        url: `/${activeOrg}/testing/scans/${scanId}/findingTypes/${findingTypeId}/`,
                        text: findingTypeData?.name as string,
                    },
                ]}
                selectedRoute={findingTypeData?.name as string}
            />
            <div className="page-header">
                <UiTitle title={findingTypeData?.name as string} type="large" />
                <div className="page-subheader">
                    <span>{findingTypeData?.test_id}</span>
                    <Divider type="vertical" />
                    <span>{moment(scanData?.created_at).format('D MMMM YYYY, HH:mm:ss')}</span>
                    <Divider type="vertical" />
                    <UiIcon name={`alert${findingTypeData?.severity}`} />
                    {findingTypeData?.labels && <Divider type="vertical" />}
                    <div className="labels-list">
                        {(() => {
                            const ret_val: JSX.Element[] | undefined = findingTypeData?.labels
                                ?.slice(0, 3)
                                .map((label, index) => <UiLabel text={label} key={'label' + index} />);
                            findingTypeData?.labels &&
                                findingTypeData?.labels?.length > 3 &&
                                ret_val &&
                                ret_val.push(
                                    <UiLabel
                                        key="labelPlus"
                                        text={`+${findingTypeData?.labels.length - 3} Tags`}
                                        moreLabel
                                    />
                                );
                            return findingTypeData?.labels && ret_val;
                        })()}
                    </div>
                </div>
            </div>
            <ExpandablePanel
                childRef={childRef}
                expandedHeight={350}
                contractedHeight={200}
                isExpanded={isTopBarExpanded}
                setExpanded={setIsTopBarExpanded}
            >
                <div className="expandable-content" ref={childRef}>
                    <div
                        className={`expandable-content-section${isTopBarExpanded ? ' expanded' : ''}`}
                        ref={(element) => (topBarContentRef.current[0] = element)}
                    >
                        <UiTitle title="Description" type="medium" />
                        <p className="ftd-description">
                            {<ReactMarkdown children={findingTypeData ? findingTypeData.description : ''} />}
                        </p>
                        <UiTitle title="References" type="medium" />
                        <div className="ftd-references">
                            {findingTypeData?.references ? (
                                <ReactMarkdown children={findingTypeData.references} />
                            ) : (
                                <span>-</span>
                            )}
                        </div>
                    </div>
                    <div
                        className={`expandable-content-section${isTopBarExpanded ? ' expanded' : ''}`}
                        ref={(element) => (topBarContentRef.current[1] = element)}
                    >
                        <UiTitle title="Solution Recommendation" type="medium" />
                        <p className="ftd-solution-recommendation">
                            <ReactMarkdown children={findingTypeData ? findingTypeData.solution : ''} />
                        </p>
                    </div>
                </div>
            </ExpandablePanel>
            <div className="findings-table">
                <FindingsTable />
            </div>
        </div>
    );
};
