import React from 'react';
import './Switch.scss';
import {Switch} from 'antd';

interface ISwitchProps {
    text?: string
    onChange?: any;
    defaultChecked?: boolean
}

export const UiSwitch = ({onChange, defaultChecked, text}: ISwitchProps) => {


    return (
        <span className="ui-switch">
            <Switch size="small" defaultChecked={defaultChecked} onChange={onChange}/>
            <span className="switch-text">{text}</span>
        </span>
    );
};
