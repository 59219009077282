import { httpGet, httpPatch, httpPOST, httpDelete } from '../../../../../general/http-service';
import { AxiosResponse } from 'axios';

export interface IDataSource {
    id: string;
    name: string;
    numberOfCollectors: number;
    numberOfSensors: number;
    createdAt: string;
    updatedAt: string;
}

const getDataSourcesApiBaseUrl = (tenantKey: string) => {
    return `organizations/${tenantKey}/cms/sources`;
};

export const getDataSources: (tenantKey: string) => Promise<IDataSource[]> = async (tenantKey) => {
    return httpGet(`${getDataSourcesApiBaseUrl(tenantKey)}`).then((res) => res.data);
};

export const getDataSource: (tenantKey: string, dsId: string) => Promise<IDataSource> = async (tenantKey, dsId) => {
    return httpGet(`${getDataSourcesApiBaseUrl(tenantKey)}/${dsId}`).then((res) => res.data);
};

export const createDataSource: (tenantKey: string, name: string) => Promise<boolean> = async (tenantKey, name) => {
    return httpPOST(`${getDataSourcesApiBaseUrl(tenantKey)}`, {
        name: name,
    }).then((res) => {
        return res?.data?.result === 'success';
    });
};

export const updateDataSource: (tenantKey: string, ds: IDataSource) => Promise<boolean> = async (tenantKey, ds) => {
    return httpPatch(`${getDataSourcesApiBaseUrl(tenantKey)}/${ds.id}`, {
        name: ds.name,
    }).then((res) => {
        return res?.data?.result === 'success';
    });
};

export const deleteDataSource: (tenantKey: string, sourceId: string) => Promise<AxiosResponse<any>> = async (
    tenantKey,
    sourceId
) => {
    return httpDelete(`organizations/${tenantKey}/cms/sources/${sourceId}`).then((res) => res);
};
