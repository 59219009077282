import React, { SyntheticEvent } from 'react';
import './SwaggerDownloadButton.scss';
import { httpPOST } from '../../general/http-service';
import { formatDateTimeString } from '../../general/utils';
import { saveAs } from 'file-saver';
import { errorMessage, successMessage } from '../../general/toast-service';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import JSZip from 'jszip';
import { UiIcon } from '../icon/UiIcon';

export type IServiceMultiSelectValue = any;

export interface ISwaggerDownloadButtonProps {
    items: any[];
    tenant: string;
    fromTimestamp: number;
    toTimestamp: number;
}

export const SwaggerDownloadButton: React.FC<ISwaggerDownloadButtonProps> = (props: ISwaggerDownloadButtonProps) => {
    const { items, tenant, fromTimestamp, toTimestamp } = props;
    const buttonOnClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        if (items.length > 1) {
            downloadListItemsSwagger();
            return;
        }
        downloadSingleServiceSwagger();
        return;
    };

    const downloadListItemsSwagger = () => {
        const dateTime = formatDateTimeString(new Date());
        let zip = new JSZip();
        httpPOST(`organizations/${tenant}/discovery/services/export`, {
            service_names: items,
            from_timestamp: fromTimestamp,
            to_timestamp: toTimestamp,
        })
            .then((res) => {
                res.data.swaggers.forEach((swaggerJson: any, index: number) => {
                    let blob = new Blob([JSON.stringify(swaggerJson.data, null, 2)], {
                        type: 'application/json;charset=utf-8',
                    });
                    zip.file(`${swaggerJson.service_name}.json`, blob);
                });
                zip.generateAsync({ type: 'blob' }).then(function (content) {
                    saveAs(content, `${dateTime}_neosec_${tenant}_swagger.zip`);
                });
                successMessage('Swagger file generated successfully');
            })
            .catch((err) => {
                errorMessage('Error retrieving Swagger file');
                console.error(`Error retrieving Swagger file ${err}`);
            });
    };

    const downloadSingleServiceSwagger = () => {
        httpPOST(`organizations/${tenant}/discovery/services/export`, {
            service_names: items,
            from_timestamp: fromTimestamp,
            to_timestamp: toTimestamp,
        })
            .then((res) => {
                const dateTime = formatDateTimeString(new Date());
                const blob = new Blob([JSON.stringify(res.data.swaggers[0].data, null, 2)], {
                    type: 'application/json;charset=utf-8',
                });
                saveAs(blob, `${dateTime}_neosec_${tenant}_swagger.json`);
                successMessage('Swagger file generated successfully');
            })
            .catch((err) => {
                errorMessage('Error retrieving Swagger file');
                console.error(`Error retrieving Swagger file ${err}`);
            });
    };

    return (
        <div className="swagger-download-button">
            <Button
                type="primary"
                icon={<UiIcon name="swagger" />}
                className="swagger-title"
                onClick={buttonOnClick}
                disabled={items.length < 1}
            >
                Swagger{items.length > 1 ? `s (${items.length})` : ''}
            </Button>
        </div>
    );
};
