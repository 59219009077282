import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import Spinner from '../../containers/spinner/Spinner';
import { httpAll, httpGet } from 'general/http-service';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import { ITimelineEvent } from 'interfaces/user.interface';
import { UiButton } from '../button/Button';
import { UiSwitch } from '../switch/Switch';
import { UiTimeline } from './UiTimeline/UiTimeline';
import { selectCurrentTenantKey } from 'api/tenantListApi';

import './EntityTimeline.scss';

interface ITimelineProps {
    onItemClicked: Function;
    entityType: string;
    eventId: string;
    eventType: string;
    onAlertsOnlyChecked: Function;
    alertsOnly: boolean;
    eventTimestamp: number;
}

interface INoMoreData {
    top: boolean;
    bottom: boolean;
}

export const EntityTimeline = (props: ITimelineProps) => {
    const currentTenantKey = useSelector(selectCurrentTenantKey);

    const [timelineData, setTimelineData] = useState<{
        allData: ITimelineEvent[];
        displayData: ITimelineEvent[];
        itemIds: { [itemId: string]: boolean };
    }>({
        allData: [],
        displayData: [],
        itemIds: {},
    });
    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [checkedItems, setCheckedItems] = useState<ITimelineEvent[]>([]);
    const [noMoreData, setNoMoreData] = useState<INoMoreData>({ top: false, bottom: false });
    const [loadExtraData, setLoadExtraData] = useInfiniteScroll(getMoreData, '.custom-timeline');
    const [currentAnchor, setCurrentAnchor] = useState<{ id: string; isTop: boolean }>({ id: '', isTop: true });
    const limit = 30;

    useEffect(() => {
        if (props.entityType && props.eventId) {
            setIsLoading(true);
            httpGet(
                `organizations/${currentTenantKey}/investigate/timeline?anchor_event_type=${
                    props.eventType
                }&anchor_id=${props.eventId}&anchor_ts=${moment(props.eventTimestamp).valueOf()}&entity_name=${
                    props.entityType
                }&before=${limit}&after=${limit}${props.alertsOnly ? '&event_types=Alert' : ''}`
            )
                .then((res: any) => {
                    setIsLoading(false);

                    if (res.data.count_before < limit) {
                        setNoMoreData((prev) => ({ ...prev, top: true }));
                    } else {
                        setNoMoreData((prev) => ({ ...prev, top: false }));
                    }
                    if (res.data.count_after < limit) {
                        setNoMoreData((prev) => ({ ...prev, bottom: true }));
                    } else {
                        setNoMoreData((prev) => ({ ...prev, bottom: false }));
                    }

                    const tempData = res.data.items.length ? sortByTimestamp(res.data.items) : [];
                    const itemIds: { [itemId: string]: boolean } = {};
                    tempData.forEach((item: ITimelineEvent) => {
                        itemIds[item.id] = true;
                    });
                    setTimelineData({ allData: tempData, displayData: tempData, itemIds });

                    const selectedEvent = tempData.find((event) => event.id === props.eventId);
                    if (!selectedEvent) {
                        props.onItemClicked(null);
                    }

                    setIsDataLoaded(true);
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log(`error getting timeline data:`, err);
                });
        }
    }, [props.alertsOnly]);

    useEffect(() => {
        const anchorElement: any = document.getElementsByClassName(`tl_${currentAnchor.id}`);
        currentAnchor.id &&
            anchorElement[0]?.scrollIntoView({
                behavior: 'auto',
                block: currentAnchor.isTop ? 'start' : 'end',
                inline: 'center',
            });
    }, [timelineData]);

    function getMoreData(isTop: boolean) {
        if (isTop ? noMoreData.top : noMoreData.bottom) {
            setLoadExtraData((prevState: any) => ({ ...prevState, isLoading: false }));
            return;
        }

        setIsLoading(true);
        let timelineEvent;
        let before;
        let after;

        if (isTop) {
            timelineEvent = timelineData.allData[0];
            before = limit;
            after = 0;
        } else {
            timelineEvent = timelineData.allData[timelineData.allData.length - 1];
            before = 0;
            after = limit;
        }
        const anchorEventType = timelineEvent.event_type;
        const anchorId = timelineEvent.id;
        setCurrentAnchor({ id: anchorId, isTop });
        const anchorTs = moment(timelineEvent.timestamp).format('x');

        httpGet(
            `organizations/${currentTenantKey}/investigate/timeline?anchor_event_type=${anchorEventType}&anchor_id=${anchorId}&anchor_ts=${anchorTs}&entity_name=${
                props.entityType
            }&before=${before}&after=${after}${props.alertsOnly ? '&event_types=Alert' : ''}`
        )
            .then(({ data }) => {
                setIsLoading(false);

                let filteredItems = data.items.filter((newItem: ITimelineEvent) => {
                    // remove duplicate items from list
                    return !timelineData.itemIds[newItem.id];
                });
                filteredItems = filteredItems.length ? sortByTimestamp(filteredItems) : [];
                const itemIds: { [itemId: string]: boolean } = {};
                filteredItems.forEach((item: ITimelineEvent) => {
                    itemIds[item.id] = true;
                });

                setLoadExtraData((prevState: any) => ({ ...prevState, isLoading: false }));
                if (
                    (data.count_before < limit && data.count_after === 0) ||
                    (data.count_before === 0 && data.count_after < limit)
                ) {
                    // Register all data loaded at top/bottom to stop further data loading in that direction
                    isTop
                        ? setNoMoreData((prev) => ({ ...prev, top: true }))
                        : setNoMoreData((prev) => ({ ...prev, bottom: true }));
                }
                setTimelineData((prevData: any) => ({
                    displayData: isTop
                        ? [...filteredItems, ...prevData.displayData]
                        : [...prevData.displayData, ...filteredItems],
                    allData: isTop ? [...filteredItems, ...prevData.allData] : [...prevData.allData, ...filteredItems],
                    itemIds: { ...prevData.itemIds, ...itemIds },
                }));
            })
            .catch((err: any) => {
                console.log('err', err);
                setIsLoading(false);
                setLoadExtraData((prevState: any) => ({ ...prevState, isLoading: false }));
            });
    }

    const onItemSelectHandler = useCallback(
        (timelineItem: ITimelineEvent) => {
            props.onItemClicked(timelineItem.event_type, timelineItem.id, moment(timelineItem.timestamp).valueOf());
        },
        [props.onItemClicked]
    );

    const switchChangeHandler = (checked: boolean) => {
        props.onAlertsOnlyChecked(checked);
    };

    const sortByTimestamp = (arr: ITimelineEvent[]) => {
        const sortedArray = [...arr];
        return sortedArray.sort((a, b) => {
            const aDate = new Date(a.timestamp).getTime();
            const bDate = new Date(b.timestamp).getTime();
            return aDate - bDate;
        });
    };

    const onItemChecked = useCallback(
        (isChecked: any, timelineItem: ITimelineEvent) => {
            let newCheckedItems = [];
            if (isChecked) {
                newCheckedItems = sortByTimestamp([...checkedItems, timelineItem]);
            } else {
                newCheckedItems = checkedItems.filter((item) => item.id !== timelineItem.id);
            }
            setCheckedItems(newCheckedItems);
        },
        [setCheckedItems, checkedItems]
    );

    const query = useCallback(() => {
        const urls = checkedItems.map(
            (timelineEvent) =>
                `organizations/${currentTenantKey}/calls/${timelineEvent.id}?call_ts=${moment(
                    timelineEvent.timestamp
                ).valueOf()}`
        );
        const timestamp = checkedItems[0]?.timestamp;
        httpAll(urls)
            .then((resArray: any) => {
                const endpointsArray = resArray.map((endpoint: any) => endpoint.data.endpoint_id);
                const search =
                    `endpointIds=${encodeURIComponent(JSON.stringify(endpointsArray))}` +
                    `&entityType=${encodeURIComponent(props.entityType)}` +
                    `&timestamp=${encodeURIComponent(timestamp)}`;

                window.open(`${process.env.REACT_APP_REDIRECT_SIGN_IN}/${currentTenantKey}/query/?${search}`)?.focus();
            })
            .catch((err: any) => console.log('err', err));
    }, [checkedItems]);

    const header = 'Timeline';

    return (
        <div className="EntityTimeline">
            <div className="timeline-header">
                <span className="timeline-header-title">{header}</span>
                <UiButton text="Go to Query" type="primary" onClick={query} disabled={!checkedItems.length} />
            </div>
            <div className="switch-container">
                <UiSwitch defaultChecked={props.alertsOnly} onChange={switchChangeHandler} text="Alerts only" />
            </div>
            <div className="custom-timeline">
                <UiTimeline
                    timelineData={timelineData.displayData}
                    isLoaded={isDataLoaded}
                    checkedItems={checkedItems}
                    selectedItemId={props.eventId}
                    onItemClicked={onItemSelectHandler}
                    onItemChecked={onItemChecked}
                    reachedTimelineStart={noMoreData.top}
                    reachedTimelineEnd={noMoreData.bottom}
                />
            </div>
            <div className={`spinner-wrapper ${isLoading ? '' : 'hidden'}`}>
                <Spinner show={true} />
            </div>
        </div>
    );
};
