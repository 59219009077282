import moment, { Moment } from 'moment';
import { skipToken } from '@reduxjs/toolkit/query';

import { IScan, useDeleteScanMutation, useGetScanQuery } from '../../../../api/testingApi';
import { errorMessage, successMessage } from '../../../../general/toast-service';
import { extractErrorMessage } from '../../../../general/utils';
import { UiModal } from '../../../ui-modal/UiModal';

import './ScanDeleteConfirmationModal.scss';

export interface IScanDeleteConfirmationModalProps {
    activeOrg: string;
    scanId?: string;
    onClose: () => void;
    acceptButton: { text: string; onClick: Function; disabled?: boolean };
    rejectButton: { text: string; onClick: Function; disabled?: boolean };
}

const getLatestTimestamp = (scan?: IScan) => {
    if (!scan) {
        return '';
    }
    const { started_at, ended_at, queued_at, created_at } = scan;
    return [started_at, ended_at, queued_at, created_at]
        .filter(Boolean)
        .map((value) => moment(value))
        .sort((a: Moment, b: Moment) => a.unix() - b.unix())[0]
        .format('D MMMM YYYY, HH:mm:ss');
};

export const ScanDeleteConfirmationModal = ({
    activeOrg,
    scanId,
    onClose,
    acceptButton,
    rejectButton,
}: IScanDeleteConfirmationModalProps) => {
    const { data: scan } = useGetScanQuery(!!scanId ? { tenant: activeOrg, scanId } : skipToken);
    const [deleteScan] = useDeleteScanMutation();

    return (
        <UiModal
            title="Delete Scan"
            isVisible={!!scanId}
            icon="trash"
            onCancel={onClose}
            acceptButton={{
                ...acceptButton,
                onClick: async () => {
                    if (!scanId) return;
                    try {
                        await deleteScan({ tenant: activeOrg, scanId }).unwrap();
                        successMessage('Successfully Deleted Scan');
                    } catch (e) {
                        errorMessage(extractErrorMessage(e, 'Unexpected error deleting scan'));
                    }
                    acceptButton.onClick();
                },
            }}
            rejectButton={rejectButton}
            width={420}
            className="ScanDeleteConfirmationModal"
        >
            {`Delete scan ${scanId} on ${scan?.application_name} from ${getLatestTimestamp(scan)}?`}
        </UiModal>
    );
};
