import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ISettingsPathParams } from '../../../Settings';
import { getDataSources, IDataSource } from './dataSourceApis';
import { errorMessage } from '../../../../../general/toast-service';
import { sortByTime } from '../../../../../general/utils';

export const useFetchAllDataSources = (storeFunc: (list: IDataSource[]) => void, runOnInitialization = true) => {
    const { activeOrg } = useParams<ISettingsPathParams>();
    const [updateNow, setUpdateNow] = useState(runOnInitialization);

    useEffect(() => {
        const fetchAll = async () => {
            try {
                const dataSourcesResponse = await getDataSources(activeOrg);
                storeFunc(dataSourcesResponse.sort(sortByTime));
            } catch (e) {
                errorMessage('Error: cannot fetch Data Sources');
            } finally {
                setUpdateNow(false);
            }
        };
        updateNow && fetchAll();
    }, [activeOrg, storeFunc, updateNow]);

    return () => setUpdateNow(true);
};
