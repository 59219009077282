import { useCallback, useState } from 'react';
import { ColumnApi, GridApi } from '@ag-grid-community/core';

import { UiButton } from '../../button/Button';
import { renderCtaButton } from '../Testing';
import { ScanListTable } from './ScanListTable/ScanListTable';
import { ScanAddModal } from './ScanAddModal/ScanAddModal';
import { ScanRunModal } from './ScanRunModal/ScanRunModal';
import { ScanDeleteConfirmationModal } from './ScanDeleteConfirmationModal/ScanDeleteConfirmationModal';

import './ScanList.scss';

export interface IScanListProps {
    activeOrg: string;
    className?: string;
}

export const ScanList = (props: IScanListProps) => {
    const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
    const [gridApi, setGridApi] = useState<GridApi>();
    const [scanIdToRun, setScanIdToRun] = useState<string>();
    const [scanIdToDelete, setScanIdToDelete] = useState<string>();

    const onGridReady = useCallback((params: { api: GridApi; columnApi: ColumnApi }) => {
        !gridApi && setGridApi(params.api);
    }, []);

    const onCreate = () => {
        setIsAddModalOpen(false);
        gridApi?.refreshServerSideStore({});
    };

    return (
        <>
            <ScanListTable
                activeOrg={props.activeOrg}
                onGridReady={onGridReady}
                setScanIdToRun={setScanIdToRun}
                setScanIdToDelete={setScanIdToDelete}
            />
            <ScanAddModal
                activeOrg={props.activeOrg}
                open={isAddModalOpen}
                onCreate={onCreate}
                onClose={() => setIsAddModalOpen(false)}
            />
            <ScanRunModal activeOrg={props.activeOrg} scanId={scanIdToRun} onClose={() => setScanIdToRun(undefined)} />
            <ScanDeleteConfirmationModal
                activeOrg={props.activeOrg}
                scanId={scanIdToDelete}
                onClose={() => {
                    setScanIdToDelete(undefined);
                }}
                acceptButton={{
                    text: 'Delete',
                    onClick: () => {
                        gridApi?.refreshServerSide();
                        setScanIdToDelete(undefined);
                    },
                }}
                rejectButton={{
                    text: 'Cancel',
                    onClick: () => {
                        setScanIdToDelete(undefined);
                    },
                }}
            />
            {renderCtaButton(<UiButton type="primary" text="New Scan" onClick={() => setIsAddModalOpen(true)} />)}
        </>
    );
};
