import React from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { Formatter } from 'recharts/types/component/DefaultTooltipContent';

import { formatNumberDisplay, roundToDigits } from '../../../general/utils';
import { IEntityChartTotals } from '../User';
import { LegendSubtotal } from './LegendSubtotal';

import './TotalReqPieChart.scss';

interface IProps {
    totalsData: IEntityChartTotals | undefined;
    colors: string[];
    isDark?: boolean;
}

export function TotalReqPieChart({ totalsData, colors, isDark = false }: IProps) {
    const counts = totalsData?.countsByStatus;

    const getLegend = () => {
        return totalsData?.countsByStatus.map((group, i) => {
            let returnedValue = totalsData?.total ? (group.value / totalsData?.total) * 100 : 0;
            return (
                <LegendSubtotal
                    isDark={isDark}
                    name={group.name}
                    subtotal={group.value}
                    color={colors[i]}
                    pct={roundToDigits(returnedValue, 1)}
                    key={group.name}
                />
            );
        });
    };

    return (
        <div className={`pie-chart-container ${isDark ? 'dark-chart' : 'light-chart'}`}>
            <div className="title">Total</div>
            <div className="content">
                <div className="chart">
                    <PieChart width={220} height={220}>
                        <text x={110} y={90} dy={8} textAnchor="middle" className="text-one">
                            {'Total'}
                        </text>
                        <text x={110} y={116} dy={8} textAnchor="middle" className="text-two">
                            {totalsData?.total.toLocaleString()}
                        </text>
                        <Tooltip formatter={formatNumberDisplay as Formatter<any, any>} />
                        <Pie
                            data={counts}
                            dataKey="value"
                            cx="50%"
                            cy="50%"
                            innerRadius={73}
                            outerRadius={100}
                            startAngle={90}
                            endAngle={-460}
                            strokeWidth="4px"
                            labelLine={false}
                        >
                            {counts?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index]} />
                            ))}
                        </Pie>
                    </PieChart>
                </div>
                <div className="chart-legend">{getLegend()}</div>
            </div>
        </div>
    );
}
