import { RefObject, useEffect, useState } from 'react';

import { isVerticalScrollNeeded } from '../../general/utils';
import { ExpandButton } from '../shared/expand-button/ExpandButton';

import './ExpandablePanel.scss';

interface IExpandablePanelProps {
    children: JSX.Element;
    childRef: RefObject<HTMLDivElement>;
    expandedHeight: number;
    contractedHeight: number;
    isExpanded: boolean;
    setExpanded: (value: boolean) => void;
}

export const ExpandablePanel = ({
    children,
    childRef,
    expandedHeight,
    contractedHeight,
    isExpanded,
    setExpanded,
}: IExpandablePanelProps) => {
    const [isExpandable, setIsExpandable] = useState<boolean>(true);

    useEffect(() => {
        childRef?.current &&
            setIsExpandable(isVerticalScrollNeeded(childRef?.current as HTMLElement, contractedHeight));
    }, [childRef, children, contractedHeight]);

    const toggleExpandPanelHandler = () => {
        setExpanded(!isExpanded);
    };

    return (
        <div
            className={`ExpandablePanel${isExpanded ? ' panel-expanded' : ''}`}
            data-expandable={isExpanded || isExpandable}
            style={{
                height: `${isExpanded ? expandedHeight : contractedHeight}px`,
            }}
        >
            {children}
            {(isExpanded || isExpandable) && (
                <ExpandButton isExpanded={isExpanded} onClick={toggleExpandPanelHandler} />
            )}
        </div>
    );
};
