import React, { useCallback, useState, WheelEvent } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Divider } from 'antd';
import { GridApi, RowNode, SelectionChangedEvent } from '@ag-grid-community/core';
import moment from 'moment';

import { INavigationLinks } from 'components/breadcrumb/BreadCrumb';
import {
    MultiExpandableContainer,
    ContentVisibilityModeEnum,
} from 'components/MultiExpandableContainer/MultiExpandableContainer';
import { ServiceSummary } from 'components/service-summary/ServiceSummary';
import { errorMessage } from 'general/toast-service';
import { extractErrorMessage, TDatetimeRange, urlEncode } from 'general/utils';
import { IFilter } from 'interfaces/filter.interface';
import { LabelSuppressPeriod } from 'interfaces/labels.interface';
import Spinner from '../../spinner/Spinner';
import { EntityRequestCharts } from '../../user/entity-request-chart/EntityRequestCharts';
import { IEntityChartSeries, IEntityChartTotals } from '../../user/User';
import { DiscoveryHeader } from '../DiscoveryHeader/DiscoveryHeader';
import {
    createServiceLabel,
    deleteServiceLabel,
    getTableData,
    QUERY_RESULTS_LIMIT,
    suppressServiceLabel,
} from '../shared/discoveryApis';
import { EndpointFilterableTable } from './EndpointFilterableTable/EndpointFilterableTable';
import { TableActions } from './TableActions/TableActions';
import { getService, useFetchServiceStats } from './useFetchServiceStats';

import './Service.scss';
import '../../Container.scss';

export const Service = () => {
    const location = useLocation();
    const queryParams: any = new URLSearchParams(location.search);
    const params = useParams() as { activeOrgParam: string; encodedServiceName: string };
    const encodedServiceName = params.encodedServiceName;
    const activeOrg = params.activeOrgParam;
    const serviceName = decodeURIComponent(params.encodedServiceName);
    const base64EncodedServiceName = urlEncode(decodeURIComponent(params.encodedServiceName));

    const [endpointFilter, setEndpointFilter] = useState<IFilter[]>([]);
    const [endpointCheckboxFilters, setEndpointCheckboxFilters] = useState<IFilter[]>([]);
    const [timeRangeFromInput, setTimeRangeFromInput] = useState<[moment.Moment, moment.Moment]>([
        moment.unix(queryParams.get('from_timestamp')),
        moment.unix(queryParams.get('to_timestamp')),
    ]);
    const [isHeaderExpanded, setIsHeaderExpanded] = useState<boolean>(true);
    const [selectedRows, setSelectedRows] = useState<RowNode[]>();
    const [gridApi, setGetGridApi] = useState<GridApi>();

    const { serviceData, setServiceData, totalRequests, seriesRequests, setIsUpdateHiddenCount } = useFetchServiceStats(
        { timeRangeFromInput }
    );

    const onTableDataUpdate = useCallback(() => gridApi?.refreshServerSideStore({}), [gridApi]);

    const getData = useCallback(
        getTableData(
            activeOrg,
            QUERY_RESULTS_LIMIT,
            [...endpointCheckboxFilters, ...endpointFilter],
            timeRangeFromInput,
            base64EncodedServiceName
        ),
        [endpointCheckboxFilters, endpointFilter, timeRangeFromInput]
    );

    const timeRangeHandler = (range: TDatetimeRange) => {
        setTimeRangeFromInput(range);
    };

    const breadcrumbList: INavigationLinks[] = [
        {
            url: `/${activeOrg}/discovery/services`,
            text: 'Services',
        },
        {
            url: `/${activeOrg}/discovery/services/${encodedServiceName}/`,
            text: serviceName,
        },
    ];

    const handleWheelEvent = (event: WheelEvent<HTMLDivElement>) => {
        setIsHeaderExpanded(event.deltaY < 0);
    };

    const createLabel = async (labelText: string): Promise<any> => {
        try {
            await createServiceLabel(activeOrg, serviceName, labelText);
            setServiceData(await getService(timeRangeFromInput, activeOrg, serviceName));
        } catch (error: any) {
            if (error.response?.status === 422) {
                errorMessage('Invalid label text');
            } else {
                errorMessage(extractErrorMessage(error));
            }
            throw 'Could not create label';
        }
    };

    const deleteLabel = async (labelId: string): Promise<any> => {
        try {
            await deleteServiceLabel(activeOrg, serviceName, labelId);
            setServiceData(await getService(timeRangeFromInput, activeOrg, serviceName));
        } catch (error: any) {
            errorMessage(extractErrorMessage(error));
            throw 'Could not delete label';
        }
    };

    const suppressLabel = async (labelId: string, period: LabelSuppressPeriod): Promise<any> => {
        try {
            await suppressServiceLabel(activeOrg, serviceName, labelId, period);
            setServiceData(await getService(timeRangeFromInput, activeOrg, serviceName));
        } catch (error: any) {
            errorMessage(extractErrorMessage(error));
            throw 'Could not suppress label';
        }
    };

    return (
        <div className="service-container container" onWheel={handleWheelEvent}>
            <DiscoveryHeader
                queryParams={queryParams}
                selectedServices={[serviceName]}
                timeRangeFromInput={timeRangeFromInput}
                onTimeChange={timeRangeHandler}
                pathParams={params}
                title={serviceName}
                isNewLabel={true}
                isLabelList={true}
                labelList={(serviceData?.service_labels || []).concat(serviceData?.endpoint_labels || [])}
                isEndpointSelect={true}
                breadcrumb={breadcrumbList}
                selectedRouteName={breadcrumbList[1].text}
                isSwagger={true}
                onLabelCreate={createLabel}
                onLabelDelete={deleteLabel}
                onLabelSuppress={suppressLabel}
                canDeleteLabel={(label) => !serviceData?.endpoint_labels.find((l) => l.label === label.label)}
            />
            {serviceData && totalRequests && seriesRequests ? (
                <MultiExpandableContainer
                    contentArray={[
                        {
                            content: <ServiceSummary service={serviceData} />,
                            mode: ContentVisibilityModeEnum.SHOWN_ALWAYS,
                        },
                        {
                            content: (
                                <Divider
                                    type="horizontal"
                                    className={`service-expandable-divider${isHeaderExpanded ? ' show' : ''}`}
                                />
                            ),
                            mode: ContentVisibilityModeEnum.SHOWN_EXPANDED,
                        },
                        {
                            content: (totalRequests || seriesRequests) && (
                                <EntityRequestCharts
                                    chartsData={{
                                        totals: totalRequests as IEntityChartTotals,
                                        series: seriesRequests as IEntityChartSeries,
                                    }}
                                    customHeight={isHeaderExpanded ? 341 : 0}
                                />
                            ),
                            mode: ContentVisibilityModeEnum.SHOWN_EXPANDED,
                        },
                    ]}
                    isExpanded={isHeaderExpanded}
                    toggleExpanded={() => setIsHeaderExpanded((prev) => !prev)}
                />
            ) : (
                <Spinner show={true} />
            )}
            <EndpointFilterableTable
                tableActions={
                    <TableActions
                        setFilters={setEndpointCheckboxFilters}
                        selectedRows={selectedRows}
                        activeOrg={activeOrg}
                        base64EncodedServiceName={base64EncodedServiceName}
                        gridApi={gridApi}
                        setIsUpdateHiddenCount={setIsUpdateHiddenCount}
                        serviceData={serviceData}
                    />
                }
                onSelectionChanged={(e: SelectionChangedEvent) => setSelectedRows(e.api.getSelectedNodes())}
                setGetGridApi={setGetGridApi}
                getData={getData}
                activeOrg={activeOrg}
                onFilterChange={setEndpointFilter}
                base64EncodedServiceName={base64EncodedServiceName}
                timeRangeFromInput={timeRangeFromInput}
                onTableDataUpdate={onTableDataUpdate}
                setIsUpdateHiddenCount={setIsUpdateHiddenCount}
                newCustomTitle={(numRows: number) =>
                    `Endpoints (${numRows}${serviceData ? ` of ${serviceData?.endpoint_count})` : ')'}`
                }
            />
        </div>
    );
};
