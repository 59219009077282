import { Dispatch, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Allotment } from 'allotment';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';

import { BusinessImpact } from '../../components/business-impact/BusinessImpact';
import { CallDetailsModal } from '../../components/call-details-modal/CallDetailsModal';
import { EntityTimeline } from '../../components/EntityTimeline/EntityTimeline';
import { UiIcon } from '../../components/icon/UiIcon';
import { LabelList } from '../../components/label-list/LabelList';
import { ITableResMetaData } from '../../components/query-results-table/QueryResultsTable';
import { SummaryDetails } from '../../components/summary-details/SummaryDetails';
import { BASIC_AGGRID_COL_TYPE } from '../../components/ui-ag-grid/commonOptions';
import {
    CellRenderCallContent,
    CellRenderDetokenizable,
    CellRenderEndpoint,
    CellRenderEndpointUserTable,
    CellRenderInfo,
    CellRenderLabelList,
    CellRenderSeverity,
    CellRenderTimeStamp,
    CellRenderVerticalCenter,
} from '../../components/ui-ag-grid/customCellRenderers';
import { UiAgGridSSRM } from '../../components/ui-ag-grid/UiAgGridSSRM';
import { EntitySummary } from '../../components/user-summary/EntitySummary';
import { ActionType, AppStateContext, IAppState } from '../../contexts/AppStateContext';
import { EventTypesEnum } from '../../enums/eventTypes.enum';
import { httpAll, httpGet, PAGE_SIZE_LIMIT } from '../../general/http-service';
import { dateTimeStringFormat, getDateRangeAround, patchAlert } from '../../general/utils';
import { IApiCall } from '../../interfaces/apiCall.interface';
import {
    MultiExpandableContainer,
    ContentVisibilityModeEnum,
} from '../../components/MultiExpandableContainer/MultiExpandableContainer';
import { IBaseLabel } from '../../interfaces/labels.interface';
import { IEntityStatistics, ITableData } from '../../interfaces/user.interface';
import { AlertStatusEnum } from '../alerts/Alerts';
import Spinner from '../spinner/Spinner';
import { EntityRequestCharts } from './entity-request-chart/EntityRequestCharts';
import { ITenant, selectCurrentTenantDetails } from 'api/tenantListApi';

import '../Container.scss';
import './User.scss';

const gridOptions: any = {
    components: {
        cellRenderTimeStamp: CellRenderTimeStamp,
        severity: CellRenderSeverity,
        labelListRender: CellRenderLabelList,
        endpointRender: CellRenderEndpoint,
        endpointRenderUserTable: CellRenderEndpointUserTable,
        callContentRender: CellRenderCallContent,
        cellRenderInfo: CellRenderInfo,
        cellRenderVerticalCenter: CellRenderVerticalCenter,
        cellRenderDetokenizable: CellRenderDetokenizable,
    },
    columnTypes: {
        // col type that cols inherit from
        basic: BASIC_AGGRID_COL_TYPE,
    },
};

interface IAllDataUser {
    entityStatistics?: IEntityStatistics;
    eventData?: any | null;
    tableData?: ITableData[];
}

export interface IEntityChartSeries {
    data: number[][];
    legend: string[];
    timestamps: [];
}

export interface IEntityChartTotalsResponse {
    counts: {
        '1xx': number;
        '2xx': number;
        '3xx': number;
        '4xx': number;
        '5xx': number;
        none: number;
    };
    total: number;
}

export interface IEntityChartTotals {
    countsByStatus: { name: string; value: number }[];
    total: number;
}

const typePathMap = {
    [EventTypesEnum.Alert]: 'alerts',
    [EventTypesEnum.Sequence]: 'sequences',
    [EventTypesEnum.Call]: 'calls',
};

const getEntityIdForCall = (entityType: string, relatedEntities: any) => {
    const relevantEntity = relatedEntities.filter((ent: any) => ent.name === entityType);
    return relevantEntity.length > 0 ? relevantEntity[0].value : 'N/A';
};

export const User = () => {
    const { state }: { state: IAppState; dispatch: Dispatch<ActionType> } = useContext(AppStateContext);
    const currentTenant = useSelector(selectCurrentTenantDetails);
    const currentTenantKey = currentTenant?.key;
    const [detailSpinner, setDetailSpinner] = useState<boolean>(false);
    const [isTopBarExpanded, setIsTopBarExpanded] = useState<boolean>(false);
    const [sharedQueryString, setSharedQueryString] = useState<string>('');
    const history = useHistory();
    const params = useParams() as {
        entityType: string;
        eventType: EventTypesEnum;
        eventId: string;
        eventTimestamp: string;
    };
    const { entityType, eventType, eventId, eventTimestamp } = params;

    const alertsOnly = new URLSearchParams(useLocation().search).get('alertsOnly') === 'true';
    const [tableResMetaData, setTableResMetaData] = useState<ITableResMetaData>({ count: 0, total: 0 });
    const [modalData, setModalData] = useState({ isVisible: false, data: null });
    const [allData, setAllData] = useState<IAllDataUser>({
        entityStatistics: undefined,
        eventData: null,
        tableData: [],
    });
    const [chartsData, setChartsData] = useState<{ totals: IEntityChartTotals; series: IEntityChartSeries } | null>(
        null
    );
    const [emptyState, setEmptyState] = useState<boolean>(false);
    const [isBusinessImpactExpanded, setIsBusinessImpactExpanded] = useState(false);
    const defaultSort = '&sort_by=desc(timestamp)';

    const onCommentCountChange = (comments_count: number) => {
        setAllData((content) => ({
            ...content,
            eventData: { ...content.eventData, comments_count },
        }));
    };

    const onTimelineClickedHandler = useCallback(
        (type?: EventTypesEnum, id?: string, timestamp?: number) => {
            if (type && id && timestamp) {
                setEmptyState(false);
                return history.push(
                    `/${currentTenantKey}/entity/${entityType}/event/${type}/${id}/${timestamp}/?alertsOnly=${alertsOnly}`
                );
            }

            setEmptyState(true);
        },
        [currentTenantKey, entityType, alertsOnly]
    );

    const openModal = useCallback((item?: any) => {
        setModalData((prevValue) => ({ isVisible: !prevValue.isVisible, data: item }));
    }, []);

    const getRelatedCalls = useCallback(
        (startIdx: number, endIdx: number, sortParams: string | undefined) => {
            if (!eventType) {
                return;
            }
            const sortStr = sortParams ? `&${sortParams}` : defaultSort;
            return httpGet(
                `organizations/${currentTenantKey}/${typePathMap[eventType]}/${eventId}/calls?anchor_ts=${eventTimestamp}&limit=${PAGE_SIZE_LIMIT}&offset=${startIdx}${sortStr}`
            );
        },
        [currentTenantKey, eventType, eventId]
    );

    useEffect(() => {
        setSharedQueryString(
            `anchor_event_type=${eventType}&` +
                `anchor_id=${eventId}&` +
                `anchor_ts=${eventTimestamp}&` +
                `entity_name=${entityType}`
        );
    }, [eventType, eventId, eventTimestamp, entityType]);

    useEffect(() => {
        if (allData?.entityStatistics?.entity_type && allData?.eventData?.timestamp) {
            const { from, to } = getDateRangeAround(allData.eventData.timestamp, 7);
            const totalRequestUrl = `organizations/${currentTenantKey}/investigate/request_total?${sharedQueryString}`;
            const reqSeriesUrl = `organizations/${currentTenantKey}/investigate/request_series?${sharedQueryString}&resolution=1d&from_timestamp=${from}&to_timestamp=${to}`;
            httpAll([totalRequestUrl, reqSeriesUrl]).then((res) => {
                const totalsData = {
                    total: res[0].data.total,
                    countsByStatus: Object.entries(res[0].data.counts as IEntityChartTotalsResponse).map(
                        (keyValPair: [string, number]) => {
                            return { name: keyValPair[0], value: keyValPair[1] };
                        }
                    ),
                };

                const seriesData = {
                    data: res[1].data.data,
                    legend: res[1].data.legend,
                    timestamps: res[1].data.timestamps.map((timestamp: number) => {
                        return dateTimeStringFormat(timestamp);
                    }),
                };
                setChartsData({ totals: totalsData, series: seriesData });
            });
        }
    }, [allData?.entityStatistics?.entity_type, allData?.eventData?.timestamp, sharedQueryString, currentTenantKey]);

    const formatTableData = (calls: IApiCall[]) => {
        const allSeqLabels: IBaseLabel[] = [];
        const tableData = calls.map((tableItem: IApiCall) => {
            const callLabels = (tableItem.service_labels || []).concat(tableItem.endpoint_labels || []);
            allSeqLabels.concat(callLabels);

            return {
                ...tableItem,
                status_code: tableItem.status_code,
                caller_ip: tableItem.caller_ip,
                timestamp: tableItem.timestamp,
                entityType: entityType,
                entityId: getEntityIdForCall(entityType, tableItem.entities),
                content: {
                    requestType: tableItem.request_content_type,
                    requestSize: tableItem.request_size,
                    responseType: tableItem.response_content_type,
                    responseSize: tableItem.response_size,
                },
                labels: callLabels,
                endpoint: [{ method: tableItem.method, name: tableItem.name || tableItem.endpoint_path }],
                info: <UiIcon name="info" onClick={() => openModal(tableItem)} />,
            };
        });

        setTableResMetaData((prevData: any) => ({
            ...prevData,
            labels: prevData.labels ? [...prevData.labels, ...allSeqLabels] : allSeqLabels,
        }));
        return tableData;
    };

    useEffect(() => {
        sharedQueryString &&
            httpGet(`organizations/${currentTenantKey}/investigate/entity_info?${sharedQueryString}`).then(
                ({ data: entityStatistics }: { data: IEntityStatistics }) => {
                    setAllData((prevData: IAllDataUser) => ({ ...prevData, entityStatistics }));
                }
            );
    }, [currentTenantKey, entityType, sharedQueryString]);

    useEffect(() => {
        try {
            if (typePathMap[eventType] && eventId) {
                const callTsQuery = eventType === EventTypesEnum.Call ? `call_ts=${eventTimestamp}` : '';
                httpGet(`organizations/${currentTenantKey}/${typePathMap[eventType]}/${eventId}?${callTsQuery}`).then(
                    (res: any) => {
                        setDetailSpinner(false);
                        setAllData((prevData: any) => {
                            const labels =
                                typePathMap[eventType] === typePathMap[EventTypesEnum.Alert]
                                    ? res.data.labels?.map((label: string) => ({ label })) || []
                                    : (res.data.service_labels || []).concat(res.data.endpoint_labels || []);

                            return {
                                ...prevData,
                                eventData: {
                                    ...res.data,
                                    labels,
                                },
                            };
                        });
                        if (eventType === EventTypesEnum.Alert || eventType === EventTypesEnum.Sequence) {
                            state.tableApi?.gridApi.refreshServerSideStore({ purge: true });
                        }
                    }
                );
            } else {
                setAllData((prevData: any) => ({ ...prevData, eventData: [], tableData: [] }));
            }
        } catch {
            setDetailSpinner(false);
        }
    }, [eventType, currentTenantKey, eventId, eventTimestamp]);

    const changeAlertStatusHandler = (newStatus: AlertStatusEnum) => {
        return patchAlert('status', currentTenant as ITenant, allData.eventData.id, newStatus)
            .then((res) => {
                setAllData({
                    ...allData,
                    eventData: {
                        ...allData.eventData,
                        status: newStatus,
                    },
                });
                return res;
            })
            .catch((err) => console.error(err));
    };

    const isCall = eventType === EventTypesEnum.Call;
    const isSequence = eventType === EventTypesEnum.Sequence;

    const getSummaryDataLabels = () => {
        if (isSequence) {
            // filter the label array for duplicates
            return [...new Set(tableResMetaData.labels?.map((label) => label.label))].map((label) => ({ label }));
        } else {
            return allData.eventData?.labels;
        }
    };

    const columns = [
        {
            headerName: 'Time',
            field: 'timestamp',
            type: 'basic',
            cellRenderer: 'cellRenderTimeStamp',
            cellRendererParams: {
                displaySingleLine: false,
            },
            flex: 1,
        },
        {
            headerName: 'Entity Type',
            field: 'entityType',
            type: 'basic',
            sortable: false,
            flex: 1.5,
            cellRenderer: 'cellRenderVerticalCenter',
        },
        {
            headerName: 'Entity ID',
            field: 'entityId',
            type: 'basic',
            sortable: false,
            cellRenderer: 'cellRenderDetokenizable',
            flex: 2,
        },
        {
            headerName: 'Endpoint',
            field: 'endpoint',
            type: 'basic',
            cellRenderer: 'endpointRenderUserTable',
            flex: 2,
        },
        {
            headerName: 'Status Code',
            field: 'status_code',
            type: 'basic',
            flex: 1,
            cellRenderer: 'cellRenderVerticalCenter',
        },
        {
            headerName: 'Source IP',
            field: 'caller_ip',
            type: 'basic',
            flex: 1,
            cellRenderer: 'cellRenderVerticalCenter',
        },
        {
            headerName: 'Labels',
            field: 'labels',
            type: 'basic',
            cellRenderer: 'labelListRender',
            sortable: false,
            flex: 2,
        },
        {
            headerName: 'Content',
            field: 'content',
            type: 'basic',
            sortable: false,
            cellRenderer: 'callContentRender',
        },
        {
            headerName: '',
            field: 'info',
            type: 'basic',
            cellRenderer: 'cellRenderInfo',
            cellRendererParams: {
                openModalCb: openModal,
            },
            sortable: false,
            resizable: false,
            flex: 1,
            maxWidth: 60,
        },
    ];

    function alertsOnlyChecked(checked: boolean) {
        if (!checked) {
            setEmptyState(false);
        }
        history.push(
            `/${currentTenantKey}/entity/${entityType}/event/${eventType}/${eventId}/${eventTimestamp}?alertsOnly=${checked}`
        );
    }

    if (allData.eventData && allData.eventData.timestamp && currentTenant) {
        return (
            <div className="user-container container">
                <MultiExpandableContainer
                    contentArray={[
                        {
                            content: <EntitySummary entityStatistics={allData.entityStatistics} />,
                            mode: ContentVisibilityModeEnum.SHOWN_ALWAYS,
                        },
                        {
                            content: (
                                <Divider
                                    type="horizontal"
                                    className={`user-expandable-divider${isTopBarExpanded ? ' show' : ''}`}
                                />
                            ),
                            mode: ContentVisibilityModeEnum.SHOWN_EXPANDED,
                        },
                        {
                            content: (
                                <EntityRequestCharts
                                    isDark
                                    customHeight={isTopBarExpanded ? 348 : 0}
                                    chartsData={chartsData}
                                />
                            ),
                            mode: ContentVisibilityModeEnum.SHOWN_EXPANDED,
                        },
                    ]}
                    isExpanded={isTopBarExpanded}
                    toggleExpanded={() => setIsTopBarExpanded((prev) => !prev)}
                    isOverlay
                    isDark
                />
                <div className="content-container">
                    <Allotment vertical={false} defaultSizes={[550, 1500]}>
                        <Allotment.Pane minSize={550} maxSize={1000}>
                            <div className="timeline">
                                <EntityTimeline
                                    onItemClicked={onTimelineClickedHandler}
                                    entityType={entityType}
                                    eventId={eventId}
                                    eventTimestamp={moment(allData.eventData.timestamp).valueOf()}
                                    eventType={eventType}
                                    alertsOnly={alertsOnly}
                                    onAlertsOnlyChecked={alertsOnlyChecked}
                                />
                            </div>
                        </Allotment.Pane>
                        <Allotment.Pane>
                            <div className="master-detail">
                                <Spinner show={detailSpinner} />
                                {emptyState ? (
                                    <span className="info-message">Select an event from the list on the left</span>
                                ) : (
                                    <>
                                        <div className="detail-header">
                                            <SummaryDetails
                                                eventData={allData.eventData}
                                                eventType={eventType}
                                                labels={getSummaryDataLabels()}
                                                setExpanded={setIsBusinessImpactExpanded}
                                                expanded={isBusinessImpactExpanded}
                                                changeAlertStatus={(e: AlertStatusEnum) => changeAlertStatusHandler(e)}
                                            />
                                        </div>
                                        <div className={`info-container ${isCall ? 'info-container--call' : ''}`}>
                                            {isSequence ? (
                                                <LabelList labels={allData.eventData?.labels || []} />
                                            ) : (
                                                <BusinessImpact
                                                    onCommentCountChange={onCommentCountChange}
                                                    isCall={isCall}
                                                    data={allData.eventData}
                                                    isExpanded={isBusinessImpactExpanded}
                                                    toggleExpanded={() => setIsBusinessImpactExpanded((prev) => !prev)}
                                                />
                                            )}
                                        </div>
                                        {eventType && !isCall && (
                                            <div className="user-table">
                                                <UiAgGridSSRM
                                                    options={gridOptions}
                                                    columns={columns}
                                                    getData={getRelatedCalls as (args: any) => Promise<AxiosResponse>}
                                                    dataMappingFunction={formatTableData}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </Allotment.Pane>
                    </Allotment>
                </div>
                <CallDetailsModal isVisible={modalData.isVisible} callData={modalData.data} toggleModal={openModal} />
            </div>
        );
    } else {
        return <Spinner show={true} />;
    }
};
