import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { ColDef } from '@ag-grid-community/core';

import { IFilter } from 'interfaces/filter.interface';
import { BASIC_COLUMN_DEFINITION, UiAgGridSSRM } from 'components/ui-ag-grid/UiAgGridSSRM';
import { UiIcon } from 'components/icon/UiIcon';
import { httpPOST } from 'general/http-service';
import { errorMessage } from 'general/toast-service';
import { agSetFilter, agTextFilter, BASIC_AGGRID_COL_TYPE } from 'components/ui-ag-grid/commonOptions';
import {
    CellRenderEndpointFromString,
    CellRenderRangeSensitiveMore,
    CellRenderVerticalCenter,
    CustomNoRowsOverlay,
    TableColumnHeader,
} from 'components/ui-ag-grid/customCellRenderers';
import { IFindingTypeDetailsParams } from 'components/Testing/FindingTypeDetails/FindingTypeDetails';
import { useGetFindingTypeQuery } from 'api/testingApi';
import { IServerQueryResponse } from 'api/baseServerApi';

export interface IFinding {
    endpoint: string;
    id: number;
    input: string;
    input_location: string;
    output: string;
    output_location: string;
}

export const FindingsTable = React.memo(() => {
    const [filters, setFilters] = useState<IFilter[][]>([]);
    const { activeOrg, scanId, findingTypeId } = useParams<IFindingTypeDetailsParams>();
    const { data: findingTypeData } = useGetFindingTypeQuery({
        tenant: activeOrg,
        scanId,
        findingTypeId,
    });

    const columnDef: ColDef[] = [
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'ID',
            field: 'id',
            sort: 'desc',
            cellRenderer: null,
            width: 110,
        },
        {
            headerName: 'Endpoint',
            field: 'endpoint',
            flex: 2,
            type: 'agTextFilter',
            cellRenderer: 'endpointRender',
            sortable: false,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Input Location',
            field: 'input_location',
            flex: 1,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
            },
            sortable: true,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Input',
            field: 'input',
            flex: 3,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
            },
            sortable: true,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Output Location',
            field: 'output_location',
            flex: 2,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
            },
            sortable: true,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: 'Output',
            field: 'output',
            flex: 3,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
            },
            sortable: true,
        },
        {
            ...BASIC_COLUMN_DEFINITION,
            headerName: '',
            field: 'more',
            width: 64,
            flex: 0,
            cellRenderer: 'moreButtonRender',
            cellRendererParams: (params: any) => ({
                menuItems: [
                    {
                        icon: <UiIcon name="show" />,
                        label: 'View Calls',
                        disabled: true,
                        tooltip: 'Will be available in the near future',
                        onClick: () => {},
                    },
                ],
            }),
            suppressMenu: true,
            suppressMovable: true,
            filter: false,
            filterParams: null,
            sortable: false,
            resizable: false,
        },
    ];

    const getData = (
        start: number,
        end: number,
        sortParams?: string
    ): Promise<AxiosResponse<IServerQueryResponse<IFinding>>> => {
        const sort_by = sortParams ? sortParams.split('=')[1] : 'desc(id)';
        return httpPOST(
            `organizations/${activeOrg}/testing/scans/${scanId}/test_results/${findingTypeId}/findings/query`,
            {
                filters,
                limit: 1000,
                offset: start,
                sort_by,
            }
        ).catch((error) => {
            return errorMessage(`${error.response?.data?.detail || 'Unexpected error while fetching data'}`);
        });
    };

    function handleFilterChange(params: any) {
        const filterArray: IFilter[][] = Object.keys(params).map((columnName: any) => {
            const column = params[columnName];

            if (column.filterType === 'number') {
                return [
                    {
                        name: columnName,
                        operator: column.type === 'lessThan' ? 'lt' : column.type === 'greaterThan' ? 'gt' : 'eq',
                        value: column.filter,
                    },
                ];
            }

            if (column.filterType === 'set') {
                return column.values?.map((value: any) => ({
                    name: columnName,
                    operator: 'eq',
                    value,
                    value_type: column.filterType,
                }));
            }

            return [
                {
                    name: columnName,
                    operator: column.type,
                    value: column.filter,
                    value_type: column.filterType,
                },
            ];
        });
        setFilters(filterArray);
    }

    return (
        <UiAgGridSSRM
            columns={columnDef}
            options={{
                columnTypes: {
                    basic: BASIC_AGGRID_COL_TYPE,
                    agSetFilter: agSetFilter,
                    agTextFilter: agTextFilter,
                },
                components: {
                    agColumnHeader: TableColumnHeader,
                    moreButtonRender: CellRenderRangeSensitiveMore,
                    customNoRowsOverlay: CustomNoRowsOverlay,
                    endpointRender: CellRenderEndpointFromString,
                    cellRenderVerticalCenter: CellRenderVerticalCenter,
                },
            }}
            getData={getData}
            onFilterChange={handleFilterChange}
            dataMappingFunction={(data: any) => {
                return data.map((item: any) => {
                    for (const key in item) {
                        if (!item[key]) {
                            item[key] = '-';
                        }
                    }
                    return item;
                });
            }}
            newCustomTitle={(numRows: number) =>
                `Findings (${numRows}${findingTypeData ? ` of ${findingTypeData?.findings_count})` : ')'}`
            }
        />
    );
});
