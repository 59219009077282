import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import { DatePicker } from 'antd';

import { splitToDateAndTimeRange, TDatetimeRangeOrPreset, TTimeRange, TDatetimeRange } from 'general/utils';
import { useOnEscOrClickOutside } from 'hooks/useOnEscOrClickOutside';
import { UiButton } from 'components/button/Button';
import { normalizeDateRange } from 'containers/query/Query';
import { DATE_FORMAT, minutesOfDayToHoursMinutes, TPresetName } from './utils';
import { UiTimeRangePicker } from './UiTimeRangePicker/UiTimeRangePicker';

import './UiChronoRangePicker.scss';

const { RangePicker } = DatePicker;

export type DateRangePresets = Partial<Record<TPresetName, TDatetimeRange>>;

export interface IChronoRangePickerProps {
    value: TDatetimeRangeOrPreset;
    onChange: (range: TDatetimeRangeOrPreset) => void;
    presets?: DateRangePresets;
    label?: string;
    disabledDate?: (date: Moment) => boolean;
}

export const UiChronoRangePicker = (props: IChronoRangePickerProps) => {
    const now = useRef(moment());
    const [selectedDateRange, setSelectedDateRange] = useState<TDatetimeRange>([now.current, now.current]);
    const [selectedTimeRange, setSelectedTimeRange] = useState<TTimeRange>(['0', '0']);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedPreset, setSelectedPreset] = useState<TPresetName | undefined>(
        typeof props.value === 'string' ? props.value : undefined
    );

    const wrapperRef = useRef<HTMLDivElement>(null);

    const setInitialDateTime = useCallback(() => {
        if (selectedPreset) {
            const foundDatetimeRange = props.presets && props.presets[selectedPreset];
            if (!foundDatetimeRange) {
                throw Error(`Preset not found: ${selectedPreset}`);
            }
            return setDateTimeByRange(foundDatetimeRange);
        }

        return setDateTimeByRange(props.value as TDatetimeRange);
    }, [props.value, selectedPreset]);

    useEffect(() => {
        setInitialDateTime();
    }, [setInitialDateTime]);

    useOnEscOrClickOutside(wrapperRef, () => isOpen && setIsOpen(false), 'crp-dropdown');

    const setDateTimeByRange = (range: TDatetimeRange) => {
        const [timeRange, dateRange] = splitToDateAndTimeRange(range);
        setSelectedTimeRange(timeRange);
        setSelectedDateRange(dateRange);
    };

    const getRangeOptions = (presets?: DateRangePresets) => {
        if (!presets) {
            return null;
        }

        return Object.entries(presets).map(([title, range]) => (
            <UiButton
                key={title}
                type="Secondary"
                className={`range-options-button ${selectedPreset === title ? 'active' : ''}`}
                text={title}
                onClick={() => {
                    setSelectedPreset(title as TPresetName);
                    setDateTimeByRange(range);
                }}
                disabled={props.disabledDate?.(range[0]) || props.disabledDate?.(range[1])}
            />
        ));
    };

    const onCalendarChange = (
        range: TDatetimeRange | null,
        rangeStrings: TTimeRange,
        info: { range: 'start' | 'end' }
    ) => {
        const normalizedDateRange = normalizeDateRange(range, rangeStrings, info, 14);
        normalizedDateRange && setSelectedDateRange(normalizedDateRange);
        setSelectedPreset(undefined);
    };

    const onTimeRangePickerChange = (timeRange: TTimeRange) => {
        setSelectedTimeRange(timeRange);
    };

    const onApply = () => {
        if (selectedPreset) {
            props.onChange(selectedPreset);
            setIsOpen(false);
            return;
        }

        const start = moment(selectedDateRange?.[0]).startOf('day').add(selectedTimeRange[0], 'minutes');
        const end = moment(selectedDateRange?.[1]).startOf('day').add(selectedTimeRange[1], 'minutes');
        if (start && end) {
            props.onChange([start, end]);
            setIsOpen(false);
        }
    };

    const onCancel = () => {
        setInitialDateTime();
        setIsOpen(false);
    };

    const selectedDateTimeFormatter = (date: Moment): string => {
        const index = selectedDateRange?.findIndex((selectedDate: Moment) => selectedDate === date);
        return index === undefined || selectedTimeRange[index] === undefined
            ? date.format(DATE_FORMAT)
            : `${date.format(DATE_FORMAT)} | ${minutesOfDayToHoursMinutes(selectedTimeRange[index])}`;
    };

    return (
        <div
            className="UiChronoRangePicker"
            onClick={() => {
                !isOpen && setIsOpen(true);
            }}
            ref={wrapperRef}
        >
            {props.label && <span className="control-label">{props.label}</span>}
            <RangePicker
                open={isOpen}
                showTime={false}
                mode={['date', 'date']}
                format={selectedDateTimeFormatter}
                value={selectedDateRange}
                disabled={!selectedDateRange}
                allowClear={false}
                // @ts-ignore
                onCalendarChange={onCalendarChange}
                disabledDate={props.disabledDate || (() => false)}
                dropdownClassName="crp-dropdown"
                panelRender={(panel) => (
                    <>
                        <div className="crp-range-options">{getRangeOptions(props.presets)}</div>
                        <div className="crp-controls">{panel}</div>
                    </>
                )}
                renderExtraFooter={() => (
                    <div className="crp-footer-container">
                        <UiTimeRangePicker
                            selectedDateRange={selectedDateRange}
                            supportedHours={24}
                            value={selectedTimeRange}
                            onChange={onTimeRangePickerChange}
                        />
                        <div className="crp-buttons">
                            <UiButton type="secondary" text="Cancel" onClick={onCancel} />
                            <UiButton type="primary" text="Apply" onClick={onApply} />
                        </div>
                    </div>
                )}
            />
        </div>
    );
};
