import React, { useRef, useState, ReactNode } from 'react';
import { GridApi, ColDef, ICellRendererParams } from '@ag-grid-community/core';

import { IProps as UISSRMProps, UiAgGridSSRM } from '../../../components/ui-ag-grid/UiAgGridSSRM';
import { agSetFilter, agTextFilter, BASIC_AGGRID_COL_TYPE } from '../../../components/ui-ag-grid/commonOptions';
import {
    CellDateView,
    CellProgressView,
    CellRedirectTitleService,
    cellRenderEndpointsChange,
    CellRenderRangeSensitiveMore,
    CellRenderLabelList,
    CellRenderTimeStamp,
    CellRenderVerticalCenter,
    TableColumnHeader,
    cellRenderRiskChange,
} from '../../../components/ui-ag-grid/customCellRenderers';
import CustomDateFilter from '../../../components/ui-ag-grid/customDateFilter';
import {
    IFilter,
    IFilterModelDateItem,
    IFilterModelSetItem,
    IFilterModelSingleItem,
} from '../../../interfaces/filter.interface';

import './FilterableTable.scss';

interface IFilterableTableParams {
    columns: ColDef[];
    tableActions?: ReactNode;
    newCustomTitle?: Function;
    getData: UISSRMProps['getData'];
    onFilterChange: Function;
    paginationPageSize?: number;
    pagination?: boolean;
    setRowsCount?: Function;
    onSelectionChanged?: Function;
    redirectOption?: string;
    tableTitle?: string;
    options?: { [key: string]: any };
    components?: { [key: string]: (data: ICellRendererParams) => ReactNode };
    setGetGridApi?: (gridApi: GridApi) => void;
    isHideRowsCount?: boolean;
    isKeepRowSelectedOnClickOutside?: boolean;
}

interface IFunctionDictionary {
    [name: string]: Function;
}

export const FilterableTable = (props: IFilterableTableParams) => {
    const {
        columns,
        getData,
        onFilterChange,
        paginationPageSize,
        pagination,
        onSelectionChanged,
        isHideRowsCount,
        isKeepRowSelectedOnClickOutside = true,
    } = props;
    const [parsedFilters, setParsedFilters] = useState<IFilter[][]>(); // Transformed filters
    const [tableFilters, setTableFilters] = useState<(IFilterModelSingleItem | IFilterModelSetItem)[]>(); // Unprocessed Filters provided by UiAgGridSSRM
    const tableWrapper = useRef<HTMLDivElement>(null);

    const gridOptions = {
        components: {
            agColumnHeader: TableColumnHeader,
            cellRenderTimeStamp: CellRenderTimeStamp,
            labelListRender: CellRenderLabelList,
            changeRender: cellRenderEndpointsChange,
            riskChangeRender: cellRenderRiskChange,
            cellProgress: CellProgressView,
            dateRender: CellDateView,
            redirectableTitle: (data: any) => CellRedirectTitleService(data, props.redirectOption || 'SERVICE'),
            cellRenderVerticalCenter: CellRenderVerticalCenter,
            customDateFilter: CustomDateFilter,
            moreButtonRender: CellRenderRangeSensitiveMore,
            ...props.components,
        },
        columnTypes: {
            // col type that cols inherit from
            basic: BASIC_AGGRID_COL_TYPE,
            agSetFilter: agSetFilter,
            agTextFilter: agTextFilter,
        },
        detailRowHeight: 160,
        detailRowAutoHeight: false,
        rowSelection: 'multiple',
        onSelectionChanged: onSelectionChanged,
        ...props.options,
    };

    const filterTypes: Map<string, string> = new Map([
        ['equals', 'eq'],
        ['lessThan', 'le'],
        ['greaterThan', 'ge'],
    ]);
    const filterMapping: IFunctionDictionary = {
        agSetColumnFilter: (filterModelItem: IFilterModelSetItem, key: String, operator: String) => {
            return (
                filterModelItem.values &&
                filterModelItem.values.map((attr: string | number) => {
                    return {
                        name: key,
                        operator: operator || 'Has',
                        value: attr,
                    };
                })
            );
        },
        agNumberColumnFilter: (filterModelItem: IFilterModelSingleItem, key: String) => {
            return [
                {
                    name: key,
                    operator: filterModelItem.type ? filterTypes.get(filterModelItem.type) : '',
                    value: filterModelItem.filter,
                },
            ];
        },
        customDateFilter: (filterModelDateItem: IFilterModelDateItem, key: String) => {
            return [
                [
                    {
                        name: key,
                        operator: 'Ge',
                        value: filterModelDateItem.value.filterDateRange[0],
                    },
                ],
                [
                    {
                        name: key,
                        operator: 'Lt',
                        value: filterModelDateItem.value.filterDateRange[1],
                    },
                ],
            ];
        },
    };

    function getParsedFilters(filterModel: (IFilterModelSingleItem | IFilterModelSetItem)[]): IFilter[][] {
        let newFilters: IFilter[][] = [];
        for (let key in filterModel) {
            newFilters = newFilters.concat(getParsedSingleFilter(key, filterModel[key]));
        }

        return newFilters;
    }

    function getParsedSingleFilter(
        key: string,
        filterModelItem: IFilterModelSingleItem | IFilterModelSetItem
    ): IFilter[][] {
        const colDef = columns.find((item) => item.field === key);
        if (colDef?.filter && filterMapping.hasOwnProperty(colDef.filter.toString())) {
            const operator = colDef.type == 'agTextFilter' ? 'eq' : 'has';
            return [filterMapping[colDef.filter.toString()](filterModelItem, key, operator)];
        }
        return [];
    }

    const onFilterChangeCallback = (filters: (IFilterModelSingleItem | IFilterModelSetItem)[]) => {
        const parsedChangedFilters = getParsedFilters(filters);
        setTableFilters(filters);
        setParsedFilters(parsedChangedFilters);
        onFilterChange(parsedChangedFilters);
    };

    return (
        <div className="filterable-table" ref={tableWrapper}>
            <UiAgGridSSRM
                columns={columns}
                options={gridOptions}
                masterDetail={false}
                filterModel={tableFilters}
                getDetailData={() => {}}
                tableActions={props.tableActions}
                getData={getData}
                customDetailComponent="tableDetailCelRenderer"
                dataMappingFunction={(data: any) =>
                    data.map((item: any) => ({
                        ...item,
                        labels: (item.service_labels || []).concat(item.endpoint_labels || []),
                    }))
                }
                onFilterChange={(filters: (IFilterModelSingleItem | IFilterModelSetItem)[]) =>
                    onFilterChangeCallback(filters)
                }
                pagination={pagination}
                paginationPageSize={paginationPageSize}
                setRowsCount={props.setRowsCount}
                isHideRowsCount={isHideRowsCount || false}
                isKeepRowSelectedOnClickOutside={isKeepRowSelectedOnClickOutside}
                customTitle={props.tableTitle}
                setGetGridApi={props.setGetGridApi}
                newCustomTitle={props.newCustomTitle}
            />
        </div>
    );
};
