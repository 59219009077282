import React, { Component } from 'react';
import { IEndpointFilter } from './EndpointFilterList';
import './ConditionCellRenderer.scss';

const operatorMap = {
    match: 'Match',
    notmatch: 'Not Match',
};

const filterNameMap = {
    endpoint_path: 'URL',
    response_content_types: 'Response Content-Type',
};

export class ConditionCellRenderer extends Component {
    private readonly value: IEndpointFilter;

    constructor(props: any) {
        super(props);
        this.value = props.value;
    }

    render() {
        return (
            <div
                className={`custom-cell-renderer ConditionCellRenderer ${
                    this.value.active ? '' : 'ConditionCellRenderer--disabled'
                }`}
            >
                <span className="ccr-operator">{operatorMap[this.value.operator]}</span>
                <span className="ccr-filter-name">{filterNameMap[this.value.name]}</span>
                <span className="ccr-value">{this.value.value}</span>
                {this.value.method?.length ? (
                    <span className="ccr-method">
                        <span className="ccr-filter-name">METHOD</span>
                        <span className="ccr-value">{this.value.method.join(', ')}</span>
                    </span>
                ) : null}
            </div>
        );
    }
}
