import { createSelector } from '@reduxjs/toolkit';

import { serverApi } from 'api/baseServerApi';

export const deTokApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        detokenize: builder.query<string, { tenant: string; tokenizedContent: string }>({
            query: (params) => ({
                url: `${params.tenant}/detokenize`,
                method: 'POST',
                body: { message: params.tokenizedContent },
            }),
        }),
    }),
});

export const useDeTokQuery = deTokApi.endpoints.detokenize.useQuery;

export const createSelectDetokenize = ({ tenant, tokenizedContent }: { tenant: string; tokenizedContent: string }) =>
    createSelector(deTokApi.endpoints.detokenize.select({ tenant, tokenizedContent }), ({ data }) => data);
