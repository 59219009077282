import moment from 'moment';
import { dummyFunctions } from '../../../service-summary/ServiceSummary';
import { CellRenderDownloadLink, cellRenderTimestampTooltip } from '../../../ui-ag-grid/customCellRenderers';
import { UiTitle } from '../../../ui-title/UiTitle';
import { IScanDetailsColumnDefinition } from '../ScanDetailsSummary/ScanDetailsSummary';
import { IScan } from '../../../../api/testingApi';

import './ScanExtraDetails.scss';

interface IProps {
    scan: IScan;
    activeOrg: string;
}

export const scanPropertiesToShow: { [key: string]: IScanDetailsColumnDefinition } = {
    oas_file_name: {
        displayName: 'Target OAS',
        dataPreparer: (value: string, scan: IScan, activeOrg: string) => ({
            url: `organizations/${activeOrg}/testing/oas/${scan.oas_file_id}`,
            text: scan.oas_file_name,
        }),
        renderer: CellRenderDownloadLink,
    },
    test_config_name: {
        displayName: 'Test config',
        dataPreparer: (value: string) => value || '-',
    },
    scan_template_name: {
        displayName: 'Scan template',
        dataPreparer: (value: string) => value || '-',
    },
    auth_config_name: {
        displayName: 'Auth config',
        dataPreparer: (value: string) => value || '-',
    },
    created_at: {
        displayName: 'Creation time',
        dataPreparer: (timestamp: string) => ({
            timestamp,
            value: timestamp ? moment(timestamp).format('DD MMMM YYYY, HH:mm:ss') : '-',
        }),
        renderer: cellRenderTimestampTooltip,
    },
    queued_at: {
        displayName: 'Queued time',
        dataPreparer: (timestamp: string) => ({
            timestamp,
            value: timestamp ? moment(timestamp).format('DD MMMM YYYY, HH:mm:ss') : '-',
        }),
        renderer: cellRenderTimestampTooltip,
    },
    started_at: {
        displayName: 'Start time',
        dataPreparer: (timestamp: string) => ({
            timestamp,
            value: timestamp ? moment(timestamp).format('DD MMMM YYYY, HH:mm:ss') : '-',
        }),
        renderer: cellRenderTimestampTooltip,
    },
    ended_at: {
        displayName: 'End time',
        dataPreparer: (timestamp: string) => ({
            timestamp,
            value: timestamp ? moment(timestamp).format('DD MMMM YYYY, HH:mm:ss') : '-',
        }),
        renderer: cellRenderTimestampTooltip,
    },
};

export const ScanExtraDetails = ({ scan, activeOrg }: IProps) => (
    <div className="ScanExtraDetails">
        {scan &&
            Object.entries(scan).map(([key, value], index) => {
                return (
                    scanPropertiesToShow.hasOwnProperty(key) && (
                        <div className="data-item" key={index}>
                            <UiTitle title={scanPropertiesToShow[key].displayName} type="medium" />
                            <div className="value-content">
                                <span className="value-text">
                                    {(scanPropertiesToShow[key].renderer || dummyFunctions.renderer)(
                                        (scanPropertiesToShow[key].dataPreparer || dummyFunctions.formatter)(
                                            value,
                                            scan,
                                            activeOrg
                                        )
                                    )}
                                </span>{' '}
                                {scanPropertiesToShow[key].getIcon && (
                                    <span className={'value-icon'}>
                                        {(scanPropertiesToShow[key].getIcon || dummyFunctions.getIcon)(value)}
                                    </span>
                                )}
                            </div>
                        </div>
                    )
                );
            })}
    </div>
);
