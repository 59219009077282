import { SuppressionRuleType } from '../suppression-rule/SuppressionRule';
import { IAutomatedActionRule } from '../../../../interfaces/throttle.interface';

export const actions: { [key: string]: IAutomatedActionRule[] } = {
    payments: [
        {
            id: '0',
            rule_name: 'Label alerts from VIP merchants',
            predicate: {
                associated_entities: [
                    {
                        id: ['36645807-67bf-55d0-a48c-a1383a8db0c9'],
                        type: 'MerchantId',
                    },
                ],
            },
            action: {
                label: 'VIP',
            },
            hit_count: 1,
            created_at: '2021-03-01T10:31:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-01T10:31:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 0,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '1',
            rule_name: 'API risk alerts to Jira',
            predicate: {
                alert_categories: ['Shadow API', 'Data Leakage', 'Authentication', 'Authorization', 'Ops'],
            },
            action: {
                jira_ticket: {
                    email: 'neosec_jira@example.com',
                    token: 'e5b72a…4b77ec',
                    project: 'API',
                },
            },
            hit_count: 3,
            created_at: '2021-03-02T14:57:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-02T14:57:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 1,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '2',
            rule_name: 'Exposed doc alerts to Jira',
            predicate: {
                alert_names: ['Exposed WSDL File', 'Exposed OpenAPI File'],
            },
            action: {
                jira_ticket: {
                    email: 'neosec_jira@example.com',
                    token: 'e5b72a…4b77ec',
                    project: 'API',
                },
            },
            hit_count: 0,
            created_at: '2021-03-02T14:59:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-02T14:59:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 2,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '3',
            rule_name: 'Email John on Ops alerts',
            predicate: {
                alert_categories: ['Ops'],
            },
            action: {
                email: 'johnm@example.com',
            },
            hit_count: 2,
            created_at: '2021-06-02T15:19:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-06-02T15:19:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 3,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '4',
            rule_name: 'All alerts to webhook processing',
            predicate: {},
            action: {
                webhook: {
                    endpoint: 'https://sec.intranet.example.com:8088/neosec_webhook',
                    username: 'johnmarks',
                    password: '****************',
                },
            },
            hit_count: 7,
            created_at: '2021-03-03T09:23:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-03T09:41:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 4,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '5',
            rule_name: 'Block data scraping user in Kong',
            predicate: {
                alert_names: ['Data Scraping'],
            },
            action: {
                kong_integration: {
                    kong_url: 'http://a6a0ebbd0bab24c83bd3a3aa611efa9f-1831aa84ff3a6018.elb.us-east-1.amazonaws.com',
                    action: 'block',
                    timeout: 30,
                    requests: 0,
                    timeframe: 'hour',
                    frequency: 0,
                },
            },
            hit_count: 1,
            created_at: '2021-03-03T09:23:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-03T09:41:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 5,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '6',
            rule_name: 'Block data scraping IP in F5',
            predicate: {
                alert_names: ['Data Scraping'],
            },
            action: {
                f5_integration: {
                    admin_api_url: 'http://10.199.199.101:8443',
                    action: 'block_ip',
                    timeout: 30,
                    basic_auth_username: 'admin',
                    basic_auth_password: '**************',
                    requests: 0,
                    timeframe: 'hour',
                    frequency: 0,
                },
            },
            hit_count: 1,
            created_at: '2021-03-03T09:27:30.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-03T09:41:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 5,
            rule_type: SuppressionRuleType.Alert,
        },
    ],
    nginx_lab: [],

    streaming: [
        {
            id: '0',
            rule_name: 'Label alerts from our Oracle Cloud',
            predicate: {
                associated_entities: [
                    {
                        id: ['108.101.234.126'],
                        type: 'IP',
                    },
                ],
            },
            action: {
                label: 'OracleCloud',
            },
            hit_count: 5,
            created_at: '2021-03-01T10:31:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-01T10:31:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 0,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '1',
            rule_name: 'API risk alerts to Jira',
            predicate: {
                alert_categories: ['Shadow API', 'Data Leakage', 'Authentication', 'Authorization', 'Ops'],
            },
            action: {
                jira_ticket: {
                    email: 'neosec_jira@example.com',
                    token: 'e5b72a…4b77ec',
                    project: 'API',
                },
            },
            hit_count: 22,
            created_at: '2021-03-02T14:57:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-02T14:57:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 1,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '2',
            rule_name: 'Exposed doc alerts to Jira',
            predicate: {
                alert_names: ['Exposed WSDL File', 'Exposed OpenAPI File'],
            },
            action: {
                jira_ticket: {
                    email: 'neosec_jira@example.com',
                    token: 'e5b72a…4b77ec',
                    project: 'API',
                },
            },
            hit_count: 0,
            created_at: '2021-03-02T14:59:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-02T14:59:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 2,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '3',
            rule_name: 'Email John on Ops alerts',
            predicate: {
                alert_categories: ['Ops'],
            },
            action: {
                email: 'johnm@example.com',
            },
            hit_count: 5,
            created_at: '2021-06-02T15:19:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-06-02T15:19:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 3,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '4',
            rule_name: 'All alerts to webhook processing',
            predicate: {},
            action: {
                webhook: {
                    endpoint: 'https://sec.intranet.example.com:8088/neosec_webhook',
                    username: 'johnmarks',
                    password: '****************',
                },
            },
            hit_count: 41,
            created_at: '2021-03-03T09:23:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-03T09:41:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 4,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '5',
            rule_name: 'Block Request Spike',
            predicate: {
                alert_names: ['Request Spike'],
            },
            action: {
                kong_integration: {
                    kong_url: 'http://a6a0ebbd0bab24c83bd3a3aa611efa9f-1831aa84ff3a6018.elb.us-east-1.amazonaws.com',
                    action: 'block',
                    timeout: 0,
                    requests: 0,
                    timeframe: 'hour',
                    frequency: 0,
                },
            },
            hit_count: 0,
            created_at: '2021-03-03T09:23:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-03T09:41:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 5,
            rule_type: SuppressionRuleType.Alert,
        },
    ],

    kong_demo: [
        {
            id: '0',
            rule_name: 'Block Request Spike',
            predicate: {
                alert_names: ['Request Spike'],
            },
            action: {
                kong_integration: {
                    kong_url: 'http://a6a0ebbd0bab24c83bd3a3aa611efa9f-1831aa84ff3a6018.elb.us-east-1.amazonaws.com',
                    action: 'block',
                    timeout: 0,
                    requests: 0,
                    timeframe: 'hour',
                    frequency: 0,
                },
            },
            hit_count: 0,
            created_at: '2021-03-03T09:23:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-03T09:41:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 0,
            rule_type: SuppressionRuleType.Alert,
        },
    ],

    items: [
        {
            created_at: '2021-06-22T11:56:06.334045Z',
            created_by: 'liorn@neosec.com',
            frequency: 4,
            hit_count: 0,
            id: 'b3657082-89f1-4999-b967-ed94c46d66e6',
            predicate: {
                associated_entities: [
                    {
                        id: ['234534534534'],
                        type: 'OrderID',
                    },
                ],
            },
            rule_name: 'Add Label',
            rule_order: 0,
            rule_type: SuppressionRuleType.Alert,
            status: 'enabled',
            timeframe: 'day',
            updated_at: '2021-06-22T11:57:46.683822Z',
            updated_by: 'liorn@neosec.com',
            action: {
                change_severity: 'High',
            },
        },
        {
            created_at: '2021-06-22T11:58:16.478923Z',
            created_by: 'dannyb@neosec.com',
            frequency: 0,
            hit_count: 0,
            id: '8c37827a-e944-41ae-b4d3-e9814bb72266',
            predicate: {
                endpoints: [{ id: 'fbf5e035-2fed-f611-5410-a47f4e593b6f', method: 'GET', endpoint_path: '/'}, { id: '0e05b9cb-d957-28d5-2aef-d37851b4a77e', method: 'GET', endpoint_path: '/' }],
            },
            rule_name: 'Ad to JIRA',
            rule_order: 1,
            rule_type: SuppressionRuleType.Alert,
            status: 'disabled',
            timeframe: 'day',
            updated_at: '2021-06-22T15:30:39.197161Z',
            updated_by: 'yonathang@ui.co.il',
            action: {
                label: 'Very severe alert',
            },
        },
        {
            created_at: '2021-06-22T12:39:54.037542Z',
            created_by: 'roym@neosec.com',
            frequency: 0,
            hit_count: 2,
            id: '53c39a80-a972-4b45-8a63-6773c997efc0',
            predicate: {
                alert_names: ['No Rate Limit'],
            },
            rule_name: 'Webhook',
            rule_order: 2,
            rule_type: SuppressionRuleType.Alert,
            status: 'enabled',
            timeframe: 'day',
            updated_at: '2021-06-22T15:26:41.438769Z',
            updated_by: 'yonathang@ui.co.il',
            action: {
                email: 'dannyb@neosec.com',
            },
        },
        {
            created_at: '2021-06-22T12:17:14.383361Z',
            created_by: 'roym@neosec.com',
            frequency: 0,
            hit_count: 3,
            id: '35eed4e1-051c-4044-b510-ac1fc859e3a6',
            predicate: {
                endpoints: [{id: '49de0127-e00a-d0c4-9608-eb304621dc2e', method: 'GET', endpoint_path: '/'}],
            },
            rule_name: 'Supress Endpoint',
            rule_order: 3,
            rule_type: SuppressionRuleType.Alert,
            status: 'disabled',
            timeframe: 'day',
            updated_at: '2021-06-22T15:49:12.153953Z',
            updated_by: 'yonathang@ui.co.il',
            action: {
                webhook: {
                    endpoint: 'http://foo.bar',
                    username: 'foo',
                    password: 'bar',
                },
            },
        },
        {
            created_at: '2021-06-22T11:16:29.524986Z',
            created_by: 'dannyb@neosec.com',
            frequency: 12345,
            hit_count: 0,
            id: 'd4d367e3-31bd-4b66-bf49-19dfe92a5312',
            predicate: {
                alert_categories: ['Shadow API', 'Authentication'],
                alert_description: {
                    operand: 'contains',
                    value: '213',
                },
                associated_entities: [
                    {
                        id: ['123', '1234'],
                        type: 'InvoiceID',
                    },
                ],
            },
            rule_name: 'dannys action',
            rule_order: 4,
            rule_type: SuppressionRuleType.Alert,
            status: 'enabled',
            timeframe: 'day',
            updated_at: '2021-06-22T15:26:36.692370Z',
            updated_by: 'yonathang@ui.co.il',
            action: {
                jira_ticket: {
                    email: 'dannyb@neosec.com',
                    token: '123456',
                    project: 'Prot',
                },
            },
        },
        {
            created_at: '2021-06-22T12:02:02.666811Z',
            created_by: 'dannyb@neosec.com',
            frequency: 24,
            hit_count: 0,
            id: '03fa35a7-3070-4506-a1d2-75e7c5fd7062',
            predicate: {
                associated_entities: [
                    {
                        id: ['123', '1234', '12345'],
                        type: 'InvoiceID',
                    },
                ],
                endpoints: [{ id: 'fbf5e035-2fed-f611-5410-a47f4e593b6f', method: 'GET', endpoint_path: '/' }],
                services: ['Payments Authentication Service', 'Payments Invoicing Service'],
            },
            rule_name: 'dannys action 3',
            rule_order: 5,
            rule_type: SuppressionRuleType.Alert,
            status: 'enabled',
            timeframe: 'hour',
            updated_at: '2021-06-22T12:04:17.999020Z',
            updated_by: 'dannyb@neosec.com',
            action: {
                change_severity: 'Low',
            },
        },
        {
            created_at: '2021-06-22T12:25:07.032269Z',
            created_by: 'roym@neosec.com',
            frequency: 1,
            hit_count: 6,
            id: '076b5525-6915-49ce-9c68-4a605f96cc6c',
            predicate: {
                associated_entities: [
                    {
                        id: ['52.95.224.6', '52.95.224.5'],
                        type: 'IP',
                    },
                ],
            },
            rule_name: 'Throttle Endpoint',
            rule_order: 6,
            rule_type: SuppressionRuleType.Alert,
            status: 'enabled',
            timeframe: 'hour',
            updated_at: '2021-06-22T12:47:15.666012Z',
            updated_by: 'roym@neosec.com',
            action: {
                label: 'new label',
            },
        },
        {
            created_at: '2021-06-22T12:25:07.032269Z',
            created_by: 'roym@neosec.com',
            frequency: 1,
            hit_count: 6,
            id: '076b5525-6915-49ce-9c68-4a615f96ccaa',
            predicate: {
                associated_entities: [
                    {
                        id: ['52.95.224.6', '52.95.224.5'],
                        type: 'IP',
                    },
                ],
            },
            rule_name: 'Throttle Endpoint',
            rule_order: 7,
            rule_type: SuppressionRuleType.Alert,
            status: 'enabled',
            timeframe: 'hour',
            updated_at: '2021-06-22T12:47:15.666012Z',
            updated_by: 'roym@neosec.com',
            action: {},
        },
    ],

    e2e_payments: [
        {
            id: '0',
            rule_name: 'Label alerts from VIP merchants',
            predicate: {
                associated_entities: [
                    {
                        id: ['36645807-67bf-55d0-a48c-a1383a8db0c9'],
                        type: 'MerchantId',
                    },
                ],
            },
            action: {
                label: 'VIP',
            },
            hit_count: 1,
            created_at: '2021-03-01T10:31:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-01T10:31:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 0,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '1',
            rule_name: 'API risk alerts to Jira',
            predicate: {
                alert_categories: ['Shadow API', 'Data Leakage', 'Authentication', 'Authorization', 'Ops'],
            },
            action: {
                jira_ticket: {
                    email: 'neosec_jira@example.com',
                    token: 'e5b72a…4b77ec',
                    project: 'API',
                },
            },
            hit_count: 3,
            created_at: '2021-03-02T14:57:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-02T14:57:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 1,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '2',
            rule_name: 'Exposed doc alerts to Jira',
            predicate: {
                alert_names: ['Exposed WSDL File', 'Exposed OpenAPI File'],
            },
            action: {
                jira_ticket: {
                    email: 'neosec_jira@example.com',
                    token: 'e5b72a…4b77ec',
                    project: 'API',
                },
            },
            hit_count: 0,
            created_at: '2021-03-02T14:59:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-02T14:59:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 2,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '3',
            rule_name: 'Email John on Ops alerts',
            predicate: {
                alert_categories: ['Ops'],
            },
            action: {
                email: 'johnm@example.com',
            },
            hit_count: 2,
            created_at: '2021-06-02T15:19:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-06-02T15:19:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 3,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '4',
            rule_name: 'All alerts to webhook processing',
            predicate: {},
            action: {
                webhook: {
                    endpoint: 'https://sec.intranet.example.com:8088/neosec_webhook',
                    username: 'johnmarks',
                    password: '****************',
                },
            },
            hit_count: 7,
            created_at: '2021-03-03T09:23:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-03T09:41:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 4,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '5',
            rule_name: 'Block Request Spike',
            predicate: {
                alert_names: ['Request Spike'],
            },
            action: {
                kong_integration: {
                    kong_url: 'http://a6a0ebbd0bab24c83bd3a3aa611efa9f-1831aa84ff3a6018.elb.us-east-1.amazonaws.com',
                    action: 'block',
                    timeout: 0,
                    requests: 0,
                    timeframe: 'hour',
                    frequency: 0,
                },
            },
            hit_count: 0,
            created_at: '2021-03-03T09:23:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-03T09:41:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 5,
            rule_type: SuppressionRuleType.Alert,
        },
    ],
    payments_neosec: [
        {
            id: '0',
            rule_name: 'Label alerts from VIP merchants',
            predicate: {
                associated_entities: [
                    {
                        id: ['36645807-67bf-55d0-a48c-a1383a8db0c9'],
                        type: 'MerchantId',
                    },
                ],
            },
            action: {
                label: 'VIP',
            },
            hit_count: 1,
            created_at: '2021-03-01T10:31:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-01T10:31:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 0,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '1',
            rule_name: 'API risk alerts to Jira',
            predicate: {
                alert_categories: ['Shadow API', 'Data Leakage', 'Authentication', 'Authorization', 'Ops'],
            },
            action: {
                jira_ticket: {
                    email: 'neosec_jira@example.com',
                    token: 'e5b72a…4b77ec',
                    project: 'API',
                },
            },
            hit_count: 3,
            created_at: '2021-03-02T14:57:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-02T14:57:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 1,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '2',
            rule_name: 'Exposed doc alerts to Jira',
            predicate: {
                alert_names: ['Exposed WSDL File', 'Exposed OpenAPI File'],
            },
            action: {
                jira_ticket: {
                    email: 'neosec_jira@example.com',
                    token: 'e5b72a…4b77ec',
                    project: 'API',
                },
            },
            hit_count: 0,
            created_at: '2021-03-02T14:59:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-02T14:59:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 2,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '3',
            rule_name: 'Email John on Ops alerts',
            predicate: {
                alert_categories: ['Ops'],
            },
            action: {
                email: 'johnm@example.com',
            },
            hit_count: 2,
            created_at: '2021-06-02T15:19:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-06-02T15:19:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 3,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '4',
            rule_name: 'All alerts to webhook processing',
            predicate: {},
            action: {
                webhook: {
                    endpoint: 'https://sec.intranet.example.com:8088/neosec_webhook',
                    username: 'johnmarks',
                    password: '****************',
                },
            },
            hit_count: 7,
            created_at: '2021-03-03T09:23:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-03T09:41:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 4,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '5',
            rule_name: 'Block Data Scraping',
            predicate: {
                alert_names: ['Data Scraping'],
            },
            action: {
                kong_integration: {
                    kong_url: 'http://a6a0ebbd0bab24c83bd3a3aa611efa9f-1831aa84ff3a6018.elb.us-east-1.amazonaws.com',
                    action: 'block',
                    timeout: 0,
                    requests: 0,
                    timeframe: 'hour',
                    frequency: 0,
                },
            },
            hit_count: 1,
            created_at: '2021-03-03T09:23:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-03T09:41:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 5,
            rule_type: SuppressionRuleType.Alert,
        },
    ],
    payments_rsa: [
        {
            id: '0',
            rule_name: 'Label alerts from VIP merchants',
            predicate: {
                associated_entities: [
                    {
                        id: ['36645807-67bf-55d0-a48c-a1383a8db0c9'],
                        type: 'MerchantId',
                    },
                ],
            },
            action: {
                label: 'VIP',
            },
            hit_count: 1,
            created_at: '2021-03-01T10:31:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-01T10:31:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 0,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '1',
            rule_name: 'API risk alerts to Jira',
            predicate: {
                alert_categories: ['Shadow API', 'Data Leakage', 'Authentication', 'Authorization', 'Ops'],
            },
            action: {
                jira_ticket: {
                    email: 'neosec_jira@example.com',
                    token: 'e5b72a…4b77ec',
                    project: 'API',
                },
            },
            hit_count: 3,
            created_at: '2021-03-02T14:57:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-02T14:57:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 1,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '2',
            rule_name: 'Exposed doc alerts to Jira',
            predicate: {
                alert_names: ['Exposed WSDL File', 'Exposed OpenAPI File'],
            },
            action: {
                jira_ticket: {
                    email: 'neosec_jira@example.com',
                    token: 'e5b72a…4b77ec',
                    project: 'API',
                },
            },
            hit_count: 0,
            created_at: '2021-03-02T14:59:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-02T14:59:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 2,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '3',
            rule_name: 'Email John on Ops alerts',
            predicate: {
                alert_categories: ['Ops'],
            },
            action: {
                email: 'johnm@example.com',
            },
            hit_count: 2,
            created_at: '2021-06-02T15:19:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-06-02T15:19:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 3,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '4',
            rule_name: 'All alerts to webhook processing',
            predicate: {},
            action: {
                webhook: {
                    endpoint: 'https://sec.intranet.example.com:8088/neosec_webhook',
                    username: 'johnmarks',
                    password: '****************',
                },
            },
            hit_count: 7,
            created_at: '2021-03-03T09:23:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-03T09:41:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 4,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '5',
            rule_name: 'Block Data Scraping',
            predicate: {
                alert_names: ['Data Scraping'],
            },
            action: {
                kong_integration: {
                    kong_url: 'http://a6a0ebbd0bab24c83bd3a3aa611efa9f-1831aa84ff3a6018.elb.us-east-1.amazonaws.com',
                    action: 'block',
                    timeout: 0,
                    requests: 0,
                    timeframe: 'hour',
                    frequency: 0,
                },
            },
            hit_count: 1,
            created_at: '2021-03-03T09:23:00.0000000',
            created_by: 'johnm@example.com',
            updated_at: '2021-03-03T09:41:00.0000000',
            updated_by: 'johnm@example.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 5,
            rule_type: SuppressionRuleType.Alert,
        },
    ],
    'webinar_lab': [
        {
            id: '0',
            rule_name: 'Block path traversal user in Kong',
            predicate: {
                alert_names: ['Path Traversal'],
            },
            action: {
                kong_integration: {
                    kong_url: 'http://a6a0ebbd0bab24c83bd3a3aa611efa9f-1831aa84ff3a6018.elb.us-east-1.amazonaws.com',
                    action: 'block',
                    timeout: 30,
                    requests: 0,
                    timeframe: 'hour',
                    frequency: 0,
                    entityName: 'User',
                },
            },
            hit_count: 1,
            created_at: '2023-02-06T09:23:00.0000000',
            created_by: 'almogc@neosec.com',
            updated_at: '2023-02-06T09:41:00.0000000',
            updated_by: 'almogc@neosec.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 0,
            rule_type: SuppressionRuleType.Alert,
        },
        {
            id: '1',
            rule_name: 'Block recon user in Kong',
            predicate: {
                alert_names: [
                    'Path Parameter Fuzzing',
                    'Path Parameter Fuzzing Attempt',
                    'Resource Enumeration',
                    'Resource Enumeration Attempt',
                    'Query Parameter Enumeration',
                    'Query Parameter Enumeration Attempt',
                    'Scan'
                ],
            },
            action: {
                kong_integration: {
                    kong_url: 'http://a6a0ebbd0bab24c83bd3a3aa611efa9f-1831aa84ff3a6018.elb.us-east-1.amazonaws.com',
                    action: 'block',
                    timeout: 30,
                    requests: 0,
                    timeframe: 'hour',
                    frequency: 0,
                    entityName: 'User',
                },
            },
            hit_count: 1,
            created_at: '2023-02-06T09:23:00.0000000',
            created_by: 'almogc@neosec.com',
            updated_at: '2023-02-06T09:41:00.0000000',
            updated_by: 'almogc@neosec.com',
            frequency: 0,
            timeframe: 'day',
            status: 'enabled',
            rule_order: 1,
            rule_type: SuppressionRuleType.Alert,
        }
    ]
};

export const actionsRes = new Promise((resolve, reject) => {
    setTimeout(() => {
        resolve(actions);
    }, 400);
});
