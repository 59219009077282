import React from 'react';
import './HomeWidget.scss';
import {UiTitle} from "../ui-title/UiTitle";
import {UiButton} from "../button/Button";

interface IHomeWidgetProps {
    title: string
    headerButton?: { text: string, onClick: any };
    children: any;
    style?: any;
}

export const HomeWidget = ({title, headerButton, children, style}: IHomeWidgetProps) => {
    return (
        <div className="home-widget">
            <div className="widget-header">
                <UiTitle type="x-large" title={title}/>
                {headerButton && <UiButton onClick={headerButton.onClick} type={"text"} text={headerButton.text}/>}
            </div>
            <div className="content" style={style}>{children}</div>
        </div>
    );
}

