import React from 'react';
import { CheckboxFilters } from './CheckboxFilters';
import { GridApi, RowNode } from '@ag-grid-community/core';
import { Button } from 'antd';

import { UiIcon } from '../../../../components/icon/UiIcon';
import { hideShowEndpointApi, IHideShowEndpoint } from '../../shared/discoveryApis';

import './TableActions.scss';

interface ITableActions {
    setFilters: Function;
    selectedRows?: RowNode[];
    activeOrg: string;
    base64EncodedServiceName: string;
    gridApi?: GridApi;
    setIsUpdateHiddenCount: Function;
    serviceData?: any;
}

const textShowOrHide = (numOfHiddenEndpoints: number, numOfTotalRows: number) => {
    if (0 === numOfHiddenEndpoints || 0 === numOfTotalRows) {
        return { text: `Hide selected endpoint (${numOfTotalRows})`, hide: true };
    }
    if (numOfTotalRows === numOfHiddenEndpoints) {
        return { text: `Show selected endpoint (${numOfTotalRows})`, hide: false };
    }
    return numOfTotalRows - numOfHiddenEndpoints > numOfTotalRows / 2
        ? {
              text: `Hide selected endpoint (${numOfTotalRows - numOfHiddenEndpoints} from ${numOfTotalRows})`,
              hide: true,
          }
        : { text: `Show selected endpoint (${numOfHiddenEndpoints} from ${numOfTotalRows})`, hide: false };
};

export const TableActions = ({
    setFilters,
    selectedRows,
    activeOrg,
    base64EncodedServiceName,
    gridApi,
    setIsUpdateHiddenCount,
    serviceData,
}: ITableActions) => {
    const numOfHiddenEndpoints = selectedRows?.filter((row) => row.data.hidden).length || 0;
    const numOfTotalRows = selectedRows?.length || 0;
    const { text, hide } = textShowOrHide(numOfHiddenEndpoints, numOfTotalRows);

    const handleClick = () => {
        const items = selectedRows?.reduce((acc: IHideShowEndpoint[], { data: { id } }: RowNode) => {
            acc.push({ id, hide });
            return acc;
        }, []);
        hideShowEndpointApi(activeOrg, base64EncodedServiceName, items || []).then(() => {
            setIsUpdateHiddenCount(true);
            gridApi?.deselectAll();
            gridApi?.refreshServerSideStore({});
        });
    };

    return (
        <>
            <Button type="link" onClick={handleClick}>
                <UiIcon name="hide" className="IconTableActions" />
                {text}
            </Button>
            <CheckboxFilters setFilters={setFilters} serviceData={serviceData} />
        </>
    );
};
