import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react';
import moment from 'moment';
import { UiDatePicker } from '../ui-date-picker/UiDatePicker';
import { IDoesFilterPassParams } from '@ag-grid-community/core';
import { IAfterGuiAttachedParams } from '@ag-grid-community/core';
import { Button } from 'antd';

type presetPeriods = null | '1_day' | '2_day' | '7_day' | '1_month' | '6_month' | 'custom';
const timeRangeOptions: { value: presetPeriods; display: string }[] = [
    { value: '1_day', display: 'Last Day' },
    { value: '2_day', display: 'Last 2 Days' },
    { value: '7_day', display: 'Last Week' },
    { value: '1_month', display: 'Last Month' },
    { value: '6_month', display: 'Last 6 Months' },
];

type ITimeRange = [moment.Moment, moment.Moment];

interface IModel {
    filterType: 'customDateFilter';
    value: {
        filterDateRange: ITimeRange;
        selectedPeriod: presetPeriods;
    };
}

export default forwardRef((props: { valueGetter: Function; filterChangedCallback: any }, ref) => {
    const nowMoment = moment();
    const [selectedPresetPeriod, setSelectedPresetPeriod] = useState<presetPeriods>(null);
    const [filterDateRange, setFilterDateRange] = useState<ITimeRange>([nowMoment, nowMoment]);
    const filterParams = useRef<IAfterGuiAttachedParams>();
    const [customDateRange, setCustomDateRange] = useState<ITimeRange>([nowMoment, nowMoment]);

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {
            doesFilterPass(params: IDoesFilterPassParams) {
                const alarmDate = moment(props.valueGetter(params));
                return alarmDate.isBetween(filterDateRange[0], filterDateRange[1]);
            },

            isFilterActive() {
                return selectedPresetPeriod !== null;
            },

            getModel() {
                if (selectedPresetPeriod) {
                    return {
                        filterType: 'customDateFilter',
                        value: {
                            filterDateRange: [
                                JSON.parse(JSON.stringify(filterDateRange[0])),
                                JSON.parse(JSON.stringify(filterDateRange[1])),
                            ],
                            selectedPeriod: selectedPresetPeriod,
                        },
                    };
                } else return null;
            },

            setModel(model: IModel) {
                const dateRange = model?.value?.filterDateRange
                    ? [moment(model.value.filterDateRange[0]), moment(model.value.filterDateRange[1])]
                    : [nowMoment, nowMoment];

                setSelectedPresetPeriod(model?.value?.selectedPeriod || null);
                setCustomDateRange(dateRange as ITimeRange);
                setFilterDateRange(dateRange as ITimeRange);
            },

            afterGuiAttached(params: IAfterGuiAttachedParams) {
                filterParams.current = params;
            },
        };
    });

    function onSelectPeriod(value: presetPeriods) {
        setSelectedPresetPeriod(value);
        setFilterDateRange(getRange(value));
    }

    const getRange = (value: presetPeriods): ITimeRange => {
        if (value && value !== 'custom') {
            const splitVal = value.split('_');
            const from = moment()
                .startOf('minute')
                .subtract(splitVal[0], splitVal[1] as any);
            return [from, nowMoment];
        } else if (value) {
            return [customDateRange[0]?.startOf?.('minute'), customDateRange[1]?.startOf?.('minute')];
        } else return [nowMoment, nowMoment];
    };

    const applyFilter = () => {
        setFilterDateRange(getRange(selectedPresetPeriod));
        if (filterParams.current) {
            const hideFilter = filterParams?.current?.hidePopup as () => void;
            hideFilter();
        }
    };

    useEffect(() => {
        props.filterChangedCallback();
    }, [filterDateRange]);

    function resetPeriod() {
        setSelectedPresetPeriod(null);
    }

    return (
        <div className="custom-filter-date">
            <div className=" options-container">
                {timeRangeOptions.map((option) => {
                    return (
                        <div
                            className={`range-option ${selectedPresetPeriod === option.value ? 'active' : ''}`}
                            onClick={() => onSelectPeriod(option.value)}
                            key={option.display}
                        >
                            {option.display}
                        </div>
                    );
                })}

                <div
                    className={`range-option ${selectedPresetPeriod === 'custom' ? 'active' : ''}`}
                    onClick={(e) => {
                        onSelectPeriod('custom');
                    }}
                >
                    Custom
                </div>
                {selectedPresetPeriod === 'custom' && (
                    <UiDatePicker
                        onChange={(e: any) => {
                            setCustomDateRange(e || ['', '']);
                        }}
                        getPopupContainer={() => document.getElementById('popupContainer') as HTMLDivElement}
                        value={[customDateRange[0], customDateRange[1]]}
                        showTime={true}
                    />
                )}
            </div>

            <div className="popup-container" id="popupContainer" />
            <div className="footer">
                <Button type="link" size="small" onClick={resetPeriod}>
                    Clear
                </Button>
                <Button type="primary" size="small" onClick={applyFilter}>
                    Apply
                </Button>
            </div>
        </div>
    );
});
