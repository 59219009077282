import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { History, Location } from 'history';
import { useSelector } from 'react-redux';

import './App.scss';

import { NavBar } from 'components/nav-bar/NavBar';
import { signOut } from 'general/auth-service';
import { ConfirmationModal } from 'components/shared/confirmaion-modal/ConfirmationModal';

import { fourOFour, selectCurrentTenantKey } from 'api/tenantListApi';
import { AppRoutes } from 'AppRoutes';

interface historyType {
    location?: Location;
    navigate?: History;
}
export const history: historyType = {
    location: undefined,
    navigate: undefined,
};

function App() {
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    history.location = useLocation();
    history.navigate = useHistory();

    useEffect(() => {
        const lastRoute = localStorage.getItem('lastRoute');
        if (lastRoute && lastRoute !== '/' && lastRoute !== `/${fourOFour}`) {
            localStorage.removeItem('lastRoute');
            history?.navigate?.push(lastRoute);
        }
    }, []);

    return (
        <div className="app">
            <NavBar showNavigation={!!currentTenantKey} signOut={signOut} />
            <div className="app-container">
                <AppRoutes />
            </div>
            <ConfirmationModal />
        </div>
    );
}

export default App;
