import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import UiMultiSelect from '../ui-multi-select/UiMultiSelect';
import { UiModal } from '../ui-modal/UiModal';
import { findInvalidIP } from '../../general/utils';
import { IEntityType } from './EntityMultiSelectList';

export interface EntityMultiSelectState {
    entity: {
        type: string;
        id: string[];
    };
    error?: {
        invalidIP?: string | null;
        requiredType?: boolean;
        requiredId?: boolean;
    };
}

export interface EntityMultiSelectProps {
    entityTypeList: IEntityType[];
    initialState: EntityMultiSelectState;
    onChange: (event: EntityMultiSelectState) => void;
}

function isValidEntityType(entity: EntityMultiSelectState['entity']): boolean {
    return !!entity.type;
}

function isValidEntityId(entity: EntityMultiSelectState['entity']): boolean {
    return entity.id.length > 0;
}

export function isValidEntity(entity: EntityMultiSelectState['entity']): boolean {
    return isValidEntityType(entity) && isValidEntityId(entity);
}

export const EntityMultiSelect = (props: EntityMultiSelectProps) => {
    const [componentState, setComponentState] = useState<EntityMultiSelectState>(props.initialState);
    const ipInputRef = useRef<any>(null);

    useEffect(() => {
        if (componentState.entity.type) {
            ipInputRef?.current?.focus();
        }
    }, [componentState.entity.type, ipInputRef]);

    function onChange(event: string | string[], key: keyof EntityMultiSelectState['entity'], bypassIPCheck?: boolean) {
        let invalidIP: string | null = null;
        if (
            componentState.entity.type === 'IP' &&
            key === 'id' &&
            componentState.entity.id.length < event.length &&
            !bypassIPCheck
        ) {
            invalidIP = findInvalidIP(componentState.entity.id, event as string[]);
        }

        setComponentState((prev) => {
            const entity = {
                ...prev.entity,
                [key]: event,
            };

            const newState = {
                entity,
                error: {
                    invalidIP,
                    requiredType: key === 'type' ? !isValidEntityType(entity) : prev.error?.requiredType,
                    requiredId: key === 'id' ? !isValidEntityId(entity) : prev.error?.requiredId,
                },
            };

            props.onChange(newState);

            return newState;
        });
    }

    function onBlur() {
        setComponentState((prev) => {
            const newState = {
                ...prev,
                error: {
                    ...prev.error,
                    requiredType: !isValidEntityType(prev.entity),
                    requiredId: !isValidEntityId(prev.entity),
                },
            };

            props.onChange(newState);

            return newState;
        });
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="control-label">type</span>
            <div style={{ position: 'relative' }}>
                <Select
                    style={{ width: 300, marginLeft: '15px', marginRight: '15px' }}
                    defaultValue={componentState.entity.type}
                    options={props.entityTypeList.map((entityType: any) => ({
                        key: entityType.id,
                        value: entityType.name,
                    }))}
                    onChange={(event) => onChange(event, 'type')}
                />
                {componentState.error?.requiredType && (
                    <div className="ui-input-error" style={{ marginRight: '15px' }}>
                        This field is required
                    </div>
                )}
            </div>
            <div style={{ position: 'relative' }}>
                <UiMultiSelect
                    ref={ipInputRef}
                    width={800}
                    value={componentState.entity.id}
                    handleChange={(event: any) => onChange(event, 'id')}
                    onBlur={onBlur}
                />
                {componentState.error?.requiredId && <div className="ui-input-error">This field is required</div>}
            </div>
            {componentState.error?.invalidIP && (
                <UiModal
                    onCancel={() => {
                        onChange(
                            componentState.entity.id.filter((ip) => ip !== componentState.error?.invalidIP),
                            'id'
                        );
                    }}
                    acceptButton={{
                        text: 'Add',
                        onClick: () => {
                            onChange(componentState.entity.id, 'id', true);
                        },
                    }}
                    rejectButton={{
                        text: 'Remove',
                        onClick: () => {
                            onChange(
                                componentState.entity.id.filter((ip) => ip !== componentState.error?.invalidIP),
                                'id'
                            );
                        },
                    }}
                    title="Value Not in IP Address Format"
                    icon="bellOutlined"
                >{`Add unrecognized IP address '${componentState.error?.invalidIP}'?`}</UiModal>
            )}
        </div>
    );
};
