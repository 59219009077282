import React, {useState} from 'react';
import {UiInput} from "../ui-input/UiInput";
import {UiTitle} from "../ui-title/UiTitle";
import {UiButton} from "../button/Button";
import {successMessage} from "../../general/toast-service";
import './JiraTicketInput.scss';

export interface JiraTicketConfig {
    email: string;
    token: string;
    project: string;
}

interface JiraTicketInputProps {
    ticket: JiraTicketConfig;
    onChange: (event: JiraTicketConfig) => any;
}

export const JiraTicketInput = (props: JiraTicketInputProps) => {
    const [output, setOutput] = useState<JiraTicketConfig>({...props.ticket});
    const [testInProgress, setTestInProgress] = useState<boolean>(false);

    function onChange(event: string, key: string) {
        const ticket = {
            ...output,
            [key]: event
        };
        setOutput(ticket);
        props.onChange(ticket);
    }

    function test() {
        setTestInProgress(true);
        setTimeout(() => {
            successMessage(`Test request was send to ${output.email}`);
            setTestInProgress(false);
        }, 700);
    }

    return (
        <div className="jira-ticket-input">
            <div className="jti-label">Create Jira ticket</div>
            <div style={{width: 500, margin: '0 0 15px 0'}}>
                <UiInput
                    label="email"
                    defaultValue={props.ticket.email}
                    onChange={(event: any) => onChange(event.target.value, 'email')}
                />
            </div>
            <div style={{margin: '0 0 15px 0'}}>
                <UiInput
                    label="API token"
                    defaultValue={props.ticket.token}
                    onChange={(event: any) => onChange(event.target.value, 'token')}
                />
            </div>
            <div style={{margin: '0 0 15px 0'}}>
                <UiInput
                    label="Project"
                    defaultValue={props.ticket.project}
                    onChange={(event: any) => onChange(event.target.value, 'project')}
                />
            </div>
            <UiButton
                type="primary"
                text="Test"
                disabled={!(output.email && output.token && output.project) || testInProgress}
                onClick={test}
            />
        </div>
    );
}