import React from 'react';

import { UiIcon } from '../icon/UiIcon';

import './UiTitle.scss';

interface ITitleProps {
    title: string | JSX.Element;
    type: 'x-large' | 'large' | 'medium' | 'small';
    iconName?: string;
}

export const UiTitle = (props: ITitleProps) => {
    return (
        <div className="ui-title">
            <span className={props.type}>{props.title}</span>
            {props.iconName ? (
                <span className="title-icon">
                    <UiIcon name={props.iconName} />
                </span>
            ) : (
                ''
            )}
        </div>
    );
};
