import React, { Dispatch, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { IServerQueryResponse } from 'api/baseServerApi';
import { isValidRow, QueryBuilder, validateRow } from 'components/query-builder/query-builder';
import { UiButton } from 'components/button/Button';
import { UiInput } from 'components/ui-input/UiInput';
import { QueryResultsTable } from 'components/query-results-table/QueryResultsTable';
import { UiDatePicker } from 'components/ui-date-picker/UiDatePicker';
import { UiDropDown } from 'components/ui-dropdown/UiDropDown';
import Spinner from 'containers/spinner/Spinner';
import { ActionType, AppStateContext, IAppState } from 'contexts/AppStateContext';
import { EventTypesEnum } from 'enums/eventTypes.enum';
import { httpGet, httpPOST, PAGE_SIZE_LIMIT } from 'general/http-service';
import { errorMessage, warningMessage } from 'general/toast-service';
import { sortEntitiesByPrecedence } from 'general/utils';
import { IApiCall, IApiCallSequence } from 'interfaces/apiCall.interface';
import { IEntity } from 'interfaces/entity.interface';
import { IEndpointDisplay } from 'interfaces/endpoint.interface';
import { ICallFilter, ICallsQuery, ICallsSequenceQuery, IQueryRow, IQueryValidation } from 'interfaces/query.interface';
import { LabelValue } from 'interfaces/labels.interface';
import { selectCurrentTenantKey } from 'api/tenantListApi';

import './Query.scss';

export const QUERY_RESULT_LIMIT = 100;
export const ENDPOINTS_RESULT_LIMIT = PAGE_SIZE_LIMIT;
let queryItems: IQueryRow[] = [];

export interface IEndpointsDataState {
    endpoints: IEndpointDisplay[];
    endpointsIds: string[];
}

export const normalizeDateRange = (
    range: [moment.Moment, moment.Moment] | null,
    rangeFormat: [string, string],
    info: { range: 'start' | 'end' },
    maxDiff: number
): [moment.Moment, moment.Moment] | null => {
    if (!range) {
        return null;
    }

    if (!range[0] || !range[1]) {
        return null;
    }

    const normalizedRange: [moment.Moment, moment.Moment] = [...range] as [moment.Moment, moment.Moment];
    if (info.range === 'start') {
        const dayDiff = moment.duration(normalizedRange[0].diff(normalizedRange[1])).asDays();
        if (dayDiff < -14) {
            normalizedRange[1] = moment(normalizedRange[0]).add(maxDiff, 'days');
            warningMessage(`Query is limited to a ${maxDiff} day range`);
        }
    } else if (info.range === 'end') {
        const dayDiff = moment.duration(normalizedRange[1].diff(normalizedRange[0])).asDays();
        if (dayDiff > 14) {
            normalizedRange[0] = moment(normalizedRange[1]).subtract(maxDiff, 'days');
            warningMessage(`Query is limited to a ${maxDiff} day range`);
        }
    }
    return normalizedRange;
};

export const Query = () => {
    const location = useLocation();
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const { state }: { state: IAppState; dispatch: Dispatch<ActionType> } = useContext(AppStateContext);
    const [queryBuilderSpinner, setQueryBuilderSpinner] = useState<boolean>(true);
    const [queryInProgress, setQueryInProgress] = useState<boolean>(false);
    const queryParams: any = new URLSearchParams(location.search);
    const endpointsIds: any[] = queryParams.get('endpointIds') ? JSON.parse(queryParams.getAll('endpointIds')) : [];
    const paramsEntityType: string = queryParams.get('entityType') || '';
    const paramsEntityId: string = queryParams.get('entityId') || '';
    const eventTimestamp: string = queryParams.get('timestamp') || '';
    const [endpointsData, setEndpointsData] = useState<IEndpointsDataState>({
        endpoints: [],
        endpointsIds: [],
    });
    const [emptyGrid, setEmptyGrid] = useState<boolean>(true);
    const [entityIdFromInput, setEntityIdFromInput] = useState<string>('');
    const queryRequest = useRef<any>({});
    const [timeRangeFromInput, setTimeRangeFromInput] = useState<[moment.Moment, moment.Moment] | [string, string]>([
        eventTimestamp ? moment(eventTimestamp) : moment().subtract(7, 'd'),
        eventTimestamp ? moment(eventTimestamp).add(7, 'd') : moment(),
    ]);

    // sort params
    const defaultSort = 'desc(timestamp)';
    const [callType, setCallType] = useState<EventTypesEnum | null>(EventTypesEnum.Call);

    const [entityTypeOptions, setEntityTypeOptions] = useState<LabelValue[]>([]);
    const [entityTypeFromInput, setEntityTypeFromInput] = useState<string>('');
    const [validation, setValidation] = useState<IQueryValidation>();
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (!entityTypeFromInput && entityTypeOptions.length) {
            setEntityTypeFromInput(entityTypeOptions[0].value);
        }
    }, [entityTypeOptions.length]);

    const [queryFilter, setQueryFilter] = useState({
        timeRange: timeRangeFromInput,
        entityType: entityTypeFromInput,
        entityId: entityIdFromInput,
    });

    const queryBuilderChanged = (data: any) => {
        queryItems = data;
    };

    /**
     * Check query builder input validation
     */
    function validate(queryItems: any): IQueryValidation {
        if (queryItems.length === 0) {
            const rows = [
                {
                    endpoint: 'This field required',
                    conditions: [],
                },
            ];

            return { rows };
        }

        const rows = queryItems.map((queryItem: any) => validateRow(queryItem));

        return { rows };
    }

    const getQueryObject = async (sortParams: string, offset: number) => {
        const callFilters: ICallFilter[] = [];
        // Convert "queryItems" to "callFilters"
        queryItems.forEach((queryItem) => {
            const callFilter: ICallFilter = {
                attribute_filters: [],
            };
            // If an Endpoint is selected - Populate the "attribute_filters"
            // with the "endpoints" info - Method , Parameterised Path, Service
            // If Any is selected - send request without the 3 attributes
            if (queryItem.defaultValue !== 'any_endpoint') {
                callFilter.attribute_filters.push(
                    {
                        name: 'Method',
                        value: queryItem.endpointMethod,
                        operator: 'Is',
                        in: 'Headers',
                        part_of: 'Request',
                        value_type: 'String',
                    },
                    {
                        name: 'Parameterised Path',
                        value: queryItem.endpointPath,
                        operator: 'Is',
                        in: 'Enrichment',
                        part_of: 'Attributes',
                        value_type: 'String',
                    },
                    {
                        name: 'Service',
                        value: queryItem.endpointServiceName,
                        operator: 'Is',
                        in: 'Enrichment',
                        part_of: 'Attributes',
                        value_type: 'String',
                    }
                );
            }
            // Populate the "attribute_filters" with the "conditions" info
            queryItem.conditions.forEach((condition: any) => {
                // convert OneOf / NotOneOf string array to string with single quotes around each value and , as delimiter
                if (condition.operator === 'OneOf' || condition.operator === 'NotOneOf') {
                    let tempVal = '';
                    condition.value.forEach((value: string, index: number) => {
                        tempVal += `'${value.replace(/'/g, "\\'")}'`; // escape single quotes
                        tempVal = index < condition.value.length - 1 ? tempVal + ',' : tempVal;
                    });
                    condition.value = tempVal;
                }

                callFilter.attribute_filters.push({
                    name: condition.name,
                    value: condition.value,
                    operator: condition.operator,
                    in: condition.in,
                    part_of: condition.partOf,
                    value_type: condition.value_type,
                });
            });
            callFilters.push(callFilter);
        });

        // Create the query object, typed depending on the amount of callFilters (query endpoints)
        let query;

        if (callFilters.length === 0) {
            // query builder is empty -> returning without making post req
            return null;
        } else if (callFilters.length === 1) {
            query = {} as ICallsQuery;
            query.call_filter = callFilters[0];
            setCallType(EventTypesEnum.Call);
        } else {
            query = {} as ICallsSequenceQuery;
            query.call_filters = callFilters;
            query.sequence_interval = '15s';
            setCallType(EventTypesEnum.Sequence);
        }

        query.entity_filter = { name: queryFilter.entityType || '', value: queryFilter.entityId || '' };

        const from = queryFilter.timeRange[0] ? queryFilter.timeRange[0].valueOf() : 0;
        const to = queryFilter.timeRange[1] ? queryFilter.timeRange[1].valueOf() : Date.now();

        query.time_range = { from: from as number, to: to as number };
        query.offset = offset;
        query.limit = QUERY_RESULT_LIMIT;
        query.sort_by = sortParams.split('=')[1] || defaultSort;
        queryRequest.current = query;

        return query;
    };

    const onClearAll = () => {
        setEntityIdFromInput('');
        setTimeRangeFromInput(['', '']);
        queryRequest.current = {};
        queryItems = [];
        // resetSortParams() TODO:clear sort in query params and in table in the future
    };

    const onDateChangeHandler = (
        range: [moment.Moment, moment.Moment] | null,
        rangeFormat: [string, string],
        info: { range: 'start' | 'end' }
    ) => {
        const normalizedDateRange = normalizeDateRange(range, rangeFormat, info, 14);
        normalizedDateRange ? setTimeRangeFromInput(normalizedDateRange) : setTimeRangeFromInput(['', '']);
    };

    useEffect(() => {
        if (!currentTenantKey) {
            return;
        }

        httpGet(`organizations/${currentTenantKey}/entities`).then((res) => {
            if (res.data?.items) {
                const sortedEntitiesDropdownOptions = sortEntitiesByPrecedence(res.data.items).map(
                    (entityType: IEntity) => ({
                        value: entityType.name,
                        label: entityType.name,
                    })
                );
                setEntityTypeOptions(sortedEntitiesDropdownOptions);
                // if no endpoint IDs were selected in url, we are done loading
                !endpointsIds.length && setQueryBuilderSpinner(false);
            }
        });
    }, [currentTenantKey]);

    useEffect(() => {
        if (!currentTenantKey) {
            return;
        }

        let offset = 0;
        let totalEndpointsToFetch: number = 0;

        queryRequest.current = {};
        queryItems = [];
        if (!entityTypeFromInput) {
            setEntityTypeFromInput(paramsEntityType);
        }
        setEntityIdFromInput(paramsEntityId);
        setQueryBuilderSpinner(true);

        const from_timestamp = moment().subtract(1, 'years').format('X');
        const to_timestamp = moment().format('X');
        httpGet(`organizations/${currentTenantKey}/discovery/endpoints`, {
            limit: ENDPOINTS_RESULT_LIMIT.toString(),
            from_timestamp,
            to_timestamp,
        })
            .then((res: any) => {
                totalEndpointsToFetch = res.data.total;
                setEndpointsData({ endpoints: res.data.items, endpointsIds });
                setQueryBuilderSpinner(false);
                if (offset + ENDPOINTS_RESULT_LIMIT <= totalEndpointsToFetch) {
                    getMoreEndpoints();
                }
            })
            .catch((err) => {
                errorMessage(err);
            });

        function getMoreEndpoints() {
            offset += ENDPOINTS_RESULT_LIMIT;
            const endpointUrl = `organizations/${currentTenantKey}/discovery/endpoints`;
            httpGet(endpointUrl, {
                offset: offset.toString(),
                limit: ENDPOINTS_RESULT_LIMIT.toString(),
                from_timestamp,
                to_timestamp,
            })
                .then((res) => {
                    setEndpointsData((prevData: IEndpointsDataState) => ({
                        ...prevData,
                        endpoints: [...prevData.endpoints, ...res.data.items],
                    }));

                    if (offset + ENDPOINTS_RESULT_LIMIT <= totalEndpointsToFetch) {
                        getMoreEndpoints();
                    }
                })
                .catch((err) => {
                    errorMessage(err);
                });
        }
    }, [currentTenantKey]);

    const getTableData = useCallback(
        async (startIdx: number, endIdx: number, sortParams: string) => {
            if (!submitted) {
                return;
            }

            const sortStr = sortParams ? sortParams.split('=')[1] : defaultSort;
            // submit
            const queryPayload = (await getQueryObject(sortParams, startIdx)) as
                | ICallsQuery
                | ICallsSequenceQuery
                | null;

            if (!queryPayload) {
                return;
            }

            queryPayload.sort_by = sortStr;
            const url =
                callType === EventTypesEnum.Sequence
                    ? `organizations/${currentTenantKey}/calls/query_sequences`
                    : `organizations/${currentTenantKey}/calls/query`;

            setQueryInProgress(true);
            return httpPOST(url, queryPayload)
                .then((result: AxiosResponse<IServerQueryResponse<IApiCall | IApiCallSequence>>) => {
                    if (!result.data?.items?.length) {
                        setEmptyGrid(true);
                    }
                    return result;
                })
                .catch((error) => {
                    if (error.response?.status === 422 || error.response?.status === 400) {
                        errorMessage(
                            `Invalid Query ${error.response?.data?.detail ? ': ' + error.response?.data?.detail : ''}`,
                            {
                                duration: 4,
                            }
                        );
                    } else {
                        errorMessage('Query timeout - please select a shorter time range or simplify your query', {
                            duration: 4,
                        });
                    }
                })
                .finally(() => {
                    setQueryInProgress(false);
                });
        },
        [
            currentTenantKey,
            queryFilter.entityType,
            queryFilter.entityId,
            queryFilter.timeRange[0],
            queryFilter.timeRange[1],
            callType,
            submitted,
        ]
    );

    function submitClickHandler() {
        setSubmitted(true);
        const validationObj = validate(queryItems);
        setValidation(validationObj);

        if (validationObj.rows.map((row) => isValidRow(row)).filter((valid) => !valid).length) {
            return;
        }

        const ct = queryItems.length > 1 ? EventTypesEnum.Sequence : EventTypesEnum.Call;
        setCallType(ct);
        setEmptyGrid(false);
        setQueryFilter({
            timeRange: timeRangeFromInput,
            entityType: entityTypeFromInput,
            entityId: entityIdFromInput,
        });
        state.tableApi?.gridApi.refreshServerSideStore({ purge: true });
    }

    return (
        <div className="query-container">
            <div className="query-builder-wrapper">
                <Spinner show={queryBuilderSpinner} size={'small'} />
                {!queryBuilderSpinner && (
                    <div className="query-builder-header">
                        <div className="top-controls">
                            <div className="control">
                                <UiDatePicker
                                    showTime={true}
                                    label={'Time Range'}
                                    value={[
                                        timeRangeFromInput[0] as moment.Moment,
                                        timeRangeFromInput[1] as moment.Moment,
                                    ]}
                                    onCalendarChange={onDateChangeHandler}
                                />
                            </div>
                            <div className="control">
                                <UiDropDown
                                    options={entityTypeOptions}
                                    handleChange={setEntityTypeFromInput}
                                    controlLabel={'entity type'}
                                    value={entityTypeFromInput.trim()}
                                    placeholder={'Select entity type'}
                                    minWidth={235}
                                    dropdownMatchSelectWidth={false}
                                />
                            </div>
                            <div className="control">
                                <UiInput
                                    onChange={(e: any) => setEntityIdFromInput(e.target.value)}
                                    label={'id'}
                                    value={entityIdFromInput.trim()}
                                    placeholder={'Type an entity ID'}
                                />
                            </div>
                            <div className="control">
                                <UiButton
                                    disabled={queryInProgress}
                                    onClick={submitClickHandler}
                                    text="Search"
                                    type="primary"
                                />
                            </div>
                            {/*<div className="control"><UiOverlayMenu menuItems={[{*/}
                            {/*    label: 'Save as Sequence', onClick: () => {*/}
                            {/*    }, disabled: true*/}
                            {/*},*/}
                            {/*    {*/}
                            {/*        label: 'Save as Alert Rule', onClick: () => {*/}
                            {/*        }, disabled: true*/}
                            {/*    }*/}
                            {/*]}/></div>*/}
                        </div>
                    </div>
                )}

                {!queryBuilderSpinner && (
                    <QueryBuilder
                        onChange={queryBuilderChanged}
                        onClearAll={onClearAll}
                        endpointData={endpointsData}
                        timeRange={{
                            from_timestamp: moment().subtract(1, 'years').format('X'),
                            to_timestamp: moment().format('X'),
                        }}
                        validation={validation}
                    />
                )}
            </div>

            <div className="query-results-summary"></div>

            <div className="query-results-container">
                <QueryResultsTable getTableData={getTableData} callType={callType} entityType={entityTypeFromInput} />
            </div>
        </div>
    );
};
