import { Fragment, useState } from 'react';
import { Body, Cell, Header, HeaderCell, HeaderRow, Row, Table } from '@table-library/react-table-library/table';

import { singleLineWithTooltip } from 'general/utils';
import { UiIcon } from 'components/icon/UiIcon';
import PrettyRenderer from 'components/pretty-renderer/PrettyRenderer';
import { ITableItem } from './SingleCallDetails';

interface IUiSimpleTableProps {
    rowsData: ITableItem[];
    resizable?: boolean;
    initialWidth?: string;
    headers: string[];
}

const theme = {
    Table: `
        & {
            width: 100%;
        }
        .data-row td {
            border-bottom: 1px solid var(--app-purple-shade-three);
            color: var(--app-grayish);
            font-size: var(--font-size-small);
            padding: 15px 24px;
            font-weight: var(--font-weight-medium);

            &:first-child {
                font-weight: var(--font-weight-semibold);
            }
        }
        .header-row td, .header-row th {
            background-color: var(--lighter-grey);
            min-height: 52px;
            padding: 15px 24px;
            text-transform: uppercase;
            width: 100%;
            font-size: var(--font-size-medium);
            font-weight: var(--font-weight-light);
        }
        .resizer-area {
            height: calc(100% - 16px);
            margin-top: 8px;
            span {
                background-color: var(--app-grayish);
            }
        }
        .resizer-area:hover {
            background-color: transparent;

            span {
                background-color: blue;
            }
        }
    `,
    Cell: `
    &.expandable {
        position: relative;
        .anticon {
            position: absolute;
            right: 20px;
            top: 20px;
        }
        & > div {
            max-width: 90%;
        }
    }
    &.expanded-cell {
        grid-column: span 2;
    }
    `,
};

export const UiSimpleTable = ({
    rowsData,
    resizable = false,
    headers = [],
    initialWidth = 'minmax(0px, 200px) minmax(0px, 1fr)',
}: IUiSimpleTableProps) => {
    const [ids, setIds] = useState<string[]>([]);

    const handleExpand = (item: any) => {
        if (ids.includes(item.id)) {
            setIds(ids.filter((id) => id !== item.id));
        } else {
            setIds(ids.concat(item.id));
        }
    };

    const getExpandableComponent = (item: any): JSX.Element => {
        const list: { [key: string]: JSX.Element } = {
            pretty: <PrettyRenderer callParameters={rowsData} content={item.value} />,
        };

        return list[item.expandable as string];
    };

    return (
        <Table
            id="resizableTable"
            onInit={(table) => {
                // due to how library manages width with JS the only way to change initial
                // default column width is via this setTimeout hack
                setTimeout(() => {
                    table.setAttribute('style', `--data-table-library_grid-template-columns: ${initialWidth};`);
                }, 0);
            }}
            data={{ nodes: rowsData }}
            theme={theme}
        >
            {(tableList) =>
                tableList.length ? (
                    <>
                        <Header>
                            <HeaderRow className="header-row">
                                {headers.map((value, index) => (
                                    <HeaderCell key={value} resize={resizable && index !== headers.length - 1}>
                                        {value}
                                    </HeaderCell>
                                ))}
                            </HeaderRow>
                        </Header>

                        <Body>
                            {tableList.map((item, index) => (
                                <Fragment key={item.id + index}>
                                    <Row
                                        resize={item.isHeader}
                                        className={item.isHeader ? 'header-row' : 'data-row'}
                                        item={item}
                                    >
                                        <Cell>{item.name}</Cell>
                                        <Cell className={item.expandable ? 'expandable' : ''}>
                                            {singleLineWithTooltip(
                                                item.value,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                true
                                            )}
                                            {item.expandable && (
                                                <UiIcon
                                                    onClick={() => {
                                                        handleExpand(item);
                                                    }}
                                                    name={ids.includes(item.id) ? 'upArrowSmall' : 'downArrowSmall'}
                                                />
                                            )}
                                        </Cell>
                                    </Row>
                                    {item.expandable && ids.includes(item.id) && (
                                        <Row item={item}>
                                            <Cell className="expanded-cell" colSpan={99}>
                                                {getExpandableComponent(item)}
                                            </Cell>
                                        </Row>
                                    )}
                                </Fragment>
                            ))}
                        </Body>
                    </>
                ) : null
            }
        </Table>
    );
};
