import React from 'react';
import './UiIconButton.scss';
import Icon from '@ant-design/icons';
import { icons } from '../../assets/icons';

interface IUiIconProps {
    name: string;
    callback?: any;
    onClick?: any;
}

export const UiIconButton = (props: IUiIconProps) => {
    const handleClick = () => {
        props.callback();
    };

    const getIcon = () =>
        props.callback ? (
            <Icon component={() => icons[props.name]} onClick={handleClick} style={{ cursor: 'pointer' }} />
        ) : (
            <Icon component={() => icons[props.name]} />
        );

    return <div className="icon-button">{icons[props.name] ? getIcon() : ''}</div>;
};
