import React from 'react';
import { Badge } from 'antd';

import { Card } from '../../../../Card/Card';
import { ICollectorConfiguration, IDataSourceTypesCollectorConfig } from '../shared/collectorConfigurationApis';
import { fullDateStringFormat } from '../../../../../general/utils';
import { gridCellGenerator } from '../../../../Card/gridCellGenerator';
import { ICollector } from '../../collectors/shared/collectorApis';
import { UiTooltip } from '../../../../ui-tooltip/UiTooltip';

import './CollectorConfigurationCard.scss';

export interface IConfigCardProps {
    menu: React.ReactNode;
    config: ICollectorConfiguration;
}

const collectorList = (collectors: ICollector[]) => {
    const numOfCollectors = collectors?.length;
    const numOfItemsToShow = 3;
    let collectorNames;
    if (numOfCollectors > numOfItemsToShow) {
        const names = collectors?.map((collector) => collector.name);
        collectorNames = names?.join(', ');
    }

    return (
        <UiTooltip title={collectorNames} visible={numOfCollectors > numOfItemsToShow}>
            {collectors?.slice(0, numOfItemsToShow).map(({ name }) => (
                <div className="description">{name}</div>
            ))}
            {collectorNames && (
                <Badge count={`+${numOfCollectors - numOfItemsToShow}`} style={{ backgroundColor: 'var(--border)' }} />
            )}
        </UiTooltip>
    );
};

const getDataSourceTypes = (data_source_types_of_collector_config?: IDataSourceTypesCollectorConfig[]) => {
    return (
        data_source_types_of_collector_config?.map((type: any) => type.edges.data_source_type.displayName).join(', ') ??
        ''
    );
};

export const CollectorConfigurationCard = ({ menu, config }: IConfigCardProps) => {
    const {
        name,
        createdAt,
        updatedAt,
        createdBy,
        updatedBy,
        edges: { collectors, data_source_types_of_collector_config },
    } = config;

    return (
        <Card title={name} menu={menu} isDisabled={!!collectors}>
            <div className="card-config-grid">
                {gridCellGenerator('applied to', collectors && collectorList(collectors), false)}
                {gridCellGenerator('created at', fullDateStringFormat(createdAt, false))}
                {gridCellGenerator('author', createdBy)}
                {gridCellGenerator('data source type', getDataSourceTypes(data_source_types_of_collector_config))}
                {gridCellGenerator('last modified', fullDateStringFormat(updatedAt, false))}
                {gridCellGenerator('last modified by', updatedBy)}
            </div>
        </Card>
    );
};
