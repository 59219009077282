import React from 'react';

import './UiVerticalMenu.scss';
import { IBaseProps } from '../IBaseProps.interface';

export interface IVerticalMenuProps extends IBaseProps {
    children: React.ReactNode;
}

/**
 * This is just a thin wrapper used to style ant design's menus as vertical menu with our styles
 */
export const UiVerticalMenu = (props: IVerticalMenuProps) => {
    return <div className={`VerticalMenu ${props.className}`}>{props.children}</div>;
};
