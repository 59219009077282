import { createListenerMiddleware, createSelector, createSlice, isAnyOf, ListenerEffectAPI } from '@reduxjs/toolkit';

import { history } from '../App';
import { serverApi } from 'api/baseServerApi';
import { AppStartListening, RootState } from 'general/store';

export interface ItenantsState {
    tenantKey: string;
}

export enum latestTransactionStatus {
    SUCCEED = 'SUCCEED',
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    TIMED_OUT = 'TIMED_OUT',
}
export interface ITenant {
    id: string;
    name: string;
    key: string;
    latest_transaction_status?: latestTransactionStatus | null;
    is_on_ch_cluster?: boolean;
    cluster_name?: string;
}

export interface ITenantResponse {
    count: number;
    items: ITenant[];
    total: number;
}

export const tenantListApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getTenantsList: builder.query<Record<string, ITenant>, void>({
            query: () => 'organizations',
            transformResponse: (responseData: ITenantResponse, meta) => {
                const tenantListFormatted = responseData.items.reduce((acc, tenant) => {
                    acc[tenant.key] = tenant;
                    return acc;
                }, {} as Record<string, ITenant>);
                return tenantListFormatted;
            },
            async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
                // After get tenant list request fulfilled, get tenant key from route, localStorage or first from tenant list
                cacheDataLoaded.then(() => {
                    dispatch(getTenantFromStorageOrRoute);
                });
            },
        }),
    }),
    overrideExisting: false,
});

export const tenantSlice = createSlice({
    name: 'tenantSlice',
    initialState: {} as ItenantsState,
    reducers: {
        getTenantFromStorageOrRoute: () => {},
        currentTenantKeyChanged: (state, { payload: { tenantKey } }) => ({ ...state, tenantKey }),
    },
});

/// -------------------------- Actions ---------------------------------------

export const { useGetTenantsListQuery } = tenantListApi;
export const { getTenantFromStorageOrRoute, currentTenantKeyChanged } = tenantSlice.actions;
/// ------------------------------------------------------------------------------

/// -------------------------- Selectors ---------------------------------------
export const selectGetTenantListResult = tenantListApi.endpoints.getTenantsList.select();
export const selectTenantList = createSelector(selectGetTenantListResult, ({ data }) => data);

export const selectTenantSlice = (state: RootState) => state.tenantSlice;
export const selectCurrentTenantKey = createSelector(selectTenantSlice, ({ tenantKey }) => tenantKey);
export const selectCurrentTenantDetails = createSelector(
    selectTenantList,
    selectTenantSlice,
    (tenantList, { tenantKey }) => tenantList && tenantList[tenantKey]
);
/// ------------------------------------------------------------------------------

export const listenerMiddleware = createListenerMiddleware();
export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const fourOFour = '404';

// if the tenantKey doesn't exist in tenant list then go to 404 component
const updateValidTenantKey = (
    tenantKey: string,
    tenantList: Record<string, ITenant>,
    api: ListenerEffectAPI<any, any>
) => {
    if (tenantList[tenantKey]) {
        api.dispatch(currentTenantKeyChanged({ tenantKey }));
    } else {
        api.dispatch(currentTenantKeyChanged({ tenantKey: fourOFour }));
    }
};

startAppListening({
    matcher: isAnyOf(getTenantFromStorageOrRoute),
    effect: (action, api: ListenerEffectAPI<any, any>) => {
        const tenantList = selectTenantList(api.getState());

        if (!tenantList) return;

        const tenantListKeys = Object.keys(tenantList);
        if (tenantListKeys.length === 0) return;

        // Get tenant key from route
        const routeCurrentTenant = history?.location?.pathname?.split('/');
        if (routeCurrentTenant && routeCurrentTenant[1] && routeCurrentTenant[1] !== '') {
            updateValidTenantKey(routeCurrentTenant[1], tenantList, api);
            return;
        }

        let tenantKey = tenantListKeys[0];
        // if not - Get tenant key from localStorage
        const activeTenantFromStorage = localStorage.getItem('tenantKey') || '';
        if (activeTenantFromStorage) {
            const activeTenantFromStorageParse = JSON.parse(activeTenantFromStorage);
            if (activeTenantFromStorageParse !== fourOFour) {
                tenantKey = JSON.parse(activeTenantFromStorage);
            }
            // if not - Get first tenant key from list and save new key in rtk, localStorage & navigate to route
        }

        updateValidTenantKey(tenantKey, tenantList, api);
    },
});

startAppListening({
    matcher: isAnyOf(currentTenantKeyChanged),
    effect: ({ payload }) => {
        const { tenantKey } = payload;
        localStorage.setItem('tenantKey', JSON.stringify(tenantKey));
    },
});
