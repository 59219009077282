import { checkFeature } from 'services/feature-toggle/feature-toggle-service';
import { datadogRum } from '@datadog/browser-rum';

const mixpanel = require('mixpanel-browser');

export function trackTenant(tenantKey: any, userEmail: string) {
    mixpanel.track('Tenant Active', {
        user: userEmail || 'N/A',
        tenant: tenantKey,
    });

    if (checkFeature(process.env, tenantKey, 'monitoring')) {
        datadogRum.init({
            applicationId: process.env.REACT_APP_REAL_TIME_MONITORING_APP_ID as string,
            clientToken: process.env.REACT_APP_REAL_TIME_MONITORING_CLIENT_TOKEN as string,
            site: 'datadoghq.com',
            service: process.env.REACT_APP_NAME,
            env: process.env.REACT_APP_ENV,
            version: process.env.REACT_APP_VERSION,
            sampleRate: 100,
            replaySampleRate: 0, // Removes recording sessions
            trackInteractions: false,
            useSecureSessionCookie: true, // Send only on HTTPS
        });
        datadogRum.addRumGlobalContext('tenant', tenantKey);
    }
}
