import { useSelector } from 'react-redux';
import { Button } from 'antd';

import { UiIcon } from 'components/icon/UiIcon';
import { useGetUserSessionTokenPayloadQuery } from 'api/userApi';
import { UiTooltip } from 'components/ui-tooltip/UiTooltip';
import { useDetok } from 'hooks/useDetok';
import { selectCurrentTenantKey } from 'api/tenantListApi';
import { checkFeature } from 'services/feature-toggle/feature-toggle-service';

import './Detokenizable.scss';

interface IDetokenizableProps {
    tokenizedContent: string;
    renderTooltip: (content: string) => JSX.Element | string;
    renderContent: (content: string) => JSX.Element | string;
    className?: string;
}

export const Detokenizable = ({ className, tokenizedContent, renderTooltip, renderContent }: IDetokenizableProps) => {
    const tenant = useSelector(selectCurrentTenantKey);
    const { data: userSessionTokenPayload } = useGetUserSessionTokenPayloadQuery(null);
    const [isDetokenized, content, toggleDetok] = useDetok(tokenizedContent);

    const getFooter = () => {
        if (!checkFeature(process.env, tenant, 'detokenization', userSessionTokenPayload?.hasDetokPermissions)) return;

        return (
            <Button type="text" icon={<UiIcon name="detokenize" />} onClick={toggleDetok}>
                {isDetokenized ? 'Revert to token' : 'De-tokenize'}
            </Button>
        );
    };

    return (
        <div className={`Detokenizable ${isDetokenized ? 'detokenized' : ''} ${className || ''}`}>
            <UiTooltip title={renderTooltip(content)} footer={getFooter()}>
                {renderContent(content)}
            </UiTooltip>
        </div>
    );
};
