import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';

import { HomeWidget } from 'components/home-widget/HomeWidget';
import { UiIcon } from 'components/icon/UiIcon';
import { httpGet } from 'general/http-service';
import { RecentOpenAlertsWidget } from 'components/recent-alerts/RecentOpenAlertsWidget';
import { fullDateStringFormat, getTimestampQuery } from 'general/utils';
import { TopEndpoints } from 'components/top-endpoints/TopEndpoints';
import { AlertsOverview, IAlertGridRow } from 'components/alerts-overview/AlertsOverview';
import { UiTooltip } from 'components/ui-tooltip/UiTooltip';
import Spinner from '../spinner/Spinner';
import { TopAlertingUsers } from 'components/top-alerting-users/TopAlertingUsers';
import { errorMessage } from 'general/toast-service';
import { IEntityInfo } from 'interfaces/user.interface';
import { getLocationLine } from 'components/user-summary/EntitySummary';
import { getTenantEndpoints } from '../discovery/shared/discoveryApis';
import { IEndpointOptionalFields } from '../discovery/shared/discoveryInterfaces';
import { selectCurrentTenantKey } from 'api/tenantListApi';
import { Detokenizable } from 'components/Detokenizable/Detokenizable';

import './Home.scss';

const usersHeader = [
    {
        title: 'user',
        icon: '',
    },
    {
        title: 'alerts',
        icon: 'bell16',
    },
    {
        title: 'location',
        icon: 'location16',
    },
    {
        title: 'active since',
        icon: 'calendar16',
    },
];

interface IAlertingUsersData {
    userName: JSX.Element;
    alerts: JSX.Element;
    activeSince: string;
    location: string;
    entityInfo?: IEntityInfo;
}

export interface IAlertsMap {
    labels: string[];
    data: number[][];
    severities: string[];
}

function enrichWithLocation(element: any) {
    if (element.entityInfo) {
        const locationLine = getLocationLine(element.entityInfo);
        return {
            ...element,
            location: (
                <UiTooltip title={locationLine}>
                    <div>{locationLine}</div>
                </UiTooltip>
            ),
        };
    }
    return element;
}

export const Home = () => {
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const [recentOpenAlertsData, setRecentOpenAlertsData] = useState<any[]>([]);
    const [topEndpointsData, setTopEndpointsData] = useState<any[]>([]);
    const [topAlertingUsersData, setTopAlertingUsersData] = useState<IAlertingUsersData[]>([]);
    const [alertsOverviewData, setAlertsOverviewData] = useState<{ heatMap: IAlertGridRow[] }>({ heatMap: [] });
    const history = useHistory();
    const usersRef = useRef<HTMLDivElement>(null);

    // spinner states
    const [alertOverviewSpinner, setAlertOverviewSpinner] = useState<boolean>(true);
    const [topUsersSpinner, setTopUsersSpinner] = useState<boolean>(true);
    const [recentOpenAlertsSpinner, setRecentOpenAlertsSpinner] = useState<boolean>(true);
    const [topEndpointsSpinner, setTopEndpointsSpinner] = useState<boolean>(true);

    useEffect(() => {
        if (currentTenantKey && currentTenantKey !== '') {
            history.push({ pathname: `/${currentTenantKey}/home` });
            getData(true);
            const interval = setInterval(
                () => {
                    getData(false);
                },
                60000,
                false
            );
            return () => {
                clearInterval(interval);
            };
        }
    }, [currentTenantKey]);

    const viewAllAlerts = () => {
        if (currentTenantKey) {
            history.push(`/${currentTenantKey}/alerts/`);
        }
    };

    const getData = (isInitialCall: boolean = false) => {
        if (isInitialCall) {
            allSpinners(true);
        }
        try {
            reloadRecentOpenAlertsCb();

            httpGet(
                `organizations/${currentTenantKey}/users?limit=20&sort_by=desc(open_alerts),desc(open_alerts_max_severity)&alerted=true`
            )
                .then((res: any) => {
                    setTopAlertingUsersData(prepareUsersDisplayData(res.data.items));
                    setTopUsersSpinner(false);
                })
                .catch((error) => {
                    console.error(error);
                    setTopAlertingUsersData([]);
                    setTopUsersSpinner(false);
                });

            httpGet(`organizations/${currentTenantKey}/alerts/label_by_severity`).then(
                (res: AxiosResponse<IAlertsMap>) => {
                    const heatMap = res.data.data.map((alertsArr, i) => {
                        return {
                            label: res.data.labels[i]?.trim(),
                            values: getlimitValArray(alertsArr),
                        };
                    });

                    setAlertsOverviewData((prev: any) => {
                        return {
                            ...prev,
                            heatMap,
                        };
                    });
                    setAlertOverviewSpinner(false);

                    function getlimitValArray(vals: number[]): number[] {
                        // makes sure the results are grouped into exactly 5 severities (info...critical)
                        let returnVals = [];
                        if (vals.length < 5) {
                            returnVals = [0, ...vals, 0];
                        } else if (vals.length > 5) {
                            returnVals = vals.slice(0, 4);
                        } else {
                            returnVals = [...vals];
                        }
                        return returnVals.reverse();
                    }
                }
            );

            httpGet(`organizations/${currentTenantKey}/config`)
                .then(({ data: { default_interval } }: any) => {
                    const timestampQuery = getTimestampQuery(default_interval || '1Y');
                    return getTenantEndpoints(
                        currentTenantKey,
                        timestampQuery.from_timestamp,
                        timestampQuery.to_timestamp,
                        '10',
                        '0',
                        'desc(call_count)',
                        undefined,
                        [IEndpointOptionalFields.call_count, IEndpointOptionalFields.call_percentage]
                    );
                })
                .then((res: any) => {
                    setTopEndpointsData(res.data.items);
                    setTopEndpointsSpinner(false);
                })
                .catch((error) => {
                    console.error(error);
                    setTopEndpointsData([]);
                    setTopEndpointsSpinner(false);
                });
        } catch (err) {
            errorMessage('Error fetching Data');
        }
    };

    const reloadRecentOpenAlertsCb = () => {
        httpGet(`organizations/${currentTenantKey}/alerts?sort_by=desc(timestamp),desc(severity)&limit=10&status=Open`)
            .then((res: any) => {
                setRecentOpenAlertsData(res?.data.items); // show last 10 alerts in the recent alert panel
                setRecentOpenAlertsSpinner(false);
            })
            .catch((error) => {
                console.error(error);
                setRecentOpenAlertsData([]);
                setRecentOpenAlertsSpinner(false);
            });
    };

    const prepareUsersDisplayData = (userData: any[]): IAlertingUsersData[] => {
        return userData
            .map((userInfo) => {
                const alerts = (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {userInfo.open_alerts_max_severity && (
                            <UiIcon name={`alert${userInfo.open_alerts_max_severity}`} />
                        )}
                        <span style={{ marginLeft: '6px' }}>{userInfo.open_alerts}</span>
                    </div>
                );

                return {
                    userName: (
                        <Detokenizable
                            tokenizedContent={userInfo.id}
                            renderTooltip={(content) => content}
                            renderContent={(content) => <div className="user-name-text no-wrap">{content}</div>}
                        />
                    ),
                    alerts,
                    activeSince: fullDateStringFormat(userInfo.first_activity_time, false),
                    entityInfo: userInfo.entity_info,
                };
            })
            .map(enrichWithLocation);
    };

    const allSpinners = (val: boolean) => {
        setAlertOverviewSpinner(val);
        setTopUsersSpinner(val);
        setRecentOpenAlertsSpinner(val);
        setTopEndpointsSpinner(val);
    };

    return (
        <div className="home-container">
            <div className="alert-overview">
                <HomeWidget title="Alert Overview">
                    <Spinner show={alertOverviewSpinner} paddingBottom={50} />
                    {!alertOverviewSpinner &&
                        (!!alertsOverviewData?.heatMap?.length ? (
                            <AlertsOverview alertsData={alertsOverviewData} />
                        ) : (
                            !alertOverviewSpinner && <span className="info-message">No alert data available yet</span>
                        ))}
                </HomeWidget>
            </div>
            <div className="top-alerting-users" ref={usersRef}>
                <HomeWidget title="Top Alerting Users">
                    <Spinner show={topUsersSpinner} paddingBottom={50} />
                    {!topUsersSpinner &&
                        (!!topAlertingUsersData?.length ? (
                            <TopAlertingUsers data={topAlertingUsersData} headers={usersHeader} />
                        ) : (
                            <span className="info-message">No alert data available yet</span>
                        ))}
                </HomeWidget>
            </div>

            <div className="top-endpoints">
                <HomeWidget title="Top Endpoints">
                    <Spinner show={topEndpointsSpinner} paddingBottom={50} />
                    {!topEndpointsSpinner &&
                        (!!topEndpointsData.length ? (
                            <TopEndpoints endpointsData={topEndpointsData} />
                        ) : (
                            <span className="info-message">No endpoint data available yet</span>
                        ))}
                </HomeWidget>
            </div>

            <div className="recent-alerts-container">
                <HomeWidget title="Recent Open Alerts" headerButton={{ text: 'view all', onClick: viewAllAlerts }}>
                    <Spinner show={recentOpenAlertsSpinner} paddingBottom={50} />
                    {!recentOpenAlertsSpinner &&
                        (!!recentOpenAlertsData.length ? (
                            <RecentOpenAlertsWidget
                                alertsData={recentOpenAlertsData}
                                reloadAlertsCb={reloadRecentOpenAlertsCb}
                            />
                        ) : (
                            <span className="info-message">No alert data available yet</span>
                        ))}
                </HomeWidget>
            </div>
        </div>
    );
};
