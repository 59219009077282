import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { PredicateType, SuppressionRulePredicate } from './SuppressionRulePredicate';
import { message, Radio } from 'antd';
import { useHistory } from 'react-router';
import {
    ISuppressionRule,
    ISuppressionRuleForm,
    ISuppressionRulePredicate,
} from '../../../../interfaces/throttle.interface';
import { EntityName } from '../../../../services/data/entityMap';
import { useEntity } from '../../../../services/data/hooks';
import { filterNullValues, omit } from '../../../../general/utils';
import { UiIcon } from '../../../icon/UiIcon';
import { IOverlayMenu, UiOverlayMenu } from '../../../ui-overlay-menu/UiOverlayMenu';
import { UiInput } from '../../../ui-input/UiInput';
import { UiButton } from '../../../button/Button';
import { UiTitle } from '../../../ui-title/UiTitle';

import './SuppressionRule.scss';
import { EntityMultiSelectState, isValidEntity } from '../../../entity-multi-select/EntityMultiSelect';
import { ISimpleEndpoint } from '../../../../interfaces/endpoint.interface';

export const predicateTypeMap: { [key: string]: PredicateType } = {
    endpoints: PredicateType.endpoint,
    services: PredicateType.service,
    associated_entities: PredicateType.associatedEntity,
    alert_description: PredicateType.containsText,
    alert_names: PredicateType.alertName,
    alert_categories: PredicateType.alertCategory,
    alert_severities: PredicateType.alertSeverity,
    labels: PredicateType.label,
};

export enum SuppressionRuleType {
    Alert = 'alert',
    Label = 'label',
}

function getNextRuleOrder(ruleList: ISuppressionRule[]): number {
    if (ruleList.length === 0) {
        return 0;
    }

    return (
        ruleList.reduce((order, rule) => {
            return rule.rule_order > order ? rule.rule_order : order;
        }, 0) + 1
    );
}

function validateRuleForm(ruleForm: ISuppressionRule): { [key: string]: string } {
    const ruleFormError: { [key: string]: string } = {};

    if (!ruleForm.rule_name) {
        ruleFormError.rule_name = 'This field is required';
    }

    if (
        ruleForm.predicate.associated_entities &&
        ruleForm.predicate.associated_entities?.length > 0 &&
        ruleForm.predicate.associated_entities?.filter((entity) => !isValidEntity(entity)).length > 0
    ) {
        ruleFormError.associated_entity = 'This field is required';
    }

    return ruleFormError;
}

export const SuppressionRule: React.FC = () => {
    const history = useHistory();
    const params = useParams() as { activeOrg: string; ruleId: string };
    const activeOrg = params.activeOrg;
    const parentId = new URLSearchParams(useLocation().search).get('parentId') as string;
    const ruleType = new URLSearchParams(useLocation().search).get('type') as SuppressionRuleType;
    const ruleId = params.ruleId;
    const [ruleForm, setRuleForm] = useState<ISuppressionRuleForm>();
    const [ruleFormError, setRuleFormError] = useState<{ [key: string]: string }>({});
    const [rule, updateRule, createRule, ruleError] = useEntity<ISuppressionRule>(
        EntityName.SuppressionRule,
        {},
        ruleId || null
    );
    const [ruleList] = useEntity<{ items: ISuppressionRule[] }>(EntityName.SuppressionRule);
    const [parentFeature] = useEntity<any>(EntityName.Alerts, {}, parentId || null);

    useEffect(() => {
        if (rule && ruleList && (!parentId || parentFeature)) {
            setRuleForm({
                ...(omit(['created_by', 'updated_by', 'created_at', 'updated_at'], rule) as ISuppressionRuleForm),
                rule_order:
                    rule.rule_order || rule.rule_order === 0 ? rule.rule_order : getNextRuleOrder(ruleList.items),
                rule_type: ruleType || rule.rule_type,
                rule_name: parentFeature
                    ? `Suppress ${ruleType === 'label' ? 'Label' : 'Alert'} ${parentFeature.name}`
                    : rule.rule_name,
                predicate: parentFeature
                    ? ({ alert_names: [parentFeature.name] } as ISuppressionRulePredicate)
                    : filterNullValues(rule.predicate),
            });
        }
    }, [parentFeature, rule, ruleList]);

    useEffect(() => {
        if (ruleError) {
            message.error(ruleError);
        }
    }, [ruleError]);

    function save() {
        if (Object.keys(ruleFormError as {}).length) {
            return;
        }

        const payload = {
            ...ruleForm,
            predicate: {
                ...ruleForm?.predicate,
                endpoints: ruleForm?.predicate.endpoints?.map((endpoint: ISimpleEndpoint | string) =>
                    endpoint.hasOwnProperty('id') ? (endpoint as ISimpleEndpoint).id : (endpoint as string)
                ),
            },
        };

        let promise;

        if (rule?.id) {
            promise = updateRule(payload);
        } else {
            promise = createRule(payload);
        }

        promise
            .then(() => {
                history.push({ pathname: `/${activeOrg}/settings/suppression` });
            })
            .catch((error: any) => {
                message.error(error);
            });
    }

    function cancel() {
        history.push(`/${activeOrg}/settings/suppression`);
    }

    function onFormChange(key: string, value: any) {
        let newRuleForm: ISuppressionRule;

        if (key === 'rule_name' || key === 'frequency' || key === 'timeframe') {
            newRuleForm = {
                ...ruleForm,
                [key]: value,
            } as ISuppressionRule;
        } else if (key === 'alert_description' && value !== undefined) {
            newRuleForm = {
                ...ruleForm,
                predicate: {
                    ...ruleForm?.predicate,
                    [key]: {
                        operand: value.contains === undefined || value.contains ? 'contains' : 'not contains',
                        value: value.text,
                    },
                },
            } as ISuppressionRule;
        } else if (key === 'associated_entities' && value?.length === 0) {
            removePredicate(key);
            return;
        } else if (key === 'associated_entities') {
            newRuleForm = {
                ...ruleForm,
                predicate: {
                    ...ruleForm?.predicate,
                    [key]: value?.map?.((v: EntityMultiSelectState) => v.entity),
                },
            } as ISuppressionRule;
        } else {
            newRuleForm = {
                ...ruleForm,
                predicate: {
                    ...ruleForm?.predicate,
                    [key]: value,
                },
            } as ISuppressionRule;
        }

        setRuleForm(newRuleForm);
        setRuleFormError(validateRuleForm(newRuleForm));
    }

    function createPredicate(key: string, value?: any, withSeparator?: boolean): JSX.Element {
        return (
            <div key={key} className="sr-predicate-container">
                {withSeparator ? (
                    <div className="sr-separator">
                        AND
                        <div className="sr-separator-line"></div>
                    </div>
                ) : null}
                <div className="sr-predicate">
                    {key !== 'associated_entities' ? (
                        <span className="sr-remove">
                            <UiIcon name="remove" onClick={() => removePredicate(key)} />
                        </span>
                    ) : null}
                    <SuppressionRulePredicate
                        predicateType={predicateTypeMap[key]}
                        value={value || []}
                        onChange={(e: any) => onFormChange(key, e)}
                    />
                </div>
            </div>
        );
    }

    function createPredicateElements(predicate: ISuppressionRulePredicate): JSX.Element[] {
        return Object.keys(predicate)
            .filter(
                (key) =>
                    predicate[key as keyof ISuppressionRulePredicate] !== undefined &&
                    predicate[key as keyof ISuppressionRulePredicate] !== null
            )
            .map((key, i) =>
                createPredicate(
                    key,
                    key === 'alert_description'
                        ? {
                              contains: predicate.alert_description?.operand === 'contains',
                              text: predicate.alert_description?.value,
                          }
                        : predicate[key as keyof ISuppressionRulePredicate],
                    i > 0
                )
            );
    }

    function addPredicate(key: string) {
        onFormChange(key, key === 'associated_entities' ? [{ entity: { type: '', id: [] } }] : []);
    }

    function removePredicate(key: string) {
        onFormChange(key, undefined);
    }

    function getPredicateOptions(predicate: ISuppressionRulePredicate): IOverlayMenu['menuItems'] {
        const menuItems = [];

        if (!predicate.endpoints) {
            menuItems.push({
                label: 'Endpoint',
                onClick: () => addPredicate('endpoints'),
            });
        }

        if (!predicate.associated_entities) {
            menuItems.push({
                label: 'Entity',
                onClick: () => addPredicate('associated_entities'),
            });
        }

        if (!predicate.services) {
            menuItems.push({
                label: 'Service',
                onClick: () => addPredicate('services'),
            });
        }

        if (!predicate.alert_names) {
            menuItems.push({
                label: 'Alert Name',
                onClick: () => addPredicate('alert_names'),
            });
        }

        if (!predicate.alert_categories) {
            menuItems.push({
                label: 'Alert Category',
                onClick: () => addPredicate('alert_categories'),
            });
        }

        if (!predicate.alert_severities) {
            menuItems.push({
                label: 'Alert Severity',
                onClick: () => addPredicate('alert_severities'),
            });
        }

        if (!predicate.alert_description) {
            menuItems.push({
                label: 'Alert Description',
                onClick: () => addPredicate('alert_description'),
            });
        }

        if (!predicate.labels) {
            menuItems.push({
                label: 'Label',
                onClick: () => addPredicate('labels'),
            });
        }

        return menuItems;
    }

    return ruleForm ? (
        <div className="suppression-rule">
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                <UiInput
                    label="Rule Name"
                    defaultValue={ruleForm?.rule_name}
                    onChange={(event: any) => onFormChange('rule_name', event.target.value)}
                    width={500}
                    error={ruleFormError?.rule_name}
                />
                <div>
                    <UiButton type="primary" text="Save" onClick={() => save()} />
                    <UiButton type="secondary" text="Cancel" onClick={() => cancel()} style={{ marginLeft: '5px' }} />
                </div>
            </div>
            <UiTitle type="large" title="Conditions" />
            <div className="box sr-conditions">
                <div className="sr-predicate-list">{createPredicateElements(ruleForm.predicate)}</div>
                <div style={{ width: '200px' }}>
                    <UiOverlayMenu
                        icon={
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <UiIcon name="add" />
                                <span style={{ color: '#436ace', marginLeft: '5px' }}>Add condition</span>
                            </div>
                        }
                        menuItems={getPredicateOptions(ruleForm.predicate)}
                    />
                </div>
            </div>
            <UiTitle type="large" title="Actions" />
            <div className="box" style={{ padding: '15px', marginTop: '5px' }}>
                <Radio.Group
                    value={ruleForm.frequency}
                    onChange={(event) => onFormChange('frequency', event.target.value)}
                >
                    <Radio value={0}>Suppress</Radio>
                    <Radio value={ruleForm.frequency === 0 ? undefined : ruleForm.frequency}>Throttle</Radio>
                </Radio.Group>
                {ruleForm.frequency !== 0 ? (
                    <div>
                        <UiTitle type="medium" title="Configure Time Window" />
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                            <span style={{ marginRight: '15px' }}>Once every</span>
                            <UiInput
                                width={150}
                                defaultValue={ruleForm.frequency}
                                onBlur={(event: any) => onFormChange('frequency', parseInt(event.target.value))}
                            />
                            <Radio.Group
                                // optionType="button"
                                style={{ marginLeft: '15px' }}
                                buttonStyle="solid"
                                defaultValue={ruleForm.timeframe || 'day'}
                                onChange={(event) => onFormChange('timeframe', event.target.value)}
                            >
                                <Radio value="day">Days</Radio>
                                <Radio value="hour">Hours</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    ) : null;
};
