import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { IConfigResponse, useGetConfigQuery } from 'api/configApi';
import { SwaggerDownloadButton } from 'components/swagger-download-button/SwaggerDownloadButton';
import { getTimestampQueryParams, TDatetimeRangeOrPreset, isDisabledDate, TDatetimeRange } from 'general/utils';
import { LabelList } from 'components/label-list/LabelList';
import { getDefaultTimeRange, getOrganizationConfig } from 'general/organization-service';
import { INavigationLinks } from 'components/breadcrumb/BreadCrumb';
import { IBaseLabel, IEndpointLabel, IServiceLabel, LabelSuppressPeriod } from 'interfaces/labels.interface';
import { DateRangePresets, UiChronoRangePicker } from 'components/shared/UiChronoRangePicker/UiChronoRangePicker';
import { EndpointRedirect } from './EndpointRedirect/EndpointRedirect';
import { getDateRangeFromPreset } from 'components/shared/UiChronoRangePicker/utils';

import './DiscoveryHeader.scss';

interface IServicesHeaderProps {
    queryParams: any;
    selectedServices: any[];
    timeRangeFromInput: TDatetimeRange;
    pathParams: any;
    title: string;
    isNewLabel: boolean;
    isLabelList: boolean;
    onTimeChange: (range: TDatetimeRange) => void;
    labelList: IServiceLabel[] | IEndpointLabel[];
    isEndpointSelect?: boolean;
    breadcrumb?: INavigationLinks[];
    selectedRouteName?: string;
    isSwagger?: boolean;
    onLabelCreate: (labelText: string) => Promise<any>;
    onLabelDelete: (labelId: string) => Promise<any>;
    onLabelSuppress: (labelId: string, period: LabelSuppressPeriod) => Promise<any>;
    canDeleteLabel: (label: IBaseLabel) => boolean;
}

const discoveryPagesDatePresets: DateRangePresets = {
    Today: [moment().startOf('day'), moment()],
    'Last 24 Hours': [moment().subtract(24, 'h'), moment()],
    'Last 7 Days': [moment().subtract(7, 'd').startOf('day'), moment().endOf('day')],
    'Last 14 Days': [moment().subtract(14, 'd').startOf('day'), moment().endOf('day')],
};

export const DiscoveryHeader = (props: IServicesHeaderProps) => {
    const { queryParams, selectedServices, onTimeChange, timeRangeFromInput, pathParams, title } = props;
    const activeTenant = pathParams.activeOrgParam;
    const [config, setConfig] = useState<IConfigResponse>();
    const { data: conf } = useGetConfigQuery(activeTenant);
    const location = useLocation();
    const history = useHistory();

    const onDateChangeHandler = (range: TDatetimeRangeOrPreset) => {
        const dateRangeFromPreset = getDateRangeFromPreset(range, config!);
        const timestampQueryParams = getTimestampQueryParams(location.search, dateRangeFromPreset);
        onTimeChange(dateRangeFromPreset);
        history.push(`${location.pathname}${timestampQueryParams}`);
    };

    const setTimeRangeByConfig = (configParam: IConfigResponse) => {
        const UrlTimestamp = {
            fromTimestamp: queryParams.get('from_timestamp'),
            toTimestamp: queryParams.get('to_timestamp'),
        };
        const ifTimestampNotInURL = !UrlTimestamp.fromTimestamp || !UrlTimestamp.toTimestamp;
        if (ifTimestampNotInURL && configParam) {
            const defaultTimeRange = getDefaultTimeRange(
                configParam?.timeframe?.start_timestamp,
                configParam?.timeframe?.end_timestamp
            );
            onDateChangeHandler(defaultTimeRange);
        }
    };

    useEffect(() => {
        config && setTimeRangeByConfig(config);
    }, [config]);

    useEffect(() => {
        getOrganizationConfig(activeTenant).then(setConfig).catch(setTimeRangeByConfig);
    }, []);

    const onLabelDelete = (labelId: string) => {
        return props.onLabelDelete(labelId);
    };

    const onLabelSuppress = (labelId: string, period: LabelSuppressPeriod) => {
        return props.onLabelSuppress(labelId, period);
    };

    const onLabelCreate = (labelText: string) => {
        return props.onLabelCreate(labelText);
    };

    const disabledDate = useMemo(() => (config ? isDisabledDate(config) : () => false), [config]);

    return (
        <div className="page-header-container">
            <div className="discovery-header-first-line">
                <div className="services-header">
                    <EndpointRedirect
                        queryParams={queryParams}
                        title={title}
                        pathParams={pathParams}
                        timeRange={timeRangeFromInput}
                        isEndpointSelect={props.isEndpointSelect}
                        breadcrumb={props.breadcrumb}
                        selectedRouteName={props.selectedRouteName}
                    />
                </div>
                <div className="top-controls">
                    <div className={`services-control`}>
                        <UiChronoRangePicker
                            presets={discoveryPagesDatePresets}
                            label="Time Range"
                            value={timeRangeFromInput}
                            disabledDate={disabledDate}
                            onChange={onDateChangeHandler}
                        />
                    </div>
                    {props.isSwagger && (
                        <>
                            <div className={'services-control'}>
                                <span> | </span>
                            </div>
                            <div className={'services-control'}>
                                <SwaggerDownloadButton
                                    items={selectedServices}
                                    tenant={activeTenant}
                                    fromTimestamp={timeRangeFromInput[0].unix()}
                                    toTimestamp={timeRangeFromInput[1].unix()}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            {props.isLabelList && (
                <div className="discovery-header-second-line">
                    <LabelList
                        labels={props.labelList || []}
                        allLabelsOptions={conf?.labels?.map((label) => ({ label, value: label }))}
                        canDeleteLabel={props.canDeleteLabel}
                        onLabelDelete={onLabelDelete}
                        onLabelCreate={onLabelCreate}
                        onLabelSuppress={onLabelSuppress}
                        newLabel={props.isNewLabel}
                        isWider={true}
                    />
                </div>
            )}
        </div>
    );
};
