import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dropdown, MenuProps } from 'antd';

import { useGetScanQuery } from '../../../api/testingApi';
import { BreadCrumb } from '../../breadcrumb/BreadCrumb';
import {
    MultiExpandableContainer,
    ContentVisibilityModeEnum,
} from '../../MultiExpandableContainer/MultiExpandableContainer';
import { UiIcon } from '../../icon/UiIcon';
import { ITestingPathParams } from '../Testing';
import { ScanExtraDetails } from './ScanExtraDetails/ScanExtraDetails';
import { ScanDetailsSummary } from './ScanDetailsSummary/ScanDetailsSummary';
import { ScanRelatedEndpoints } from './ScanRelatedEndpoints/ScanRelatedEndpoints';
import { FindingTypesTable } from './FindingTypesTable/FindingTypesTable';

import './ScanDetails.scss';

export interface IScanDetailsPathParams extends ITestingPathParams {
    scanId: string;
}

export interface IScanListProps {
    className?: string;
}

export const ScanDetails = (props: IScanListProps) => {
    const [isHeaderExpanded, setIsHeaderExpanded] = useState(false);
    const { activeOrg, scanId } = useParams<IScanDetailsPathParams>();
    const { data: scan } = useGetScanQuery({ tenant: activeOrg, scanId });

    const items: MenuProps['items'] = [
        {
            key: '1',
            disabled: true,
            label: (
                <div className="export-dropdown-action-item" onClick={() => {}}>
                    <span className="item-text">CSV</span>
                </div>
            ),
        },
        {
            key: '2',
            disabled: true,
            label: (
                <div className="export-dropdown-action-item" onClick={() => {}}>
                    <span className="item-text">PDF</span>
                </div>
            ),
        },
        {
            key: '3',
            disabled: true,
            label: (
                <div className="export-dropdown-action-item" onClick={() => {}}>
                    <span className="item-text">JSON</span>
                </div>
            ),
        },
    ];

    return scan ? (
        <div className={`ScanDetails${props.className ? ` ${props.className}` : ''}`}>
            <BreadCrumb
                isPassParams={true}
                breadcrumbList={[
                    {
                        url: `/${activeOrg}/testing/scans`,
                        text: 'Scans',
                    },
                    {
                        url: `/${activeOrg}/testing/scans/${scanId}/`,
                        text: `Scan ${scanId}`,
                    },
                ]}
                selectedRoute={('Scan ' + scanId) as string}
            />
            <div className="page-header">
                <span className="scan-summary-title">Scan {scan?.scan_id}</span>
                <Dropdown
                    className="alert-dropdown"
                    overlayClassName="UiDropdownOverlay"
                    trigger={['click']}
                    placement="bottomRight"
                    menu={{ items }}
                >
                    <div className="dropdown-container">
                        <span>Export</span>
                        <UiIcon name="downArrowWhite" />
                    </div>
                </Dropdown>
            </div>
            <MultiExpandableContainer
                contentArray={[
                    { content: <ScanDetailsSummary scan={scan} />, mode: ContentVisibilityModeEnum.SHOWN_ALWAYS },
                    {
                        content: (
                            <div className={`expanded-section${isHeaderExpanded ? ' isExpanded' : ''}`}>
                                <ScanExtraDetails scan={scan} activeOrg={activeOrg} />
                                <ScanRelatedEndpoints excluded_endpoints={(scan as any)?.excluded_endpoints ?? []} />
                            </div>
                        ),
                        mode: ContentVisibilityModeEnum.SHOWN_EXPANDED,
                    },
                ]}
                isExpanded={isHeaderExpanded}
                toggleExpanded={() => setIsHeaderExpanded((prev) => !prev)}
                className="scan-details-expandable"
            />

            <div className="finding-types-table">
                <FindingTypesTable />
            </div>
        </div>
    ) : null;
};
