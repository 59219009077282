import React from 'react';
import { Select } from 'antd';
import { IDataSourceType, useGetDataSourceTypesQuery } from '../../api/nodeApi';
import UiMultiSelect from '../ui-multi-select/UiMultiSelect';

import './DataSourceTypesMultiSelect.scss';

const { Option } = Select;

interface IDataSourceTypesMultiSelectProps {
    width: number;
    onChange: (event: string[]) => void;
}

export const DataSourceTypesMultiSelect = (props: IDataSourceTypesMultiSelectProps) => {
    const { data: dataSourceTypes, isLoading } = useGetDataSourceTypesQuery();

    return (
        <div className="DataSourceTypesMultiSelect">
            <div className="label">Data Source Type</div>
            <UiMultiSelect
                mode="multiple"
                showDropdown={true}
                width={424}
                height="auto"
                handleChange={(list: string[]) => {
                    props.onChange(list);
                }}
            >
                {!isLoading && dataSourceTypes
                    ? dataSourceTypes.items.map((type: IDataSourceType) => (
                          <Option key={type.filename} value={type.filename}>
                              {type.displayName}
                          </Option>
                      ))
                    : null}
            </UiMultiSelect>
        </div>
    );
};
