import { skipToken } from '@reduxjs/toolkit/query';
import { Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import { copyToClipboard } from '../../../../general/utils';
import { useGetScanQuery, useGetScanCliCommandQuery } from '../../../../api/testingApi';
import { UiModal } from '../../../ui-modal/UiModal';

import './ScanRunModal.scss';

export interface IScanRunModalProps {
    activeOrg: string;
    scanId?: string;
    onClose: () => void;
}

export const ScanRunModal = ({ activeOrg, scanId, onClose }: IScanRunModalProps) => {
    const { data: scan } = useGetScanQuery(!!scanId ? { tenant: activeOrg, scanId } : skipToken);
    const { data: cliCommandData } = useGetScanCliCommandQuery(!!scanId ? { tenant: activeOrg, scanId } : skipToken);

    return (
        <UiModal
            className="ScanRunLocallyModal"
            title="Run Locally"
            acceptButton={{
                text: 'OK',
                onClick: onClose,
            }}
            onCancel={onClose}
            width={640}
            isVisible={!!scanId}
            isFormModal
        >
            <section>
                <div className="subtitle">Instructions</div>
                <div className="section-content instructions">
                    <ol>
                        <li>Make sure Docker is installed on your machine</li>
                        <li>Run the two commands below to execute the scan</li>
                    </ol>
                </div>
            </section>
            <section>
                <div className="subtitle">Commands</div>
                <div className="section-content command">
                    <>
                        <Button
                            ghost
                            icon={<CopyOutlined />}
                            onClick={() => copyToClipboard(cliCommandData?.cli_command || '')}
                            className="copy-button"
                        />
                        <span className="command-content">{cliCommandData?.cli_command}</span>
                    </>
                </div>
            </section>
        </UiModal>
    );
};
