import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { SingleCallDetails } from '../single-call-details/SingleCallDetails';
import { GenericDetails } from './generic-details/GenericDetails';
import { IAlert } from '../../interfaces/alert.interface';
import { UiTitle } from '../ui-title/UiTitle';
import { bulletSpaceMarkup, decodeHTMLChars } from '../../general/utils';
import { CommentCard } from '../comment-card/CommentCard';
import { useVirtualScroll } from '../../hooks/useInfiniteScroll';
import { ICommentsResponse, useGetAlertCommentsQuery } from '../../api/alertApi';
import { useParams } from 'react-router-dom';
import Spinner from '../../containers/spinner/Spinner';
import { CommentAddPopover } from '../comment-add-popover/CommentAddPopover';
import { UiIcon } from '../icon/UiIcon';
import { UiButton } from '../button/Button';
import {
    MultiExpandableContainer,
    ContentVisibilityModeEnum,
} from '../MultiExpandableContainer/MultiExpandableContainer';

import './BusinessImpact.scss';

interface IBusinessImpact {
    isCall: boolean;
    data: any | IAlert;
    onCommentCountChange: (comments_count: number) => void;
    isExpanded: boolean;
    toggleExpanded: () => void;
}

export const BusinessImpact = ({ isCall, data, onCommentCountChange, isExpanded, toggleExpanded }: IBusinessImpact) => {
    const DEFAULT_SIZE = 30;
    const [page, setPage] = useState(1);
    const scrollRef = useRef<HTMLDivElement>(null);
    const { activeOrgParam } = useParams<{ activeOrgParam: string }>();
    const {
        data: comments,
        isLoading,
        isFetching,
    }: ICommentsResponse = useGetAlertCommentsQuery(
        {
            tenant: activeOrgParam,
            alertId: data.id,
            limit: page * DEFAULT_SIZE,
            offset: 0,
        },
        { skip: isCall }
    );

    const handleCommentCountChange = useCallback((comments_count: number) => {
        onCommentCountChange(comments_count);
    }, []);

    useEffect(() => {
        if (comments?.total) {
            handleCommentCountChange(comments.total);
        }
    }, [comments?.total, handleCommentCountChange]);

    useVirtualScroll(() => {
        if (Math.ceil((comments?.total ?? 0) / DEFAULT_SIZE) > page) {
            setPage((pg) => pg + 1);
        }
    }, scrollRef);

    const expandableContent = useRef(null);
    const [expandableContentHeight, setExpandableContentHeight] = useState(200);

    useLayoutEffect(() => {
        if (expandableContent.current) {
            const infoPanelHeight = (expandableContent?.current as unknown as Element).clientHeight + 20;
            setExpandableContentHeight(infoPanelHeight);
        }
    });

    const dataKeys = [
        { key: 'description', header: 'description' },
        { key: 'category', header: 'category' },
        { key: 'severity', header: 'severity' },
    ];

    const addCommentSection = (
        <CommentAddPopover alert={data}>
            <div
                className={`add-comment-button ${(comments?.total ?? 0) >= 200 ? 'disabled-btn' : ''}`}
                onClick={() => {}}
            >
                <UiIcon name="add" />
                <UiButton className="add-comment-btn" type="text" text="Add comment" />
            </div>
        </CommentAddPopover>
    );

    return (
        <div className="impact-container">
            {isCall ? (
                <SingleCallDetails data={data} />
            ) : (
                <MultiExpandableContainer
                    contentArray={[
                        {
                            content: <GenericDetails tenant={activeOrgParam!} dataObject={data} dataKeys={dataKeys} />,
                            mode: ContentVisibilityModeEnum.SHOWN_ALWAYS,
                        },
                        {
                            content: (
                                <div
                                    className={`expandable-content ${isExpanded ? 'open' : 'closed'}`}
                                    ref={expandableContent}
                                >
                                    <div className={`expandable-content-cols ${!comments?.total ? 'full-width' : ''}`}>
                                        <div className="col">
                                            <UiTitle title="More Info" type="medium" />
                                            <div className="section-content more-info pre-wrap">
                                                {bulletSpaceMarkup(decodeHTMLChars(data.alert_info)) || 'N/A'}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="section-header">
                                                <UiTitle title="Recommendations" type="medium" />
                                                {!comments?.total ? addCommentSection : null}
                                            </div>
                                            <div className="section-content recommendations pre-wrap">
                                                {bulletSpaceMarkup(decodeHTMLChars(data.recommendations)) || 'N/A'}
                                            </div>
                                        </div>
                                    </div>
                                    {comments?.total ? (
                                        <div className="section-comments">
                                            <div className="section-header">
                                                <UiTitle title={`Comments (${comments?.total})`} type="medium" />
                                                {addCommentSection}
                                            </div>
                                            <div
                                                ref={scrollRef}
                                                className="section-content"
                                                style={{ height: expandableContentHeight - 74 }}
                                            >
                                                <Spinner show={isLoading || isFetching} />
                                                {comments?.items.map((item, index) => (
                                                    <CommentCard
                                                        key={`comment-${data.id}-${index}`}
                                                        alertId={data.id}
                                                        comment={item}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            ),
                            mode: ContentVisibilityModeEnum.SHOWN_EXPANDED,
                        },
                    ]}
                    isExpanded={isExpanded}
                    toggleExpanded={toggleExpanded}
                    className="business-impact-expandable"
                />
            )}
        </div>
    );
};
