import React, {forwardRef, useImperativeHandle} from "react";

export const CustomTooltip = forwardRef((props: any, ref) => {

    const isHeader = props.rowIndex === undefined;
    const isGroupedHeader = isHeader && !!props.colDef.children;
    const valueToDisplay = props.value.value ? props.value.value : '- Missing -';
    useImperativeHandle(ref, () => {
        return {
            getReactContainerClasses() {
                return ['custom-tooltip'];
            },
        };
    });

    return isHeader ? (
            <div className="custom-tooltip">
                <div className="tooltip-inner"> {props.value}</div>
            </div>
        )
        : (
            <div className="custom-tooltip">
                <p>
                    <span>{valueToDisplay}</span>
                </p>
            </div>
        );
});
