import moment from 'moment';

import { TDatetimeRangeOrPreset, TDatetimeRange } from 'general/utils';
import { LabelValue } from 'interfaces/labels.interface';
import { IConfigResponse } from 'api/configApi';

export const ONE_DAY_IN_MINUTES = 1440;

export const EOD_TIME_LABEL_VALUE: LabelValue = { label: 'EOD', value: (ONE_DAY_IN_MINUTES - 1).toString() };

// get a range of quantity of minutes in 24 hours with 15 minute intervals
// ['00', '01', '02', ... , '23'] -> [0, 15, 30, 45, 60, 75, ... , 1425]
export const get15MinuteIntervals = (hourRange: string[]): number[] => {
    return hourRange
        .map((hour) => parseInt(hour))
        .flatMap((hour) => [hour * 60, hour * 60 + 15, hour * 60 + 30, hour * 60 + 45]);
};

// ['00', '01', '02', ... , '23'] -> ['00:00', '00:15', '00:30', '00:45', ... , '23:45']
export const get15MinuteIntervalsFormatted = (hourRange: string[]): string[] => {
    return hourRange.flatMap((hour) => [`${hour} : 00`, `${hour} : 15`, `${hour} : 30`, `${hour} : 45`]);
};

export const allTimeOptions = (): LabelValue[] => {
    const hourRange = [...Array(24).keys()].map((hour) => String(hour).padStart(2, '0'));
    const timeLabels = get15MinuteIntervalsFormatted(hourRange);
    const timeValues = get15MinuteIntervals(hourRange);
    const options = timeLabels
        .map((v, i) => ({ label: v, value: String(timeValues[i]) }))
        .concat([EOD_TIME_LABEL_VALUE]);

    return options;
};

export const DATE_FORMAT = 'DD MMM YYYY';

export const minutesOfDayToHoursMinutes = (minutes: string): string => {
    if (minutes === (ONE_DAY_IN_MINUTES - 1).toString()) {
        return 'EOD'; // 1439 minutes === 23:59
    }

    return `${Math.floor(Number(minutes) / 60)
        .toString()
        .padStart(2, '0')}:${(Number(minutes) % 60).toString().padStart(2, '0')}`;
};

export type TPresetName = 'Today' | 'Last 24 Hours' | 'Last 7 Days' | 'Last 14 Days' | 'Last 30 Days';

export const PRESET_DICT: Record<TPresetName, number> = {
    Today: 0,
    'Last 24 Hours': 24,
    'Last 7 Days': 7 * 24,
    'Last 14 Days': 14 * 24,
    'Last 30 Days': 30 * 24,
};

export const getDateRangeFromPreset = (range: TDatetimeRangeOrPreset, config: IConfigResponse): TDatetimeRange => {
    if (typeof range === 'string') {
        return [
            moment(config.timeframe.start_timestamp).subtract(PRESET_DICT[range], 'hours').startOf('day'),
            moment(config.timeframe.end_timestamp).subtract(PRESET_DICT[range], 'hours').endOf('day'),
        ];
    }

    return range;
};
