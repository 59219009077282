import React from 'react';
import { useParams } from 'react-router-dom';

import { collectorStateMachine, CollectorStates, ICollector, updateCollector } from '../shared/collectorApis';
import { errorMessage, successMessage } from '../../../../../general/toast-service';
import { ISettingsPathParams } from '../../../Settings';
import { UiModal } from '../../../../ui-modal/UiModal';

export interface IEnableOrDisableModalProps {
    onClose: () => void;
    collectorToEnableOrDisable: ICollector;
    setUpdateListDelay: (delay: number) => void;
}

export const CollectorEnableOrDisableModal = ({
    collectorToEnableOrDisable: collector,
    onClose,
    setUpdateListDelay,
}: IEnableOrDisableModalProps) => {
    const { activeOrg } = useParams<ISettingsPathParams>();

    const isDisabled = collectorStateMachine[collector.state]?.isDisableable;
    const text = isDisabled ? 'Disable' : 'Enable';

    const enableOrDisableCollector = async (collector: ICollector) => {
        try {
            await updateCollector(activeOrg, collector.sourceId, collector.id, {
                state: isDisabled ? CollectorStates.pending_disabled : CollectorStates.pending_enabled,
            });
            setUpdateListDelay(1000);
        } catch (e) {
            errorMessage(`Error: cannot ${text.toLowerCase()} collection`);
        }
    };

    return (
        <UiModal
            onCancel={onClose}
            acceptButton={{
                text: text,
                onClick: () => {
                    enableOrDisableCollector(collector);
                    onClose();
                },
            }}
            rejectButton={{ text: 'Cancel', onClick: onClose }}
            title={`${text} Collection?`}
        >
            {`${text} collection on '${collector.name}'?`}
        </UiModal>
    );
};
