import { Input } from 'antd';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAddAlertCommentMutation } from '../../api/alertApi';
import { errorMessage } from '../../general/toast-service';
import { dateStringFormat, extractErrorMessage, TimeDisplayResEnum, timeStringFormat } from '../../general/utils';
import { useOnEscOrClickOutside } from '../../hooks/useOnEscOrClickOutside';
import { IAlert } from '../../interfaces/alert.interface';
import { UiButton } from '../button/Button';
import { UiIcon } from '../icon/UiIcon';

import { UiPopover } from '../ui-popover/UiPopover';
import './CommentAddPopover.scss';

export const CommentAddPopover = ({
    children,
    alert,
    onClose,
}: {
    alert: IAlert;
    children?: ReactNode;
    onClose?: () => void;
}) => {
    const { activeOrgParam } = useParams<{ activeOrgParam: string }>();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [addComment] = useAddAlertCommentMutation();
    const popoverWrapper = useRef(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const trimmedValue = useMemo(() => value.trim(), [value]);

    const handleAddClick = () => {
        close(true);
        addComment({ tenant: activeOrgParam, alertId: alert.id, content: trimmedValue })
            .unwrap()
            .then(() => {
                onClose?.();
            })
            .catch((e) => {
                errorMessage(extractErrorMessage(e));
            });
    };

    const close = (skipExternalClose = false) => {
        setValue('');
        setOpen(false);
        if (!skipExternalClose) onClose?.();
    };

    useOnEscOrClickOutside(
        popoverWrapper,
        () => {
            if (open && !trimmedValue.length) {
                close();
            }
        },
        '',
        true
    );

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                textAreaRef?.current?.focus();
            }, 500);
        }
    }, [open]);

    const title = (
        <div className="CommentAddPopover">
            <div className="alert-popover-title-content">
                <span className="alert-popover-title">Add Comment</span>
                <UiIcon onClick={close} name="closeItem" />
            </div>
        </div>
    );

    const content = (
        <div className="CommentAddPopover">
            <div className="alert-popover-main-content">
                <div className="alert-popover-meta-content">
                    <span className="ui-title">Alert</span>
                    <div className="alert-info">
                        <UiIcon name={`alert${alert.severity}`} />
                        <span className="alert-meta-data">
                            {dateStringFormat(alert.timestamp)},{' '}
                            {timeStringFormat(alert.timestamp, TimeDisplayResEnum.SEC)} | {alert.name}
                        </span>
                    </div>
                </div>

                <div className="form-control">
                    <div className="input-metadata">
                        <span className="ui-title">Comment</span>
                        <span className="char-count">{trimmedValue.length}/500 Characters</span>
                    </div>
                    <Input.TextArea
                        ref={textAreaRef}
                        placeholder="Enter your comment here"
                        className="comment-content"
                        value={value}
                        onChange={(e) => {
                            if (e.target.value.length <= 500) {
                                setValue(e.target.value);
                            }
                        }}
                    />
                </div>
            </div>
            <div className="alert-popover-footer">
                <UiButton type="secondary" text="Cancel" onClick={close} />
                <UiButton disabled={!trimmedValue.length} onClick={handleAddClick} type="primary" text="Add" />
            </div>
        </div>
    );

    return (
        <div className="CommentAddPopover" ref={popoverWrapper}>
            <UiPopover
                closeAble={!Boolean(trimmedValue.length)}
                onClose={onClose}
                onOpenChange={setOpen}
                open={open}
                title={title}
                content={content}
            >
                {children}
            </UiPopover>
        </div>
    );
};
